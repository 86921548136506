import React from "react";
import SlideBoxDataSection from "./components/SlideBoxDataSection";
import SlideboxLeftSider from "./components/SlideboxTreeview";
import "./SlideBox.scss";
import { connect } from "react-redux";

const SlideBoxPage = ({
  isModal,
  setOpenSlidebox,
  isFolderSelected,
  setIsFolderSelected,
}) => {
  return (
    <div className="slidebar-container">
      <SlideboxLeftSider isModal={isModal} />

      <SlideBoxDataSection
        isModal={isModal}
        setOpenSlidebox={setOpenSlidebox}
        isFolderSelected={isFolderSelected}
        setIsFolderSelected={setIsFolderSelected}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.SlideBox.selectedListData,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SlideBoxPage);
