import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import showCollapsibleIcon from "./collapsible-icons/show-collapsible.svg";
import closeCollapsibleIcon from "./collapsible-icons/close-collapsible.svg";
import IconButton from "@material-ui/core/IconButton";
import UploadIcon from "../../icons/upload_btn.svg";
import Hamber from "../../icons/humber.svg";
import "./viewer-collapsible-sidebar.scss";
import { toggleSidebarAction } from "../../../../actions/global-action";
import OpenSeadragon from "../../utils/fabricjs-overlay";
import {
  getAnnotationArea,
  valuesObject,
  keysObject,
} from "../../../../utils/utils";
import { SocketContext } from "../../../../utils/serviceContext";
import ToolTip from "react-tooltip";
import AnnotationUploadPopup from "./annotation-upload-popup";
import CopyIconSuccess from "../../icons/clipboard-approve.png";
import CopyIcon from "../../icons/board-copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Notify } from "../../../../components";
import {
  getSlideHeatMapDataAction,
  updateDescriptionAction,
  resetDownloadAnnotationAction,
} from "../../actions/viewer-action";
import EditAnnoDetails from "../../icons/edit-anno-details.svg";
import SaveAnno from "../../icons/save-icon.svg";
import EditAnnoLine from "../../icons/edit-line.svg";
import Download from "../../icons/save-line.svg";
import { connect } from "react-redux";
import ImageTag from "../../../slidebox/components/SlideItems/ImageTag";
import moment from "moment";
import DefaultAvatar from "../../../../assets/icons/default-avatar.svg";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import UserProfilePopup from "../../../profile/components/UserProfilePopup";
import Can from "../../../../hoc/FullControllHoc/can";
import { showAnalysisResultInViewerAction } from "../../../aiAnalytics/actions/aiAnalytics-action";
import EyeIcon from "../../icons/eye-open.svg";
import EyeCloseIcon from "../../icons/eye-close.svg";
import { FeatureGate } from "react-feature-gate";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { sortedListData: sortedItems, requestSort, sortConfig };
};

const ViewerCollapsibleSidebar = ({
  imageMetadata,
  showCollapsibleSidebar,
  toggleSidebar,
  viewer,
  annotationDownloadUrl,
  downloadAnnotation,
  setDescriptionAction,
  viewerActivityData,
  analysisResult,
  showAnalysisResultInViewer,
  isAnalysis,
  getSlideHeatMapData,
  getAnalysisResultPending,
  resetDownloadAnnotation,
}) => {
  const socket = useContext(SocketContext);
  const annotationList = useSelector((state) => state.Viewer.annotationData);
  const uniqueSlideId = useSelector((state) => state.Viewer.slideUniqueId);
  const scaleUnit = useSelector((state) => state.Viewer.scaleUnit);
  const scaleRatio = useSelector((state) => state.Viewer.scaleRatio);
  const userDetails = useSelector((state) => state.Global.loggedUserDetails);
  const dispatch = useDispatch();
  const [editDescription, setEditDescription] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [copytoClipboard, setCopytoClipboard] = useState(false);
  const [description, setDescription] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [
    copytoClipboardAnnotationId,
    setCopytoClipboardAnnotationId,
  ] = useState(false);

  const { sortedListData, requestSort, sortConfig } = useSortableData(
    annotationList
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const [modalOpen, setModalOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const [anchorElForPopup, setAnchorElForPopup] = useState(null);

  const handleModalOpen = (
    index,
    firstName,
    lastName,
    userEmail,
    roleName,
    profile,
    userImageSrc,
    newPlacement
  ) => (event) => {
    setAnchorElForPopup(event.currentTarget);
    // setModalOpen((prev) => placement !== newPlacement || !prev);
    setModalOpen(!modalOpen);
    setPlacement(newPlacement);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (annotationDownloadUrl) {
      const host = window.location.origin;
      const newFileUrl = `${host}${annotationDownloadUrl}`;
      downloadFile(newFileUrl);
      resetDownloadAnnotation();
    }
  }, [annotationDownloadUrl]);

  useEffect(() => {
    // close sidebar
    //dispatch(toggleSidebarAction(false));
    // open collapsible sidebar contents
    const expansibleItems = document.getElementsByClassName("default_open");
    for (let i = 0; i < expansibleItems.length; i++) {
      toggleExpansible(expansibleItems[i]);
    }

    socket.getAnnotationMessageSubject().subscribe((item) => {
      if (item && item.fromSrc === "toolbar-annotation") {
        // get current active annotation
        let activeAnno = document.querySelectorAll(
          ".viewer-annotation-detail.active"
        );
        if (
          activeAnno &&
          activeAnno.length > 0 &&
          activeAnno[0].getAttribute("data-pk") == item.pk
        ) {
          return;
        }
        collapseAllAnnotations();
        let anno = document.querySelectorAll(
          `.viewer-annotation-detail[data-pk="${item.pk}"]`
        );
        if (anno && anno.length > 0) {
          toggleExpansible(anno[0]);
        }
      }
    });
  }, []);

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          // color: theme.palette.common.white,
        },
      },
      "&.MuiMenuItem-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  }))(MenuItem);

  const ButtonAttributes = {
    Name: {
      onClick: () => {
        requestSort("text");
        setAnchorEl(null);
      },
    },
    Annotator: {
      onClick: () => {
        requestSort("first_name");
        setAnchorEl(null);
      },
    },
    Type: {
      onClick: () => {
        requestSort("type");
        setAnchorEl(null);
      },
    },
    Created_At: {
      onClick: () => {
        requestSort("createdAt");
        setAnchorEl(null);
      },
    },
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadFile = (newFileUrl) => {
    let element = document.createElement("a");
    element.setAttribute("href", newFileUrl);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const toggleExpansible = (e) => {
    let el = e.target ? e.target : e;
    el.classList.toggle("active");
    let content = el.nextElementSibling;
    if (!content) {
      return false;
    }
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  };

  const collapseExpansible = (e) => {
    let el = e.target ? e.target : e;
    el.classList.remove("active");
    let content = el.nextElementSibling;
    content.style.display = "none";
  };

  const rotate = (cx, cy, x, y, angle) => {
    var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = cos * (x - cx) - sin * (y - cy) + cx,
      ny = cos * (y - cy) + sin * (x - cx) + cy;
    return [nx, ny];
  };

  const fitViewportToObject = (item) => {
    var radians = (Math.PI / 180) * item.angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      centerX = item.left + (cos * item.width) / 2,
      centerY = item.top + (sin * item.height) / 2;

    const itemXY = rotate(
      centerX,
      centerY,
      item.left,
      item.top,
      -1 * item.angle
    );
    const nVX = itemXY[0] / imageMetadata.width;
    const nVY = itemXY[1] / imageMetadata.width;
    const nVW = (item.width * item.scaleX) / imageMetadata.width;
    const nVH = (item.height * item.scaleY) / imageMetadata.width;
    const newBounds = new OpenSeadragon.Rect(
      nVX - 0.1,
      nVY - 0.1,
      nVW + 0.2,
      nVH + 0.2
    );

    viewer.viewport.fitBounds(newBounds);
    return newBounds;
  };

  const collapseAllAnnotations = () => {
    const expansibleItems = document.querySelectorAll(
      ".viewer-annotation-detail"
    );
    for (let i = 0; i < expansibleItems.length; i++) {
      collapseExpansible(expansibleItems[i]);
    }
  };

  const onAnnotationSelect = (e, item) => {
    if (!e.target.classList.contains("active")) {
      setEditDescription(false);
      collapseAllAnnotations();
    }
    toggleExpansible(e);
    if (viewer) {
      fitViewportToObject(item);
    }
  };

  const onEditAnnotation = (item) => {
    socket
      .getAnnotationMessageSubject()
      .next({ ...item, fromSrc: "collapsible-sidebar" });
  };

  const onDownloadAnnotation = (item) => {
    const { pk, unique_id } = item;
    downloadAnnotation({
      annotationId: pk,
      annotationUniqueId: unique_id,
    });
  };

  const getAnnotationType = (item) => {
    if (!item || !item.type) {
      return "";
    }

    switch (item.type) {
      case "ellipse":
        return "Ellipse";
      case "arrow":
        return "Arrow";
      case "path":
        return "Path";
      case "rect":
        return "Rectangle";
    }

    return "";
  };

  const setCopytoClipboardValue = () => {
    setCopytoClipboard(true);
    Notify({ value: "Copied to Clipboard!" });
  };

  const copyAnnotationUniqueId = () => {
    setCopytoClipboardAnnotationId(true);
    Notify({ value: "Copied to Clipboard!" });
  };

  const onEditDescription = (item) => {
    setDescription(item);
    setEditDescription(!editDescription);
  };

  const onSaveDescription = (description, slideId, uniqueId) => {
    if (editDescription) {
      setDescriptionAction({
        description: description,
        slideId: slideId,
        uniqueId: uniqueId,
      });
    }
    setEditDescription(!editDescription);
  };

  const timeDifferent = (dt2, dt1) => {
    let diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
    let min = Math.ceil(Math.round(diff / 60));
    if (min >= 60) {
      let num = min;
      let hours = num / 60;
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      let hourData =
        rhours > 1 ? `${rhours}${" "}hours` : `${rhours}${" "}hour`;
      let minData =
        rminutes > 1 ? `${rminutes}${" "}minutes` : `${rminutes}${" "}minutes`;
      return `${" "}for${" "}${hourData} and ${minData}`;
    } else {
      if (min === 0) {
        min = 1;
      }
      return min > 1
        ? `${" "}for${" "}${min}${" "}minutes`
        : `${" "}for${" "}${min}${" "}minute`;
    }
  };

  const ActivityTab = ({ activityData }) => (
    <div className="details-activity-container viewer-activity">
      {activityData.length > 0 ? (
        activityData.map((activity, index) => (
          <div key={index} className="details-activity-block">
            <span className="activity-date">
              {moment(activity.activity_start_at).format("MMM DD") +
                " " +
                moment(activity.activity_start_at).format("HH:mm")}
            </span>
            <div className="activity-details">
              <ImageTag
                onMouseOver={handleModalOpen(
                  index,
                  activity.first_name,
                  activity.last_name,
                  activity.first_name,
                  activity.last_name,
                  "",
                  DefaultAvatar,
                  "left-end"
                )}
                onMouseOut={handleModalClose}
                src={DefaultAvatar}
                className="user-profile-image"
              />
              {userDetails.userID === activity.user_id ? (
                <b>You </b>
              ) : (
                <b>{activity.first_name} </b>
              )}
              {"reviewed the slide"}
              {timeDifferent(
                activity.activity_end_at,
                activity.activity_start_at
              )}
            </div>
          </div>
        ))
      ) : (
        <span className="activity-empty">No activity record found</span>
      )}
    </div>
  );

  const showSortingOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const showHeatmapData = (heatMapdata, runId, status) => {
    getSlideHeatMapData(heatMapdata);
    showAnalysisResultInViewer(heatMapdata, runId, status, true);
  };
  return (
    <div
      id="viewer-collapsible-sidebar"
      className={`viewer-collapsible-sidebar-container viewer-sidebar-${
        showCollapsibleSidebar ? "open" : "collapsed"
      }`}
    >
      {/* <UserProfilePopup
        openModal={modalOpen}
        onClose={handleModalClose}
        userDetails={userDetails}
        isDashboard={true}
        isHeader={false}
        placement={placement}
        anchorEl={anchorElForPopup}
      /> */}
      <div className="viewer-collapsible-sidebar-header">
        {showCollapsibleSidebar && <span> SLIDE MANAGER </span>}
        <img
          onClick={toggleSidebar}
          src={
            showCollapsibleSidebar ? closeCollapsibleIcon : showCollapsibleIcon
          }
          alt="menu"
        />
      </div>

      {showCollapsibleSidebar && (
        <div className="viewer-collapsible-sidebar-content">
          <div className="expansible-item">
            <div
              className={
                isAnalysis ? "expansible-header" : "expansible-header" // default_open remove from here to initially showing collapse
              }
              onClick={toggleExpansible}
            >
              <span className="anno-details">Slide Details</span>
            </div>
            <div className="expansible-content">
              <div className="item">
                <div>Filename:</div>
                <div className="file-name">
                  {imageMetadata["aperio.Filename"]
                    ? imageMetadata["aperio.Filename"]
                    : "-"}
                </div>
              </div>

              <div className="item">
                <div>Unique Id:</div>
                <div className="file-name unique_id">
                  {uniqueSlideId && uniqueSlideId.length > 20
                    ? uniqueSlideId.substring(0, 20) + "..."
                    : uniqueSlideId}
                </div>
                <CopyToClipboard
                  text={uniqueSlideId}
                  onCopy={setCopytoClipboardValue}
                >
                  <div
                    className="code-box-slide-unique-id"
                    title="Copy to clipboard"
                  >
                    <img
                      className={copytoClipboard ? "copy-success" : ""}
                      src={copytoClipboard ? CopyIconSuccess : CopyIcon}
                    />
                  </div>
                </CopyToClipboard>
              </div>
              <div className="item">
                <div>Height:</div>
                <div className="file-name">
                  {imageMetadata.height ? imageMetadata.height : "-"}
                </div>
              </div>
              <div className="item">
                <div>Width:</div>
                <div className="file-name">
                  {imageMetadata.width ? imageMetadata.width : "-"}
                </div>
              </div>
              <div className="item">
                <div>Magnification:</div>
                <div className="file-name">
                  {imageMetadata["openslide.objective-power"]
                    ? imageMetadata["openslide.objective-power"]
                    : "-"}
                </div>
              </div>
              <div className="item">
                <div>Resolution:</div>
                <div className="file-name">
                  {imageMetadata["aperio.MPP"]
                    ? imageMetadata["aperio.MPP"]
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="expansible-item annotation-details">
            <div
              className={isAnalysis ? "expansible-header" : "expansible-header"}
              onClick={toggleExpansible}
            >
              <span className="anno-details">Annotation Details</span>
              <span></span>
              <div className="annotation-details-icons">
                <div className="anno-icons">
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    style={{ color: "#1D1D1B" }}
                  >
                    {valuesObject(ButtonAttributes).map(
                      ({ icon, type, ...rest }, index) => (
                        <StyledMenuItem
                          className={"dropdown-li"}
                          {...rest}
                          key={index}
                        >
                          {icon}
                          <ListItemText
                            primary={keysObject(ButtonAttributes)[
                              index
                            ].replace(/_/g, " ")}
                          />
                        </StyledMenuItem>
                      )
                    )}
                  </StyledMenu>
                </div>
                <div className="sort-icon" onClick={showSortingOptions}>
                  <img src={Hamber} alt="" />
                </div>
                <img
                  src={UploadIcon}
                  className="upload-annotation"
                  onClick={() => setShowUploadPopup(true)}
                  data-for="annotation-action-tooltip"
                  data-tip="Upload Annotation"
                />
              </div>
            </div>
            <div className="expansible-annotation-details">
              {sortedListData.map((item) => (
                <div key={item.pk} className="no-border">
                  <div
                    data-pk={item.pk}
                    style={{ color: item.stroke ? item.stroke : "black" }}
                    className="expansible-annotation-header header-close viewer-annotation-detail"
                    onClick={(e) => onAnnotationSelect(e, item)}
                  >
                    {item.text ? item.text : "No Description"}
                    {/* <div className="icon-set">
                      <img src={EditAnnoDetails} alt="edit-details" />
                      <img src={EditAnnoLine} alt="edit-anno" />
                      <img src={Download} alt="save-line" />
                    </div> */}
                  </div>

                  <div class="expansible-content">
                    <div className="item">
                      <div>Annotator:</div>
                      <div className="file-name">
                        {item.first_name
                          ? item.first_name + " " + item.surname
                          : "N/A"}
                      </div>
                    </div>

                    <div className="item unique-id-container">
                      <div>Unique Id:</div>
                      <div className="file-name">
                        {item.unique_id && item.unique_id.length > 20
                          ? item.unique_id.substring(0, 10) + "..."
                          : item.unique_id}
                        <CopyToClipboard
                          text={item.unique_id}
                          onCopy={copyAnnotationUniqueId}
                        >
                          <div
                            className="code-box-annotation-unique-id copy"
                            title="Copy to clipboard"
                          >
                            <img
                              className={
                                copytoClipboardAnnotationId
                                  ? "copy-success"
                                  : ""
                              }
                              src={
                                copytoClipboardAnnotationId
                                  ? CopyIconSuccess
                                  : CopyIcon
                              }
                            />
                          </div>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="item">
                      <div>Type:</div>
                      <div className="file-name">{getAnnotationType(item)}</div>
                    </div>
                    {getAnnotationArea(
                      item.type,
                      item.height,
                      item.width,
                      scaleUnit,
                      scaleRatio
                    ) && (
                      <div className="item">
                        <div>Area:</div>
                        <div className="file-name">
                          {getAnnotationArea(
                            item.type,
                            item.height,
                            item.width,
                            scaleUnit,
                            scaleRatio
                          )}
                          <sup>2</sup>
                        </div>
                      </div>
                    )}
                    <div className="item">
                      <div>Description:</div>
                      {editDescription ? (
                        <textarea
                          className="file-name"
                          rows="3"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          style={{ marginBottom: "7px" }}
                        />
                      ) : (
                        <div
                          className="file-name"
                          style={{
                            height: "auto",
                            overflow: "auto",
                          }}
                        >
                          {item.description}
                        </div>
                      )}
                    </div>
                    <div className="item unique-id-container">
                      <div>Created At:</div>
                      <div className="file-name annotation-actions">
                        <span>
                          {" "}
                          {item.createdAt !== null &&
                            moment(item.createdAt).format("ll")}{" "}
                        </span>

                        <div className="item annotation-link">
                          {!editDescription ? (
                            <IconButton
                              color="default"
                              aria-label="info"
                              component="span"
                              onClick={() =>
                                onEditDescription(item.description)
                              }
                              data-for="annotation-action-tooltip"
                              data-tip="Edit Description"
                            >
                              <img src={EditAnnoDetails} />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="#0b569f"
                              aria-label="info"
                              component="span"
                              onClick={() =>
                                onSaveDescription(
                                  description,
                                  item.slide_id,
                                  item.unique_id
                                )
                              }
                              data-for="annotation-action-tooltip"
                              data-tip="Save Description"
                              style={{ backgroundColor: "#e8f0fe" }}
                            >
                              <img src={SaveAnno} />
                            </IconButton>
                          )}
                          {(userDetails.userID === item.user_id ||
                            !item.first_name) && (
                            <IconButton
                              color="default"
                              aria-label="info"
                              component="span"
                              onClick={() => onEditAnnotation(item)}
                              data-for="annotation-action-tooltip"
                              data-tip="Edit Annotation"
                            >
                              <img src={EditAnnoLine} />
                            </IconButton>
                          )}
                          <IconButton
                            color="default"
                            aria-label="info"
                            component="span"
                            onClick={() => onDownloadAnnotation(item)}
                            data-for="annotation-action-tooltip"
                            data-tip="Download Annotation"
                          >
                            <img src={Download} />
                          </IconButton>
                          <ToolTip
                            id="annotation-action-tooltip"
                            place="bottom"
                            type="dark"
                            effect="solid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Can
            taskContainer="viewer"
            task="activity-log"
            edit={() => (
              <div className="expansible-item slide-activity">
                <div
                  className={
                    isAnalysis ? "expansible-header" : "expansible-header"
                  }
                  onClick={toggleExpansible}
                >
                  <span className="anno-details">Slide review log</span>
                </div>
                <div class="expansible-content">
                  <ActivityTab
                    activityData={viewerActivityData.filter(
                      (dataSet) => dataSet.activity_end_at
                    )}
                  />
                </div>
              </div>
            )}
          />
          <FeatureGate feature="analysisSection">
            <Can
              taskContainer="viewer"
              task="activity-log"
              edit={() => (
                <div className="expansible-item slide-activity">
                  <div
                    className={"expansible-header"}
                    onClick={toggleExpansible}
                  >
                    <span className="anno-details">Slide analysis result</span>
                  </div>
                  <div class="expansible-content">
                    {analysisResult &&
                      analysisResult.length > 0 &&
                      analysisResult
                        .filter(
                          (filterObj) => filterObj.fabricPoints.length > 0
                        )
                        .map((resultObj, index) => (
                          <div className="analysis-result">
                            <span>
                              {resultObj.alg_id === 1
                                ? `Nuclei Segmentation ${index + 1}`
                                : `Orion AI ${index + 1}`}
                            </span>
                            {resultObj.selected && (
                              <img
                                src={EyeIcon}
                                className="view-icon"
                                onClick={() =>
                                  resultObj.alg_id === 1
                                    ? showAnalysisResultInViewer(
                                        resultObj.fabricPoints,
                                        resultObj.runId,
                                        false
                                      )
                                    : showHeatmapData(
                                        resultObj.fabricPoints,
                                        resultObj.runId,
                                        false
                                      )
                                }
                              />
                            )}
                            {!resultObj.selected && (
                              <img
                                src={EyeCloseIcon}
                                className="view-icon"
                                onClick={() =>
                                  resultObj.alg_id === 1
                                    ? showAnalysisResultInViewer(
                                        resultObj.fabricPoints,
                                        resultObj.runId,
                                        true
                                      )
                                    : showHeatmapData(
                                        resultObj.fabricPoints,
                                        resultObj.runId,
                                        true
                                      )
                                }
                              />
                            )}
                          </div>
                        ))}
                    {getAnalysisResultPending && <span>Loading...</span>}
                  </div>
                </div>
              )}
            />
          </FeatureGate>

          {showUploadPopup && (
            <AnnotationUploadPopup onClose={() => setShowUploadPopup(false)} />
          )}
        </div>
      )}
    </div>
  );
};

ViewerCollapsibleSidebar.propTypes = {
  imageMetadata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  viewerActivityData: state.Viewer.viewerActivityData,
  analysisResult: state.AIAnalytics.analysisResult,
  getAnalysisResultPending: state.AIAnalytics.getAnalysisResultPending,
});

const mapDispatchToProps = (dispatch) => ({
  setDescriptionAction: (payload, actions) =>
    dispatch(updateDescriptionAction(payload, actions)),
  showAnalysisResultInViewer: (payload, runId, visible, isHeatmap) =>
    dispatch(
      showAnalysisResultInViewerAction(payload, runId, visible, isHeatmap)
    ),
  getSlideHeatMapData: (payload) =>
    dispatch(getSlideHeatMapDataAction(payload)),
  resetDownloadAnnotation: () => dispatch(resetDownloadAnnotationAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewerCollapsibleSidebar);
