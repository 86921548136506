import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
const { Panel } = Collapse;
import "../../AnalyticsDashboard.scss";
import "antd/dist/antd.css";
import {
  getSlideboxSlideDataAction,
  getViewerAnnotationDataAction,
  getSlideboxCaseDataAction,
  getSlideboxNewAndCompletedCaseDataAction,
  getCaseSummaryAction,
} from "../../actions/adminDashboard-action";
import { connect } from "react-redux";
import ViewerAnnotationLineChart from "./LineChart/ViewerAnnotationLineChart";
import SlidesUploadedBarChart from "./BarGraph/SlidesUploadedBarChart";
import CaseStatusSummaryPieChart from "./PieChart/CaseStatusSummaryPieChart";
import ProductivityBarChart from "./ProductivityChart/ProductivityBarChart";
import AverageTimeStatusChart from "./StackedBarChart/AverageTimeStatusChart";
import CaseAgingSummary from "./StackedBarChart/CaseAgingSummary";

const AnalyticsDashboard = ({
  viewerAnnotationData,
  slideData,
  getSlideData,
  getViewerAnnotationData,
  getSlideboxCaseData,
  getSlideboxCaseStatusData,
  getCaseStatusSummary,
  caseStatusSummaryData,
}) => {
  const [expandIconPosition, setExpandIconPosition] = useState("right");

  useEffect(() => {
    getSlideData("month");
  }, []);

  useEffect(() => {
    getViewerAnnotationData("month");
  }, []);

  useEffect(() => {
    getSlideboxCaseData();
    getSlideboxCaseStatusData("month");
  }, []);
  useEffect(() => {
    getCaseStatusSummary();
  }, []);
  return (
    <div className="analytics-dashboard-container">
      <Collapse
        defaultActiveKey={["1", "2"]}
        expandIconPosition={expandIconPosition}
      >
        <Panel header="Usage Analytics" key="1" className="accordion-heading">
          <div className="usage-container">
            <div className="slides-uploaded-chart">
              <SlidesUploadedBarChart
                width={500}
                height={300}
                top={10}
                bottom={30}
                left={30}
                right={0}
                data={slideData}
                getSlideData={getSlideData}
              />
            </div>
            <div className="annotations-created-chart">
              <ViewerAnnotationLineChart
                viewerAnnotationData={viewerAnnotationData}
              />
            </div>
          </div>
        </Panel>
        <Panel
          header="Productivity Analytics"
          className="productivity-chart-main-section"
          key="2"
        >
          <div className="productivity-analytics-section">
            <div className="first-section">
              <CaseAgingSummary />
              <ProductivityBarChart
                width={500}
                height={270}
                top={10}
                bottom={30}
                left={30}
                right={0}
                data={slideData}
                getSlideData={getSlideData}
              />
            </div>
            <div className="second-section">
              {caseStatusSummaryData && (
                <CaseStatusSummaryPieChart
                  caseStatusSummaryData={caseStatusSummaryData}
                />
              )}
              <div className="bar-chart">
                <AverageTimeStatusChart />
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
  slideData: state.Dashboard.slideData,
  viewerAnnotationData: state.Dashboard.viewerAnnotationData,
  caseStatusSummaryData: state.Dashboard.caseSummary,
});

const mapDispatchToProps = (dispatch) => ({
  getSlideData: (payload) => dispatch(getSlideboxSlideDataAction(payload)),
  getViewerAnnotationData: (payload) =>
    dispatch(getViewerAnnotationDataAction(payload)),
  getSlideboxCaseData: () => dispatch(getSlideboxCaseDataAction()),
  getSlideboxCaseStatusData: (payload) =>
    dispatch(getSlideboxNewAndCompletedCaseDataAction(payload)),
  getCaseStatusSummary: () => dispatch(getCaseSummaryAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDashboard);
