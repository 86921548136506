import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DefaultAvatar from "../../../../assets/icons/default-avatar.svg";

import ImageTag from "../../../slidebox/components/SlideItems/ImageTag";
import Loader from "../../../slidebox/components/SlideTable/Loader";
import EmptyMessage from "../../../slidebox/components/SlideTable/EmptyMessage";
import DashboardTableMoreOption from "./DashboardTableMoreOption";
import UserProfilePopup from "../../../profile/components/UserProfilePopup";
import { connect } from "react-redux";
import Can from "./../../../../hoc/FullControllHoc/can";

const useStyles = makeStyles({
  container: {
    height: '55vh !important',

  },
  table: {
    minWidth: 650,
    width: "93%",
    overflowY: 'scroll !important'
  },
  tableHead: {
    textAlign: 'center'
  },
  arrowIcon: {
    color: "#6a6e72",
    fontSize: 18,
    marginLeft: 5,
  },
});

const DashboardTable = ({
  showLoader,
  isPopUpOpen,
  filterData,
  loggedUserEmail,
  loggedUserRoleId,
  blink,
  setBlink,
  ...rest
}) => {
  const classes = useStyles();
  const [nameSorting, setNameSorting] = React.useState(true);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();

  const handleModalOpen = (
    index,
    firstName,
    lastName,
    userEmail,
    roleName,
    profile,
    userImageSrc,
    newPlacement
  ) => (event) => {
    setAnchorEl(event.currentTarget);
    setUserDetails({
      firstName,
      lastName,
      userEmail,
      roleName,
      profile,
      userImageSrc,
    });
    // setModalOpen((prev) => placement !== newPlacement || !prev);
    setModalOpen(!modalOpen);
    setPlacement(newPlacement);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  React.useEffect(() => {
    !isPopUpOpen && setSelectedRowIndex("");
  }, [isPopUpOpen]);

  return showLoader ? (
    <Loader />
  ) : filterData.length ? (
    <div className="user-list-container">
      <TableContainer className={classes.container + "table-scroll"} elevation={0} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <span className="user-list-name">Name</span>
                <span
                  onClick={() => {
                    setBlink(true);
                    setNameSorting(!nameSorting);
                    setTimeout(() => setBlink(false), 500);
                  }}
                >
                  <i
                    className={`fas fa-arrow-up ${nameSorting ? "" : " arrow-down"
                      } arrow-icon`}
                  />
                </span>
              </TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Account Status</TableCell>
              <TableCell
                align="left"
                className="dashboard-table-more-options"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={blink ? "blink-user-list-table" : ""}>
            {filterData
              .sort((a, b) =>
                nameSorting
                  ? a.firstName.localeCompare(b.firstName)
                  : b.firstName.localeCompare(a.firstName)
              )
              .map(
                (
                  {
                    profile,
                    firstName,
                    lastName,
                    userEmail,
                    status,
                    invitationExpiryAt,
                    roleName,
                    roleId,
                    userImageSrc,
                  },
                  index
                ) => {
                  let expired = false;
                  if (invitationExpiryAt) {
                    expired = new Date() >= new Date(invitationExpiryAt);
                  }
                  return (
                    <TableRow
                      key={index}
                      className={`${selectedRowIndex === index ? "table-row-selected" : ""
                        } ${status === "deactivated"
                          ? "table-row-deactivated"
                          : " "
                        }`}
                    >
                      <TableCell>
                        <div className="table-user-container">
                          <div className="table-user-details">
                            <div className="user-profile-image">
                              <ImageTag
                                src={
                                  userImageSrc !== null
                                    ? userImageSrc
                                    : DefaultAvatar
                                }
                                className="user-profile-image"
                                onMouseOver={handleModalOpen(
                                  index,
                                  firstName,
                                  lastName,
                                  userEmail,
                                  roleName,
                                  profile,
                                  userImageSrc,
                                  "left-end"
                                )}
                                onMouseOut={handleModalClose}
                              />
                            </div>
                            <div>
                              <div
                                className={status === "active" ? "user-name" : ""}
                              >
                                {firstName + " " + lastName}
                              </div>
                              <div className="user-email">{userEmail}</div>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="center" className="role-capitalize">
                        {roleName}
                      </TableCell>
                      <TableCell align="center" className="role-capitalize">
                        {status === "invited"
                          ? expired
                            ? "Invitation expired"
                            : status
                          : status}
                      </TableCell>
                      <TableCell>
                        {userEmail === loggedUserEmail ? (
                          ""
                        ) : loggedUserRoleId !== 4 &&
                          (roleId === 1 || roleId === 4) ? (
                              ""
                            ) : (
                              <Can
                                task={"manage-members"}
                                taskContainer="dashboard"
                                edit={() => (
                                  <DashboardTableMoreOption
                                    {...{
                                      index,
                                      selectedRowIndex,
                                      setSelectedRowIndex,
                                      ...rest,
                                    }}
                                    userList={filterData[index]}
                                  />
                                )}
                              />
                            )}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <UserProfilePopup
        openModal={modalOpen}
        onClose={handleModalClose}
        userDetails={userDetails}
        isDashboard={true}
        isHeader={false}
        placement={placement}
        anchorEl={anchorEl}
      />
    </div >
  ) : (
        <div class="dashboard-empty-message">
          <EmptyMessage mainMessage="No users found" />
        </div>
      );
};

const mapStateToProps = (state) => ({
  loggedUserEmail: state.Global.loggedUserDetails.userEmail,
  loggedUserRoleId: state.Global.loggedUserDetails.roleId,
});

export default connect(mapStateToProps)(DashboardTable);
