import React, { Fragment, useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import SendIcon from "../icons/send-icon.svg";
import attachmentIcon from "../icons/attachment.svg";
import emojiIcon from "../icons/emoji.svg";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { setFile } from "../../../pages/groups/actions/groups-action";
import ChatFile from "./chat-file";

const MessageBar = ({ send, text, setText, group, file, dispatch }) => {
  const [showPicker, setShowPicker] = useState(false);
  const inputRef = useRef(null);
  const fileRef = useRef(null);
  const checkEnter = (e) => {
    if (e.key === "Enter") {
      send();
    }
  };
  const onSend = () => {
    send();
    setShowPicker(false);
  };
  const checkFile = () => {
    if (!file) fileRef.current.click();
    else dispatch(setFile);
  };

  return (
    <Fragment>
      <div className="message-bar">
        {showPicker && (
          <Picker
            onSelect={(emoji) => setText(text + emoji.native)}
            ispopup="true"
          />
        )}
        <div className="input-container">
          <input
            placeholder="Send a message..."
            value={text}
            ref={inputRef}
            onChange={() => setText(inputRef.current.value)}
            type="text"
            onKeyPress={checkEnter}
          />
          <div className="action-group">
            <input
              type="file"
              accept="*"
              hidden
              ref={fileRef}
              onChange={() => dispatch(setFile(fileRef.current.files[0]))}
            ></input>
            <img src={attachmentIcon} onClick={checkFile} />
            <img src={emojiIcon} onClick={() => setShowPicker(!showPicker)} />
          </div>
        </div>
        <img src={SendIcon} alt="send" className="send-btn" onClick={onSend} />
      </div>
      {file && <ChatFile fileRef={fileRef} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const { selectedBoard: group, file } = state.Groups;
  return {
    group,
    file,
  };
};

export default connect(mapStateToProps)(MessageBar);
