import React, { Component } from "react";
import AttachItem from "./components/SlideUpload/AttachItem";
import axios from "../../utils/axiosInstance";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dropzone } from "../../components";
import { Notify } from "../../components";
import StorageAlert from "./components/SlideUpload/StorageAlert";
import Tooltip from "react-tooltip";
import {
  showSnackBarComponent,
  setFileUploadProgress,
  setFileCanceller,
  resetSlideUploadState,
} from "./actions/slidebox-action";
import { getUserDetailsAction } from "../../actions/global-action";
import { getNotificationsAction } from "../../pages/notifications/actions/notification-actions";
import { ComplexImageTypes } from "../../../config/constants";

import "./SlideBox-upload.scss";

import UploadIcon from "../../assets/icons/upload.svg";
import DocumentIcon from "../../assets/icons/documents2.svg";
import ArrowLeftIcon from "../../assets/icons/arrow_left.svg";

class SlideBoxUpload extends Component {
  state = {
    files: [],
    fileEdit: false,
    changedFileName: null,
    agreeTerms: false,
    fileSavingS3Path: null,
    uplodFileIndex: null,
    fileNameExits: false,
    errorCount: 0,
    defaultSelectedStain: "H&E",
    showMoveFilePopup: false,
    storageLimitExceed: false,
    storageIsExceedPopup: false,
  };

  componentDidMount() {
    const { parentFiles } = this.props;
    if (parentFiles) {
      let temp = this.normalizeFile(parentFiles);
      this.setState({ files: temp });
    }
    if (this.props.fileList.length === 0) {
      this.props.resetUploadState();
    }
  }

  openMoveFile = () => {
    this.setState({
      showMoveFilePopup: true,
    });
  };

  closeMoveFile = () => {
    this.setState({ showMoveFilePopup: false });
  };

  normalizeFile = (files) => {
    let tempFiles = [];
    let newFiles;

    for (let i = 0; i < files.length; i++) {
      const nameSplitted = files[i].name.split(".");
      const extention = nameSplitted[nameSplitted.length - 1];
      const isComplexImage = this.checkImageIsComplex(extention);
      if (isComplexImage) {
        files[i]["checked"] = true;
        tempFiles[i] = files[i];
      }
    }
    if (this.props.selectedFolderData.selectedFolderType !== "case") {
      newFiles = tempFiles.filter((fileData) => {
        const nameSplitted = fileData.name.split(".");
        if (
          nameSplitted[nameSplitted.length - 1] !== "doc" &&
          nameSplitted[nameSplitted.length - 1] !== "docx" &&
          nameSplitted[nameSplitted.length - 1] !== "pdf"
        ) {
          return true;
        } else {
          Notify({ value: "Documents cannot be uploaded to Folder" });
        }
      });
    } else {
      newFiles = tempFiles;
    }
    return newFiles;
  };

  checkImageIsComplex = (imgtype) => {
    return ComplexImageTypes.some((type) => type === imgtype.toLowerCase());
  };

  onUploadFile = async (event) => {
    const files = this.state.files;
    const target = event.target;
    await this.setState({
      files: [...files, ...this.normalizeFile(target.files)],
      errorCount: 0,
    });
    this.checkStorageLimitExceed(this.state.files);
  };

  onDropzonUploadFile = async (dropzoneFiles) => {
    const files = this.state.files;
    await this.setState({
      files: [...files, ...this.normalizeFile(dropzoneFiles)],
      errorCount: 0,
    });
    this.checkStorageLimitExceed(this.state.files);
  };

  toggleUploadFile = (listOfFiles) => {
    const dxAccountId = this.props.loggedUserDetails.dxAccountId;
    const rootPath = "slidebox/" + dxAccountId + "/";
    const rootPathDocument = "documents/" + dxAccountId + "/";
    const filesLength = listOfFiles.length;
    const fileSavingS3Path = rootPath;
    this.setState({ fileSavingS3Path: fileSavingS3Path });
    listOfFiles.forEach((file) => (file.uploadedAt = Date.now()));
    if (listOfFiles.length > 0) {
      listOfFiles.forEach((fileData, index) => {
        const fileSplitedArray = fileData.originalFileName.split(".");
        const fileExtention = fileSplitedArray[fileSplitedArray.length - 1];
        fileData["extention"] = fileExtention;
        const uploadingIndex = index + this.props.fileList.length; // fileIndex is used to update store
        let filePath;
        if (
          fileExtention === "docx" ||
          fileExtention === "doc" ||
          fileExtention === "pdf"
        ) {
          filePath = rootPathDocument;
        } else {
          filePath = rootPath;
        }
        axios
          .get("api/slidebox/getPresignedPutUrl", {
            params: {
              fileName: filePath + fileData.originalFileName,
              fileType: fileData.type,
            },
          })
          .then((response) => {
            this.uploadToS3Direct(
              response.data,
              fileData,
              uploadingIndex,
              filesLength
            );
            this.props.getLoggedUserDetails();
          })
          .catch((e) => console.warn(e));
      });
      this.props.showSnackBarComponent({
        show: true,
        fileList: listOfFiles,
        conversionComplete: null,
        percentCompleted: null,
      });
    } else {
      Notify({ value: "Please select the image" });
    }
  };

  uploadToS3Direct = (data, file, fileIndex, filesLength) => {
    const fileData = new FormData();
    Object.keys(data.params).forEach((key) => {
      if (key === "content-type") {
        fileData.append(key, "");
      } else {
        fileData.append(key, data.params[key]);
      }
    });
    fileData.append("file", file);
    let config = {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.props.setFileUploadProgress({ fileIndex, percentCompleted });
      },
      cancelToken: new axios.CancelToken((cancel) => {
        this.props.setFileCanceller({ fileIndex, canceller: cancel });
      }),
    };
    axios.post(data.endpoint_url, fileData, config).then((response) => {
      this.handleOnUploadToDataBase(response, file, filesLength);
      this.props.getLoggedUserDetails();
    });
  };

  handleOnUploadToDataBase = async (response, file, filesLength) => {
    let fileIndex = this.state.uplodFileIndex
      ? this.state.uplodFileIndex + 1
      : 1;
    await this.setState({ uplodFileIndex: fileIndex });
    try {
      let data = {
        originalFileName: file.originalFileName,
        fileName: file.name,
        uniquePath: "slidebox",
        isLargeFile: file.size > 20971520,
        fileIndex: fileIndex,
        stain:
          typeof file.stain === "undefined"
            ? this.state.defaultSelectedStain
            : file.stain,
        filesLength,
        selectedFolderId: this.props.selectedFolder,
        selectedFolderType: this.props.selectedFolderType,
      };

      let fileUploadUrl;
      if (
        file.extention === "docx" ||
        file.extention === "doc" ||
        file.extention === "pdf"
      ) {
        fileUploadUrl = "api/slidebox/addSlideboxDocuments";
      } else {
        fileUploadUrl = "api/slidebox/uploadFiles";
      }

      axios
        .post(fileUploadUrl, data)
        .then((response) => {
          if (response.data.msg === "success") {
            if (filesLength === fileIndex) {
              this.props.history.push("/", {
                folderSelected: true,
                folderPath: this.state.fileSavingS3Path,
              });
            }
            this.props.getLoggedUserDetails();
          }
        })
        .catch((e) => console.warn(e));
    } catch (error) {
      Notify({ value: "Please select the image" });
    }
  };

  onChangeCheck = (index) => {
    const files = this.state.files;
    const tempFile = _.merge({}, files[index], {
      checked: !files[index].checked,
    });
    files[index] = tempFile;
    this.setState({ files: files, errorCount: 0 });
  };

  onRemoveFile = async (index) => {
    const files = this.state.files;
    if (files[index]) {
      files.splice(index, 1);
    }
    await this.setState({ files: files, errorCount: 0 });
    this.checkStorageLimitExceed(this.state.files);
  };

  handleOnUpload = (response, folderPath) => {
    if (response) {
      this.props.history.push("/", {
        folderSelected: true,
        folderPath: folderPath,
      });
      this.props.getLoggedUserDetails();
    }
  };

  goBack = () => {
    this.props.history.push("/");
  };

  fileRenameInit = (file, index) => {
    this.setState({
      changedFileName: file.name,
      fileEdit: true,
      selectedFileToRename: index,
    });
  };

  fileNameChangeDone = (file, editFileIndex, changedFileNameState) => {
    const modifyFile = new File([file], changedFileNameState, {
      type: file.type,
    });
    modifyFile["checked"] = true;
    const filesList = this.state.files;
    const modifiedFilesList = filesList.map((data, index) => {
      if (index === editFileIndex && changedFileNameState) {
        return modifyFile;
      } else {
        return data;
      }
    });
    this.setState({ files: modifiedFilesList, fileEdit: false, errorCount: 0 });
  };

  handleChangeagreeTerms = () => {
    this.setState({ agreeTerms: !this.state.agreeTerms });
  };

  showWarning = (event) => {
    event.preventDefault();
    Notify({ value: "Please check the agreement!!" });
  };

  onClickUpload = (event) => {
    if (!this.state.agreeTerms || this.state.fileExits)
      return this.showWarning(event);
    if (!this.state.storageLimitExceed) {
      const files = this.state.files;
      const filesToBeUploaded = files.filter((file) => file.checked);
      this.toggleUploadFile(filesToBeUploaded);
      this.props.getLoggedUserDetails();
    } else {
      this.setState({ storageIsExceedPopup: true });
    }
  };

  setFileNameStateError = (status) => {
    const errorCount = status
      ? this.state.errorCount + 1
      : this.state.errorCount == 0
      ? 0
      : this.state.errorCount - 1;
    this.setState({
      fileNameExits: status,
      errorCount: errorCount,
    });
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  checkStorageLimitExceed = (files) => {
    const { storageLimit, storageUsed } = this.props.loggedUserDetails;
    const currentUploadSize = files.reduce((a, file) => a + file.size, 0);
    const totalCurrentFilesSize = currentUploadSize + storageUsed;

    if (totalCurrentFilesSize > storageLimit) {
      this.setState({ storageLimitExceed: true });
      return true;
    } else {
      this.setState({ storageLimitExceed: false });
      return false;
    }
  };

  closeStorageExceedPopup = () => {
    this.setState({ storageIsExceedPopup: false });
  };

  componentDidUpdate() {
    if (this.props.conversionComplete === "Uploaded") {
      this.props.getNotificationList();
    }
  }

  render() {
    const { files, changedFileName, agreeTerms, errorCount } = this.state;
    return (
      <div className="slidebox-upload-page" data-test="uploadFileContainer">
        <div className="slidebox-upload-header">
          <div className="upload-header">
            <button
              onClick={this.goBack}
              className="back-arrow"
              data-test="gobackButton"
            >
              <img src={ArrowLeftIcon} />
            </button>
            <span data-test="uploadToFolder">Upload to folder: </span>
            <span>
              {this.props.selectedFolder && this.props.selectedFolderName
                ? this.props.selectedFolderName
                : "My SlideBox"}
            </span>
          </div>
          <p className="slidebox-parahgraph" data-test="slidebox-paragraph">
            Please add any slides you would like to use on Crosscope
          </p>
        </div>
        <div className="slidebox-upload-container">
          <div className="upload-content" id="upload-content-area">
            <div className="container">
              <div className="upload-area">
                <div className="upload-area-border">
                  <div className="drag-and-drop-wrapper">
                    <img
                      src={UploadIcon}
                      alt="drag and drop"
                      data-test="uploadIcon"
                    />
                    <Dropzone setDragAndDropFiles={this.onDropzonUploadFile} />
                  </div>
                  <div className="input-file-wrapper">
                    <button>CHOOSE FILES</button>
                    <input
                      multiple
                      onChange={this.onUploadFile}
                      type="file"
                      data-test="upload-file"
                    />
                  </div>
                </div>
              </div>
              <div className="select-item-header">
                {files.length > 0 && (
                  <React.Fragment>
                    <span>File name</span>
                    <span>Stain</span>
                  </React.Fragment>
                )}
              </div>
              <div className="list-items" id="uploadedFilesListContainer">
                {files.map((file, index) => (
                  <AttachItem
                    changedFileName={changedFileName}
                    key={index}
                    file={file}
                    index={index}
                    fileNameEditDone={this.fileNameChangeDone}
                    onChangeCheck={this.onChangeCheck}
                    onRemoveFile={this.onRemoveFile}
                    userId={this.props.id}
                    uploadFileNameError={this.setFileNameStateError}
                  />
                ))}
              </div>
              <div className="submit-wrapper">
                <div className="agree-terms">
                  <input
                    id="agreeToTermsOfServiceCheckBox"
                    type="checkbox"
                    className="s_terms"
                    checked={this.state.agreeTerms}
                    onChange={this.handleChangeagreeTerms}
                  />
                  <p>
                    <a
                      className="term-agreement-link"
                      target="_blank"
                      href="https://crosscope.com/terms-conditions/"
                    >
                      I agree to the Terms of Service
                    </a>
                  </p>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <button onClick={this.goBack} className="back-button">
                    BACK
                  </button>
                  <button
                    data-test="uploadSlideButton"
                    id="uploadSlideButton"
                    onClick={this.onClickUpload}
                    className="upload-button"
                    disabled={
                      !agreeTerms || errorCount > 0 || files.length === 0
                    }
                  >
                    UPLOAD
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="upload-instruction">
            <div className="upload-instruction-header">
              <img src={DocumentIcon} alt="DocumentIcon" />
              <span className="supportHeader">SUPPORTED FILE FORMATS</span>
            </div>
            <ul className="list">
              <li>Non-proprietary (JPG, PNG)</li>
              <li>Aperio (SVS, TIF)</li>
              <li>Leica (SCN)</li>
              <li>Hamamatus (NDPI, VMS)</li>
              <li>
                {" "}
                <a
                  data-for="upload-slidebox-tooltip"
                  data-tip="Upload as ZIP archive"
                  className="dash-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  3D Histech
                </a>
              </li>
              <li>Ventana (BIF)</li>
              <li>Philips (TIFF)</li>
              {this.props.selectedFolderData.selectedFolderType === "case" && (
                <li>Documents (PDF, DOCX)</li>
              )}
              <li className="">
                <a
                  data-for="upload-slidebox-tooltip"
                  data-tip="Upload as ZIP archive"
                  className="dash-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MRXS
                </a>
              </li>
            </ul>
          </div>
        </div>
        {this.state.storageIsExceedPopup && (
          <StorageAlert
            acceptMessage={this.closeStorageExceedPopup}
            closeStorageAlert={this.closeStorageExceedPopup}
          />
        )}
        <Tooltip
          id="upload-slidebox-tooltip"
          place="top"
          type="dark"
          effect="solid"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedUserDetails: state.Global.loggedUserDetails,
  fileList: state.SlideBox.fileList,
  folders: state.SlideBox.folders,
  selectedFolderData: state.SlideBox.selectedFolderData,
  conversionComplete: state.SlideBox.conversionComplete,
  ...state.SlideBox.selectedFolderData,
});

const mapDispatchToProps = (dispatch) => ({
  showSnackBarComponent: (payload) => dispatch(showSnackBarComponent(payload)),
  setFileUploadProgress: (payload) => dispatch(setFileUploadProgress(payload)),
  setFileCanceller: (payload) => dispatch(setFileCanceller(payload)),
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
  resetUploadState: () => dispatch(resetSlideUploadState()),
  getNotificationList: () => dispatch(getNotificationsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SlideBoxUpload));
