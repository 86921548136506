import React from 'react';
import { ContextMenu } from '../../../../components';

const SlideBreadcrumb = ({
  openDirData,
  toggleDirHandler,
  userDetails,
  isSearch,
  deleteDirItemPopup,
  showSharedPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  isModal,
  importSlide,
}) => {
  return (
    <ul class='breadcrumb-continer' data-test='breadcrumb-continer'>
      {isSearch ? (
        <React.Fragment>
          <li>
            <span className='breadcrumb-nav'>Search Result</span>
          </li>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {openDirData.length > 0 &&
            openDirData.map((dirData, index) => {
              return (
                <li
                  key={index}
                  id={
                    dirData.dirName === 'Slidebox' ? 'breadcrumbSlideBox' : ''
                  }
                >
                  {index !== openDirData.length - 1 && (
                    <span
                      className={
                        dirData.parentDir
                          ? 'breadcrumb-nav breadcrumb-child'
                          : 'breadcrumb-nav'
                      }
                      onClick={() =>
                        toggleDirHandler({
                          dirId: dirData.dirId,
                          parentDir: dirData.parentDir,
                          dirType: dirData.caseOrFolder,
                        })
                      }
                      data-test='Slidebox'
                    >
                      {dirData.dirName}
                    </span>
                  )}
                  {index === openDirData.length - 1 && (
                    <span
                      id={
                        dirData.parentDir
                          ? 'last-breadcrum-item'
                          : 'root-breadcrum-item'
                      }
                      className={
                        dirData.parentDir
                          ? 'breadcrumb-nav breadcrumb-child'
                          : 'breadcrumb-nav'
                      }
                      data-test='breadcrumb-child'
                    >
                      {dirData.dirName}
                    </span>
                  )}
                </li>
              );
            })}
        </React.Fragment>
      )}
      {!importSlide && (
        <ContextMenu
          domElement={'table-padding'}
          showSharedPopup={showSharedPopup}
          deleteDirItemPopup={deleteDirItemPopup}
          userDetails={userDetails}
          renameSlideItem={renameSlideItem}
          handleToggleMovePopup={handleToggleMovePopup}
          setShowDetails={setShowDetails}
          rightClickFrom={'breadcrumb'}
          isModal={isModal}
          data-test='contextMenu'
        />
      )}
    </ul>
  );
};

export default SlideBreadcrumb;
