import React from 'react';
import UploadIcon from '../../../../assets/icons/upload-slide.svg';
import { NavLink } from 'react-router-dom';
import ToolTip from 'react-tooltip';
import Can from '../../../../hoc/FullControllHoc/can';

const UploadButton = ({ slideboxLeftSiderIsCollapse, showUploadButton }) => {
  return (
    <Can
      data-test="uploadComponent"
      task={'create-case'}
      taskContainer="slidebox"
      edit={() => (
        <div
          data-test="divContainer"
          className={
            showUploadButton === 'false'
              ? !slideboxLeftSiderIsCollapse
                ? 'upload-container disable-btn-create'
                : 'slideBoxButton-container disable-btn-create'
              : !slideboxLeftSiderIsCollapse
                ? 'upload-container'
                : 'slideBoxButton-container'
          }
        >
          <NavLink to="/slidebox/upload">
            <button
              type="button"
              id="uploadFilesToDirectoryButton"
              className={
                !slideboxLeftSiderIsCollapse
                  ? 'upload-icon-collapse'
                  : 'btn-upload'
              }
              data-for="viewer-tooltip"
              data-tip="Upload"
              data-tip-disable={slideboxLeftSiderIsCollapse}
              disabled={showUploadButton === 'false'}
            >
              <img src={UploadIcon} className="upload-icon" />
              {slideboxLeftSiderIsCollapse && <span>Upload</span>}
            </button>
          </NavLink>
          <ToolTip
            id="viewer-tooltip"
            place="bottom"
            type="dark"
            effect="solid"
          />
        </div>
      )}
    />
  );
};

export default UploadButton;
