import React from "react";
import { FadeIn } from "animate-components";
import RevokeIcon from '../../../../assets/icons/revoke.svg'
import {
    Overlay,
    ModalHeader,
    PrimaryButton,
    SecondaryButton,
} from "../../../../components";

const DeleteUserInvitationConfirm = (
    {
        onClose, deleteUserInvitation,
        deleteInvitationFn,
    }) => {
    const { userID } = deleteUserInvitation;
    const label = "Revoke";

    const handleInvitationDeleteClick = () => {
        deleteInvitationFn({ userID })
        onClose(false)
    }

    return (
        <React.Fragment>
            <Overlay />
            <div className="prompt">
                <FadeIn duration="300ms">
                    <ModalHeader
                        title={label + " invitation?"}
                        onClick={() => onClose(false)}
                        img={RevokeIcon}
                        alt={'revoke'}
                    />
                    <div className="modal_middle">
                        <div className="deactivate-info">
                            <p>What happens when an account is deleted?</p>
                            <ul>
                                <li>The member will no longer be able to register in to workspace.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="prompt-bottom" style={{ marginTop: 0 }}>
                        <SecondaryButton label="Cancel" onClick={() => onClose(false)} />
                        <PrimaryButton
                            label={label}
                            onClick={handleInvitationDeleteClick}
                            extraClass="prompt-done"
                        />
                    </div>
                </FadeIn>
            </div>
        </React.Fragment>
    );
};

export default DeleteUserInvitationConfirm;
