import {
  addNewUserApi,
  activateOrDeactivateUserApi,
  dashboardUserListApi,
  searchUserListApi,
  updateDxUserRoleApi,
  resendUserInvitationApi,
  revokeUserInvitationApi,
  getSlideboxNewAndCompletedCaseDataApi,
  getSlideboxSlideDataApi,
  getViewerAnnotationDataApi,
  getSlideboxCaseDataApi,
  getCaseSummaryApi,
} from "../services/adminDashboard-service";

const setUserList = (userList) => {
  return {
    type: "SET_USER_LIST",
    payload: userList,
  };
};

export const getUserListDataAction = () => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    const response = await dashboardUserListApi();
    if (response.data.success) {
      dispatch(setUserList(response.data.body));
    }
    dispatch(showLoader(false));
  } catch (error) {}
};

const showLoader = (show) => {
  return {
    type: "SHOW_DASHBOARD_LOADER",
    showLoader: show,
  };
};

const addNewUserInviation = (data, msg) => {
  return {
    type: "ADD_NEW_USER_INVITATION",
    payload: data,
    message: msg,
  };
};

const showMessage = (message) => {
  return {
    type: "SHOW_MESSAGE",
    message,
  };
};

const errorMessageAction = (message) => {
  return {
    type: "ERROR_MESSAGE",
    message,
  };
};

const setCaseData = (data) => {
  return {
    type: "SET_CASE_DATA",
    payload: data,
  };
};

const setCaseSummary = (data) => {
  return {
    type: "SET_CASE_SUMMARY",
    payload: data,
  };
};

const setCaseStatusData = (data) => {
  return {
    type: "SET_CASE_STATUS_DATA",
    payload: data,
  };
};

const setSlideData = (data) => {
  return {
    type: "SET_SLIDE_DATA",
    payload: data,
  };
};

const setViewerAnnotationData = (data) => {
  return {
    type: "SET_VIEWER_ANNOTATION_DATA",
    payload: data,
  };
};

export const addNewUserAction = (data) => async (dispatch) => {
  try {
    const response = await addNewUserApi(data);
    if (response.data.success) {
      dispatch(
        addNewUserInviation(response.data.body, "Invitation email sent")
      );
    } else {
      dispatch(
        errorMessageAction(
          response.data.message
            ? response.data.message
            : "Error while adding new user"
        )
      );
    }
  } catch (error) {
    dispatch(errorMessageAction("Error while adding new user"));
  }
};

export const resetMessageAction = () => {
  return {
    type: "RESET_MESSAGE",
  };
};

const revokeUserInvitation = (userID) => {
  return {
    type: "REVOKE_USER_INVITATION",
    payload: userID,
  };
};

export const activateOrDeactivateUserAction = ({ data, isActive }) => async (
  dispatch
) => {
  const successMessage = isActive ? "Account activated" : "Account deactivated";
  const errorMessage = isActive ? "activating account" : "deactivating account";
  try {
    const response = await activateOrDeactivateUserApi(data);
    if (response.data.success) {
      dispatch(showMessage(successMessage));
      dispatch({
        type: "ACTIVATE_OR_DEACTIVATE_USER",
        message: successMessage,
        data,
      });
    } else {
      dispatch(errorMessageAction(`Error while ${errorMessage}`));
    }
  } catch (error) {
    dispatch(errorMessageAction(`Error while ${errorMessage}`));
  }
};

export const resendInvitationAction = ({ userID }) => async (dispatch) => {
  const successMessage = "Invitation email sent";
  const errorMessage = "Invitation email sent failed";
  try {
    const response = await resendUserInvitationApi({ userId: userID });
    if (response.data.success) {
      dispatch({
        type: "SHOW_MESSAGE",
        message: successMessage,
        data,
      });
      dispatch(getUserListDataAction());
    } else {
      dispatch(errorMessageAction(`Error while ${errorMessage}`));
    }
  } catch (error) {
    dispatch(errorMessageAction(`Error while ${errorMessage}`));
  }
};

export const revokeInvitationAction = ({ userID }) => async (dispatch) => {
  const successMessage = "Invitation revoked successfully";
  const errorMessage = "Invitation revoke failed";
  try {
    const response = await revokeUserInvitationApi({ userId: userID });
    if (response.data.success) {
      dispatch(revokeUserInvitation(userID));
      dispatch({
        type: "SHOW_MESSAGE",
        message: successMessage,
      });
    } else {
      dispatch(errorMessageAction(`Error while ${errorMessage}`));
    }
  } catch (error) {
    dispatch(errorMessageAction(`Error while ${errorMessage}`));
  }
};

const searchList = (userList) => {
  return {
    type: "DASHBOARD_SEARCH_LIST",
    payload: userList,
  };
};

export const searchDashboardUserListAction = (data) => async (dispatch) => {
  try {
    dispatch(showLoader(true));
    const response = await searchUserListApi(data);
    if (response.data.success) {
      dispatch(searchList(response.data.body));
    }
    dispatch(showLoader(false));
  } catch (error) {}
};

export const changeDxUserRoleAction = (data) => async (dispatch) => {
  try {
    const response = await updateDxUserRoleApi(data);
    if (response.data.success) {
      dispatch(showMessage("Role changed"));
      dispatch({
        type: "CHANGE_DX_USER_ROLE_TYPE",
        data,
      });
    } else {
      dispatch(errorMessage("Error while changing role"));
    }
  } catch (error) {}
};

export const getSlideboxCaseDataAction = () => async (dispatch) => {
  try {
    const response = await getSlideboxCaseDataApi();
    if (response.data.success) {
      dispatch(setCaseData(response.data.data));
    }
  } catch (error) {}
};

export const getSlideboxNewAndCompletedCaseDataAction = (data) => async (
  dispatch
) => {
  try {
    const response = await getSlideboxNewAndCompletedCaseDataApi(data);
    if (response.data.success) {
      dispatch(setCaseStatusData(response.data.data));
    }
  } catch (error) {}
};

export const getSlideboxSlideDataAction = (data) => async (dispatch) => {
  try {
    const response = await getSlideboxSlideDataApi(data);
    if (response.data.data) {
      dispatch(setSlideData(response.data.data));
    }
  } catch (error) {}
};

export const getViewerAnnotationDataAction = (data) => async (dispatch) => {
  try {
    const response = await getViewerAnnotationDataApi(data);
    if (response.data.success) {
      dispatch(setViewerAnnotationData(response.data.data));
    }
  } catch (error) {}
};

export const getCaseSummaryAction = () => async (dispatch) => {
  try {
    const response = await getCaseSummaryApi();
    if (response.data.success) {
      dispatch(setCaseSummary(response.data.data));
    }
  } catch (error) {}
};
