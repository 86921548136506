import React from "react";
import { TextField } from "@material-ui/core";
import { PrimaryButton, SecondaryButton } from "../../../components";
import { FadeIn } from "animate-components";
import { getAnnotationPopupPosition } from "../../../utils/utils";
import { useState } from "react";

const AnnotationEditPopup = ({
  onClose,
  setAnnoatationName,
  handleSaveClick,
  currentAnnotationName,
  currentAnnotationData,
}) => {
  const getPositionStyle = () => {
    if (
      !currentAnnotationData ||
      Object.keys(currentAnnotationData).length === 0 ||
      !currentAnnotationData.oCoords
    ) {
      return {
        zIndex: "50",
      };
    }

    let position = getAnnotationPopupPosition(
      currentAnnotationData.oCoords,
      250,
      107
    );

    return {
      ...position,
      zIndex: "50",
    };
  };

  const [annoName, setAnnoName] = useState(currentAnnotationName);
  const handleAnnotationName = (e) => {
    setAnnoatationName(e.target.value);
    setAnnoName(e.target.value)
  }

  return (
    <React.Fragment>
      <div
        className="prompt annotation-edit-popup"
        style={getPositionStyle()}
      >
        <FadeIn duration="300ms">
          <div className="modal_middle" style={{ height: "50px" }}>
            <div className="modal_content" style={{ marginTop: "15px" }}>
              <TextField
                InputProps={{
                  style: { fontSize: "20px" },
                }}
                value={currentAnnotationName ? currentAnnotationName : ""}
                style={{ width: "100%", padding: "0 20px" }}
                onChange={(e) => handleAnnotationName(e)}
              />
            </div>
          </div>
          <div
            className="prompt-bottom"
            style={{
              marginTop: 0,
              justifyContent: "center",
              padding: "10px 20px !important",
            }}
          >
            <SecondaryButton
              extraClass="annotation-btn"
              label="Cancel"
              onClick={() => onClose(false)}
            />
            <PrimaryButton
              label="Save"
              disabled={!annoName}
              style={{ margin: "0 0 0 20px" }}
              onClick={handleSaveClick}
              extraClass="annotation-btn"
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

export default AnnotationEditPopup;
