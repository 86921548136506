import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import moment from "moment";

const XAxis = ({ top, bottom, left, right, height, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current).call(d3.axisBottom(scale));
  }, [scale]);

  return (
    <g
      className="axis x"
      ref={axis}
      transform={`translate(${left}, ${height - bottom})`}
      style={{ fontSize: 14, fontFamily: "Roboto, sans-serif" }}
    />
  );
};

const YAxis = ({ top, bottom, left, right, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current).call(d3.axisLeft(scale).ticks(7));
  }, [scale]);

  return (
    <g
      className="axis y"
      ref={axis}
      transform={`translate(${left}, ${top})`}
      style={{ fontSize: 14, fontFamily: "Roboto, sans-serif" }}
    />
  );
};

const SlidesUploadedBarChart = (props) => {
  const { data, getSlideData, height, width } = props;
  const [sort, setSort] = useState(false);
  const [monthSelected, setMonthSelected] = useState(true);
  const [weekSelected, setWeekSelected] = useState(false);
  const [daySelected, setDaySelected] = useState(false);
  const [slideData, setSlideData] = useState(data);
  const axis = useRef(null);

  const Rect = ({ data, x, y, height, top, bottom }) => {
    return (
      <g transform={`translate(${x(data.name)}, ${y(data.value)})`}>
        <rect
          width={10}
          height={height - bottom - top - y(data.value)}
          fill={"#FFB200"}
          transform={weekSelected ? `translate(60,0)` : `translate(20,0)`}
          rx={0}
          ry={0}
        >
          <title> {data.value} </title>
        </rect>
      </g>
    );
  };

  const displaySlidesUploaded = (type) => {
    if (type === "week") {
      setWeekSelected(true);
      setMonthSelected(false);
      setDaySelected(false);
    } else if (type === "month") {
      setMonthSelected(true);
      setWeekSelected(false);
      setDaySelected(false);
    } else if (type === "day") {
      setMonthSelected(false);
      setWeekSelected(false);
      setDaySelected(true);
    }
    getSlideData(type);
  };

  const x = d3
    .scaleBand()
    .range([0, props.width - props.left - props.right])
    .domain(slideData.map((d) => d.name))
    .padding(-0.1);

  const y = d3
    .scaleLinear()
    .range([props.height - props.top - props.bottom, 0])
    .domain([0, d3.max(slideData, (d) => d.value)]);

  useEffect(() => {
    setSlideData(data);
  }, [data]);

  useEffect(() => {
    d3.select(axis.current)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -20)
      .attr("x", -120)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("letter-spacing", "1px")
      .attr("fill", "black")
      .style("font-size", "14px")
      .style("font-family", "Roboto, sans-serif")
      .text("Number of Slides Uploaded");
  }, []);
  return (
    <React.Fragment>
      <div className="chart-header">
        <span class="chart-heading">Slides Uploaded</span>

        <div className="button-section">
          <div className={`button-container ${daySelected ? `selected` : ""}`}>
            <span
              className="chart-button"
              onClick={() => displaySlidesUploaded("day")}
            >
              D
            </span>
          </div>
          <div
            className={`button-container ${weekSelected ? `selected` : ""}`}
            onClick={() => displaySlidesUploaded("week")}
          >
            <span className="chart-button">W</span>
          </div>
          <div
            className={`button-container ${monthSelected ? `selected` : ""}`}
            onClick={() => displaySlidesUploaded("month")}
          >
            <span className="chart-button">M</span>
          </div>
        </div>
      </div>
      <span className="date" style={{ marginLeft: 18 }}>
        {moment().format("DD MMMM YYYY")}
      </span>

      <svg
        width="100%"
        height={props.height}
        viewBox="60 -20 350 400"
        fontSize="14px"
        ref={axis}
        style={{ marginTop: 60 }}
      >
        <XAxis
          scale={x}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
          height={props.height}
        />
        <YAxis
          scale={y}
          top={props.top}
          bottom={props.bottom}
          left={props.left}
          right={props.right}
          height={props.height}
        />
        <g transform={`translate(${props.left}, ${props.top})`}>
          {slideData.map((d, i) => (
            <Rect
              data={d}
              x={x}
              y={y}
              top={props.top}
              bottom={props.bottom}
              height={props.height}
            />
          ))}
        </g>
      </svg>
    </React.Fragment>
  );
};

export default SlidesUploadedBarChart;
