import React, { useState, useEffect, useRef } from "react";
import SlideboxFilter from "../SlideboxFilter";
import AdvancedSearch from "./AdvancedSearch";
import closeIcon from "../../../assets/icons/close-new.svg";
import SearchIcon from "../../../assets/icons/search-icon.svg";
import NewFilters from "./SlideboxFilters";

const SlideboxSearch = ({
  getSearchDirData,
  loadSlideboxDirDataList,
  folderType,
  selectedFolderId,
  setFilteredData,
  memberDetails,
  slideDirectory,
  stainType,
  isModal,
  label,
  setLabel,
  setRadioButtonChecked,
}) => {
  const [search, setSearch] = useState("");
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const myNameRef = useRef("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setShowCloseIcon(true);
      getSearchDirData({ search: event.target.value });
      setRadioButtonChecked({});
    }
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
    setLabel(event.target.value);
  };
  useEffect(() => {
    if (label) {
      myNameRef.current.value = label
      setShowCloseIcon(true);

    }
  });

  const handleSearch = () => {
    setShowCloseIcon(true);
    getSearchDirData({ search: search });
  };

  return (
    <div class="slidebox-search" data-test="slidebox-search">
      <div
        className="filter-i-input"
        style={{ position: "relative", width: "100%" }}
      >
        <input
          className="filter-input filter-slidebox"
          type="text"
          value={search}
          placeholder="Search..."
          onChange={(e) => handleChange(e)}
          onKeyDown={handleKeyDown}
          ref={myNameRef}
        />
        <img
          src={SearchIcon}
          className="searchIcon"
          alt="search"
          onClick={() => handleSearch()}
        />
        {showCloseIcon && (
          <span
            className="search-close-icon"
            onClick={() => {
              setShowCloseIcon(false);
              setSearch("");
              setLabel("");
              loadSlideboxDirDataList({
                dirId: selectedFolderId,
                dirType: folderType,
              });
            }}
          >
            <img src={closeIcon} alt="" />
          </span>
        )}

        {!isModal && (
          <React.Fragment>
            <NewFilters
              memberDetails={memberDetails}
              slideDirectory={slideDirectory}
              setFilteredData={setFilteredData}
              getSearchDirData={getSearchDirData}
              search={search}
              loadSlideboxDirDataList={loadSlideboxDirDataList}
              dirType={folderType}
              selectedFolderId={selectedFolderId}
              stainType={stainType}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default SlideboxSearch;
