import axios from "../../../utils/axiosInstance";

export const dashboardUserListApi = async () => {
  try {
    return await axios.get(`/api/user/dxAccountUsers`);
  } catch (err) {
    throw err;
  }
};

export const slideBoxFileApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      `/api/slidebox/listS3Objects"`,
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxStorageDetailsApi = async () => {
  try {
    const resData = await axios.post("/api/slidebox/getSlideBoxStorageDetails");
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxCreateDirApi = async (dirType, dataParams) => {
  try {
    let endPoint = null;
    if (dirType === "folder") {
      endPoint = "createFolder";
    } else {
      endPoint = "createCase";
    }
    const resData = await axios.post(`/api/slidebox/${endPoint}`, {
      ...dataParams,
      dirType
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxRenameDirApi = async (dirType, dataParams) => {
  try {
    let endPoint = null;
    if (dirType === "folder") {
      endPoint = "renameFolder";
    }
    if (dirType === "case") {
      endPoint = "renameCase";
    }
    if (dirType === "slide") {
      endPoint = "renameSlide";
    }
    if (dirType === "document") {
      endPoint = "renameDocument";
    }

    const resData = await axios.post(`/api/slidebox/${endPoint}`, dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxLoadDirApi = async () => {
  try {
    const resData = await axios.get("/api/slidebox/getSlideBoxDirectoryList");
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxLoadDirDataListApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/slidebox/getSlideBoxDirDataList", {
      params: dataParams
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDeleteDirApi = async (dirType, dataParams) => {
  try {
    let endPoint = null;
    if (dirType === "folder") {
      endPoint = "deleteFolder";
    }
    if (dirType === "case") {
      endPoint = "deleteCase";
    }
    if (dirType === "slide") {
      endPoint = "deleteSlide";
    }
    if (dirType === "document") {
      endPoint = "deleteDocument";
    }

    const resData = await axios.post(`/api/slidebox/${endPoint}`, dataParams);
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxSearchDirApi = async (searchData) => {
  try {
    const resData = await axios.get(
      "/api/slidebox/getSlideBoxSearchDirDataList",
      {
        params: searchData
      }
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDirDescriptionUpdateApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/updateSlideDirDetails",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxLabelsUpdateApi = async (dataParams) => {
  console.log(dataParams);
  try {
    const resData = await axios.post(
      "/api/slidebox/addLables",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxMoveDirApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/moveDirectories",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDirCollaboratorsApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/slidebox/getDirCollaborators", {
      params: dataParams
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDirAssignUsersApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/slidebox/getDirAssignUsers", {
      params: dataParams
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxDirActivityApi = async (dataParams) => {
  try {
    const resData = await axios.get("/api/slidebox/slideboxActivity", {
      params: dataParams
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxCollaboratorsApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/addDirCollaborators",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxRemoveCollaboratorsApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/removeDirCollaborators",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxAssignUserToCaseApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/assignUserToCase",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxRemoveAssignUserToCaseApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/removeAssignUserToCase",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getCaseStatusApi = async () => {
  try {
    const resData = await axios.get("/api/slidebox/getCaseReviewStatus");
    return resData;
  } catch (error) {
    throw error;
  }
};

export const updateCaseStatusApi = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/slidebox/updateCaseReviewStatus",
      dataParams
    );
    return resData;
  } catch (error) {
    throw error;
  }
};

export const slideboxAdvancedSearchApi = (dataParams) => {
  try {
    const resData = axios.post(
      "/api/slidebox/getAdvancedSearchDetails",
      dataParams
    );
    return resData;
  } catch (err) {
    //console.log(err);
  }
};

export const getStainTypeApi = () => {
  try {
    const resData = axios.get("/api/slidebox/getStainType");
    return resData;
  } catch (err) {
    throw err;
  }
};

export const slideBoxFileDownloadApi = (dataParams) => {
  try {
    const resData = axios.get("/api/slidebox/getFileDownloadUrl", {
      params: dataParams
    });
    return resData;
  } catch (err) {
    throw err;
  }
};

export const getSlidesInfoApi = (dataParams) => {
  try {
    const resData = axios.get("/api/slidebox/getSlidesInfo", {
      params: dataParams
    });
    return resData;
  } catch (err) {
    throw err;
  }
};
