import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import { Notify } from "../../../../components";
import { connect } from "react-redux";
import moment from "moment";
import FolderDirInactiveIcon from "../../../../assets/icons/folder-dir-close.png";
import CaseDirInActiveIcon from "../../../../assets/icons/case-dir-close.png";
import DocumentActiveIcon from "../../../../assets/icons/doc-single.png";
import {
  loadSlideboxDirDataList,
  setOpenListDirectory,
  updateSlideBoxSlideDetails,
  updateSlideboxLabels,
  updateCaseStatusAction,
  getAllSildesAction,
} from "../../actions/slidebox-action.js";
import DetailClose from "../../../../assets/icons/close-new.svg";
import EmptyDetail from "../../../../assets/icons/empty-detail.svg";
import InfoEdit from "../../../../assets/icons/info-edit.svg";
import Save from "../../../../assets/icons/save.svg";
import demo from "../../../../assets/icons/folder-dir-open.png";
import { SingleSelect, SecondaryButton } from "../../../../components";
import { bytesToSize } from "../../../../utils/utils";
import ImageTag from "./ImageTag";
import { withRouter } from "react-router";
import CopyIconSuccess from "../../../../assets/icons/clipboard-approve.png";
import DefaultAvatar from "../../../../assets/icons/default-avatar.svg";
import CopyIcon from "../../../../assets/icons/clipboard.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import UserProfilePopup from "../../../profile/components/UserProfilePopup";
import StainCard from "../../../../components/Select/StainCard";
import ToolTip from "react-tooltip";
import Can from "../../../../hoc/FullControllHoc/can";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FeatureGate } from "react-feature-gate";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
    slidesToSlide: 3,
  },
};

const drawerWidth = 450;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    zIndex: 2,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },

  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 110,
    zIndex: 1,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  sidebarHeading: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    height: 50,
    backgroundColor: "#ECE7F8",
    width: 448,
  },
  folderIcon: {
    marginRight: 20,
  },
  img: {
    maxWidth: 30,
  },
  title: {
    marginBottom: 0,
    cursor: "pointer",
    "&:hover": {
      fontWeight: "bold",
      textDecoration: "underline",
      color: "#1e0191",
    },
    fontSize: "18px",
  },
  closeIcon: {
    marginLeft: "auto",
    opacity: 0.8,
    cursor: "pointer",
    "&:hover": {
      opacity: 1,
    },
  },
  imageInfoIcon: {
    borderTop: "1px solid #DDD",
    borderBottom: "1px solid #DDD",
    padding: 20,
  },
  imageInfoIconHeight: {
    maxWidth: 100,
    maxHeight: 100,
    display: "table",
    margin: [[0, "auto"]],
  },
  emptyDetailContainer: {
    margin: [[0, "auto"]],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyDetailIcon: {
    padding: 20,
    opacity: 0.6,
  },
  formControl: {
    "& .MuiInputBase-root": {
      fontSize: 13,
      fontWeight: 700,
    },
    minWidth: 120,
  },
  tabs: {
    minWidth: "145px !important",
  },
}));

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const getFileType = (fileName) => {
  const fileExtention = fileName.split(".")[1];
  const fileType =
    fileExtention === "jpg" ||
    fileExtention === "jpeg" ||
    fileExtention === "png"
      ? "Static"
      : "WSI";

  return fileType;
};

const Details = ({
  showDetails,
  setShowDetails,
  selectedDetails = {},
  updateSlideBoxDirDetails,
  updateLabels,
  history,
  loadSlideboxDirDataList,
  setOpenListDirectory,
  slideDirectory,
  selectedDirActivities,
  userDetails,
  updateCaseStatus,
  getStainTypeAction,
  selectedCaseAssignUsers,
  setSelectedDetails,
  allSildeinfo,
  getAllSildes,
  selectedFolderType,
  selectedFolder,
}) => {
  const classes = useStyles();
  const [copytoClipboard, setCopytoClipboard] = useState(false);
  const [editing, setEditing] = useState(false);
  const [description, setDescription] = useState(null);
  const [notes, setNotes] = useState(null);
  const [caseReviewStatus, setCaseReviewStatus] = useState("");
  const [stainType, setStainType] = useState("");
  const stainRef = React.useRef(null);
  const [searching, setSearching] = useState("");
  const [results, setResults] = useState([]);
  const [done, setDone] = useState(true);
  const [timeout, setTimeoutState] = useState(undefined);
  const [stainSelect, setStainSelect] = useState([]);
  const [labels, setLabels] = useState([]);
  const [tabOption, setTabOption] = useState("info");
  const [noteEdit, setNoteEdit] = useState(false);
  const [labelEdit, setLabelEdit] = useState(false);
  const toggleCaseInfoRef = useRef();
  const toggleLabelsRef = useRef();
  const toggleNotesRef = useRef();

  const handleTabChange = (tab) => {
    setTabOption(tab);
  };

  useEffect(() => {
    const { dirDataId, caseOrFolder } = selectedDetails;
    dirDataId &&
      getAllSildes({
        dirId:
          selectedDetails.caseOrFolder === "slide" ? selectedFolder : dirDataId,
        dirType:
          selectedDetails.caseOrFolder === "slide"
            ? selectedFolderType
            : caseOrFolder,
      });
  }, [selectedDetails, showDetails]);

  useEffect(() => {
    setEditing(false);
    if (typeof selectedDetails.description !== "undefined") {
      setDescription(selectedDetails.description);
    }
  }, [selectedDetails, setDescription]);

  useEffect(() => {
    setNoteEdit(false);
    if (typeof selectedDetails.notes !== "undefined") {
      setNotes(selectedDetails.notes);
    }
  }, [selectedDetails, setNotes]);

  useEffect(() => {
    if (typeof selectedDetails.review_status !== "undefined") {
      setCaseReviewStatus(selectedDetails.review_status);
    }
    setStainType(selectedDetails.stainType);
  }, [selectedDetails, selectedDetails.stainType]);

  useEffect(() => {
    setResults([]);
    setDone(false);
    clearTimeout(timeout);
    if (searching !== "") {
      setTimeoutState(setTimeout(search, 1000));
    }
  }, [searching]);

  useEffect(() => {
    if (selectedDetails.labels) {
      setLabels(selectedDetails.labels);
    } else {
      setLabels("");
    }
  }, [selectedDetails, setLabels]);

  const onStainSelect = (stain) => {
    setStainSelect([...StainTags, stain]);
  };

  const addSelf = (stain) => {
    setStainType(stain);
    setSearching("");
    stainRef.current.value = "";
  };

  const search = () => {
    const stainList = StainTags.filter(
      (item) =>
        item.value.toLowerCase() === searching || item.value === searching
    );
    setResults(stainList);
    setDone(true);
  };

  const handleChangeCaseReviewStatus = (event) => {
    setEditing(true);
    setCaseReviewStatus(event.target.value);
  };

  const handleSelectStainChange = ({ value }) => {
    selectedDetails["stain"] = value;
    setStainType(value);
  };

  const handleSelectChange = (selectedOption) => {
    setLabels(selectedOption);
  };

  const handleSaveClick = () => {
    if (selectedDetails.caseOrFolder === "slide") {
      if (description !== selectedDetails.description) {
        updateSlideBoxDirDetails({
          dirId: selectedDetails.dirDataId,
          dirDescription: description,
          dirType: selectedDetails.caseOrFolder,
          stainType: stainType,
          notes: notes,
        });
        setSelectedDetails({ ...selectedDetails, description });
      }
      if (noteEdit) {
        updateSlideBoxDirDetails({
          dirId: selectedDetails.dirDataId,
          dirDescription: description,
          notes: notes,
          dirType: selectedDetails.caseOrFolder,
          stainType: stainType,
        });
        setSelectedDetails({ ...selectedDetails, notes });
      }
      if (stainType !== selectedDetails.stainType) {
        updateSlideBoxDirDetails({
          dirId: selectedDetails.dirDataId,
          dirDescription: description,
          notes: notes,
          dirType: selectedDetails.caseOrFolder,
          stainType: stainType,
        });
        setSelectedDetails({ ...selectedDetails, stainType });
      }

      updateLabels({
        entityId: selectedDetails.dirDataId,
        entityType: selectedDetails.caseOrFolder,
        labels: labels,
      });
      Notify({
        value: "Slide details updated",
      });
      setEditing(false);
      setLabelEdit(false);
      setNoteEdit(false);
    } else if (selectedDetails.caseOrFolder === "case") {
      updateLabels({
        entityId: selectedDetails.dirDataId,
        entityType: selectedDetails.caseOrFolder,
        labels: labels,
      });
      if (caseReviewStatus !== selectedDetails.review_status) {
        updateCaseStatus({
          dirId: selectedDetails.slidebox_case_id
            ? selectedDetails.slidebox_case_id
            : selectedDetails.dirDataId,
          reviewStatus: caseReviewStatus,
        });
        setCaseReviewStatus(caseReviewStatus);
      }

      if (description !== selectedDetails.description) {
        updateSlideBoxDirDetails({
          dirId: selectedDetails.dirDataId,
          dirDescription: description,
          dirType: selectedDetails.caseOrFolder,
        });
        setSelectedDetails({ ...selectedDetails, description });
      }

      if (noteEdit !== selectedDetails.notes) {
        updateSlideBoxDirDetails({
          dirId: selectedDetails.dirDataId,
          notes,
          dirType: selectedDetails.caseOrFolder,
        });
        setSelectedDetails({ ...selectedDetails, notes });
      }

      Notify({
        value: "Case details updated",
      });
      setEditing(false);
      setLabelEdit(false);
      setNoteEdit(false);
    } else {
      updateSlideBoxDirDetails({
        dirId: selectedDetails.dirDataId,
        dirDescription: description,
        dirType: selectedDetails.caseOrFolder,
      });
      updateLabels({
        entityId: selectedDetails.dirDataId,
        entityType: selectedDetails.caseOrFolder,
        labels: labels,
      });
      setSelectedDetails({ ...selectedDetails, description });
      Notify({
        value: "Folder details updated",
      });
      setEditing(false);
      setLabelEdit(false);
      setNoteEdit(false);
    }
  };

  const finalImage =
    selectedDetails.caseOrFolder === "folder"
      ? FolderDirInactiveIcon
      : selectedDetails.caseOrFolder === "case"
      ? CaseDirInActiveIcon
      : selectedDetails.caseOrFolder === "slide"
      ? selectedDetails.dzi_thumbnail
      : DocumentActiveIcon;

  const handleTitleClick = () => {
    if (selectedDetails.caseOrFolder === "slide") {
      history.push({
        pathname: `/viewer`,
        search: `?image=${selectedDetails.keyFolder}&thumb=${selectedDetails.thumbDataToken}`,
      });
    } else {
      const { dirDataId, parentDir, caseOrFolder } = selectedDetails;
      const folderListId = slideDirectory.filter(
        (dirData) =>
          dirData.dirId === dirDataId && dirData.caseOrFolder === caseOrFolder
      );
      loadSlideboxDirDataList({
        dirId: dirDataId,
        dirType: caseOrFolder ? caseOrFolder : "root",
      });
      setOpenListDirectory({
        dirId: dirDataId,
        listId: folderListId[0]["listId"],
        parentDir,
        dirType: caseOrFolder ? caseOrFolder : "root",
      });
    }
  };

  const setShowDetailsPopup = () => {
    setEditing(false);
    setShowDetails();
  };

  const setCopytoClipboardValue = () => {
    setCopytoClipboard(true);
    Notify({ value: "Copied to Clipboard!" });
  };

  const timeDifferent = (dt2, dt1) => {
    let diff = (new Date(dt2).getTime() - new Date(dt1).getTime()) / 1000;
    let min = Math.ceil(Math.round(diff / 60));
    if (min >= 60) {
      let num = min;
      let hours = num / 60;
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      let hourData =
        rhours > 1 ? `${rhours}${" "}hours` : `${rhours}${" "}hour`;
      let minData =
        rminutes > 1 ? `${rminutes}${" "}minutes` : `${rminutes}${" "}minutes`;
      return `${" "}for${" "}${hourData} and ${minData}`;
    } else {
      if (min === 0) {
        min = 1;
      }
      return min > 1
        ? `${" "}for${" "}${min}${" "}minutes`
        : `${" "}for${" "}${min}${" "}minute`;
    }
  };

  const ActivityTab = ({ activityData }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userDetails, setUserDetails] = React.useState({});
    const [modalOpen, setModalOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();

    const handleModalOpen = (
      index,
      firstName,
      lastName,
      userEmail,
      roleName,
      profile,
      userImageSrc,
      newPlacement
    ) => (event) => {
      setAnchorEl(event.currentTarget);
      setUserDetails({
        firstName,
        lastName,
        userEmail,
        roleName,
        profile,
        userImageSrc,
      });
      setModalOpen(!modalOpen);
      setPlacement(newPlacement);
    };

    const handleModalClose = () => {
      setModalOpen(false);
    };

    return (
      <div className="details-activity-container">
        {activityData.length > 0 ? (
          activityData.map((activity, index) => (
            <React.Fragment>
              {activity.slidebox_activity !== "review-slide" && (
                <div key={index} className="details-activity-block">
                  <UserProfilePopup
                    openModal={modalOpen}
                    onClose={handleModalClose}
                    userDetails={userDetails}
                    isDashboard={true}
                    placement={placement}
                    anchorEl={anchorEl}
                  />
                  <span className="activity-date">
                    {moment(activity.activity_end_at).format("lll")}
                  </span>
                  <div className="activity-details">
                    <ImageTag
                      onMouseOver={handleModalOpen(
                        index,
                        activity.first_name,
                        activity.last_name,
                        activity.userEmail,
                        activity.userRole,
                        "",
                        activity.userImageSrc,
                        "left-end"
                      )}
                      onMouseOut={handleModalClose}
                      src={
                        activity.userImageSrc
                          ? activity.userImageSrc
                          : DefaultAvatar
                      }
                      className="user-profile-image"
                    />
                    {userDetails.userID === activity.user_id ? (
                      <b>You </b>
                    ) : (
                      <b>
                        {activity.first_name} {activity.last_name}{" "}
                      </b>
                    )}
                    {activity.entity_type === "status"
                      ? `updated the case status to ${activity.slidebox_activity}`
                      : activity.slidebox_activity === "create-case"
                      ? "created this case"
                      : activity.slidebox_activity === "create-folder"
                      ? "created this folder"
                      : activity.slidebox_activity === "rename-case"
                      ? "renamed this case"
                      : activity.slidebox_activity === "rename-folder"
                      ? "renamed this folder"
                      : activity.slidebox_activity === "assign-case" &&
                        "assigned the case to"}
                    {activity.other_user_data ? (
                      <b>
                        {" "}
                        {activity.other_user_data.first_name}{" "}
                        {activity.other_user_data.last_name}
                      </b>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))
        ) : (
          <span className="activity-empty">No activity record found</span>
        )}
      </div>
    );
  };

  const CaseDetailsTab = ({ selectedDetails }) => {
    return (
      <div className="case-details-container">
        <div className="details-section">
          <div className="info-details-section">
            <div className="expansible-item">
              <div
                onClick={toggleExpansible}
                className="expansible-header default_open heading-font"
              >
                <span className="file-name">Patient Details</span>
              </div>
              <div class="expansible-content">
                <div className="item">
                  <div className="slide-item-heading">
                    {selectedDetails.caseOrFolder}&nbsp;Name:
                  </div>
                  <div className="item-value" id="slideUniqueIdContainer">
                    {selectedDetails.dirDataName}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="info-details-section">
            <div className="expansible-item">
              <div
                onClick={toggleExpansible}
                className="expansible-header default_open heading-font"
              >
                <span className="file-name">Case History</span>
              </div>
            </div>
          </div>
          <div className="info-details-section">
            <div className="expansible-item">
              <div
                onClick={toggleExpansible}
                className="expansible-header default_open heading-font"
              >
                <span className="file-name">Microscopic Description</span>
              </div>
            </div>
          </div>
          <div className="info-details-section">
            <div className="expansible-item">
              <div
                onClick={toggleExpansible}
                className="expansible-header default_open heading-font"
              >
                <span className="file-name">Tumour Description</span>
              </div>
            </div>
          </div>
          <div className="info-details-section">
            <div className="expansible-item">
              <div
                onClick={toggleExpansible}
                className="expansible-header default_open heading-font"
              >
                <span className="file-name">Name & Signature</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Slides = () => {
    return allSildeinfo.length > 0 ? (
      <Carousel responsive={responsive}>
        {allSildeinfo.map((slide, index) => (
          <div className="carousel-slide" key={index}>
            <img src={slide.dzi_thumbnail} alt="" />
          </div>
        ))}
      </Carousel>
    ) : (
      "No Slides Available"
    );
  };

  const toggleExpansible = (e) => {
    let el = e.target ? e.target : e;
    el.classList.toggle("active");
    let content = el.nextElementSibling;
    if (!content) {
      return false;
    }
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  };

  useEffect(() => {
    const expansibleItems = document.getElementsByClassName("default_open");
    for (let i = 0; i < expansibleItems.length; i++) {
      toggleExpansible(expansibleItems[i]);
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={showDetails}
        classes={{ paper: classes.drawerPaper }}
      >
        {Object.keys(selectedDetails).length === 0 &&
        selectedDetails.constructor === Object ? (
          <div className={classes.emptyDetailContainer}>
            <div className={classes.sidebarHeading}>
              <div className={classes.folderIcon}></div>
              <div onClick={handleTitleClick}>
                <h5 className={classes.title}>{selectedDetails.dirDataName}</h5>
              </div>
              <div className={classes.closeIcon}>
                <ImageTag
                  src={DetailClose}
                  alt=""
                  className={classes.img}
                  onClick={() => {
                    setShowDetailsPopup();
                    setDescription(selectedDetails.description);
                    setCaseReviewStatus(selectedDetails.review_status);
                    setStainType(selectedDetails.stainType);
                    setNotes(selectedDetails.notes);
                  }}
                />
              </div>
            </div>
            <div className={classes.emptyDetailIcon}>
              <ImageTag
                src={EmptyDetail}
                alt=""
                className={classes.imageInfoIconHeight}
              />
            </div>
            <div>Select a file or folder to view its details</div>
          </div>
        ) : (
          <List>
            <div className={classes.sidebarHeading}>
              <div className={classes.folderIcon}>
                <ImageTag src={finalImage} alt="" className={classes.img} />
              </div>
              <div onClick={handleTitleClick}>
                <h5
                  data-for="slide-name"
                  data-tip={selectedDetails.dirDataName}
                  className={classes.title}
                >
                  {selectedDetails.dirDataName &&
                  selectedDetails.dirDataName.length > 15
                    ? selectedDetails.dirDataName.substring(0, 15) + "..."
                    : selectedDetails.dirDataName}
                </h5>
                <ToolTip
                  id="slide-name"
                  place="bottom"
                  type="dark"
                  effect="solid"
                />
              </div>
              <div className={classes.closeIcon}>
                <ImageTag
                  src={DetailClose}
                  alt=""
                  className={classes.img}
                  onClick={() => {
                    setShowDetailsPopup();
                    setDescription(selectedDetails.description);
                    setCaseReviewStatus(selectedDetails.review_status);
                    setStainType(selectedDetails.stainType);
                    setNotes(selectedDetails.notes);
                  }}
                />
              </div>
            </div>
            <div className="tabOption">
              <div
                className={tabOption === "info" ? "active tab" : "tab"}
                onClick={() => handleTabChange("info")}
              >
                <span>Info</span>
              </div>
              {selectedDetails.caseOrFolder === "case" && (
                <FeatureGate feature="infoDetails">
                  <div
                    className={tabOption === "details" ? "active tab" : "tab"}
                    onClick={() => handleTabChange("details")}
                  >
                    <span>Case Details</span>
                  </div>
                </FeatureGate>
              )}
              <div
                className={tabOption === "activity" ? "active tab" : "tab"}
                onClick={() => handleTabChange("activity")}
              >
                <span>Activity</span>
              </div>
            </div>
            {tabOption === "info" && (
              <React.Fragment>
                <div className="details-section">
                  <div className="info-details-section">
                    <div className="expansible-item">
                      <div
                        onClick={toggleExpansible}
                        className="expansible-header default_open heading-font"
                        ref={toggleCaseInfoRef}
                      >
                        <span className="file-name">
                          {selectedDetails.caseOrFolder} Information
                        </span>
                        <span>
                          <img
                            src={editing ? Save : InfoEdit}
                            alt=""
                            className="edit-icon"
                            onClick={() => {
                              !editing ? setEditing(true) : handleSaveClick();
                              !toggleCaseInfoRef.current.classList.contains(
                                "active"
                              ) && toggleCaseInfoRef.current.click();
                            }}
                          />
                        </span>
                      </div>
                      <div class="expansible-content">
                        <div className="item">
                          <div className="slide-item-heading">
                            {selectedDetails.caseOrFolder !== "case"
                              ? selectedDetails.caseOrFolder + " Name"
                              : "Case Accession"}
                          </div>
                          <div
                            className="item-value"
                            id="slideUniqueIdContainer"
                          >
                            {selectedDetails.dirDataName}
                          </div>
                        </div>
                        {selectedDetails.caseOrFolder === "case" && (
                          <React.Fragment>
                            <div className="item">
                              <div className="slide-item-heading">
                                Case Status
                              </div>
                              <div
                                className="item-value"
                                id="slideUniqueIdContainer"
                              >
                                <Can
                                  task={"update-case-status"}
                                  taskContainer="slidebox"
                                  edit={() =>
                                    userDetails.roleId !== 3 ? (
                                      <div className="information-row">
                                        <div className="information-row-content">
                                          <FormControl
                                            className={classes.formControl}
                                          >
                                            <Select
                                              labelId="demo-simple-select-helper-label"
                                              id="demo-simple-select-helper"
                                              value={
                                                caseReviewStatus
                                                  ? caseReviewStatus
                                                  : selectedDetails.review_status
                                              }
                                              onChange={(e) =>
                                                handleChangeCaseReviewStatus(e)
                                              }
                                            >
                                              <MenuItem value={"pending"}>
                                                Pending
                                              </MenuItem>
                                              <MenuItem value={"review"}>
                                                Review
                                              </MenuItem>
                                              <MenuItem value={"completed"}>
                                                Completed
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="information-row">
                                        <b>
                                          {caseReviewStatus
                                            ? capitalizeFirstLetter(
                                                caseReviewStatus
                                              )
                                            : capitalizeFirstLetter(
                                                selectedDetails.review_status
                                              )}
                                        </b>
                                      </div>
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="item">
                              <div className="slide-item-heading">
                                Assigned To
                              </div>
                              <div
                                className="item-value"
                                id="slideUniqueIdContainer"
                              >
                                <b>
                                  {selectedDetails.assignTo &&
                                  selectedDetails.assignTo.length > 0
                                    ? `${
                                        selectedDetails.assignTo[0].first_name
                                      }${" "}${
                                        selectedDetails.assignTo[0].last_name
                                      }`
                                    : null}
                                </b>
                              </div>
                            </div>
                            <div className="item">
                              <div className="slide-item-heading">
                                Number of Slides
                              </div>
                              <div
                                className="item-value"
                                id="slideUniqueIdContainer"
                              >
                                {allSildeinfo.length && allSildeinfo.length}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        {selectedDetails.caseOrFolder !== "case" && (
                          <div className="item">
                            <div className="slide-item-heading">Size</div>
                            <div
                              className="item-value"
                              id="slideUniqueIdContainer"
                            >
                              {selectedDetails.dirDataSize
                                ? bytesToSize(selectedDetails.dirDataSize)
                                : "-"}
                            </div>
                          </div>
                        )}

                        {selectedDetails.caseOrFolder === "slide" && (
                          <React.Fragment>
                            <div className="item">
                              <div className="slide-item-heading">
                                Annotation Count
                              </div>
                              <div
                                className="item-value"
                                id="slideUniqueIdContainer"
                              >
                                <b>{selectedDetails.annotationCount}</b>
                              </div>
                            </div>
                            <div className="item">
                              <div className="slide-item-heading">
                                Unique Id
                              </div>
                              <div
                                className="item-value"
                                id="slideUniqueIdContainer"
                              >
                                <b>
                                  {selectedDetails.uniqueId &&
                                  selectedDetails.uniqueId.length > 30
                                    ? selectedDetails.uniqueId.substring(
                                        0,
                                        30
                                      ) + "..."
                                    : selectedDetails.uniqueId}
                                </b>
                                <CopyToClipboard
                                  text={selectedDetails.uniqueId}
                                  onCopy={setCopytoClipboardValue}
                                >
                                  <div
                                    className="code-box-unique-id"
                                    title="Copy to clipboard"
                                  >
                                    <img
                                      className={
                                        copytoClipboard ? "copy-success" : ""
                                      }
                                      src={
                                        copytoClipboard
                                          ? CopyIconSuccess
                                          : CopyIcon
                                      }
                                    />
                                  </div>
                                </CopyToClipboard>
                              </div>
                            </div>
                            <div className="item">
                              <div className="slide-item-heading">
                                Slide Type
                              </div>
                              <div
                                className="item-value"
                                id="slideUniqueIdContainer"
                              >
                                <b>
                                  {getFileType(selectedDetails.dirDataName)}
                                </b>
                              </div>
                            </div>
                            <div className="item">
                              <div className="slide-item-heading">Stain</div>
                              <div
                                className="item-value"
                                id="slideUniqueIdContainer"
                              >
                                {editing ? (
                                  <SingleSelect
                                    classname="stain-select"
                                    defaultValue={{
                                      value: stainType,
                                      label: stainType,
                                    }}
                                    handleChange={handleSelectStainChange}
                                    type={"stains"}
                                    placeholder="Stains"
                                    customStyles={true}
                                    style={{ width: "100%", marginTop: 10 }}
                                  />
                                ) : (
                                  <b>{stainType || "-"}</b>
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        )}

                        <div className="item">
                          <div className="slide-item-heading">Description</div>
                          <div
                            className="item-value"
                            id="slideUniqueIdContainer"
                          >
                            {editing ? (
                              <textarea
                                style={{ width: "100%" }}
                                rows="3"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            ) : (
                              selectedDetails.description
                            )}
                          </div>
                        </div>
                        <div className="item">
                          <div className="slide-item-heading">Created by</div>
                          <div
                            className="item-value"
                            id="slideUniqueIdContainer"
                          >
                            <b>{`${selectedDetails.first_name}${" "}${
                              selectedDetails.last_name
                            }`}</b>
                          </div>
                        </div>
                        <div className="item">
                          <div className="slide-item-heading">Modified On</div>
                          <div
                            className="item-value"
                            id="slideUniqueIdContainer"
                          >
                            <b>
                              {moment(selectedDetails.lastModified).format(
                                "lll"
                              )}
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedDetails.caseOrFolder !== "document" && (
                    <div className="info-details-section">
                      <div className="expansible-item">
                        <div
                          onClick={toggleExpansible}
                          className="expansible-header heading-font"
                        >
                          <span className="file-name">Slides</span>
                        </div>
                        <div class="expansible-content">
                          <div className="item">
                            <div
                              className="item-value fit-width"
                              id="slideUniqueIdContainer"
                            >
                              <Slides />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedDetails.caseOrFolder !== "document" && (
                    <div className="info-details-section">
                      <div className="expansible-item">
                        <div
                          onClick={toggleExpansible}
                          className="expansible-header heading-font"
                          ref={toggleLabelsRef}
                        >
                          <span className="file-name">Labels</span>
                          <span>
                            <img
                              src={labelEdit ? Save : InfoEdit}
                              alt=""
                              className="edit-icon"
                              onClick={() => {
                                !labelEdit
                                  ? setLabelEdit(true)
                                  : handleSaveClick();
                                !toggleLabelsRef.current.classList.contains(
                                  "active"
                                ) && toggleLabelsRef.current.click();
                              }}
                            />
                          </span>
                        </div>
                        <div class="expansible-content">
                          <div className="item">
                            <div
                              className="item-value fit-width"
                              id="slideUniqueIdContainer"
                            >
                              <div className="information-row-content labels">
                                <b>
                                  {labelEdit ? (
                                    <SingleSelect
                                      defaultValue={
                                        selectedDetails.labels &&
                                        selectedDetails.labels
                                          .split(",")
                                          .map((label, index) => ({
                                            value: label,
                                            label: label,
                                          }))
                                      }
                                      options={[]}
                                      handleChange={handleSelectChange}
                                      placeholder="Add labels"
                                      customStyles={true}
                                      style={{ width: "100%", marginTop: 10 }}
                                    />
                                  ) : (
                                    <div className="lable_tags">
                                      {selectedDetails.labels &&
                                        selectedDetails.labels
                                          .split(",")
                                          .map((label, index) => (
                                            <span key={index}>{label}</span>
                                          ))}
                                    </div>
                                  )}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedDetails.caseOrFolder !== "document" && (
                    <div className="info-details-section">
                      <div className="expansible-item">
                        <div
                          onClick={toggleExpansible}
                          className="expansible-header heading-font"
                          ref={toggleNotesRef}
                        >
                          <span className="file-name">Notes</span>
                          <span>
                            <img
                              src={noteEdit ? Save : InfoEdit}
                              alt=""
                              className="edit-icon"
                              onClick={() => {
                                !noteEdit
                                  ? setNoteEdit(true)
                                  : handleSaveClick();
                                !toggleNotesRef.current.classList.contains(
                                  "active"
                                ) && toggleNotesRef.current.click();
                              }}
                            />
                          </span>
                        </div>
                        <div class="expansible-content">
                          <div className="item">
                            <div
                              className="item-value fit-width"
                              id="slideUniqueIdContainer"
                            >
                              {noteEdit ? (
                                <textarea
                                  style={{ width: "100%" }}
                                  rows="3"
                                  value={notes}
                                  onChange={(e) => setNotes(e.target.value)}
                                />
                              ) : (
                                selectedDetails.notes
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* <SecondaryButton
                  label='Cancel'
                  onClick={() => {
                    setEditing(false);
                    setDescription(selectedDetails.description);
                    setCaseReviewStatus(selectedDetails.review_status);
                    setStainType(selectedDetails.stainType);
                  }}
                /> */}
              </React.Fragment>
            )}
            {tabOption === "details" && (
              <CaseDetailsTab selectedDetails={selectedDetails} />
            )}

            {tabOption === "activity" && (
              <ActivityTab activityData={selectedDirActivities} />
            )}
          </List>
        )}
        <ToolTip id="slide-name" place="bottom" type="dark" effect="solid" />
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.folders,
  selectedDirActivities: state.SlideBox.selectedDirActivities,
  userDetails: state.Global.loggedUserDetails,
  selectedCaseAssignUsers: state.SlideBox.selectedCaseAssignUsers,
  ...state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
  allSildeinfo: state.SlideBox.allSlides,
});

const mapDispatchToProps = (dispatch) => ({
  updateSlideBoxDirDetails: (payload) =>
    dispatch(updateSlideBoxSlideDetails(payload)),
  updateLabels: (payload) => dispatch(updateSlideboxLabels(payload)),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  setOpenListDirectory: (payload) => dispatch(setOpenListDirectory(payload)),
  updateCaseStatus: (payload) => dispatch(updateCaseStatusAction(payload)),
  getAllSildes: (payload) => dispatch(getAllSildesAction(payload)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Details)
);
