import React, { useState, useRef, useEffect } from "react";
import DropdownIcon from "../../../assets/icons/dropdown-icon.svg";
import NewGroupIcon from "../../../assets/icons/new-group-icon.svg";
import ThreeDotsIcon from "../../../assets/icons/more-icon.svg";
import Can from "../../../hoc/FullControllHoc/can";

const LeftTabHeader = ({
  collapse,
  createGroupPopup,
  onChangeActiveTab,
  onChangeCollapseGroup,
  footerNav,
  loggedUserDetails,
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const createRef = useRef(null);
  const togglePopup = () => {
    let tempOpenPopup = openPopup;

    setOpenPopup(!tempOpenPopup);
  };
  const onClickNewGroup = () => {
    togglePopup();
    onChangeActiveTab("new");
    if (footerNav) {
      footerNav("middle");
    }
  };

  const handleClickOutside = (event) => {
    if (createRef.current && !createRef.current.contains(event.target)) {
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className="header">
      {collapse && (
        <React.Fragment>
          <h3>Groups</h3>
          <React.Fragment>
            <button className="rotate-button">
              <img
                src={DropdownIcon}
                alt="arrow"
                onClick={onChangeCollapseGroup}
              />
            </button>
            <Can
              task={"manage-groups"}
              taskContainer="groups"
              edit={() => (
                <button className="three-dots-btn" onClick={togglePopup}>
                  <img src={ThreeDotsIcon} alt="new group" />
                </button>
              )}
            />
          </React.Fragment>

        </React.Fragment>
      )}
      {collapse && openPopup && (
        <div
          ref={createRef}
          className="create-group-popup"
          onClick={onClickNewGroup}
        >
          <img src={NewGroupIcon} className="popup-image" alt="new group" />
          <div className="popup-content">
            <h3 className="popup-title" style={{ lineHeight: "20px" }}>
              {" "}
              New group
            </h3>
            <p className="popup-description">Chat where everyone can write</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftTabHeader;
