import React from "react";
import FolderDirectory from "./FolderDirectory";
import CaseDirectory from "./CaseDirectory";

const FolderRootDirectory = ({
  dirData,
  toggleDir,
  setSelectedDir,
  slideboxLeftSiderIsCollapse,
  setExpandDir,
}) => {
  const NestedChildTree = ({ childDirData, customClass, customRootClass }) => (
    <React.Fragment>
      <FolderDirectory
        setSelectedDir={setSelectedDir}
        toggleDir={toggleDir}
        setExpandDir={setExpandDir}
        customClass={customRootClass}
        {...childDirData}
        slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
      />
      {childDirData.open &&
        childDirData.children.map((childDir, index) => (
          <React.Fragment key={index}>
            {childDir.caseOrFolder === "folder" &&
              childDir.children.length === 0 && (
                <FolderDirectory
                  toggleDir={toggleDir}
                  setSelectedDir={setSelectedDir}
                  setExpandDir={setExpandDir}
                  customClass={customClass}
                  {...childDir}
                  slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
                />
              )}
            {childDir.caseOrFolder === "case" && (
              <CaseDirectory
                toggleDir={toggleDir}
                setSelectedDir={setSelectedDir}
                setExpandDir={setExpandDir}
                customClass={customClass}
                {...childDir}
                slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
              />
            )}
            {childDir.caseOrFolder === "folder" &&
              childDir.children.length > 0 && (
                <NestedChildTree
                  childDirData={childDir}
                  customClass="child dir-child-dir"
                  customRootClass="child dir"
                />
              )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <FolderDirectory
        setSelectedDir={setSelectedDir}
        toggleDir={toggleDir}
        setExpandDir={setExpandDir}
        {...dirData}
        slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
      />
      {dirData.open &&
        dirData.children.map((childDir, index) => (
          <React.Fragment key={index}>
            {childDir.caseOrFolder === "folder" &&
              childDir.children.length === 0 && (
                <FolderDirectory
                  toggleDir={toggleDir}
                  setSelectedDir={setSelectedDir}
                  setExpandDir={setExpandDir}
                  customClass={"child"}
                  {...childDir}
                  slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
                />
              )}
            {childDir.caseOrFolder === "case" && (
              <CaseDirectory
                toggleDir={toggleDir}
                setSelectedDir={setSelectedDir}
                setExpandDir={setExpandDir}
                customClass={"child"}
                {...childDir}
                slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
              />
            )}
            {childDir.caseOrFolder === "folder" &&
              childDir.children.length > 0 && (
                <NestedChildTree
                  childDirData={childDir}
                  customClass="child dir"
                  customRootClass="child"
                />
              )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default FolderRootDirectory;
