import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import GroupFlatList from "./components/group-flatlist";
import GroupPanel from "./components/group-panel";
import Chat from "../../components/Chat/chat";
import _ from "lodash";
import {
  setMessages,
  addTumorBoard,
  fetchUserBoards,
  setSelectedBoard,
  setSelectedMembers,
} from "./actions/groups-action";
import axios from "../../utils/axiosInstance";

import GroupListIcon from "../../assets/icons/active-groups-list.svg";
import ChatIcon from "../../assets/icons/active-chat.svg";
import ImgIcon from "../../assets/icons/active-img.svg";
import "./Groups.scss";
import { Notify } from "../../components";

const useOutsideAlerter = (ref, eventEmitter) => {
  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      eventEmitter.emit("onGroupClick", event.target);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

const Groups = ({
  isMobile,
  file,
  groups,
  publicGroups,
  selectedGroup,
  onClickEmitter,
  dispatch,
  history,
  loggedUserDetails,
}) => {
  const [activeTab, setActiveTab] = useState("");
  const [selectedGroupItem, setSelectedGroupItem] = useState({});
  const [selectedGroupItemIndex, setSelectedGroupItemIndex] = useState(-1);
  // const [groups, setGroups] = useState([])
  const [groupState, setGroupState] = useState(groups);
  const [loading, setLoading] = useState(false);
  const [creatingError, setCreatingError] = useState(null);
  const [openSlidebox, setOpenSlidebox] = useState(false);
  const [isOnPublicGroup, setIsOnPublicGroup] = useState(false);

  // handle closing popups
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClickEmitter);

  useEffect(() => {
    setGroupState(groups);
  }, [groups]);

  useEffect(() => {
    if (!selectedGroup) {
      setSelectedGroupItemIndex(-1);
      setActiveTab("");
    }
  }, [selectedGroup]);

  useEffect(() => {
    manualGetGroupDetail(isOnPublicGroup);
  }, [selectedGroupItemIndex]);

  useEffect(() => {
    if (
      history.location &&
      history.location.state &&
      typeof history.location.state.selectedGroupIndex !== "undefined"
    ) {
      const index = history.location.state.selectedGroupIndex;
      const state = { ...history.location.state };
      delete state.selectedGroupIndex;
      history.replace({ ...history.location, state });
      onSelectGroupItem(index, false, false);
    }
  }, [groupState]);

  const onSelectGroupItem = (index, isPublicGroup = false) => {
    if (
      isOnPublicGroup === isPublicGroup &&
      index === selectedGroupItemIndex &&
      index !== -1
    )
      return;
    dispatch(setMessages([]));
    if (index === -1) {
      setSelectedGroupItem({});
      setActiveTab("");
    } else {
      setIsOnPublicGroup(isPublicGroup);
      if (!isPublicGroup) {
        setSelectedGroupItem(groupState[index]);
        dispatch(setSelectedBoard(groupState[index]));
        setActiveTab("image");
      } else {
        setSelectedGroupItem(publicGroups[index]);
        dispatch(setSelectedBoard(publicGroups[index]));
        setActiveTab("image");
      }
    }
    if (index === selectedGroupItemIndex) manualGetGroupDetail(isPublicGroup);
    setSelectedGroupItemIndex(index);
  };

  const onChangeTab = (activeTab) => {
    setActiveTab(activeTab);
    // onSelectGroupItem(-1) // hide chat
  };

  const newGroupSubmit = async (data) => {
    const { name, bio, isPublic, members, file } = data;
    const membersIds = [];
    members.forEach((member) => membersIds.push(member.id));
    const formData = new FormData();
    formData.append("name", name);
    formData.append("bio", bio);
    formData.append("type", !isPublic ? "private" : "public");
    formData.append("members", JSON.stringify(membersIds));
    if (file) formData.append("img", file);
    setLoading(true);
    await axios
      .post("/api/groups", formData, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch(addTumorBoard(response.data));
          onSelectGroupItem(-1);
          Notify({ value: "Group created successfully" });
        } else {
          setCreatingError(
            "There was an error while creating your tumor board. Please try again."
          );
        }
      })
      .then(() => setLoading(false))
      .catch(() =>
        setCreatingError(
          "There was an error while connecting to the server. Please try again."
        )
      );
  };

  const editGroupSubmit = async (data) => {
    const { name, bio, isPublic, members, file } = data;
    console.log(bio, name, members);
    const formData = new FormData();
    formData.append("tumorBoardId", selectedGroupItem.id);
    formData.append("type", !isPublic ? "private" : "public");
    if (name.trim() === "") {
      return;
    }
    if (file) formData.append("img", file);
    formData.append("name", name);
    if (bio) formData.append("bio", bio);
    try {
      const res = await Promise.all([
        axios.put("/api/groups", formData, { withCredentials: true }),
        addMembers(members),
      ]);
      {
        ((name || bio || file) && members.length === 1) ?
          Notify({ value: "Group updated successfully" })
          : Notify({ value: "Invitation sent successfully" })
      }
      if (res[0].status === 200) {
        dispatch(fetchUserBoards());
        fetchGroupDetails(groupState[selectedGroupItemIndex].id); // refresh detail
        setActiveTab("details");
      }
    } catch (err) {
      //console.log('error editing group', err)
    }
  };

  const addMembers = (members) => {
    const toBeAddedMemberIds = [];
    const membersIds = members.map((m) => m.id);
    membersIds.forEach((id) => {
      if (selectedGroupItem.members.findIndex((m) => m.id === id) === -1) {
        toBeAddedMemberIds.push(id);
      }
    });
    if (toBeAddedMemberIds.length === 0) return;
    return axios.post(
      "/api/groups/addMembers",
      { tumorBoardId: selectedGroupItem.id, members: toBeAddedMemberIds },
      { withCredentials: true }
    );
  };

  const manualGetGroupDetail = (isPublic = false) => {
    if (!isPublic) {
      if (groupState[selectedGroupItemIndex]) {
        fetchGroupDetails(groupState[selectedGroupItemIndex].id);
      }
    } else {
      if (publicGroups[selectedGroupItemIndex]) {
        fetchGroupDetails(publicGroups[selectedGroupItemIndex].id);
      }
    }
  };

  const fetchGroupDetails = async (groupId) => {
    const { data, status } = await axios.get(
      `/api/groups/GroupDetails/${groupId}`,
      {
        withCredentials: true,
      }
    );
    if (status === 200 && typeof data.group_name !== "undefined") {
      const membersDetails = [];
      data.members.forEach((member) => {
        membersDetails.push({
          boardId: member.group_id,
          addedBy: member.group_added_by,
          admin: member.group_member_admin === 1,
          joined: member.group_joined,
          id: member.user_id,
          email: member.email,
          firstName: member.firstname,
          surname: member.surname,
          username: member.username,
          connected: member.connected,
          userLiveShareStatus: member.group_member_live_share_status,
          userImageSrc: member.userImageSrc,
          userRole: member.roleName,
        });
      });

      setSelectedGroupItem({
        ...selectedGroupItem,
        name: data.group_name,
        bio: data.group_bio,
        imgSrc: data.group_img_src,
        type: data.group_type,
        members: membersDetails,
        slide: JSON.parse(data.group_slide),
        tumorBoardSlideId: data.group_slide_id,
        tumorBoardSlideHistory: data.group_slide_history,
      });
      dispatch(setSelectedMembers(membersDetails));
    }
  };

  const onGroupRefresh = () => {
    if (selectedGroupItemIndex === -1) return;
    if (!isOnPublicGroup) {
      fetchGroupDetails(groupState[selectedGroupItemIndex].id);
    } else {
      fetchGroupDetails(publicGroups[selectedGroupItemIndex].id);
    }
  };

  const footerNav = (value) => {
    const activeGroup = document.getElementById("activeGroup");
    const activeChat = document.getElementById("activeChat");
    const activeImg = document.getElementById("activeImg");
    const imageBtn = document.getElementById("active-group-image");
    const groupFlatlist = document.getElementsByClassName(
      "group-flatlist-component"
    )[0];
    const chatPanel = document.getElementsByClassName("chat")[0];
    const generalPanel = document.getElementsByClassName(
      "group-panel-component"
    )[0];
    const imagePanel = document.getElementsByClassName("image-panel")[0];
    const aboutGroup = document.getElementsByClassName("group-selected")[0];
    const newGroup = document.getElementsByClassName("group-new")[0];

    switch (value) {
      case "left":
        activeGroup.style.opacity = "1";
        activeChat.style.opacity = "0.5";
        activeImg.style.opacity = "0.5";
        generalPanel.style.display = "none";
        if (chatPanel) {
          chatPanel.style.display = "none";
          imageBtn.style.display = "none";
        }
        if (imagePanel) {
          imagePanel.style.display = "none";
        }
        groupFlatlist.style.display = "block";
        break;
      case "middle":
        activeGroup.style.opacity = "0.5";
        activeChat.style.opacity = "1";
        activeImg.style.opacity = "0.5";
        generalPanel.style.display = "block";
        groupFlatlist.style.display = "none";
        if (chatPanel) {
          imageBtn.style.display = "block";
          chatPanel.style.display = "block";
          imagePanel.style.display = "none";
        }
        if (!imagePanel && imageBtn) {
          imageBtn.style.display = "none";
        }
        if (newGroup) {
          imageBtn.style.display = "none";
        }
        break;
      case "right":
        activeGroup.style.opacity = "0.5";
        activeChat.style.opacity = "0.5";
        activeImg.style.opacity = "1";
        groupFlatlist.style.display = "none";
        if (chatPanel) {
          chatPanel.style.display = "none";
        }
        if (imagePanel) {
          imagePanel.style.display = "block";
        } else {
          imageBtn.style.display = "none";
        }
    }
  };

  const onOpenSlideboxDirect = () => {
    history.push("");
  };

  if (!isMobile) {
    return (
      <div className="group-page" ref={wrapperRef}>
        <GroupFlatList
          onSelectGroupItem={onSelectGroupItem}
          groups={groups}
          onSetGroupState={setGroupState}
          selectedGroupItemIndex={selectedGroupItemIndex}
          onSelectedPublicGroupItem={(index) => onSelectGroupItem(index, true)}
          onChangeActiveTab={onChangeTab}
          loggedUserDetails={loggedUserDetails}
          expanded={["", "details", "new", "edit"].includes(activeTab)}
        />
        {!_.isEmpty(selectedGroupItem) && activeTab === "image" && (
          <Chat
            activeTab={onChangeTab}
            group={selectedGroup}
            isPublicGroup={isOnPublicGroup}
            loggedUserDetails={loggedUserDetails}
            onGroupRefresh={() => onGroupRefresh()}
            onOpenSlidebox={() => setOpenSlidebox(true)}
          />
        )}
        <GroupPanel
          group={selectedGroupItem}
          activeTab={activeTab}
          onChangeActiveTab={onChangeTab}
          onSubmit={newGroupSubmit}
          onEditSubmit={editGroupSubmit}
          creatingError={creatingError}
          openSlidebox={openSlidebox}
          setOpenSlidebox={setOpenSlidebox}
          onGroupRefresh={() => onGroupRefresh()}
          isPublicGroup={isOnPublicGroup}
          loading={loading}
        />
      </div>
    );
  } else {
    return (
      <div className="group-page" ref={wrapperRef}>
        <GroupFlatList
          onSelectGroupItem={onSelectGroupItem}
          groups={groups}
          onSetGroupState={setGroupState}
          selectedGroupItemIndex={selectedGroupItemIndex}
          onSelectedPublicGroupItem={(index) => onSelectGroupItem(index, true)}
          onChangeActiveTab={onChangeTab}
          expanded={["", "details", "new", "edit"].includes(activeTab)}
          footerNav={footerNav}
        />
        {!_.isEmpty(selectedGroupItem) && activeTab === "image" && (
          <Chat
            activeTab={onChangeTab}
            group={selectedGroup}
            isPublicGroup={isOnPublicGroup}
            onGroupRefresh={() => onGroupRefresh()}
            onOpenSlidebox={() => setOpenSlidebox(true)}
            footerNav={footerNav}
          />
        )}
        <GroupPanel
          group={selectedGroupItem}
          activeTab={activeTab}
          onChangeActiveTab={onChangeTab}
          onSubmit={newGroupSubmit}
          onEditSubmit={editGroupSubmit}
          creatingError={creatingError}
          openSlidebox={openSlidebox}
          setOpenSlidebox={setOpenSlidebox}
          onGroupRefresh={() => onGroupRefresh()}
          footerNav={footerNav}
        />
        <div className="group-footer">
          <div className="active-groups-list" onClick={() => footerNav("left")}>
            <img src={GroupListIcon} alt="groups" id="activeGroup" />
          </div>
          <div className="active-group" onClick={() => footerNav("middle")}>
            <img
              src={ChatIcon}
              alt="chat"
              id="activeChat"
              style={{ opacity: "0.5" }}
            />
          </div>
          <div
            id="active-group-image"
            onClick={() => footerNav("right")}
            style={{ display: "none" }}
          >
            <img
              src={ImgIcon}
              alt="image"
              id="activeImg"
              style={{ opacity: "0.5" }}
            />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  const {
    file,
    myBoards,
    selectedBoard: selectedGroup,
    publicGroups,
    onClickEmitter,
  } = state.Groups;
  const { loggedUserDetails } = state.Global;
  return {
    isMobile: false,
    file,
    groups: myBoards,
    publicGroups,
    selectedGroup,
    onClickEmitter,
    loggedUserDetails,
  };
};

export default connect(mapStateToProps)(withRouter(Groups));
