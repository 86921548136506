import React from "react";
import { select, selectAll } from "d3-selection";
import { transition } from "d3-transition";
import * as d3 from "d3";
class Line extends React.Component {
  constructor() {
    super();
    this.newCaseRef = React.createRef();
    this.completedCaseRef = React.createRef();
  }
  componentDidMount() {
    const node1 = this.newCaseRef.current;
    const {
      xScale,
      yScale,
      data,
      lineGenerator,
      newCases,
      completedCases,
      left,
      height,
    } = this.props;
    console.log({ data });
    const initialDataNewCase = data.map((d) => ({
      name: d.name,
      value: 0,
    }));

    select(node1)
      .append("path")
      .datum(initialDataNewCase)
      .attr("id", "line1")
      .attr("stroke", "#642BB6")
      .attr("stroke-width", 2)
      .attr("fill", "none")
      .attr("d", lineGenerator)
      .attr("viewBox", "0 0 200 200")
      .attr("cx", function(obj) {
        return xScale(obj.name);
      })
      .attr("cy", function(obj) {
        return yScale(obj.value);
      });

    select(node1)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - left)
      .attr("x", 0 - height / 2)
      .attr("dy", "5em")
      .style("text-anchor", "middle")
      .style("letter-spacing", "1px")
      .style("font-size", "14px")
      .style("font-family", "Roboto, sans-serif")
      .text("Number of Annotations");

    this.updateChart();
  }
  componentDidUpdate() {
    this.updateChart();
  }

  updateChart() {
    const { lineGenerator, xScale, yScale, data } = this.props;

    const t = transition().duration(1000);
    const node1 = this.newCaseRef.current;

    const line1 = select("#line1");
    const dot1 = selectAll(".circle1");

    const tooltipNewCase = d3
      .select(".tooltip-area-new-case")
      .style("opacity", 0);

    line1
      .datum(data)
      .transition(t)
      .attr("d", lineGenerator);

    dot1
      .data(data)
      .transition(t)
      .attr("cx", (d, key) => xScale(key))
      .attr("cy", (d) => yScale(d.count));

    select(node1)
      .selectAll("points")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", function(d) {
        return xScale(d.name);
      })
      .attr("cy", function(d) {
        return yScale(d.value);
      })
      .attr("r", 10)
      .style("opacity", 0)
      .on("mouseover", function(event, d) {
        tooltipNewCase
          .transition()
          .style("opacity", 1)
          .style("cursor", "pointer");
      })
      .on("mousemove", function(event, d) {
        const text = d3.select(".tooltip-area-new-case-text");
        text.text(d.value);
        const [x, y] = d3.pointer(event);
        tooltipNewCase.attr("transform", `translate(${x}, ${y})`);
      })
      .on("mouseout", function(d) {
        tooltipNewCase
          .transition()
          .duration(100)
          .style("opacity", 0);
      });
  }

  render() {
    return (
      <React.Fragment>
        <g className="newCase" ref={this.newCaseRef}>
          <g className="tooltip-area-new-case">
            <text className="tooltip-area-new-case-text"></text>
          </g>
        </g>
      </React.Fragment>
    );
  }
}

export default Line;
