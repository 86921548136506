import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import OpenViewer from "../../../../assets/icons/open-viewer.svg";
import RenameIcon from "../../../../assets/icons/rename.svg";
import MoveIcon from "../../../../assets/icons/folder-icon.svg";
import DownloadIcon from "../../../../assets/icons/download-icon.svg";
import AddToGroup from "../../../../assets/icons/add-group.svg";
import { setSlideBoxMovePopupAction } from "../../actions/slidebox-action";
import MoreVertIcon from "../../../../assets/icons/more-icon.svg";
import ImageTag from "../SlideItems/ImageTag";
import { valuesObject, keysObject } from "../../../../utils/utils";
import { IconButton } from "@material-ui/core";
import MoveToPopUp from "./MoveToPopUp";
import { withRouter } from "react-router";
import Can from "../../../../hoc/FullControllHoc/can";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    right: "117px !important",
    left: "auto !important",
  },
})((props) => (
  <Menu
    elevation={5}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const MoreDetailsDropDown = ({
  history,
  renameDir,
  downloadSlide,
  selectedListType,
  setSlideBoxMovePopup,
  movePopupState,
  showGroupsSelectPopup,
  selectedListSlideData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [folderDirHover, setFolderDirHover] = useState(null);
  const [openPopup, setOpenPopup] = useState(null);

  const ButtonAttributes = {
    ...(selectedListType === "slide"
      ? {
        Open_with_viewer: {
          icon: <img src={OpenViewer} className="dropdown-menu-icon" />,
          type: "openWithViewer",
          onClick: () => {
            handleShowInViewer(), handleClose();
          },
        },
      }
      : []),

    ...(selectedListType === "slide"
      ? {
        Add_to_groups: {
          icon: <img src={AddToGroup} className="dropdown-menu-icon" style={{ width: '18px', height: '18px' }} />,
          type: "addSlideToGroup",
          onClick: () => {
            showGroupsSelectPopup(), handleClose();
          },
        },
      }
      : []),
    ...(selectedListType !== "document"
      ? {
        Move_to: {
          onClick: () => {
            handleToggleMovePopup(!openPopup), handleClose();
          },
          onMouseEnter: () => moveToHoverIconFn("move"),
          onMouseLeave: () => moveToHoverIconFn(null),
          icon: <img src={MoveIcon} className="dropdown-menu-icon" />,
          type: "move",
        },
      }
      : []),
    Rename: {
      onClick: (e) => {
        renameDir(), handleClose();
      },
      onMouseEnter: () => moveToHoverIconFn("rename"),
      onMouseLeave: () => moveToHoverIconFn(null),
      icon: <img src={RenameIcon} className="dropdown-menu-icon" />,
      type: "rename",
    },
    ...(selectedListType === "slide" || selectedListType === "document"
      ? {
        Download: {
          onClick: (e) => {
            downloadSlide(), handleClose();
          },
          onMouseEnter: () => moveToHoverIconFn("download"),
          onMouseLeave: () => moveToHoverIconFn(null),
          icon: <img src={DownloadIcon} className="dropdown-menu-icon" style={{ marginLeft: '2px', marginRight: '8px' }} />,
          type: "download",
        },
      }
      : []),
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const moveToHoverIconFn = (type) => setFolderDirHover(type);

  const handleShowInViewer = () => {
    history.push({
      pathname: `/viewer`,
      search: `?image=${selectedListSlideData.keyFolder}&thumb=${selectedListSlideData.thumbDataToken}`,
    });
  };

  const handleToggleMovePopup = () => {
    setSlideBoxMovePopup(!movePopupState);
  };
  return (
    <React.Fragment>
      <IconButton
        className={`border-right-more ${anchorEl !== null ? "more-icon-selected" : ""
          }`}
        color="info"
        aria-label="info"
        component="span"
        data-for="viewer-tooltip"
        data-tip="More action"
      >
        <img src={MoreVertIcon}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
        />
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {valuesObject(ButtonAttributes).map(
            ({ icon, type, ...rest }, index) => (
              <React.Fragment>
                {type === "addSlideToGroup" && (
                  <Can
                    task={"add-slide"}
                    taskContainer="groups"
                    edit={() => (
                      <StyledMenuItem
                        className={"dropdown-li"}
                        {...rest}
                        key={index}
                      >
                        {icon}
                        <ListItemText
                          primary={keysObject(ButtonAttributes)[index].replace(
                            /_/g,
                            " "
                          )}
                        />
                      </StyledMenuItem>
                    )}
                  />
                )}

                {type !== "addSlideToGroup" && type !== "openWithViewer" && (
                  <Can
                    task={
                      type === "move"
                        ? "create-case"
                        : "rename"
                          ? "create-case"
                          : ""
                    }
                    taskContainer="slidebox"
                    edit={() => (
                      <StyledMenuItem
                        className={"dropdown-li"}
                        {...rest}
                        key={index}
                      >
                        {icon}
                        <ListItemText
                          primary={keysObject(ButtonAttributes)[index].replace(
                            /_/g,
                            " "
                          )}
                        />
                      </StyledMenuItem>
                    )}
                  />
                )}
                {type === "openWithViewer" && (
                  <StyledMenuItem
                    className={"dropdown-li"}
                    {...rest}
                    key={index}
                  >
                    {icon}
                    <ListItemText
                      primary={keysObject(ButtonAttributes)[index].replace(
                        /_/g,
                        " "
                      )}
                    />
                  </StyledMenuItem>
                )}
              </React.Fragment>
            )
          )}
        </StyledMenu>
      </IconButton>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.moveFolders,
  uploadPageLoad: state.SlideBox.uploadPageLoad,
  movePopupState: state.SlideBox.movePopupState,
  ...state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
  selectedListSlideData: state.SlideBox.selectedListSlideData,
});

const mapDispatchToProps = (dispatch) => ({
  setSlideBoxMovePopup: (payload) =>
    dispatch(setSlideBoxMovePopupAction(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MoreDetailsDropDown)
);
