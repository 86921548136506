import React, { Component, useState, useEffect, useRef } from "react";
import UploadImageIcon from "../../../assets/icons/camera.svg";
import PrivateGroupIcon from "../../../assets/icons/private-group-icon.svg";
import PublicGroupIcon from "../../../assets/icons/public-group-icon.svg";
import DropdownIcon from "../../../assets/icons/dropdown-icon.svg";
import ArrowLeft from "../../../assets/icons/arrow_left.svg";
import ThreeDots from "../../../assets/icons/three-dots.svg";
import User from "./user";
import _ from "lodash";
import MemberInvation from "./member-invitation";
import altAvatarIcon from "../../../assets/icons/default-avatar.svg";

const GroupEdit = ({
  group,
  onSubmit,
  editError,
  footerNav,
  changeActiveTab,
}) => {
  const [file, setFile] = useState();
  const [bloblFileURL, setBloblFileURL] = useState(group.imgSrc);
  const [isPublic, setIsPublic] = useState(group.type === "public");
  const [about, setAbout] = useState(group.bio);
  const [name, setName] = useState(group.name);
  const [open, setOpen] = useState(false);
  const [members, setMembers] = useState(group.members || []);
  const [nameError, setNameError] = useState();
  const [error, setError] = useState(editError);

  const nameRef = useRef(null);
  const fileRef = useRef(null);

  useEffect(() => {
    setError(editError);
  }, [editError]);

  const submit = () => {
    let req = {
      file,
      isPublic,
      bio: about,
      name,
      members,
    };

    onSubmit && onSubmit(req);
  };

  const checkUser = (toCheck) => {
    const checked = members.filter((user) => {
      if (user.id === toCheck.id) return user.id;
    });
    return checked.length === 0;
  };

  const onClickDropdown = () => {
    setOpen(!open);
  };

  const onUploadFile = (e) => {
    const { files } = e.target;
    // const files = fileRef.current
    setBloblFileURL(URL.createObjectURL(files[0]));
    setFile(files[0]);
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    // this.setState({ [name]: value })
    if (value === "") {
      setNameError("Please enter a Group name");
    } else {
      setNameError();
    }
    setName(value);
  };

  const onAboutChange = (e) => {
    const { value, name } = e.target;
    setAbout(value);
  };

  const onSelectChange = (value) => {
    //console.log('onSelectChange value', value)
    setIsPublic(value);
    setOpen(!open);
  };

  const addSelf = (user) => {
    if (checkUser(user)) {
      setMembers([user, ...members]);
    }
  };

  return (
    <div className="group-new">
      <div className="group-new-content">
        <div className="go-to-previous-page" onClick={() => footerNav("left")}>
          <img src={ArrowLeft} alt="go back" />
        </div>
        <h3 className="group-header">Edit Group</h3>
        <div className="group-info">
          <div className="upload-input-wrapper">
            <div className="upload-img-wrapper">
              <img
                src={_.isEmpty(bloblFileURL) ? UploadImageIcon : bloblFileURL}
                style={
                  !_.isEmpty(bloblFileURL)
                    ? { borderRadius: "100%" }
                    : { width: "fit-content" }
                }
                alt="upload image"
              />
            </div>
            <input
              type="file"
              accept="image/*"
              ref={fileRef}
              onChange={onUploadFile}
            />
          </div>
          <div className="input-wrapper">
            <input
              type="text"
              onChange={onChange}
              placeholder="Group name"
              value={name}
            />
            {nameError && <p class="invalid-message">{nameError}</p>}
            {/* <Select
              isPublic={isPublic}
              open={open}
              onChange={onSelectChange}
              onClickDropdown={onClickDropdown}
            /> */}
            <div className="textarea-wrapper">
              <h3>About</h3>
              <textarea onChange={onAboutChange} value={about || ""} />
            </div>
            <div className="group-new-members">
              <div className="group-new-title">
                <h3>Members</h3>
                <p>{members.length}</p>
              </div>
              <MemberInvation onMemberSelect={addSelf} />
              <div className="users">
                {members.map((member, i) => (
                  <User
                    key={i}
                    // TODO: remove this absolute path
                    img={member.userImageSrc || altAvatarIcon}
                    fullName={`${member.firstName || member.firstname} ${member.surname
                      }`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="submit-button-wrapper">
          <button
            onClick={() => changeActiveTab("details")}
            className="cancel-button"
          >
            Cancel
          </button>
          <button
            onClick={submit}
            disabled={nameError}
            className="create-button"
          >
            Save
          </button>
          <p style={{ color: "red" }}>{error}</p>
        </div>
      </div>
    </div>
  );
};

export default GroupEdit;

const Select = ({ isPublic, onChange, open, onClickDropdown }) => {
  return (
    <div className="group-new-select">
      <div className="select-condition">
        <div className="select-condition-text">
          <p className="group-type">Group type</p>
          <p className="actual-choice">{isPublic ? "Public" : "Private"}</p>
        </div>
        <button onClick={onClickDropdown} className="select-condition-btn">
          <img src={DropdownIcon} alt="dropdown" className="triangle" />
          <img
            src={ThreeDots}
            alt="dropdown"
            style={{ display: "none" }}
            className="three-dots"
          />
        </button>
      </div>
      {open && (
        <div className="choices-list">
          <div
            className="choice-item"
            onClick={() => {
              onChange(true);
            }}
          >
            <div className="content">
              <h3 className="title">Public Group</h3>
              <p className="description">
                Community members can find this group and contribute to the
                discussions.
              </p>
            </div>
            <img src={PublicGroupIcon} />
          </div>
          <div
            className="choice-item"
            onClick={() => {
              onChange(false);
            }}
          >
            <div className="content">
              <h3 className="title">Private Group</h3>
              <p className="description">
                Just group members can contribute and join only by invitation.
              </p>
            </div>
            <img src={PrivateGroupIcon} />
          </div>
        </div>
      )}
    </div>
  );
};

const UserCard = ({ user, addSelf, id }) => {
  return (
    user.id !== id && (
      <div className="userCard" onClick={() => addSelf()}>
        <img
          // TODO: remove absolute link
          src={`/users/${user.id}/avatar.jpg`}
          style={{
            width: "50px",
            height: "50px",
            margin: "5px",
            borderRadius: "25px",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/icons/default-avatar.svg";
          }}
        />
        <div
          style={{
            height: "60px",
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
          <p className="searchName">
            {(typeof user.firstname !== undefined
              ? user.firstname
              : user.firstName) +
              " " +
              user.surname}
          </p>
          <p className="searchName">@{user.username}</p>
        </div>
      </div>
    )
  );
};
