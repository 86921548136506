import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FolderIconUnselected from "../../../assets/icons/folder-close-new.svg";
import FolderIconSelected from "../../../assets/icons/folder-dir-open.svg";
import CaseIconUnselected from "../../../assets/icons/case-close.svg";
import DocumentSingleIcon from "../../../assets/icons/doc-single.png";
import CaseIcon from "../../../assets/icons/case-open.svg";
import DefaultFileIcon from "../../../assets/icons/default-file.svg";
import Loader from "../components/SlideTable/Loader";
import EmptyMessage from "./SlideTable/EmptyMessage";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ToolTip from "react-tooltip";
import { breadcrumbAction } from "../actions/slidebox-action";
import { ContextMenu } from "../../../components";
import Can from "../../../hoc/FullControllHoc/can";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginRight: 10,
  },
  paper: {
    padding: theme.spacing(1.5),
    marginTop: 20,
    marginLeft: 10,
    cursor: "pointer",
    border: "1px solid #dadce0",
    height: 50,
    fontSize: "0.875rem",
    fontWeight: 400,
    fontFamily: "Roboto, sans-serif",
    letterSpacing: "0.01071em",
    width: 250,
  },
  customClass: {
    padding: theme.spacing(1.5),
    marginTop: 20,
    marginLeft: 10,
    cursor: "pointer",
    border: "1px solid #dadce0",
    height: 50,
    backgroundColor: "#EDE7F9",
    color: "#0f0da1 !important",
    fill: "#0f0da1 !important",
    fontSize: "0.875rem",
    fontWeight: 400,
    fontFamily: "Roboto, sans-serif",
    letterSpacing: "0.01071em",
    width: 250,
  },
  section: {
    color: "red",
  },
  folderName: {
    color: "#0f0da1",
    paddingTop: 35,
    paddingLeft: 23,
  },
  folderLabel: {
    width: "100%",
    marginTop: 20,
    marginLeft: 20,
  },
  dirStyling: {
    fontWeight: 500,
    fontSize: 13,
    fontFamily: "Roboto, sans-serif",
    letterSpacing: "0.01071em",
  },
}));

const emptyMessage = {
  root: "Use the New Button to create a case",
  folder: "Create a case or upload images",
  case: "Upload images",
  undefined: "",
  emptySearch: "No results found",
};

const SlideGridView = ({
  gridData,
  setSelectedDir,
  breadcrumbAction,
  toggleDirHandler,
  showLoader,
  selectedList,
  selectedListType,
  isSearch,
  history,
  selectedFolderType,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  selectedListItem,
  showGroupsSelectPopup,
  selectedListSlideData,
  isModal,
  downloadSlide,
  selectedFolderName,
  description,
}) => {
  const classes = useStyles();
  const [isTableRowSelected, setIsTableRowSelected] = useState({});
  const [selectedDataToAnalyze, setSelectedDataToAnalyze] = useState();
  const [selectedDirDetails, setSelectedDirDetails] = useState({});

  const onDirDoubleClick = (e, listDataItem) => {
    const { dirDataId, parentDir, caseOrFolder } = listDataItem;
    if (caseOrFolder === "slide" || caseOrFolder === "document") {
      if (!isModal) {
        toggleDirHandler({
          dirId: dirDataId,
          parentDir,
          dirType: caseOrFolder,
        });
      }
    } else {
      toggleDirHandler({
        dirId: dirDataId,
        parentDir,
        dirType: caseOrFolder,
      });
    }
  };
  const onSelectedRow = (e, listDataItem, index) => {
    const { dirDataId, parentDir, caseOrFolder } = listDataItem;
    setIsTableRowSelected({ [index]: true });
    setSelectedDir({
      dirId: dirDataId,
      parentDir,
      dirType: caseOrFolder,
    });
    breadcrumbAction({ showIcon: true, [index]: listDataItem });
    setSelectedDataToAnalyze(listDataItem);
  };

  const handleShowInViewer = (slideItem = selectedListSlideData) => {
    if (!isModal) {
      history.push({
        pathname: `/viewer`,
        search: `?image=${slideItem.keyFolder}&thumb=${slideItem.thumbDataToken}`,
      });
    }
  };

  const foldersDirType = gridData.filter(
    (type) => type.caseOrFolder === "folder"
  );

  const casesDirType = gridData.filter((type) => type.caseOrFolder === "case");
  const slidesType = gridData.filter((type) => type.caseOrFolder === "slide");
  const documentsType = gridData.filter(
    (type) => type.caseOrFolder === "document"
  );

  const handleClickAnalyzeSlide = () => {
    history.push({
      pathname: `/analysis`,
      state: { fromSlidebox: true, selectedDataToAnalyze, selectedDirDetails },
    });
  };

  useEffect(() => {
    setSelectedDirDetails({ dirDataName: selectedFolderName, description });
  }, [selectedFolderName, description]);

  return showLoader ? (
    <Loader />
  ) : (
    <Can
      task={"viewing-folder-or-case"}
      taskContainer="slidebox"
      edit={() => (
        <React.Fragment>
          {foldersDirType.length > 0 && (
            <React.Fragment>
              <Grid container spacing={1} className="slidebox-grid-gap">
                <div className={classes.folderLabel}>
                  <span className={classes.dirStyling}>
                    {selectedFolderType === "root" ||
                    foldersDirType.length !== 0
                      ? "Folders"
                      : casesDirType.length !== 0
                      ? "Cases"
                      : selectedFolderType === "folder" &&
                        (slidesType.length !== 0 || documentsType.length !== 0)
                      ? "Files"
                      : ""}
                  </span>
                </div>

                {foldersDirType.map((row, index) => {
                  const gridRowSelected =
                    selectedList === row.dirDataId &&
                    selectedListType === row.caseOrFolder;
                  const className = gridRowSelected
                    ? classes.customClass
                    : classes.paper;
                  return (
                    <Grid item xs={5} sm={3} spacing={4}>
                      <Paper
                        // className={classes.paper}
                        variant="outlined"
                        className={`${className} ${
                          gridRowSelected ? "grid-view-card" : ""
                        }`}
                        onClick={(e) => onSelectedRow(e, row, index)}
                        onDoubleClick={(e) => {
                          row.caseOrFolder === "slide"
                            ? handleShowInViewer(row)
                            : onDirDoubleClick(e, row);
                        }}
                        data-for="viewer-tooltip"
                        data-tip={row.dirDataName}
                      >
                        <img
                          src={
                            gridRowSelected
                              ? FolderIconSelected
                              : FolderIconUnselected
                          }
                          alt="icons"
                          className={
                            row.caseOrFolder
                              ? "slide-dir slide-icon"
                              : "slide-image slide-icon"
                          }
                        />
                        {row.dirDataName.length > 20
                          ? row.dirDataName.substring(0, 20) + "..."
                          : row.dirDataName}
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </React.Fragment>
          )}
          {casesDirType.length > 0 && (
            <React.Fragment>
              <Grid container spacing={1} className="slidebox-grid-gap">
                <div className={classes.folderLabel}>
                  <span className={classes.dirStyling}>
                    {selectedFolderType === "root" && casesDirType.length !== 0
                      ? "Cases"
                      : selectedFolderType !== "root"
                      ? "Cases"
                      : "Files"}
                  </span>
                </div>

                {casesDirType.map((row, index) => {
                  const gridRowSelected =
                    selectedList === row.dirDataId &&
                    selectedListType === row.caseOrFolder;
                  const className = gridRowSelected
                    ? classes.customClass
                    : classes.paper;
                  return (
                    <Grid item xs={5} sm={3}>
                      <Paper
                        // className={classes.paper}
                        variant="outlined"
                        className={`${className} ${
                          gridRowSelected ? "grid-view-card" : ""
                        }`}
                        onClick={(e) => onSelectedRow(e, row, index)}
                        onDoubleClick={(e) => {
                          row.caseOrFolder === "slide"
                            ? handleShowInViewer(row)
                            : onDirDoubleClick(e, row);
                        }}
                        data-for="viewer-tooltip"
                        data-tip={row.dirDataName}
                      >
                        <img
                          src={gridRowSelected ? CaseIcon : CaseIconUnselected}
                          alt="icons"
                          height="30px"
                          width="30px"
                          className={
                            row.caseOrFolder ? "slide-dir" : "slide-image"
                          }
                        />
                        {row.dirDataName.length > 20
                          ? row.dirDataName.substring(0, 20) + "..."
                          : row.dirDataName}
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </React.Fragment>
          )}
          {slidesType.length > 0 && (
            <React.Fragment>
              <Grid container spacing={1} className="slidebox-grid-gap">
                <div className={classes.folderLabel}>
                  <span className={classes.dirStyling}>
                    {selectedFolderType === "root" && slidesType.length === 0
                      ? ""
                      : slidesType.length !== 0
                      ? "Slides"
                      : ""}
                  </span>
                </div>
                {slidesType.map((row, index) => {
                  const gridRowSelected =
                    selectedList === row.dirDataId &&
                    selectedListType === row.caseOrFolder;
                  const className = gridRowSelected
                    ? classes.customClass
                    : classes.paper;
                  return (
                    <Grid item xs={5} sm={3}>
                      <Paper
                        // className={classes.paper}
                        className={`${className} ${
                          gridRowSelected ? "grid-view-card" : ""
                        }`}
                        variant="outlined"
                        onClick={(e) => onSelectedRow(e, row, index)}
                        onDoubleClick={(e) => {
                          row.caseOrFolder === "slide"
                            ? handleShowInViewer(row)
                            : onDirDoubleClick(e, row);
                        }}
                        data-for="viewer-tooltip"
                        data-tip={row.dirDataName}
                      >
                        <img
                          src={
                            row.caseOrFolder === "folder"
                              ? FolderIconUnselected
                              : row.caseOrFolder === "case"
                              ? FolderIconSelected
                              : row.dzi_thumbnail
                              ? row.dzi_thumbnail
                              : row.caseOrFolder === "document"
                              ? DocumentSingleIcon
                              : DefaultFileIcon
                          }
                          alt="icons"
                          height="30px"
                          width="30px"
                          className={
                            row.caseOrFolder ? "slide-dir" : "slide-image"
                          }
                        />
                        {row.dirDataName.length > 20
                          ? row.dirDataName.substring(0, 20) + "..."
                          : row.dirDataName}
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </React.Fragment>
          )}
          {isModal !== true && documentsType.length > 0 && (
            <React.Fragment>
              <Grid container spacing={1} className="slidebox-grid-gap">
                <div className={classes.folderLabel}>
                  <span className={classes.dirStyling}>
                    {selectedFolderType === "root" && documentsType.length === 0
                      ? ""
                      : documentsType.length !== 0
                      ? "Files"
                      : ""}
                  </span>
                </div>
                {documentsType.map((row, index) => {
                  const gridRowSelected =
                    selectedList === row.dirDataId &&
                    selectedListType === row.caseOrFolder;
                  const className = gridRowSelected
                    ? classes.customClass
                    : classes.paper;
                  return (
                    <Grid item xs={5} sm={3}>
                      <Paper
                        // className={classes.paper}
                        className={`${className} ${
                          gridRowSelected ? "grid-view-card" : ""
                        }`}
                        variant="outlined"
                        onClick={(e) => onSelectedRow(e, row, index)}
                        onDoubleClick={(e) => {
                          row.caseOrFolder === "slide"
                            ? handleShowInViewer(row)
                            : row.caseOrFolder === "document"
                            ? ""
                            : onDirDoubleClick(e, row);
                        }}
                        data-for="viewer-tooltip"
                        data-tip={row.dirDataName}
                      >
                        <img
                          src={
                            row.caseOrFolder === "folder"
                              ? FolderIconUnselected
                              : row.caseOrFolder === "case"
                              ? FolderIconSelected
                              : row.dzi_thumbnail
                              ? row.dzi_thumbnail
                              : row.caseOrFolder === "document"
                              ? DocumentSingleIcon
                              : DefaultFileIcon
                          }
                          alt="icons"
                          height="30px"
                          width="30px"
                          className={
                            row.caseOrFolder ? "slide-dir" : "slide-image"
                          }
                        />
                        {row.dirDataName.length > 20
                          ? row.dirDataName.substring(0, 20) + "..."
                          : row.dirDataName}
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>

              <ToolTip
                id="viewer-tooltip"
                place="bottom"
                type="dark"
                effect="solid"
              />
            </React.Fragment>
          )}
          {gridData && gridData.length === 0 && (
            <EmptyMessage
              mainMessage={
                emptyMessage[isSearch ? "emptySearch" : selectedFolderType]
              }
              selectedFolderType={selectedFolderType}
            />
          )}
          <ContextMenu
            domElement={classes.customClass}
            showSharedPopup={showSharedPopup}
            deleteDirItemPopup={deleteDirItemPopup}
            renameSlideItem={renameSlideItem}
            handleToggleMovePopup={handleToggleMovePopup}
            setShowDetails={setShowDetails}
            selectedListItem={selectedListItem}
            showGroupsSelectPopup={showGroupsSelectPopup}
            openInViewer={() => handleShowInViewer()}
            downloadSlide={downloadSlide}
            rightClickFrom={"list-table"}
            isModal={isModal}
            analyzeSlide={() => handleClickAnalyzeSlide()}
          />
        </React.Fragment>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  selectedFolderData: state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
  ...state.SlideBox.selectedFolderData,
});

const mapDispatchToProps = (dispatch) => ({
  breadcrumbAction: (payload) => dispatch(breadcrumbAction(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SlideGridView)
);
