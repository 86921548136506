import React from "react";
import { FadeIn } from "animate-components";
import { connect } from "react-redux";
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";
import ChangeTypeIcon from '../../../../assets/icons/change-accout-type.svg';

const superAdminOptions = [
  { name: "admin", id: 1 },
  { name: "manager", id: 2 },
  { name: "user", id: 3 },
];
const adminOptions = [
  { name: "manager", id: 2 },
  { name: "user", id: 3 },
];

const ChangeRoleType = ({
  onClose,
  userRoleInfo,
  changeDxUserRoleFn,
  loggedUserRoleId,
}) => {
  const {
    firstName,
    lastName,
    roleName,
    userID: userId,
    roleId,
  } = userRoleInfo;
  const [newRoleName, setNewRoleName] = React.useState(roleName);
  const [newRoleId, setNewRoleId] = React.useState(roleId);

  const handleRoleChangeClick = () => {
    changeDxUserRoleFn({ newRoleId, newRoleName, userId });
    onClose(false);
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            title={`Change ${firstName}'s account type`}
            onClick={() => onClose(false)}
            img={ChangeTypeIcon}
            alt={'change-type'}
          />
          <div className="modal_middle">
            <p className="form-group role-description">
              Choose what account type {firstName + " " + lastName} will have in
              the Crosscope workspace.
              <a href="#">Learn more about account types</a>
            </p>
            <div className="custom-radio">
              {(loggedUserRoleId === 1 ? adminOptions : superAdminOptions).map(
                ({ name, id }, index) => (
                  <div className="form-group" key={index}>
                    <input
                      type="radio"
                      name="role"
                      value={name}
                      checked={newRoleName === name}
                      onChange={(e) => {
                        setNewRoleId(id);
                        setNewRoleName(e.currentTarget.value);
                      }}
                    />
                    <label>{name}</label>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="prompt-bottom">
            <SecondaryButton label="Cancel" onClick={() => onClose(false)} />
            <PrimaryButton
              label="Save"
              onClick={handleRoleChangeClick}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  loggedUserRoleId: state.Global.loggedUserDetails.roleId,
});

export default connect(mapStateToProps)(ChangeRoleType);
