import React from "react";
import PropTypes from "prop-types";
import OpenSeadragon from "../utils/fabricjs-overlay";
import OpenSeadragonControls from "./react-openseadragon-controls";
import "../utils/openseadragon-input-hook";
import "../utils/openseadragon-imaging-helper";
import { connect } from "react-redux";
import { SocketContext } from "../../../utils/serviceContext";
import {
  liveShareOnAction,
  liveShareUserOnAction,
} from "../actions/viewer-action";
// import { updateSelectedBoardLiveSync } from "../../../actions/tumorBoards";
import {
  setViewerScaleUnit,
  setViewerDefaultScaleUnit,
  setViewerScaleRatio,
  setViewerDefaultScaleRatio,
} from "../actions/viewer-action";

import ViewerToolBar from "./viewer-toolbar";
import ViewerScaleBar from "./viewer-scalebar";
import { htmlDecode } from "../../../utils/utils";

let OPENSEADRAGONVIEWER = undefined;
let OPENSEADRAGONBUTTON = undefined;

class OpenSeadragonViewer extends React.Component {
  constructor(props) {
    super(props);
    this._config = this._config.bind(this);
    this._id = this._id.bind(this);
    this._currentPageIndex = this._currentPageIndex.bind(this);
    this._osdViewer = this._osdViewer.bind(this);
    this.setStrings = this.setStrings.bind(this);
    this.setStringsItems = this.setStringsItems.bind(this);
    this.state = {
      pages: props.pages,
      osdDisplay: {},
      textDisplay: { display: "none" },
      currentRotate: 0,
      currentImageId: 0,
      mouseOverImage: false,
      mouseOverImageSet: [],
      zoomValueBar: 1,
      imageZoomValue: 0,
      WebCoordinates: { x: 0, y: 0 },
      viewPortCoordinates: { x: 0, y: 0 },
      imageCoordinates: { x: 0, y: 0 },
      curColor: "#000000",
      scaleVal: 1,
      enableFullScreenState: false,
      annotationCoordinates: [],
    };

    this.setHomeRef = React.createRef();
    this.setFullScreenRef = React.createRef();
    this.homeButton = "reset";
    this.startPos = null;
    this.endPos = null;
    this.angleInRad = 0;
    this.microscopeMaxZoom = 20;

    this.overlay = null;
    if (this.props.imageMetadata["openslide.objective-power"]) {
      this.microscopeMaxZoom = this.props.imageMetadata[
        "openslide.objective-power"
      ];
    }
  }

  static contextType = SocketContext;

  componentDidMount() {
    this.initSocketConnection();
    this.setEmptyStrings();
    this.loadCanvas();
    window.addEventListener("keydown", this.handleKeyDown);
    if (this.props.when === "analytics") {
      OPENSEADRAGONVIEWER.setControlsEnabled(false);
      OPENSEADRAGONVIEWER.setMouseNavEnabled(false);
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.liveShareIsOn !== this.props.liveShareIsOn) {
      if (this.props.groupAdmin) {
        this.socketInstance.emit("viewerLiveShareOnOff", {
          slideId: this.props.osdConfig.slideId,
          when: this.props.when,
          status: nextProps.liveShareIsOn,
          featureId:
            this.props.when === "quorum"
              ? this.props.quorum_id
              : this.props.when === "groups"
                ? this.props.groupId
                : null,
        });
      }
    }

    if (nextProps.userLiveShareOn !== this.props.userLiveShareOn) {
      this.socketInstance.emit("viewerLiveShareOnOffByNonAdmin", {
        slideId: this.props.osdConfig.slideId,
        when: this.props.when,
        status: nextProps.userLiveShareOn,
        featureId:
          this.props.when === "quorum"
            ? this.props.quorum_id
            : this.props.when === "groups"
              ? this.props.groupId
              : null,
      });
    }
  };
  loadCanvas = async () => {
    this.setStrings();
    if (typeof window.OpenSeadragon !== "undefined") {
      OPENSEADRAGONVIEWER = window.OpenSeadragon(this._config());
    } else {
      OPENSEADRAGONVIEWER = OpenSeadragon(this._config());
      OPENSEADRAGONVIEWER.addHandler("zoom", (event) => {
        this.osdZoomHandler(event);
      });
      OPENSEADRAGONVIEWER.addHandler("rotate", (event) => {
        // this.osdRotateHandler(event);
      });

      OPENSEADRAGONVIEWER.addHandler("full-screen", async (event) => {
        if (!event.fullScreen) {
          await this.setState({
            enableFullScreenState: false,
          });
        }
      });

      OPENSEADRAGONBUTTON = new OpenSeadragon.Button({
        element: this.setFullScreenRef.current,
        onClick: this.enableFullScreen,
        tooltip: "",
      });
    }

    if (this.props.when !== "analytics") {
      this.props.setViewerObject(OPENSEADRAGONVIEWER);
    }
    const zoom = OPENSEADRAGONVIEWER.viewport.getZoom().toFixed(3);
    const imageZoom = OPENSEADRAGONVIEWER.viewport
      .viewportToImageZoom(zoom)
      .toFixed(3);
    this.setState({
      zoomValueBar: zoom,
      imageZoomValue: imageZoom,
    });

    const imageJson = {
      scale: this.props.imageMetadata["width"],
    };
    this.overlay = OPENSEADRAGONVIEWER.fabricjsOverlay(imageJson);

    this.imagingHelper = OPENSEADRAGONVIEWER.activateImagingHelper({
      onImageViewChanged: (event) => this.onImageViewChanged(event),
    });
  };

  enableFullScreen = async () => {
    await this.setState({
      enableFullScreenState: !this.state.enableFullScreenState,
    });
  };

  initSocketConnection = () => {
    this.socketInstance = this.context.getSocketInstance();
    if (this.props.when === "groups") {
      const liveShareStatus =
        this.props.selectedGroupData &&
          this.props.selectedGroupData.slideLiveShareStatus
          ? true
          : false;
      const groupMemberArray =
        this.props.selectedMembers && this.props.selectedMembers.length > 0
          ? this.props.selectedMembers.filter(
            ({ id }) => id === this.props.userDetails.userID
          )
          : null;
      const userSlideShare = groupMemberArray
        ? groupMemberArray[0].userLiveShareStatus
          ? true
          : false
        : false;
      this.props.liveShareOn(liveShareStatus);
      this.props.liveShareUserOn(userSlideShare);
      this.context.onViewerSyncroniztion().subscribe((data) => {
        if (
          data.slideId === this.props.osdConfig.slideId &&
          data.userId !== this.props.userDetails.userID &&
          this.props.userLiveShareOn
        ) {
          this.imagingHelper.setView(
            data.width,
            data.height,
            new OpenSeadragon.Point(data.centerX, data.centerY),
            false
          );
        }
      });
      this.context.onViewerSyncroniztionStart().subscribe((data) => {
        if (
          data.slideId === this.props.osdConfig.slideId &&
          data.userId !== this.props.userDetails.userID
        ) {
          this.props.liveShareUserOn(data.status);
        }
        // this.props.updateGroupLiveSynStat(data);
      });
    }

    if (this.props.liveSyncMetaData) {
      this.props.liveShareOn(true);
      this.props.liveShareUserOn(true);
      this.context.onViewerSyncroniztion().subscribe((data) => {
        if (
          data.slideId === this.props.osdConfig.slideId &&
          data.userId !== this.props.userDetails.userID &&
          this.props.userLiveShareOn
        ) {
          this.imagingHelper.setView(
            data.width,
            data.height,
            new OpenSeadragon.Point(data.centerX, data.centerY),
            false
          );
        }
      });
      this.context.onViewerSyncroniztionStart().subscribe((data) => {
        if (
          data.slideId === this.props.osdConfig.slideId &&
          data.userId !== this.props.userDetails.userID
        ) {
          this.props.liveShareUserOn(data.status);
        }
      });
    }
  };

  setEmptyStrings = () => {
    OpenSeadragon.setString("Tooltips.Home", "");
    OpenSeadragon.setString("Tooltips.ZoomOut", "");
    OpenSeadragon.setString("Tooltips.ZoomIn", "");
    OpenSeadragon.setString("Tooltips.FullPage", "");
    OpenSeadragon.setString("Tooltips.ScreenshotToggle", "");
  };

  osdZoomHandler = (event) => {
    const defZoom = OPENSEADRAGONVIEWER.viewport.getHomeZoom();
    const maxZoom = OPENSEADRAGONVIEWER.viewport.getMaxZoom();
    const zoom = (event.zoom * this.microscopeMaxZoom) / maxZoom;
    let currentScale =
      ((this.props.imageMetadata["aperio.MPP"] * maxZoom) / event.zoom) * 100;

    if (currentScale > 1000) {
      currentScale /= 1000;
      currentScale = currentScale.toFixed(2);
      if (!this.state.enableFullScreenState) {
        this.props.setScaleUnit("mm");
        this.props.setScaleRatio(this.props.imageMetadata["aperio.MPP"] * 0.1);
      }
    } else if (Number.isNaN(currentScale)) {
      if (!this.state.enableFullScreenState) {
        this.props.setDefaultScaleUnit("cm");
        this.props.setDefaultScaleRatio();
      }
    } else {
      currentScale = currentScale.toFixed(2);
      if (!this.state.enableFullScreenState) {
        this.props.setScaleUnit(htmlDecode("&#181;m"));
        this.props.setScaleRatio(this.props.imageMetadata["aperio.MPP"] * 0.1);
      }
    }

    this.setState({
      zoomValueBar: zoom.toFixed(3),
      scaleVal: currentScale,
    });
    this.forceUpdate();
    if (typeof this.overlay.fabricCanvas() !== "undefined" && this.overlay) {
      this.overlay
        .fabricCanvas()
        .getObjects()
        .some((obj) => {
          if (obj.type !== "path") {
            obj.strokeWidth = 80 / this.state.zoomValueBar;
          }
        });
    }
  };

  viewportZoom = (e) => {
    let currentZoom = OPENSEADRAGONVIEWER.viewport.getZoom(true);
  };

  rotateImageHandler = (val) => {
    OPENSEADRAGONVIEWER.viewport.setRotation(val);
    this.angleInRad = (val * Math.PI) / 180;
  };

  osdRotateHandler = (event) => {
    var objs = this.overlay.fabricCanvas().getObjects();
    for (var i = 0; i < objs.length; i++) {
      // console.log(objs[i].type, event.degrees )
      if (objs[i].type == "rect") {
        if (objs[i].centeredRotation) {
          objs[i].centeredRotation = false;
        }
        objs[i].rotate(event.degrees);
      }
    }
  };

  handleOsdFilters = (val, filterType) => {
    switch (filterType) {
      case "brightness":
        OPENSEADRAGONVIEWER.setFilterOptions({
          filters: {
            processors: OpenSeadragon.Filters.BRIGHTNESS(val),
          },
        });
        break;
      case "saturation":
        break;
      case "contrast":
        OPENSEADRAGONVIEWER.setFilterOptions({
          filters: {
            processors: OpenSeadragon.Filters.CONTRAST(val / 10),
          },
        });
        break;
      case "hue":
        break;
    }
  };

  onImageViewChanged(event) {
    if (this.props.liveShareIsOn) {
      if (this.props.groupAdmin) {
        this.socketInstance.emit("viewerLiveShareData", {
          width: event.viewportWidth,
          height: event.viewportHeight,
          centerX: event.viewportCenter.x,
          centerY: event.viewportCenter.y,
          zoomFactor: event.zoomFactor,
          slideId: this.props.osdConfig.slideId,
          featureId: this.props.when === "groups" ? this.props.groupId : null,
        });
      }

      if (
        this.props.liveSyncMetaData &&
        this.props.userDetails.userID ===
        this.props.liveSyncMetaData.liveBroadcasterId
      ) {
        this.socketInstance.emit("viewerLiveShareData", {
          width: event.viewportWidth,
          height: event.viewportHeight,
          centerX: event.viewportCenter.x,
          centerY: event.viewportCenter.y,
          zoomFactor: event.zoomFactor,
          slideId: this.props.osdConfig.slideId,
          featureId: null,
        });
      }
    }
  }

  setStringsItems() {
    return typeof this.props.osdConfig.setStrings !== "undefined"
      ? this.props.osdConfig.setStrings
      : [];
  }

  setStrings() {
    this.setStringsItems().map((item) =>
      OpenSeadragon.setString(item.name, `${item.value}`)
    );
  }

  _currentPageIndex() {
    let urlParts = window.location.href.split("/");
    return parseInt(
      urlParts
        .map((part, i) => {
          if (part === "image") {
            return urlParts[i + 1];
          }
          return "";
        })
        .join("")
        .trim(),
      [0],
      10
    );
  }

  _currentPage() {
    return this.state.pages[this._currentPageIndex()];
  }

  _config() {
    return Object.assign(this.props.osdConfig, {
      id:
        this.props.when === "central_feed" ? this.props.viewerId : "osd-viewer",
    });
  }

  _id() {
    return parseInt(this.props.match.params.id, 10);
  }

  _osdViewer() {
    const {
      when,
      liveShareIsOn,
      viewerId,
      userLiveShareOn,
      userDetails,
      selectedGroupData,
    } = this.props;
    const style = {
      height: "100%",
    };
    return (
      <div className={"viewer-container"}>
        <div className="viewer">
          <div
            className="openseadragon container-viewer"
            style={style}
            id={when === "central_feed" ? viewerId : "osd-viewer"}
          >
            <OpenSeadragonControls
              when={when}
              isMobile={this.props.isMobile}
              viewerId={this.props.viewerId}
              postId={this.props.postId}
              keyFolder={this.props.keyFolder ? this.props.keyFolder : ""}
            />
            {this.props.showToolbar === true || this.props.when === "viewer" ? (
              <ViewerToolBar
                showToolbar={this.props.showToolbar}
                fromCaseStream={
                  this.props.fromCaseStream ? this.props.fromCaseStream : null
                }
                slideOwnerOrCollaborator={this.props.slideOwnerOrCollaborator}
                osdButtons={OPENSEADRAGONBUTTON}
                viewer={OPENSEADRAGONVIEWER}
                OpenSeadragon={OpenSeadragon}
                overlay={this.overlay}
                thumbnailSliderShow={this.props.thumbnailSliderShow}
                toggleThumbnailSlider={this.props.toggleThumbnailSlider}
                slideThumbData={this.props.slideThumbData}
                zoomValueBar={this.state.zoomValueBar}
                slideId={this.props.osdConfig.slideId}
                when={this.props.when}
                featureId={
                  this.props.when === "quorum"
                    ? this.props.quorum_id
                    : this.props.when === "groups"
                      ? this.props.groupId
                      : null
                }
                postIsMine={this.props.postIsMine}
                groupAdmin={this.props.groupAdmin}
                setFullScreenRef={this.setFullScreenRef}
                enableFullScreenState={this.state.enableFullScreenState}
                selectedGroupData={this.props.selectedGroupData}
                postIsValidate={
                  this.props.postIsValidate ? this.props.postIsValidate : false
                }
                annotationCoordinates={this.props.annotationCoordinates}
                setAnnotationCoordinates={this.props.setAnnotationCoordinates}
                highlightAreas={this.props.highlightAreas}
                runAnalysis={this.props.runAnalysis}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  handleHomeClick = () => {
    if (OPENSEADRAGONVIEWER.viewport) {
      OPENSEADRAGONVIEWER.viewport.setRotation(0);
      OPENSEADRAGONVIEWER.viewport.goHome();
      this.angleInRad = 0;
      this.setBounds();
    }
  };

  render() {
    if (typeof OPENSEADRAGONVIEWER !== "undefined") {
      OPENSEADRAGONVIEWER.goToPage(this.props.currentPageId);
    }
    return (
      <React.Fragment>
        {this._osdViewer()}
        {this.props.when !== "analytics" && (
          <ViewerScaleBar
            OPENSEADRAGONVIEWER={OPENSEADRAGONVIEWER}
            imageMetadata={this.props.imageMetadata}
            scaleVal={this.state.scaleVal}
            overlay={this.overlay}
            zoomValueBar={this.state.zoomValueBar}
          />
        )}
      </React.Fragment>
    );
  }
}

OpenSeadragonViewer.defaultProps = {
  viewSearchText: "",
  osdConfig: {
    defaultZoomLevel: 0,
    tileSources: [],
    setStrings: [],
    visibilityRatio: 1.0,
    constrainDuringPan: true,
  },
};

OpenSeadragonViewer.propTypes = {
  osdConfig: PropTypes.shape({
    defaultZoomLevel: PropTypes.number,
    tileSources: PropTypes.object,
    setStrings: PropTypes.array,
    visibilityRatio: 1.0,
    constrainDuringPan: true,
  }),
  viewSearchText: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  liveShareOn: (payload) => dispatch(liveShareOnAction(payload)),
  liveShareUserOn: (payload) => dispatch(liveShareUserOnAction(payload)),
  // updateGroupLiveSynStat: payload => dispatch(updateSelectedBoardLiveSync(payload)),
  setScaleUnit: (payload) => dispatch(setViewerScaleUnit(payload)),
  setDefaultScaleUnit: () => dispatch(setViewerDefaultScaleUnit()),
  setScaleRatio: (payload) => dispatch(setViewerScaleRatio(payload)),
  setDefaultScaleRatio: () => dispatch(setViewerDefaultScaleRatio()),
});

const mapStateToProps = (state) => ({
  userDetails: state.Global.loggedUserDetails,
  windowSize: state.Global.windowSize,
  isMobile: false,
  liveShareIsOn: state.Viewer.liveShareOn,
  selectedGroupData: state.Groups.selectedBoard,
  userLiveShareOn: state.Viewer.userLiveShareOn,
  selectedMembers: state.Groups.selectedMembers,
  scaleUnit: state.Viewer.scaleUnit,
  liveSyncMetaData: state.Viewer.liveSyncMetaData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenSeadragonViewer);
