import React, { useEffect, useState } from "react";
import { FadeIn } from "animate-components";
import { BulletList } from "react-content-loader";
import { cLoading } from "../../../utils/utils";
import NotificationsHeader from "../NotificationsHeader";
import Nothing from "../nothing";
import End from "../end";
import "../notification.scss";
import NotificationList from "./NotificationList";
import {
  getNotificationsAction,
  setNotifications,
} from "../actions/notification-actions";
import { connect } from "react-redux";

const Notifications = (props) => {
  const { getNotificationList, notificationList, showLoader } = props;
  const [notifications, setNotifications] = useState([]);

  const map_notifications =
    notifications.length !== 0 &&
    notifications.map((n) => {
      return (
        <NotificationList
          key={n.id}
          {...n}
          setNotifications={setNotifications}
        />
      );
    });

  useEffect(() => {
    getNotificationList();
  }, []);

  useEffect(() => {
    setNotifications(notificationList);
  }, [notificationList]);

  return (
    <div className="notification-container">
      {/* <IsLoading loading={loading} when="page" /> */}

      {showLoader && (
        <div style={{ margin: "0 0 0 25%" }}>
          <BulletList />
        </div>
      )}
      <FadeIn duration="300ms" className={cLoading(showLoader)}>
        <div className="notifications_div">
          <NotificationsHeader
            len={notificationList ? notificationList.length : 0}
          />
          {notificationList && notificationList.length == 0 ? (
            <Nothing />
          ) : (
            map_notifications
          )}
          {notificationList && notificationList.length != 0 && <End />}
        </div>
      </FadeIn>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notificationList: state.Notifications.notificationList,
  showLoader: state.Notifications.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getNotificationList: () => dispatch(getNotificationsAction()),
  setNotifications: (payload) => dispatch(setNotifications(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
