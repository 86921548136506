import React, { Component, Fragment } from "react";
import NotificationTypePost from "./NotificationTypePost";
import PropTypes from "prop-types";


export default class NotificationActionType extends Component {
  state = { isFollowing: false };

  componentDidMount = () =>
    this.setState({
      isFollowing: this.props.details.isFollowing,
    });

  componentWillReceiveProps = ({ details: { isFollowing } }) =>
    this.setState({ isFollowing });

  render() {
    let {
      details: {
        type,
        user_username,
        group_id,
        notify_by,
        notify_by_username,
        postid_token,
        file_folder,
        notifyId,
        slideData,
        groupInvitationId,
        invitedGroupId,
        setNotifications
      },
    } = this.props;
    return (
      <Fragment>
        <div className="noti_right follow_noti_right">
          {type == "slidebox" ? (
            <NotificationTypePost
              post_id={notifyId}
              postid_token={slideData.dzi_key_folder}
              type={type}
            />
          ) :
            null
          }
          {type == "groups" ? (
            <NotificationTypePost
              post_id={notifyId}
              groupInvitationId={groupInvitationId}
              invitedGroupId={invitedGroupId}
              type={type}
              setNotifications={setNotifications}
            />
          ) :
            null
          }
        </div>
      </Fragment>
    );
  }
}

NotificationActionType.propTypes = {
  details: PropTypes.shape({
    type: PropTypes.string.isRequired,
    user_username: PropTypes.string.isRequired,
    notify_by: PropTypes.number.isRequired,
    notify_by_username: PropTypes.string.isRequired,
    post_id: PropTypes.number.isRequired,
    group_id: PropTypes.number.isRequired,
    isFollowing: PropTypes.bool.isRequired,
  }).isRequired,
};
