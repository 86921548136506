import {
  loggedUserDetailsApi,
  useLogoutApi,
  dxAccountDetailsApi,
} from '../services/global-service';

const getUserDetails = (data) => {
  return {
    type: 'GET_USER_DETAILS',
    payload: data,
  };
};

const getDxAccountDetails = (data) => {
  return {
    type: 'GET_DX_ACCOUNT_DETAILS',
    payload: data,
  };
};

export const resetPicUpload = (payload) => {
  return {
    type: 'RESET_PROFILE_PIC',
    payload,
  };
};

export const updateUserPropileAction = (data) => {
  return {
    type: 'SET_USER_PROFILE',
    payload: data,
  };
};

export const uploadProfilePicPending = (data) => {
  return {
    type: 'SET_PROFILE_UPLOAD_PENDING',
    payload: data,
  };
};

export const userLogoutAction = () => {
  return async (dispatch) => {
    try {
      const response = await useLogoutApi();
      if (response.status === 200) {
        window.location.href = '/logout';
        setTimeOut(() => {
          sessionStorage.clear();
        }, 100);
        dispatch({ type: 'USER_LOGOUT' });
      }
    } catch (error) { }
  };
};

export const getUserDetailsAction = () => {
  return async (dispatch) => {
    try {
      const response = await loggedUserDetailsApi();
      if (response.status === 200) {
        if (response.data.body[0].status === 'deactivated') {
          dispatch(userLogoutAction());
        }
        dispatch(getUserDetails(response.data.body[0]));
      }
    } catch (error) {
      dispatch(getUserDetails({}));
    }
  };
};

export const getAccountDetailsAction = () => {
  return async (dispatch) => {
    try {
      const response = await dxAccountDetailsApi();
      if (response.status === 200) {
        dispatch(getDxAccountDetails(response.data.body[0]));
      }
    } catch (error) {
      dispatch(getDxAccountDetails({}));
    }
  };
};

export const toggleSidebarAction = (toggle) => {
  return {
    type: 'TOGGLE_SIDEBAR',
    payload: toggle,
  };
};
