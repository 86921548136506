import React, { useState } from "react";
import { connect } from "react-redux";

import Croppie from "croppie";

import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FadeIn } from "animate-components";

import DefaultAvatar from "../../../assets/icons/default-avatar.svg";
import CloseIcon from "../../../assets/icons/close-image.svg";
import EditProfileIcon from "../../../assets/icons/edit-profile.svg";
import ChangeAvatarPreview from "./ChangeAvatarPreview";

import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Notify,
} from "../../../components";

import {
  updateProfileDetails,
  uploadProfilePic,
} from ".././actions/profile-action.js";

import { fetchUserBoards } from "../../groups/actions/groups-action";

const useStyles = makeStyles({
  inputFieldStyles: {
    width: "80%",
  },
  inputFieldStylesDisabled: {
    width: "80%",
    color: "black",
    backgroundColor: "#ededed",
  },
  inputField: {
    display: "flex",
    flexDirection: "column",
    marginTop: 25,
  },
});

const EditProfile = ({
  openModal,
  onClose,
  userDetails,
  profileDetails,
  updateUserProfileDetails,
  uploadProfilePhoto,
  profileUploadPending,
  fetchUserBoards,
}) => {
  const classes = useStyles();
  const [userName, setUserName] = useState(userDetails.userName);
  const [firstName, setFirstName] = useState(userDetails.firstName);
  const [lastName, setLastName] = useState(userDetails.lastName);
  const [croppieInstance, setCroppieInstance] = useState(null);
  const [inputImageLoad, setInputImageLoad] = useState(false);
  const [changeAvatar, setChangeAvatar] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(
    userDetails.userImageSrc
  );
  const uploadedImage = React.useRef(null);
  const uploadPhotoRef = React.useRef(null);

  const fileChange = async ({ target }) => {
    const screenWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    let viewportSize, boundarySize;
    if (screenWidth <= 768) {
      viewportSize = 100;
      boundarySize = 138;
    } else if (screenWidth < 993) {
      viewportSize = 137;
      boundarySize = 175;
    } else {
      viewportSize = 150;
      boundarySize = 200;
    }
    await setChangeAvatar(true);
    let profileImage = new Croppie(
      document.getElementById("create-profile-image"),
      {
        viewport: { width: viewportSize, height: viewportSize, type: "circle" },
        boundary: { width: boundarySize, height: boundarySize },
        showZoomer: true,
        enableOrientation: true,
      }
    );

    let input = target;
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        profileImage
          .bind({
            url: e.target.result,
          })
          .then(function() {
            //console.log('jQuery bind complete');
            // console.log(profileImage.data.url);
            setProfileImageUrl(profileImage.data.url);
          });
      };
      reader.readAsDataURL(input.files[0]);
    } else {
      // swal("Sorry - you're browser doesn't support the FileReader API");
    }
    setCroppieInstance(profileImage);
    setInputImageLoad(true);
  };

  const uploadProfileHandler = () => {
    croppieInstance
      .result({ type: "blob", size: "original" })
      .then((blobData) => {
        const options = {
          file: blobData,
          user_id: userDetails.userID,
        };
        uploadAvatar(options);
      });
  };

  const uploadAvatar = (options) => {
    uploadProfilePhoto({ file: options.file, userId: userDetails.userID });
    setProfileImageUrl(croppieInstance.data.url);
    setChangeAvatar(false);
  };

  const handleSaveClick = () => {
    updateUserProfileDetails(
      {
        userId: userDetails.userID,
        username: userName,
        firstName: firstName,
        lastName: lastName,
      },
      {
        onSuccess: afterEditProfileDetailsUpdateSuccess,
        onError: afterProfileDetailsUpdateFail,
      }
    );

    onClose(false);
  };

  const afterEditProfileDetailsUpdateSuccess = () => {
    Notify({ value: "Profile updated successfully!" });
    fetchUserBoards();
  };

  const afterProfileDetailsUpdateFail = () => {
    Notify({ value: "Unable to update profile" });
  };

  const handleChange = (e) => {
    setFirstName(e.target.value);
  };

  const cancleUpload = () => {
    setChangeAvatar(false);
    setInputImageLoad(false);
    setProfileImageUrl(userDetails.userImageSrc);
    removePreviewImg();
  };
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const removePreviewImg = () => {
    uploadPhotoRef.current.value = null;
  };

  React.useEffect(() => {
    if (profileDetails.url !== undefined) {
      setProfileImageUrl(profileDetails.url);
    }
  }, []);

  return (
    openModal && (
      <React.Fragment>
        <Overlay />
        <div className="prompt edit-profile">
          <FadeIn duration="300ms">
            <ModalHeader
              title="Edit Profile"
              onClick={() => {
                onClose(false);
                removePreviewImg();
                setUserName(userDetails.userName);
                setFirstName(userDetails.firstName);
                setLastName(userDetails.lastName);
                setProfileImageUrl(userDetails.userImageSrc);
                setChangeAvatar(false);
              }}
              img={EditProfileIcon}
              alt={"edit-profile"}
            />
            <div className="modal_middle">
              <div className="image-section">
                <input
                  type="file"
                  id="file_input"
                  accept="image/*"
                  onChange={fileChange}
                  ref={uploadPhotoRef}
                />
                {changeAvatar && (
                  <div className="close-image" onClick={cancleUpload}>
                    <img src={CloseIcon} alt="close" />
                  </div>
                )}
                {changeAvatar ? (
                  <ChangeAvatarPreview
                    uploadProfileHandler={uploadProfileHandler}
                    cancleUpload={cancleUpload}
                  />
                ) : (
                  <img
                    src={
                      profileImageUrl !== null ? profileImageUrl : DefaultAvatar
                    }
                    width="150px"
                    height="150px"
                    ref={uploadedImage}
                    style={{ borderRadius: "50%" }}
                  />
                )}

                {!changeAvatar && (
                  <button
                    className="button-styles"
                    onClick={() => uploadPhotoRef.current.click()}
                  >
                    {profileUploadPending ? "Uploading..." : "Upload an Image"}
                  </button>
                )}
              </div>

              <div className="profile-details" data-test="edit-product">
                <span className="label-styles">Username</span>
                <TextField
                  placeholder="Username"
                  InputProps={{
                    style: { fontSize: "14px" },
                    disableUnderline: true,
                  }}
                  name="username"
                  value={userName}
                  className={classes.inputFieldStyles}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <div className={classes.inputField}>
                  <span className="label-styles">First Name</span>
                  <TextField
                    placeholder="First Name"
                    InputProps={{
                      style: { fontSize: "14px" },
                      disableUnderline: true,
                    }}
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                    className={classes.inputFieldStyles}
                  />
                </div>
                <div className={classes.inputField}>
                  <span className="label-styles">Last Name</span>
                  <TextField
                    placeholder="Last Name"
                    InputProps={{
                      style: { fontSize: "14px" },
                      disableUnderline: true,
                    }}
                    name="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className={classes.inputFieldStyles}
                  />
                </div>
                <div className={classes.inputField}>
                  <span className="label-styles">Role</span>
                  <TextField
                    placeholder="Role"
                    InputProps={{
                      style: { fontSize: "14px" },
                      disableUnderline: true,
                    }}
                    name="role"
                    value={capitalizeFirstLetter(userDetails.roleName)}
                    className={classes.inputFieldStylesDisabled}
                    disabled={true}
                  />
                </div>
                <div className={classes.inputField}>
                  <span className="label-styles">Email</span>
                  <TextField
                    placeholder="Email"
                    InputProps={{
                      style: { fontSize: "14px" },
                      disableUnderline: true,
                    }}
                    name="email"
                    value={userDetails.userEmail}
                    className={classes.inputFieldStylesDisabled}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="prompt-bottom" style={{ marginTop: 0 }}>
              <SecondaryButton
                label="Cancel"
                onClick={() => {
                  onClose(false);
                  setUserName(userDetails.userName);
                  setFirstName(userDetails.firstName);
                  setLastName(userDetails.lastName);
                  setProfileImageUrl(userDetails.userImageSrc);
                  setChangeAvatar(false);
                }}
              />
              <PrimaryButton
                label="Save"
                onClick={!profileUploadPending ? handleSaveClick : null}
                disabled={
                  profileUploadPending ||
                  firstName.trim() === "" ||
                  lastName.trim() === "" ||
                  userName.trim() === ""
                    ? true
                    : false
                }
                extraClass="prompt-done"
              />
            </div>
          </FadeIn>
        </div>
      </React.Fragment>
    )
  );
};

const mapStateToProps = (state) => ({
  profileDetails: state.Global.loggedUserDetails,
  profileUploadPending: state.Global.profileUploadPending,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfileDetails: (payload, actions) =>
    dispatch(updateProfileDetails(payload, actions)),
  uploadProfilePhoto: (payload, actions) =>
    dispatch(uploadProfilePic(payload, actions)),
  fetchUserBoards: () => dispatch(fetchUserBoards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
