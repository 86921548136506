import React, { useEffect, useState, useRef } from "react";
import axios from "../../../../utils/axiosInstance";
import { Notify, SingleSelect } from "../../../../components";
import { connect } from "react-redux";
import ToolTip from "react-tooltip";
import CloseIcon from "../../../../assets/icons/close-new.svg";
const AttachItem = ({
  file,
  onRemoveFile,
  index,
  fileNameEditDone,
  uploadFileNameError,
  selectedFolder,
  userId,
}) => {
  const [fileNameState, setFileNameState] = useState(true);
  const [errorState, setErrorState] = useState(false);
  const [fileEditState, setFileEditState] = useState(false);
  const [changedFileNameState, setChangedFileNameState] = useState(null);
  const [selectedFileIndexState, setSelectedFileIndexState] = useState(null);
  const inputEl = useRef();
  let fileName = false;

  useEffect(() => {
    setFileEditState(false);
    const folderId = selectedFolder
      ? selectedFolder
      : sessionStorage.getItem("selectedFolderId");
    const data = {
      fileName: file.name,
      fileSavingFolder: folderId,
    };
    axios
      .post("api/slidebox/checkFileExists", data)
      .then((response) => {
        if (response.data.msg === "success") {
          if (response.data.fileExits) {
            setFileNameState(
              response.data.fileExits && response.data.inSameFolder
            );
            setErrorState(
              response.data.fileExits && response.data.inSameFolder
            );
            if (response.data.inSameFolder) {
              onFileNameEdit(file, index);
              uploadFileNameError(true);
            } else {
              file["originalFileName"] = response.data.orgFileName;
              uploadFileNameError(false);
            }
          } else {
            file["originalFileName"] = file.name;
            setFileNameState(response.data.fileExits);
            setErrorState(response.data.fileExits);
            uploadFileNameError(false);
          }
        }
      })
      .catch((e) => console.warn(e));
  }, [file, selectedFolder]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [file, changedFileNameState]);

  const convertSize = (size) => {
    return parseFloat(size / 1024).toFixed(2) + " KB";
  };

  if (window.innerWidth < 767 && file.name.length >= 20) {
    fileName = file.name.replace(
      /([.\s\S]{10})([.\s\S]*)([.\s\S]{7})/g,
      "$1...$3"
    );
  }

  const onFileNameEdit = (file, index) => {
    setFileEditState(true);
    setChangedFileNameState(file.name);
    setSelectedFileIndexState(index);
  };

  const toggleInputName = (changedName) => {
    setChangedFileNameState(changedName);
  };

  const handleClickOutside = (event) => {
    if (
      (inputEl.current && inputEl.current.contains(event.target)) ||
      event.target.id === "file-edit-init-span" ||
      event.target.id === "file-edit-done-span" ||
      event.target.className === "attach-item-details" ||
      event.target.className === "back-button" ||
      event.target.className === "upload-button" ||
      event.target.className === "checkbox-container" ||
      (event.target.tagName === "INPUT" &&
        event.target.id !== "agreeToTermsOfServiceCheckBox")
    ) {
      return false;
    } else {
      if (inputEl.current && parseInt(inputEl.current.id) === index) {
        fileNameEditDone(
          file,
          parseInt(inputEl.current.id),
          changedFileNameState
        );
      }
    }
  };

  const handleClickEditDone = (event, file, index) => {
    event.preventDefault();
    if (changedFileNameState === "") {
      Notify({ value: "File name cannot be empty" });
    }
    fileNameEditDone(file, index, changedFileNameState);
  };

  const handleSelectStainChange = ({ value }) => {
    file["stain"] = value;
  };

  return (
    <div className="attach-item px-4 py-2">
      <div className="attach-item-details ">
        {fileEditState && selectedFileIndexState === index ? (
          <input
            id={index}
            ref={inputEl}
            autoFocus={true}
            type="text"
            value={changedFileNameState}
            style={{ width: "175px" }}
            onChange={(e) => toggleInputName(e.target.value)}
          />
        ) : (
          <React.Fragment>
            <div className="attach-item-details-sec">
              <span
                className="attach-item-name"
                data-for="tooltip"
                data-tip={fileName || file.name}
              >
                {fileName || file.name.length > 20
                  ? file.name.substring(0, 20) + "..."
                  : file.name}
              </span>
              {!fileNameState && !fileEditState && (
                <p className="attach-item-size">{convertSize(file.size)}</p>
              )}
            </div>
            {!fileNameState &&
              !fileEditState &&
              file.type !==
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                <SingleSelect
                  classname="stain-select"
                  defaultValue={{ value: "H&E", label: "H&E" }}
                  handleChange={handleSelectStainChange}
                  placeholder="H&E"
                  type={"stains"}
                  customStyles={true}
                  style={{ width: "100%", marginTop: 10 }}
                />
              )}
          </React.Fragment>
        )}
      </div>
      {fileEditState && errorState && (
        <div style={{ position: "absolute", marginLeft: "190px" }}>
          <span style={{ color: "red", fontSize: "11px" }}>
            File already exists in current folder. Rename it.
          </span>
        </div>
      )}
      {fileEditState && index === selectedFileIndexState ? (
        <span
          id="file-edit-done-span"
          className="file-name-edit"
          onClick={(e) => handleClickEditDone(e, file, index)}
        >
          <i class={"fa fa-check"}></i>
        </span>
      ) : (
        <span
          id="file-edit-init-span"
          className="file-name-edit"
          onClick={() => onFileNameEdit(file, index)}
        >
          <i class={"fa fa-pencil-alt"}></i>
        </span>
      )}

      <label
        className="checkbox-container"
        onClick={() => {
          onRemoveFile(index);
        }}
      >
        <img src={CloseIcon} />
      </label>
      <ToolTip id="tooltip" place="bottom" type="dark" effect="solid" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.SlideBox.selectedFolderData,
});

export default connect(mapStateToProps)(React.memo(AttachItem));
