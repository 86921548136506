import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import { valuesObject, keysObject } from "../../utils/utils";
import filterArray from "../adminDashboard/components/DashboardItems/FilterArray";
import FilterIcon from '../../assets/icons/filter-icon.svg';

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={5}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

let filter = {
  caseOrFolder: [],
  review_status: [],
  lastModified: [],
  assignedToFullName: [],
};

let counter = {};

const SlideboxFilter = ({
  filterData,
  slideDirDataList,
  setFilterData,
  memberDetails,
}) => {
  const [blink, setBlink] = useState(false);

  let filterAttributes = {
    Type: ["folder", "case"],
    Case_Status: ["review", "pending", "completed"],
    Assigned_To: memberDetails
      .filter((member) => member.status !== "invited")
      .map((member) => member.firstName + " " + member.lastName),
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleResetClick = (e) => {
    const items = document.getElementsByName("checkbox");
    for (let i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") items[i].checked = false;
    }
    setBlink(true);

    filter = {
      caseOrFolder: [],
      review_status: [],
      assignedToFullName: [],
    };

    setTimeout(() => {
      setFilterData(slideDirDataList);
      setBlink(false);
    }, 500);
  };

  useEffect(() => {
    counter = {};
    filterData !== undefined &&
      filterData.length !== 0 &&
      filterData.forEach(
        ({ caseOrFolder, review_status, assignedToFullName }) => {
          counter[caseOrFolder] = (counter[caseOrFolder] || 0) + 1;
          counter[review_status] = (counter[review_status] || 0) + 1;
          counter[assignedToFullName] = (counter[assignedToFullName] || 0) + 1;
        }
      );
  }, [filterData, setFilterData]);

  const handleFilterChange = (name) => async (event) => {
    setBlink(true);
    let finalName;
    if (name === "Type") {
      finalName = "caseOrFolder";
    } else if (name === "Assigned To") {
      finalName = "assignedToFullName";
    } else if (name === "Case Status") {
      finalName = "review_status";
    } else if (name === "Date Modified") {
      finalName = "lastModified";
    }
    const nameValue =
      name === "Assigned To"
        ? event.target.value
        : event.target.value.toLowerCase().trim();

    const checked = event.target.checked;

    Object.keys(filter).forEach(async (filterKeys) => {
      if (filterKeys === finalName && checked) {
        filter[filterKeys].push(nameValue);
      }
      if (filterKeys === finalName && !checked) {
        filter[filterKeys].forEach((removeValue, index) => {
          if (removeValue === nameValue) {
            filter[filterKeys].splice(index, 1);
          }
        });
      }
    });
    setTimeout(() => {
      setFilterData(filterArray(slideDirDataList, filter));
      setBlink(false);
    }, 500);
  };

  return (
    <React.Fragment>
      <button
        className="filter-btn"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          marginLeft: "20px",
          border: "1px solid #ddd",
          backgroundColor: "transparent",
          padding: "5px 15px",
          borderRadius: "5px",
          flex: "0 0 130px",
          textAlign: "left",
          height: 35,
          outline: "none",
        }}
      >
        <img src={FilterIcon} alt="filter" />
        Filters
      </button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="filter-dropdown"
      >
        <div className="filter-heading">
          <h5 className="filter-dropdown-title">Filter by</h5>
          <div className="reset-filters-btn">
            <button onClick={handleResetClick}>Reset filters</button>
          </div>
        </div>
        <div className="filter-wrap">
          <div className="filter-container">
            {valuesObject(filterAttributes).map((data, index) => {
              const tName = keysObject(filterAttributes)[index].replace(
                /_/g,
                " "
              );
              return (
                <div className="filter-dropdown-wrapper" key={index}>
                  <div className="filter-box">
                    <h5>{tName}</h5>
                    <div className="filter-checkbox-container">
                      {data.map((name, allIndex) => {
                        return (
                          <React.Fragment key={allIndex}>
                            <div className="filter-checkbox">
                              <input
                                type="checkbox"
                                name="checkbox"
                                value={name}
                                onChange={handleFilterChange(tName)}
                              />
                              <label htmlFor={allIndex}>
                                {name} ({counter[name] || 0})
                              </label>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </StyledMenu>
    </React.Fragment>
  );
};

export default SlideboxFilter;
