import React from "react";
import SlideTable from "./SlideTable/SlideTable";
import SlideGridView from "./SlideGridView";

const ListView = ({
  listData,
  showLoader,
  toggleDirHandler,
  setSelectedDir,
  isSearch,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  selectedListItem,
  showGroupsSelectPopup,
  selectedFolderType,
  isListView,
  downloadSlide,
  filteredData,
  isModal,
  when,
  setContinueButtonDisabled,
  setSelectedSlideDataToAnalyze,
  importSlide,
  selectedSlideDataToAnalyze,
  getSearchDirData,
  setLabel,
  setRadioButtonChecked,
  radioButtonChecked,
}) => {
  return (
    <div className="slide-content">
      <SlideTable
        listData={listData}
        showLoader={showLoader}
        toggleDirHandler={toggleDirHandler}
        setSelectedDir={setSelectedDir}
        isSearch={isSearch}
        showSharedPopup={showSharedPopup}
        deleteDirItemPopup={deleteDirItemPopup}
        renameSlideItem={renameSlideItem}
        handleToggleMovePopup={handleToggleMovePopup}
        setShowDetails={setShowDetails}
        selectedListItem={selectedListItem}
        showGroupsSelectPopup={showGroupsSelectPopup}
        selectedFolderType={selectedFolderType}
        isListView={isListView}
        downloadSlide={downloadSlide}
        filteredData={filteredData}
        isModal={isModal}
        when={when}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
        importSlide={importSlide}
        selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
        getSearchDirData={getSearchDirData}
        setLabel={setLabel}
        radioButtonChecked={radioButtonChecked}
        setRadioButtonChecked={setRadioButtonChecked}
      />
    </div>
  );
};

const GridView = ({
  gridData,
  setSelectedDir,
  switchView,
  toggleDirHandler,
  showLoader,
  selectedList,
  selectedListType,
  deleteDirItemPopup,
  showSharedPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  selectedListItem,
  isModal,
  showGroupsSelectPopup,
  downloadSlide,
}) => {
  return (
    <div className="grid-content">
      <SlideGridView
        gridData={gridData}
        setSelectedDir={setSelectedDir}
        switchView={switchView}
        toggleDirHandler={toggleDirHandler}
        showLoader={showLoader}
        selectedList={selectedList}
        selectedListType={selectedListType}
        deleteDirItemPopup={deleteDirItemPopup}
        showSharedPopup={showSharedPopup}
        renameSlideItem={renameSlideItem}
        handleToggleMovePopup={handleToggleMovePopup}
        setShowDetails={setShowDetails}
        selectedListItem={selectedListItem}
        isModal={isModal}
        showGroupsSelectPopup={showGroupsSelectPopup}
        downloadSlide={downloadSlide}
      />
    </div>
  );
};

const SlideboxListView = ({
  // view = "list",
  view = "list",
  slideDirDataList,
  showLoader,
  toggleDirHandler,
  setSelectedDir,
  isSearch,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  selectedListItem,
  showGroupsSelectPopup,
  switchView,
  setSwitchView,
  selectedListType,
  selectedList,
  selectedFolderType,
  isListView,
  downloadSlide,
  filteredData,
  isModal,
  when,
  setContinueButtonDisabled,
  setSelectedSlideDataToAnalyze,
  importSlide,
  selectedSlideDataToAnalyze,
  getSearchDirData,
  setLabel,
  radioButtonChecked,
  setRadioButtonChecked,
}) => {
  // if (view === "list")
  if (isListView) {
    return (
      <ListView
        listData={filteredData}
        showLoader={showLoader}
        toggleDirHandler={toggleDirHandler}
        setSelectedDir={setSelectedDir}
        isSearch={isSearch}
        showSharedPopup={showSharedPopup}
        deleteDirItemPopup={deleteDirItemPopup}
        renameSlideItem={renameSlideItem}
        handleToggleMovePopup={handleToggleMovePopup}
        setShowDetails={setShowDetails}
        selectedListItem={selectedListItem}
        showGroupsSelectPopup={showGroupsSelectPopup}
        selectedList={selectedList}
        selectedListType={selectedListType}
        selectedFolderType={selectedFolderType}
        downloadSlide={downloadSlide}
        isModal={isModal}
        when={when}
        setContinueButtonDisabled={setContinueButtonDisabled}
        setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
        importSlide={importSlide}
        selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
        getSearchDirData={getSearchDirData}
        setLabel={setLabel}
        radioButtonChecked={radioButtonChecked}
        setRadioButtonChecked={setRadioButtonChecked}
      />
    );
  } else
    return (
      <GridView
        gridData={filteredData}
        showLoader={showLoader}
        toggleDirHandler={toggleDirHandler}
        setSelectedDir={setSelectedDir}
        isSearch={isSearch}
        switchView={switchView}
        selectedList={selectedList}
        selectedListType={selectedListType}
        showSharedPopup={showSharedPopup}
        deleteDirItemPopup={deleteDirItemPopup}
        renameSlideItem={renameSlideItem}
        handleToggleMovePopup={handleToggleMovePopup}
        setShowDetails={setShowDetails}
        selectedListItem={selectedListItem}
        isModal={isModal}
        showGroupsSelectPopup={showGroupsSelectPopup}
        downloadSlide={downloadSlide}
        when={when}
      />
    );
};

export default SlideboxListView;
