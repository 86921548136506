import React from "react";
import { Link, withRouter } from "react-router-dom";
import { FeatureGate } from "react-feature-gate";

const routeConfigSuperAdmin = [
  {
    url: "/dashboard",
    name: "Dashboard",
    tag: "dashboard img",
  },
  {
    url: "/",
    name: "Slidebox",
    tag: "slidebox img",
  },
  {
    url: "/groups",
    name: "Groups",
    tag: "groups img",
  },
  {
    url: "/analysis-dashboard",
    name: "Analysis",
    tag: "analysis img",
  },
];

const routeConfigAdmin = [
  {
    url: "/dashboard",
    name: "Dashboard",
    tag: "dashboard img",
  },
  {
    url: "/",
    name: "Slidebox",
    tag: "slidebox img",
  },
  {
    url: "/groups",
    name: "Groups",
    tag: "groups img",
  },
  {
    url: "/analysis-dashboard",
    name: "Analysis",
    tag: "analysis img",
  },
];

const routeConfigManager = [
  {
    url: "/dashboard",
    name: "Dashboard",
    tag: "dashboard img",
  },
  {
    url: "/",
    name: "Slidebox",
    tag: "slidebox img",
  },
  {
    url: "/groups",
    name: "Groups",
    tag: "groups img",
  },
  {
    url: "/analysis-dashboard",
    name: "Analysis",
    tag: "analysis img",
  },
];

const routeConfigUser = [
  {
    url: "/dashboard",
    name: "Dashboard",
    tag: "dashboard img",
  },
  {
    url: "/",
    name: "Slidebox",
    tag: "slidebox img",
  },
  {
    url: "/groups",
    name: "Groups",
    tag: "groups img",
  },
  {
    url: "/analysis-dashboard",
    name: "Analysis",
    tag: "analysis img",
  },
];

const Route = ({ activeUrl, name, tag, url }) => {
  return (
    <div
      className={`route ${
        activeUrl === "/analysis"
          ? url.includes(activeUrl)
            ? "active"
            : ""
          : url === activeUrl
          ? "active"
          : ""
      }`}
      id={
        name === "Slidebox"
          ? "leftSidebarSlidebox"
          : name === "Dashboard"
          ? "leftSidebarDashboard"
          : ""
      }
    >
      {
        <Link to={url} className={name}>
          <div className={tag}></div>
          <span>{name}</span>
        </Link>
      }
    </div>
  );
};

const LeftSidebar = ({ collapseSidebar, history, userData }) => {
  const path = history.location.pathname.replace(/(?!^)(\W.+)/, "");
  let routeData = [];
  if (userData.roleId === 1) {
    routeData = routeConfigAdmin;
  }
  if (userData.roleId === 2) {
    routeData = routeConfigManager;
  }

  if (userData.roleId === 3) {
    routeData = routeConfigUser;
  }

  if (userData.roleId === 4) {
    routeData = routeConfigSuperAdmin;
  }

  return (
    <div
      className={`left-sidebar-component sidebar-${
        collapseSidebar ? "collapse" : "open"
      }`}
    >
      <div
        className="fixed-container"
        style={{ width: `${fixedWidth(collapseSidebar)}` }}
      >
        {routeData.map((route, index) => {
          if (route.name === "Analysis") {
            return (
              <FeatureGate feature="analysisSection">
                <Route
                  key={index}
                  {...route}
                  activeUrl={path}
                  collapse={collapseSidebar}
                />
              </FeatureGate>
            );
          } else {
            return (
              <Route
                key={index}
                {...route}
                activeUrl={path}
                collapse={collapseSidebar}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

const fixedWidth = (collapseSidebar) => {
  if (collapseSidebar) {
    return "71px";
  } else {
    return "250px";
  }
};

export default withRouter(LeftSidebar);
