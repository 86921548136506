import {
  editProfileAPI,
  updateProfilePictureAPI,
  changePasswordAPI,
  changeNotificationPreferenceAPI,
} from "../services/profile-service";
import {
  resetPicUpload,
  getUserDetailsAction,
  updateUserPropileAction,
  uploadProfilePicPending,
} from "../../../actions/global-action";

import { getUserListDataAction } from "../../../pages/adminDashboard/actions/adminDashboard-action";

export const profileDetailsUpdate = (payload) => {
  return {
    type: "UPDATE_USER_PROFILE_DETAILS",
    payload,
  };
};

export const changePasswordAction = (payload) => {
  return {
    type: "CHANGE_PASSWORD",
    payload,
  };
};

export const changeNotificationPreferenceAction = (payload) => {
  return {
    type: "CHANGE_NOTIFICATION_PREFERENCE",
    payload,
  };
};

export const changeUserPassword = (data, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await changePasswordAPI(data);
      if (response.data.success) {
        dispatch(changePasswordAction(data));
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      //console.log(error);
      onError();
    }
  };
};

export const updateProfileDetails = (data, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await editProfileAPI(data);
      if (response.data.success) {
        dispatch(profileDetailsUpdate(data));
        dispatch(getUserDetailsAction());
        dispatch(getUserListDataAction());
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      //console.log(error);
      onError();
    }
  };
};

export const uploadProfilePic = (data, callbackFn) => {
  return async (dispatch) => {
    // const { onSuccess, onError } = callbackFn;
    try {
      dispatch(uploadProfilePicPending(true));
      const response = await updateProfilePictureAPI(data);
      if (response.data.success) {
        dispatch(resetPicUpload(data));
        dispatch(updateUserPropileAction(response.data.url));
        dispatch(uploadProfilePicPending(false));
      } else {
        // onError();
      }
    } catch (error) {
      // onError();
      // console.log("error", error);
    }
  };
};

export const changeNotificationPreference = (data, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await changeNotificationPreferenceAPI(data);
      if (response.data.success) {
        dispatch(changeNotificationPreferenceAction(data));
        dispatch(getUserDetailsAction());
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      console.log(error);
      onError();
    }
  };
};
