import React, { useState, useRef } from 'react';
import moment from 'moment';
import DefaultAvatar from '../../../assets/icons/default-avatar.svg';
import PdfIcon from '../../../assets/icons/pdf-icon-new.svg';
import DocIcon from '../../../assets/icons/DocIcon-new.svg';
import CsvIcon from '../../../assets/icons/csvIcon-new.svg';
import EditIcon from '../../../assets/icons/message-edit-icon.svg';
import DeleteIcon from '../../../assets/icons/message-delete-icon.svg';
import { connect } from 'react-redux';
import axios from '../../../utils/axiosInstance';
import { Notify } from '../../index';
import Fade from '../../Animations/Fade';
import GroupDeleteMessage from '../../../pages/groups/components/group-delete-message';
import { editMessage } from '../../../pages/groups/actions/groups-action';
import UserProfilePopup from '../../../pages/profile/components/UserProfilePopup';

const FileItem = ({
  userId,
  firstName,
  surname,
  body,
  filename,
  time,
  userImageSrc,
  img,
  loggedId,
  status,
  type,
  setEdit,
  edit,
  id: msgId,
  group,
  email,
  roleName,
  dispatch,
}) => {
  const [editing, setEditing] = useState('');
  const inputRef = useRef(null);
  const [toDelete, setToDelete] = useState(-1);

  const formattedTime = moment(time).format('h:mm A');

  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();
  const [userDetails, setUserDetails] = useState({
    firstName,
    lastName: surname,
    userEmail: email,
    roleName,
    userImageSrc,
  });

  const sendEdit = async (id) => {
    const formData = new FormData();
    formData.append('tumorBoardId', group.id);
    formData.append('msg', editing);
    formData.append('msgId', id);
    const { status, data } = await axios
      .put('api/groups/msgs', formData, { withCredentials: true })
      .catch(() => {
        Notify({ value: 'Error editing message.' });
      });
    if (status === 200) {
      dispatch(editMessage(id, editing));
    }
    setEditing('');
    setEdit(-1);
  };

  const handleModalOpen = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setModalOpen(!modalOpen);
    setPlacement(newPlacement);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="conversation-item">
      <div>
        {/* TODO: remove absolute link */}
        <img
          src={userImageSrc || DefaultAvatar}
          alt="user"
          className="user-img"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = { DefaultAvatar };
          }}
          onMouseOver={handleModalOpen('right-end')}
          onMouseOut={handleModalClose}
        />
      </div>
      <div className="conversation-item-content">
        <div className="conversation-item-details">
          <h3 className="details-fullname">{`${firstName} ${surname}`}</h3>
          <p>{formattedTime}</p>
          {status === 'updated' && <span>&nbsp;(edited)</span>}
          {loggedId === userId &&
            type !== 'joined' &&
            type !== 'removed' &&
            status !== 'deleted' && (
              <img
                src={EditIcon}
                className="edit-and-delete"
                alt="edit"
                onClick={() => setEdit(msgId)}
              />
            )}
          {loggedId === userId &&
            type !== 'joined' &&
            type !== 'removed' &&
            status !== 'deleted' && (
              <img
                src={DeleteIcon}
                className="edit-and-delete"
                alt="delete"
                onClick={() => setToDelete(msgId)}
              />
            )}
        </div>
        <div className="conversation-message">
          {edit === msgId ? (
            <div className="message-field">
              <input
                autoFocus
                onFocus={() => setEditing(body)}
                value={editing}
                onChange={() => setEditing(inputRef.current.value)}
                ref={inputRef}
              />
              <div className="actions-button">
                <button
                  id="cancel"
                  className="cancel-button"
                  onClick={() => setEdit(-1)}
                >
                  Cancel
                </button>
                <button id="send" onClick={() => sendEdit(msgId)}>
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <p>{status !== 'deleted' ? body : 'This message was deleted.'}</p>
          )}
          <a href={img} className="file-item">
            {filename.substring(filename.length - 3) === 'pdf' ? (
              <img src={PdfIcon} alt="pdf" />
            ) : filename.substring(filename.length - 3) === 'csv' ? (
              <img src={CsvIcon} alt="csv" />
            ) : (
              <img src={DocIcon} alt="doc" />
            )}
            <span>{filename}</span>
          </a>
        </div>
      </div>
      <Fade show={toDelete !== -1} duration="300ms">
        <GroupDeleteMessage close={() => setToDelete(-1)} toDelete={msgId} />
      </Fade>
      <UserProfilePopup
        openModal={modalOpen}
        onClose={handleModalClose}
        userDetails={userDetails}
        isDashboard={false}
        isHeader={false}
        placement={placement}
        anchorEl={anchorEl}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loggedUserDetails } = state.Global;
  const { selectedBoard: group } = state.Groups;
  return {
    loggedId: loggedUserDetails.userID,
    group,
  };
};

export default connect(mapStateToProps)(FileItem);
