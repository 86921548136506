import React from "react";
import FolderDirectory from "./FolderDirectory";
import CaseDirectory from "./CaseDirectory";
import FolderRootDirectory from "./FolderRootDirectory";

const ChildDirectory = ({
  children,
  toggleDir,
  setSelectedDir,
  setExpandDir,
  slideboxLeftSiderIsCollapse,
}) => {
  return (
    <React.Fragment>
      {children.map((dirData, index) => {
        const fieldset = {
          ...{
            ...dirData,
            toggleDir,
            setSelectedDir,
            setExpandDir,
            slideboxLeftSiderIsCollapse,
          },
        };
        return (
          <React.Fragment key={index}>
            {dirData.caseOrFolder === "folder" &&
              dirData.children.length === 0 && (
                <FolderDirectory {...fieldset} />
              )}
            {dirData.caseOrFolder === "case" &&
              dirData.children.length === 0 && <CaseDirectory {...fieldset} />}

            {dirData.caseOrFolder === "folder" &&
              dirData.children.length > 0 && (
                <FolderRootDirectory
                  {...{
                    dirData,
                    toggleDir,
                    setSelectedDir,
                    setExpandDir,
                    slideboxLeftSiderIsCollapse,
                  }}
                  data-test="test"
                />
              )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default ChildDirectory;
