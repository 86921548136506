import React from 'react';
import { bytesToSize } from '../../../../utils/utils';
import $ from 'jquery';
import { getUserDetailsAction } from '../../../../actions/global-action';
import { connect } from 'react-redux';

const TotalSlideBoxStorage = ({
  storageUsed,
  storageLimit,
  loggedUserDetails,
  getLoggedUserDetails,
}) => {
  const [usage, setUsage] = React.useState('');
  const [total, setTotal] = React.useState('');
  React.useEffect(() => {
    getLoggedUserDetails();
    const usagePercentage = storageUsed / storageLimit;
    let barSize = $('.meteo-progress').width();
    setUsage(bytesToSize(storageUsed));
    setTotal(bytesToSize(storageLimit));
    $('.meteo-progress > span').each(function() {
      $(this)
        .data('origWidth', barSize * usagePercentage)
        .width(0)
        .animate({ width: $(this).data('origWidth') }, 1200);
    });
  }, []);

  React.useEffect(() => {
    setUsage(bytesToSize(loggedUserDetails.storageUsed));
  }, [loggedUserDetails]);

  return (
    <div className="total-slide-box-storage-container">
      <div>
        {usage} of {total} used
      </div>
      <div className="meteo-progress">
        <span></span>
      </div>
      {/* <div>
                <a href="mailto:info@crosscope.com?subject=Request to extend Crosscope Storage&amp;body=Hi, <br /> [Add your message here] <br /> <br />">
                    Extend Storage
                </a>
            </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedUserDetails: state.Global.loggedUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TotalSlideBoxStorage);
