import React from "react";
import FolderDirActiveIcon from "../../../../assets/icons/folder-dir-open.svg";
import FolderDirInactiveIcon from "../../../../assets/icons/folder-close-new.svg";
import FolderDirOpen from "../../../../assets/icons/folder-open-new.svg";
import DirOpenIcon from "../../../../assets/icons/directory-open.svg";
import DirCloseIcon from "../../../../assets/icons/directory-close.svg";
import classnames from "classnames";

const FolderDirectory = ({
  open,
  active,
  dirName,
  dirId,
  listId,
  parentDir,
  insideRoot,
  customClass = null,
  toggleDir,
  setSelectedDir,
  setExpandDir,
}) => {
  return (
    <div
      data-test="folder-directory"
      className={classnames(
        active
          ? customClass
            ? `folderDirectory-container-${customClass} active`
            : "folderDirectory-container active"
          : customClass
            ? `folderDirectory-container-${customClass}`
            : "folderDirectory-container"
      )}
      onClick={() =>
        setSelectedDir({
          dirId,
          listId,
          parentDir: insideRoot ? null : parentDir,
          dirType: "folder",
        })
      }
      onDoubleClick={() =>
        insideRoot
          ? toggleDir({ dirId, listId, parentDir: null, dirType: "folder" })
          : toggleDir({ dirId, listId, parentDir, dirType: "folder" })
      }
    >
      <img
        data-test="dirIcon"
        src={open ? DirOpenIcon : DirCloseIcon}
        onClick={() =>
          insideRoot
            ? setExpandDir({
              dirId,
              listId,
              parentDir: null,
              dirType: "folder",
            })
            : setExpandDir({ dirId, listId, parentDir, dirType: "folder" })
        }
        className="dir-open-close"
      />
      <img
        data-test="dirIcon"
        src={
          open ? FolderDirOpen
            : active ? FolderDirActiveIcon : FolderDirInactiveIcon
        }
        className={classnames("folder-dir")}
      />
      {dirName && dirName.length > 21
        ? dirName.substring(0, 20) + "..."
        : dirName}
    </div>
  );
};

export default FolderDirectory;
