import { useEffect } from "react";
import axios from './axiosInstance';
import { Notify } from '../components';
// import { getUserDetails, getMutualUsers } from "../actions/user";
// import { isFollowing, getUserStats } from "../actions/follow";
// import { getUserPosts, getGroupPosts } from "../actions/post";
// import { getGroupDetails, joinedGroup } from "../actions/group";
import Compress from 'image-compressor.js';
import d from './dom';

/**
 *  Shortens what with string length
 * @param {String} what
 * @param {Number} length
 */
export const shortener = (what, length) => {
  let parse = parseInt(length),
    len = what.length;
  if (!parse) {
    return;
  }
  return len >= parse
    ? `${what.substr(0, length - 2)}..`
    : len < parse
      ? what
      : null;
};

/**
 * Returns data stored in dataset
 * @param {String} what Which data
 */
export const uData = (what) => true;

/**
 * Returns unique string, useful for key
 */

export const uDetails = (what) => {
  const { User } = true;
  return User[what];
};

export const isMine = (userId) => {
  const { User } = true;
  return User['user_details'].id === userId;
};

export const uniq = () =>
  Math.random()
    .toString(5)
    .slice(2);

export const randNum = () => Math.random() * 200;

/**
 * Returns human-readable text
 *
 * @param {Number} value
 * @param {String} text
 */
export const humanReadable = (value, text) => {
  let hr =
    value == 0 ? `No ${text}s` : value == 1 ? `1 ${text}` : `${value} ${text}s`;
  return hr;
};

/**
 * Toggles the element
 * @param {HTMLElement} el element to toggle
 */
export const toggle = (el) => {
  let style = el.style.display;
  style === 'none' ? (el.style.display = 'block') : (el.style.display = 'none');
};

/**
 * Capitalizes str
 * @param {String} str
 */
export const c_first = (str) => str.charAt(0).toUpperCase() + str.substr(1);

/**
 * Removes hr of last element of modal
 */
export const llr = () => {
  let elements = Array.from(new d('.modal_items').toAll());
  let element = elements[elements.length - 1];

  element
    ? Array.from(element.children).map((child) =>
      child.nodeName == 'HR' ? child.remove() : null
    )
    : null;
};

/**
 * Toggle show password
 */
export const viewPassword = ({ input, icon }) => {
  let _input = new d(input);
  let _icon = new d(icon);

  if (_input.getAttr('type') == 'password') {
    _input.setAttr('type', 'text');
    _icon.html('<i class="fas fa-unlock-alt"></i>');
    _icon.css('color', '#e91e63');
  } else {
    _input.setAttr('type', 'password');
    _icon.html('<i class="fas fa-lock"></i>');
    _icon.css('color', 'darkturquoise');
  }
  _input.focus();
};

/**
 * For replacing illegal characters
 */
export const replacer = (el, filter) => {
  let elem = new d(el);
  let regex =
    filter == 'normal' ? /[^a-z0-9_.@$#]/i : filter == 'bio' ? /[<>]/i : null;

  elem.on('keyup', (e) => {
    let value = e.currentTarget.value;
    elem.setValue(value.replace(regex, ''));
  });
};

/**
 * Returns whether it's me
 */
export const Me = (user) => true;

/**
 * Returns whether email is verified
 */
export const e_v = () => {
  let ea = uData('emailVerified');
  return ea == 'yes' ? true : false;
};

/**
 * Returns whether user is private
 */
export const isPrivate = (user, isFollowing, accountType) => {
  let sprivate =
    !Me(user) && !isFollowing && accountType == 'private' ? true : false;
  return sprivate;
};

/**
 * Compresses and returns file
 * @param {File} file
 */
export const imageCompressor = (file) => {
  return new Promise((resolve) => {
    new Compress(file, {
      quality: 0.6,
      success: (file) => resolve(file),
      error: (err) => console.log(err.message),
    });
  });
};

/**
 * Scrolls down to 380
 */
export const bottomScroll = () => (new d('html, body').toDOM().scrollTop = 380);

/**
 * Notifies user [on the notification page]
 * @param {Object} options
 * @param {Number} options.to
 * @param {String} options.type
 * @param {Number} options.post_id
 * @param {Number} options.group_id
 * @param {Number} options.user
 */

export const insta_notify = async (options) => {
  let defaults = {
    to: null,
    type: '',
    post_id: 0,
    group_id: 0,
    user: 0,
  },
    obj = { ...defaults, ...options },
    { to, type, post_id, group_id, user } = obj;

  await axios.post('/api/notify', {
    to,
    type,
    post_id,
    group_id,
    user,
  });
};

/**
 * Dispatcher helper for dispatching data retrieved from URL
 *
 * @param {String} type Dispatch type
 * @param {String} url /api/URL to get data from
 * @param {Object} data data requested with the url
 */

export const dispatchHelper = (type, url, data = {}) => {
  return (dispatch) =>
    axios
      .post(`/api/${url}`, data)
      .then((p) => {
        if (type === 'GET_FEED') {
          if (p.data.length === 0) {
            dispatch({ type: 'SET_ALL_FEED_LOADED' });
          } else {
            dispatch({ type, payload: { data: p.data, page: data.page } });
          }
        } else {
          if (type === 'GET_QUORUM') {
            dispatch({ type, payload: p.data.quorum_post });
          } else if (type === 'GET_ANNOTATION_COLOR') {
            dispatch({ type, payload: p.data.color });
          } else if (type === 'GET_IS_LOGOUT_USER') {
            dispatch({ type, payload: p.data.status });
          } else {
            dispatch({ type, payload: p.data });
          }
        }
      })
      .catch((e) => console.log(e));
};

/**
 * If mssg is an array returns first element else returns it as a string.
 *
 * @param {String} mssg Message value
 * @returns { String } Individual string message
 */
export const ObjectMssg = (mssg) => {
  return typeof mssg == 'object' ? (mssg.length > 0 ? mssg[0] : mssg) : mssg;
};

/**
 * Notifies 'please wait..'
 */
export const wait = () => {
  Notify({ value: 'Please wait..' });
};

/**
 * If loading, then add 'cLoading' class to the specified component which hides it until it is loaded
 * @param {Boolean} loading
 */
export const cLoading = (loading) => `${loading ? 'cLoading' : ''}`;

/**
 * Request a response from an API endpoint.
 * @param {String} url Url to get response from
 * @param {Object} data Optional data to pass
 * @param {String} method Method type. Default is post
 */
export const APIRequest = (url, data = {}, method = 'post') => {
  return new Promise((resolve, reject) => {
    axios[method](url, data)
      .then((resp) => resolve(resp))
      .catch((err) => reject(err));
  });
};

export const dateDetails = (dateTimesStamp) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return new Date(parseInt(dateTimesStamp)).toLocaleString('en-US', options);
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (index === 0) return `${bytes} ${sizes[index]}`;
  return `${(bytes / 1024 ** index).toFixed(1)} ${sizes[index]}`;
};

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

export function convertAreaUnit(area, unit) {
  // currently only "µm" or "cm"
  let nArea = area;
  let nUnit = unit;
  if (area > 10000 && unit === 'µm') {
    // convert to mm square
    nArea = (area / 1000000).toFixed(2);
    nUnit = 'mm';
  }

  return nArea + ' ' + nUnit;
}

export const getAnnotationArea = (type, height, width, unit, ratio) => {
  let area = null;
  switch (type) {
    case 'ellipse':
      area = Math.ceil(Math.PI * height * width * ratio);
      return convertAreaUnit(area, unit);
    case 'rect':
      area = Math.ceil(height * width * ratio);
      return convertAreaUnit(area, unit);
    default:
      return null;
  }
};

export const getViewportSize = () => {
  return {
    width: Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ),
    height: Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ),
  };
};

export const getAnnotationPopupPosition = (
  oCoords,
  popupWidth,
  popupHeight
) => {
  // default offset
  const offset = 10;
  const headerHeight = 70;
  const annotationToolbarHeight = 50;
  const viewport = getViewportSize();
  let offsetX = 300;
  let offsetY = 300;

  if (!oCoords || !popupWidth || !popupHeight) {
    return {};
  }

  let { bl, br, tl, tr, ml, mr, mb, mt, mtr } = oCoords;

  let leftBottomMost = {
    x: Math.min(bl.x, br.x, tl.x, tr.x, ml.x, mr.x, mb.x, mt.x, mtr.x),
    y: Math.max(bl.y, br.y, tl.y, tr.y, ml.y, mr.y, mb.y, mt.y, mtr.y),
  };

  let topMost = Math.min(
    leftBottomMost.y,
    br.y,
    tl.y,
    tr.y,
    ml.y,
    mr.y,
    mb.y,
    mt.y,
    mtr.y
  );

  let placeAtBtm = headerHeight + leftBottomMost.y + popupHeight + offset;
  let placeAtTop = popupHeight + offset + annotationToolbarHeight;

  // for Y
  if (viewport.height > placeAtBtm) {
    // default firstly try to place at bottom
    offsetY = leftBottomMost.y + offset;
    offsetX = leftBottomMost.x;
  } else if (topMost > placeAtTop) {
    offsetY = topMost - (popupHeight + offset);
    offsetX = leftBottomMost.x;
  }

  // check if x exceed screen width
  const mainSidebar = document.getElementById('main-left-sidebar');
  const viewerSidebar = document.getElementById('viewer-collapsible-sidebar');
  const groupFlatlist = document.getElementById('group-flatlist');
  const groupChat = document.getElementById('group-chat');
  const quorumSidebar = document.getElementById('quorum-left-sidebar');

  let mainSidebarWith =
    mainSidebar && mainSidebar.offsetWidth ? mainSidebar.offsetWidth : 0;
  let secondarySidebarWidth = 0;
  if (viewerSidebar && viewerSidebar.offsetWidth) {
    secondarySidebarWidth += viewerSidebar.offsetWidth;
  }
  if (groupFlatlist && groupFlatlist.offsetWidth) {
    secondarySidebarWidth += groupFlatlist.offsetWidth;
  }
  if (groupChat && groupChat.offsetWidth) {
    secondarySidebarWidth += groupChat.offsetWidth;
  }
  if (quorumSidebar && quorumSidebar.offsetWidth) {
    secondarySidebarWidth += quorumSidebar.offsetWidth;
  }

  const overWidth =
    viewport.width -
    (mainSidebarWith + secondarySidebarWidth + offsetX + popupWidth);
  if (overWidth < 0) {
    offsetX += overWidth;
  }

  return {
    top: offsetY + 'px',
    left: offsetX + 'px',
  };
};
export const keysObject = (object) => Object.keys(object);

export const valuesObject = (object) => Object.values(object);

export const emailValidation = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.toLowerCase());
};

export const loadParentDirArray = (
  selectedDir,
  selectedDirType,
  parentFolder,
  folders
) => {
  const array = [];
  const dirArray = loadDirData(
    selectedDir,
    selectedDirType,
    parentFolder,
    folders,
    array
  );
  return dirArray.reverse();
};

export const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (typeof e.target.className === "string"
      && (e.target.className.includes('MuiGrid-container')
        || e.target.className.includes('filter-i-input')
        || e.target.className.includes('slideboxData-container')
        || e.target.className.includes('tree-view-section')
        || e.target.className.includes('filter-slidebox')
        || e.target.className.includes('header_container')
        || e.target.className.includes('header_right')
      )) {
      callback();
    }

  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const loadDirData = (id, idType, parentId, folders, dirArray) => {
  const currentPk = `${idType}-${id}`;
  const parentPk = `folder-${parentId}`;
  if (parentId) {
    const selectedDirData = folders.filter(
      (dirData) => dirData.pk === parentPk
    );
    const [{ dirId, parentDir }] = selectedDirData;
    dirArray.push(currentPk);
    loadDirData(dirId, 'folder', parentDir, folders, dirArray);
  } else {
    dirArray.push(currentPk);
  }

  return dirArray;
};
