import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { SocketContext } from "../../../../utils/serviceContext";
import * as slideBoxActions from "../../actions/slidebox-action";
import _ from "lodash";
import { ProgressBar } from "react-bootstrap";
import CloseIcon from '../../../../assets/icons/close-new.svg'


class ProgressBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackBar: props.open,
      uploadFileList: props.fileList,
      isConverting: {},
      isConverted: {},
      uploadProgress: {},
      expanding: true,
    };
    this.uploadSucess = {};
    this.onFileUploadDoneSubscriber = undefined;
  }

  static contextType = SocketContext;

  componentWillReceiveProps(newProps) {
    if (!_.isEmpty(newProps.conversionComplete)) {
      this.setState({
        isConverting: {
          ...this.state.isConverting,
          [newProps.conversionComplete.name]: true,
        },
      });
    }
    if (newProps.fileList && this.props.fileList) {
      this.setState({
        uploadFileList: [...newProps.fileList],
      });
    }
  }

  componentDidMount() {
    this.onFileUploadDoneSubscriber = this.context
      .onFileUploadDone()
      .subscribe((data) => {
        const { closeCallBack } = this.props;
        setTimeout(() => {
          if (
            this.state.uploadFileList.filter((f) => f.progress !== 100)
              .length === 0
          ) {
            closeCallBack("Uploaded", true);
            if (this.props.history.location.pathname === "/slidebox") {
              this.props.fetchSlideBoxFolders();
            }
          }
        }, 1000);
      });
    this.context.onFileUploadProgress().subscribe((data) => {
      if (data.progress === 100) {
        setTimeout(() => {
          this.uploadSucess[data.name] = true;
        }, 300);
      }
      this.setState({
        uploadProgress: {
          ...this.state.uploadProgress,
          [data.name]: data && data.progress ? data.progress : 0,
        },
      });
    });
  }
  componentWillUnmount() {
    this.onFileUploadDoneSubscriber.unsubscribe();
  }

  onToggleExpand = () => {
    this.setState({
      expanding: !this.state.expanding,
    });
  };

  onStopUploading = (fileIndex = -1) => {
    const file = this.state.uploadFileList[fileIndex];
    if (!file) return;
    file.canceller && file.canceller();
    this.props.removeUploadingFile(fileIndex);
  };
  render() {
    const { closeCallBack } = this.props;
    const { uploadFileList, expanding } = this.state;
    return (
      <div className="progress-bar-container">
        <ProgressBarHeader
          fileCount={
            uploadFileList ? uploadFileList.filter((file) => file).length : 0
          }
          onClose={closeCallBack}
          expanding={expanding}
          onToggleExpand={this.onToggleExpand}
          style={{ backgroundColor: '#ffffff', color: '#0f0da1', fontFamily: "Roboto, sans-serif" }}
        />
        <div
          className="progress-content"
          style={{ display: expanding ? "inherit" : "none" }}
        >
          {uploadFileList.map((fileData, i) => {
            // not render undefined (removed) files
            if (fileData)
              return (
                <div className="progress-item" key={i}>
                  <span>{fileData.name}</span>
                  <ProgressBar
                    variant="success"
                    now={fileData.progress}
                    label={
                      fileData.progress !== 100
                        ? `${typeof fileData.progress === "undefined"
                          ? 0
                          : fileData.progress
                        }%`
                        : "Finishing..."
                    }
                  />
                  <span className="progress-speed">{fileData.speed}</span>
                  <div
                    class="progress-item-close"
                    onClick={() => this.onStopUploading(i)}
                  >
                    <img src={CloseIcon} alt="" />
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    );
  }
}

const ProgressBarHeader = ({
  fileCount,
  onClose,
  expanding,
  onToggleExpand,
}) => {
  return (
    <div className="progress-bar-header">
      <h3>{`Uploading ${fileCount} File...`}</h3>
      <div class="expand-progress" onClick={onToggleExpand}>
        <i
          className={expanding ? "fas fa-chevron-down" : "fas fa-chevron-up"}
        />
      </div>
      <div className="close-progress" onClick={onClose}>
        <img src={CloseIcon} alt="close" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...slideBoxActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProgressBarComponent));
