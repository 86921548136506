import React from "react";
import CaseDirExpandIcon from "../../../../assets/icons/case-expand.svg";
import CaseDirActiveIcon from "../../../../assets/icons/case-open.svg";
import CaseDirInActiveIcon from "../../../../assets/icons/case-close.svg";
import DirOpenIcon from "../../../../assets/icons/directory-open.svg";
import DirCloseIcon from "../../../../assets/icons/directory-close.svg";
import classnames from "classnames";

const CaseDirectory = ({
  open,
  active,
  dirName,
  dirId,
  listId,
  parentDir,
  insideRoot,
  customClass = null,
  toggleDir,
  setExpandDir,
  setSelectedDir,
}) => {
  return (
    <div
      data-test="case-directory"
      id="caseDirectoryContainer"
      className={classnames(
        active
          ? customClass
            ? `caseDirectory-container-${customClass} active`
            : "caseDirectory-container active"
          : customClass
            ? `caseDirectory-container-${customClass}`
            : "caseDirectory-container"
      )}
      onClick={() =>
        insideRoot
          ? setSelectedDir({
            dirId,
            listId,
            parentDir: null,
            dirType: "case",
          })
          : setSelectedDir({ dirId, listId, parentDir, dirType: "case" })
      }
      onDoubleClick={() =>
        insideRoot
          ? toggleDir({ dirId, listId, parentDir: null, dirType: "case" })
          : toggleDir({ dirId, listId, parentDir, dirType: "case" })
      }
    >
      <img
        data-test="caseIcon"
        src={open ? DirOpenIcon : DirCloseIcon}
        onClick={() =>
          insideRoot
            ? setExpandDir({
              dirId,
              listId,
              parentDir: null,
              dirType: "case",
            })
            : setExpandDir({ dirId, listId, parentDir, dirType: "case" })
        }
        className="dir-open-close"
      />
      <img
        data-test="caseIcon"
        src={
          open ? CaseDirExpandIcon
            : active ? CaseDirActiveIcon : CaseDirInActiveIcon
        }
        className="case-dir"
      />
      {dirName && dirName.length > 20
        ? dirName.substring(0, 18) + "..."
        : dirName}
    </div>
  );
};

export default CaseDirectory;
