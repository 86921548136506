import * as methods from "./methods";

const initialState = {
  folders: [],
  moveFolders: [],
  dirDataList: [],
  fileList: [],
  selectedMoveFolderData: {
    selectedMoveFolder: null,
    selectedMoveFolderName: null,
    selectedMoveFolderType: null,
  },
  selectedFolderData: {
    selectedFolder: null,
    selectedFolderName: null,
    selectedFolderType: null,
    description: null,
  },
  selectedListData: {
    selectedList: null,
    selectedListName: null,
    selectedListType: null,
  },
  showSnackBar: false,
  conversionComplete: false,
  percentCompleted: false,
  breadCrumbIcon: { showIcon: false },
  showLoader: false,
  isSearchData: false,
  uploadPageLoad: false,
  movePopupState: false,
  selectedRightClickItem: null,
  selectedListSlideData: null,
  selectedCaseAssignUsers: [],
  selectedDirActivities: [],
  isListView: true,
  caseReviewStatus: "",
  userList: [],
  advancedSearchData: [],
  stainType: [],
  isMoveDirActive: false,
  allSlides: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_LIST":
      return {
        ...state,
        userList: action.payload,
      };

    case "SET_SLIDE_BOX_LOADING": {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case "SET_FOLDER_LISTS": {
      return {
        ...state,
        folders: action.payload,
        moveFolders: action.payload,
        selectedListData: initialState.selectedListData,
        selectedFolderData: {
          selectedFolder:
            action.payload.length > 0 ? action.payload[0]["dirId"] : null,
          selectedFolderName:
            action.payload.length > 0 ? action.payload[0]["dirName"] : null,
          selectedFolderType: "root",
        },
      };
    }

    case "SET_FOLDERS": {
      const { folders } = action.data;
      return {
        ...state,
        folders,
        moveFolders: folders,
      };
    }

    case "SET_SELECTED_UPLOAD_FOLDER": {
      const { folder } = action.data;
      return {
        ...state,
        selectedUploadFolder: folder,
      };
    }

    case "SET_LOAD_QUOROM": {
      return {
        ...state,
        isQuorumLoaded: true,
        quorumImageFromSlideBox: action.data,
      };
    }

    case "SET_CASESTREAM_MODAL": {
      return {
        ...state,
        casestreamModalData: action.data,
      };
    }

    case "SET_ADD_QUOROM_BUTTON": {
      return {
        ...state,
        addQuorumEnableButton: action.data,
      };
    }

    case "SET_ADD_TUMOR_BOARDS_ENABLE": {
      return {
        ...state,
        addTumorBoardEnableButton: action.enable,
      };
    }

    case "SET_EXPAND_FOLDERS": {
      return {
        ...state,
        folders: action.parentFolder
          ? methods.setExpandChildFolder(action, state.folders)
          : methods.setExpandFolder(action, state.folders),
        selectedFolderData: {
          selectedFolder: action.folder,
          selectedFolderName: methods.setOpenFolderName(
            action.listId,
            state.folders
          ),
          selectedFolderType: action.dirType,
        },
      };
    }

    case "SET_EXPAND_FOLDERS_IN_MOVE":
      {
        return {
          ...state,
          moveFolders: action.parentFolder
            ? methods.setExpandChildFolder(action, state.moveFolders)
            : methods.setExpandFolder(action, state.moveFolders),
          selectedMoveFolderData: {
            selectedMoveFolder: action.folder,
            selectedMoveFolderName: methods.setOpenFolderName(
              action.listId,
              state.moveFolders
            ),
            selectedMoveFolderType: action.dirType,
          },
        };
      }
      s;

    case "SET_SELECTED_DIRS": {
      return {
        ...state,
        folders: action.parentFolder
          ? methods.setSelectedChildFolder(action, state.folders)
          : methods.setSelectedFolder(action, state.folders),
        selectedFolderData: {
          selectedFolder: action.folder,
          selectedFolderName: methods.setOpenFolderName(
            action.listId,
            state.folders
          ),
          description: methods.setDescription(action.listId, state.folders),
          selectedFolderType: action.dirType,
        },
      };
    }

    case "SET_SELECTED_DIRS_IN_MOVE": {
      return {
        ...state,
        moveFolders: action.parentFolder
          ? methods.setSelectedChildFolder(action, state.moveFolders)
          : methods.setSelectedFolder(action, state.moveFolders),
        selectedMoveFolderData: {
          selectedMoveFolder: action.folder,
          selectedMoveFolderName: methods.setOpenFolderName(
            action.listId,
            state.moveFolders
          ),
          selectedMoveFolderType: action.dirType,
        },
      };
    }

    case "SET_OPEN_FOLDERS": {
      return {
        ...state,
        folders: action.parentFolder
          ? methods.setOpenChildFolder(action, state.folders)
          : methods.setOpenFolder(action, state.folders),
        selectedFolderData: {
          selectedFolder: action.folder,
          selectedFolderName: methods.setOpenFolderName(
            action.listId,
            state.folders
          ),
          selectedFolderType: action.dirType,
        },
      };
    }

    case "SET_OPEN_FOLDERS_IN_MOVE": {
      return {
        ...state,
        moveFolders: action.parentFolder
          ? methods.setOpenChildFolder(action, state.moveFolders)
          : methods.setOpenFolder(action, state.moveFolders),
        selectedMoveFolderData: {
          selectedMoveFolder: action.folder,
          selectedMoveFolderName: methods.setOpenFolderName(
            action.listId,
            state.moveFolders
          ),
          selectedMoveFolderType: action.dirType,
        },
      };
    }

    case "SET_OPEN_LIST_FOLDERS": {
      return {
        ...state,
        folders: action.parentFolder
          ? methods.setOpenChildFolder(action, state.folders)
          : methods.setOpenFolder(action, state.folders),
        selectedFolderData: {
          selectedFolder: action.folder,
          selectedFolderName: methods.setOpenFolderName(
            action.listId,
            state.folders
          ),
          selectedFolderType: action.dirType,
          description: methods.setDescription(action.listId, state.folders),
        },
        selectedListData: initialState.selectedListData,
      };
    }

    case "SET_SELECTED_LIST_DIRS": {
      return {
        ...state,

        selectedListData: {
          selectedList: action.folder,
          selectedListName:
            action.dirType === "slide" || action.dirType === "document"
              ? methods.setOpenSlideName(action.listId, state.dirDataList)
              : methods.setOpenFolderName(action.listId, state.folders),
          selectedListType: action.dirType,
        },
      };
    }

    case "SET_SLIDE_DATA_FROM_LIST": {
      return {
        ...state,
        selectedListSlideData: action.payload,
      };
    }

    case "SET_NEW_DIRECTORY": {
      return {
        ...state,
        folders: [
          ...state.folders,
          { ...action.payload, listId: state.folders.length + 1 },
        ],
        moveFolders: [
          ...state.moveFolders,
          { ...action.payload, listId: state.moveFolders.length + 1 },
        ],
      };
    }

    case "RENAME_DIRECTORY": {
      return {
        ...state,
        folders: methods.setRenameFolderName(
          action.dirId,
          action.dirName,
          state.folders,
          action.dirType
        ),
        moveFolders: methods.setRenameFolderName(
          action.dirId,
          action.dirName,
          state.moveFolders,
          action.dirType
        ),
        dirDataList: methods.setRenameListFolderName(
          action.dirId,
          action.dirName,
          state.dirDataList
        ),
        selectedListData: {
          ...state.selectedListData,
          selectedListName: action.dirName,
        },
        selectedFolderData: {
          ...state.selectedFolderData,
          selectedFolderName: action.dirName,
        },
      };
    }

    case "DELETE_DIRECTORY": {
      return {
        ...state,
        folders:
          action.dirType === "slide" || action.dirType === "document"
            ? state.folders
            : methods.setDeleteFolder(action.dirId, state.folders),
        moveFolders:
          action.dirType === "slide" || action.dirType === "document"
            ? state.moveFolders
            : methods.setDeleteFolder(action.dirId, state.moveFolders),
        dirDataList: methods.setDirDataListFolder(
          action.dirId,
          state.dirDataList
        ),
        selectedListData: {
          selectedList: null,
          selectedListName: null,
          selectedListType: null,
        },
      };
    }

    case "DELETE_FOLDER": {
      return {
        ...state,
        dirDataList: methods.setDirDataListFolder(
          action.dirId,
          state.dirDataList
        ),
        selectedListData: {
          selectedList: null,
          selectedListName: null,
          selectedListType: null,
        },
      };
    }

    case "SET_FOLDER_DATA_LISTS": {
      return {
        ...state,
        dirDataList: action.payload,
        isSearchData: false,
        uploadPageLoad: false,
        selectedListData: {
          selectedList: null,
          selectedListName: null,
          selectedListType: null,
        },
      };
    }

    case "UNSET_SELECTED_LIST_DATA": {
      return {
        ...state,
        isSearchData: false,
        uploadPageLoad: false,
        selectedListData: {
          selectedList: null,
          selectedListName: null,
          selectedListType: null,
        },
      };
    }

    case "SHOW_SNACK_BAR_COMPONENT": {
      return {
        ...state,
        showSnackBar: action.payload.show,
        fileList: [...state.fileList, ...action.payload.fileList],
        conversionComplete: action.payload.conversionComplete,
        percentCompleted: action.payload.percentCompleted,
      };
    }

    case "SET_FILE_UPLOAD_PROGRESS": {
      return {
        ...state,
        fileList: methods.updateFileData(
          state.fileList,
          action.payload.fileIndex,
          {
            progress: action.payload.percentCompleted,
          }
        ),
      };
    }

    case "SET_FILE_CANCELLER": {
      return {
        ...state,
        fileList: methods.updateFileData(
          state.fileList,
          action.payload.fileIndex,
          {
            canceller: action.payload.canceller,
          }
        ),
      };
    }

    case "REMOVE_UPLOADING_FILE": {
      return {
        ...state,
        fileList: methods.removeFile(state.fileList, action.payload.fileIndex),
      };
    }

    case "CLEAR_UPLOADING_LIST": {
      return {
        ...state,
        fileList: [],
      };
    }

    case "BREADCRUMB_ICONS_STATUS": {
      return {
        ...state,
        breadCrumbIcon: action.payload,
      };
    }

    case "SHOW_LOADER": {
      return {
        ...state,
        showLoader: action.showLoader,
      };
    }

    case "SWITCH_VIEW": {
      return {
        ...state,
        isListView: action.isListView,
      };
    }

    case "SET_SEARCH_DIR_DATA": {
      return {
        ...state,
        dirDataList: action.payload,
        isSearchData: true,
      };
    }

    case "RESET_SLIDE_UPLOAD_STATE": {
      return {
        ...state,
        showSnackBar: false,
        conversionComplete: false,
        percentCompleted: false,
        uploadPageLoad: true,
      };
    }

    case "SLIDEBOX_DIR_DETAILS_UPDATE": {
      return {
        ...state,
        dirDataList: methods.updateDirDetails(
          action.payload,
          state.dirDataList
        ),
      };
    }

    case "SLIDEBOX_LABELS_UPDATE": {
      return {
        ...state,
        dirDataList: state.dirDataList.map((item) => {
          if (
            item.dirDataId === action.payload.entityId &&
            item.caseOrFolder === action.payload.entityType
          ) {
            return {
              ...item,
              labels:
                action.payload.labels &&
                action.payload.labels.map((label) => label.value).toString(),
            };
          } else {
            return item;
          }
        }),
      };
    }

    case "SET_SLIDEBOX_MOVE_POPUP_STATE": {
      return {
        ...state,
        moveFolders: state.moveFolders.map((dirData) => {
          return {
            ...dirData,
            active: dirData.dirId === 1 ? true : false,
          };
        }),
        movePopupState: action.payload,
        selectedMoveFolderData: {
          selectedMoveFolder: state.moveFolders[0]["dirId"],
          selectedMoveFolderName: state.moveFolders[0]["dirName"],
          selectedMoveFolderType: "root",
        },
      };
    }

    case "SET_SLIDEBOX_MOVE_DIR": {
      return {
        ...state,
        folders: methods.setUpdateFolder(action.payload, state.folders),
        moveFolders: methods.setUpdateMoveFolder(
          action.payload,
          state.moveFolders
        ),
        dirDataList: methods.setDirDataListFolder(
          action.payload.selectedDir,
          state.dirDataList
        ),
      };
    }

    case "RESET_MOVE_DIR_ACTIVE": {
      return {
        ...state,
        isMoveDirActive: action.payload,
      };
    }

    case "SET_SLIDEBOX_DIR_COLLABORATORS": {
      return {
        ...state,
        selectedDirCollaborators: action.payload,
      };
    }

    case "SET_SLIDEBOX_NEW_COLLABORATORS": {
      return {
        ...state,
        selectedDirCollaborators: [
          ...state.selectedDirCollaborators,
          ...action.payload,
        ],
      };
    }

    case "SET_SLIDEBOX_REMOVE_COLLABORATORS": {
      return {
        ...state,
        selectedDirCollaborators: methods.removeCollaborators(
          action.payload,
          state.selectedDirCollaborators
        ),
      };
    }

    case "SET_SLIDEBOX_RIGHT_CLICK_SLECTED": {
      return {
        ...state,
        selectedRightClickItem: action.payload,
      };
    }

    case "SET_SLIDEBOX_CASE_ASSIGN": {
      return {
        ...state,
        selectedCaseAssignUsers: action.payload,
        dirDataList:
          typeof action.dirId !== "undefined"
            ? methods.setCaseAssignUser(
                action.payload,
                action.dirId,
                state.dirDataList
              )
            : state.dirDataList,
      };
    }

    case "SET_SLIDEBOX_CASE_ASSIGN_USERS": {
      return {
        ...state,
        selectedCaseAssignUsers: action.payload,
      };
    }

    case "SET_SLIDEBOX_DIR_ACTIVITY": {
      return {
        ...state,
        selectedDirActivities: action.payload,
      };
    }

    case "REMOVE_SLIDEBOX_CASE_ASSIGN": {
      return {
        ...state,
        selectedCaseAssignUsers: null,
        dirDataList: methods.updateCaseAssignUser(
          action.payload.dirId,
          state.dirDataList
        ),
      };
    }

    case "SET_SLIDEBOX_DIR_ASSIGN_USERS": {
      return {
        ...state,
        selectedCaseAssignUsers: action.payload,
      };
    }

    case "SET_CASE_REVIEW_STATUS": {
      return {
        ...state,
        dirDataList: state.dirDataList.map((item) => {
          if (item.dirDataId === action.payload.dirId) {
            return {
              ...item,
              review_status: action.payload.reviewStatus,
              lastModified: action.payload.lastModified,
            };
          } else {
            return item;
          }
        }),
      };
    }

    case "SET_ADVANCED_SEARCH_DATA": {
      return {
        ...state,
        // advancedSearchData: action.payload,
        dirDataList: action.payload,
      };
    }

    case "SET_STAIN_TYPE": {
      return {
        ...state,
        stainType: action.payload,
      };
    }

    case "UPDATE_STAIN_TYPE": {
      return {
        ...state,
        dirDataList: state.dirDataList.map((item) => {
          if (item.dirDataId === action.payload.dirId) {
            return {
              ...item,
              stainType: action.payload.stainType,
            };
          } else {
            return item;
          }
        }),
      };
    }

    case "GET_ALL_SLIDES": {
      return {
        ...state,
        allSlides: action.payload,
      };
    }

    default:
      return state;
  }
};
