import axios from "../../../utils/axiosInstance";

export const changePasswordAPI = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/user/changeUserPassword",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const editProfileAPI = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/user/updateUserProfileData",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};

export const updateProfilePictureAPI = async (dataParams) => {
  try {
    let { file } = dataParams;
    let formData = new FormData();

    if (file.size > 6000000) {
      Notify({ value: "Image should be less than 4MB!!" });
    } else {
      formData.append("image", file);
      const config = {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      };
      // formData.append("config", config);
      formData.append("userId", dataParams.userId);
      const resData = await axios.post(
        "/api/user/profile-image-upload",
        formData
      );
      return resData;
    }
  } catch (error) {
    throw error;
  }
};

export const changeNotificationPreferenceAPI = async (dataParams) => {
  try {
    const resData = await axios.post(
      "/api/user/changeNotificationPreference",
      dataParams
    );
    return resData;
  } catch (err) {
    throw err;
  }
};
