import React from "react";
import PieSVG from "./PieSVG";
import moment from "moment";

const CaseStatusSummaryPieChart = ({ caseStatusSummaryData }) => {
  return (
    <div className="summary-pie-chart">
      <span className="chart-heading">Case Status Summary</span>
      <span className="date">{moment().format("DD MMMM YYYY")}</span>
      <div className="pie-chart">
        <div>
          <PieSVG
            data={caseStatusSummaryData}
            width={200}
            height={200}
            innerRadius={0}
            outerRadius={100}
          />
        </div>
        <div className="chart-indicators">
          <div className="circle review"></div>
          <span className="status review">Under Review</span>
          <div className="circle pending"></div>
          <span className="status pending">Pending</span>
          <div className="circle completed"></div>
          <span className="status completed">Completed</span>
        </div>
      </div>
    </div>
  );
};

export default CaseStatusSummaryPieChart;
