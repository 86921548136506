import React, { useState, useEffect, useContext } from "react";
import SlideboxPage from "../../slidebox/SlideBox";
import { connect } from "react-redux";
// import Viewer from '../../imageViewer/ImageViewer';
import Viewer from "../../../pages/slideSplitViewer/viewer-container";
import ReactTooltip from "react-tooltip";
import { SocketContext } from "../../../utils/serviceContext";
import PlusSrc from "../../../assets/icons/add.svg";
import { withRouter } from "react-router";
import { FadeIn } from "animate-components";
import ClosePopupIcon from "../../../assets/icons/close-new.svg";
import AddFromSlideIcon from "../../../assets/icons/add-slide.svg";
import { setSlideUrl, setSelectedBoard } from "../actions/groups-action";
import { addTumorBoardEnable } from "../../slidebox/actions/slidebox-action";
import { Notify } from "../../../components";
import axios from "../../../utils/axiosInstance";

const styles = {
  disabledButton: {
    color: "#999",
    cursor: "not-allowed",
    border: "1px solid #0b569f",
    marginTop: "40%",
  },
};
const closeSrc = "/images/img/closeModal.svg";

const ImagePanel = ({
  openSlidebox,
  setOpenSlidebox,
  isImageLoaded,
  imageFromSlideBox,
  group,
  onGroupRefresh,
  loggedUser,
  history,
  setSlideUrl,
  setSelectedBoard,
  addTumorBoardEnable,
  selectedListSlideData,
  groups,
}) => {
  const [slideBoxId, setSlideBoxId] = useState(null);
  const [isModal, setIsModal] = useState(true);
  const [isFolderSelected, setIsFolderSelected] = useState(true);

  const socket = useContext(SocketContext);

  const handleOnNext = () => {
    const selectedBoard = groups.selectedBoard;
    selectedBoard.slide = [
      {
        dzi_data: `${selectedListSlideData.dzi_url.split(".dzi_files/")[0]
          }.dzi.dzi`,
        dzi_url: selectedListSlideData.dzi_url,
      },
    ];
    selectedBoard.slideId = selectedListSlideData.dirDataId;
    setTumorBoardSlide(selectedBoard);
  };

  const setTumorBoardSlide = async (folderPath) => {
    let body = {
      tumorBoardId: folderPath.id,
      slide: JSON.stringify(folderPath.slide[0]),
      slideId: folderPath.slideId,
    };
    Notify({ value: "Adding slide to group" });
    const result = await axios
      .put("/api/groups/slidePath", body)
      .catch((err) => {
        Notify({ value: err.response.data.message });
        return false;
      });
    if (typeof result.status !== "undefined" && result.status === 200) {
      setOpenSlidebox(false);
      setSlideUrl(
        {
          slide: JSON.stringify(folderPath.slide[0]),
          slideId: folderPath.slideId,
        },
        true
      );
      folderPath.current = folderPath.slide.length - 1;
      setSelectedBoard(folderPath);
      let getFileDataResult = await axios.post("/api/slidebox/getFileData", {
        keyFolder: selectedListSlideData.keyFolder,
      });
      Notify({ value: "Slide added successfully!!" });
      onGroupRefresh();
      addTumorBoardEnable(false);
    } else {
      setOpenSlidebox(false);
    }
  };

  const toggleModal = (e) => {
    const overlay = document.getElementById("quorum-modal-overlay");
    if (
      e == "CLOSE MODAL" ||
      e.target == overlay ||
      e.target.classList.contains("slidebox-next-page-btn") ||
      e.target.id == "close_modal_streams"
    ) {
      setOpenSlidebox(false);
    } else {
      setOpenSlidebox(true);
    }
  };

  useEffect(() => {
    socket.onUpdateBoard().subscribe((id) => {
      //console.log('Updating ', id)
      if (group.id === parseInt(id)) {
        onGroupRefresh();
      }
    });
  }, []);

  useEffect(() => {
    let slideBoxId;
    if (Object.keys(imageFromSlideBox).length > 0) {
      slideBoxId = imageFromSlideBox.fileid;
    } else {
      slideBoxId = group.tumorBoardSlideId;
    }
    setSlideBoxId(slideBoxId);
  }, [imageFromSlideBox, group]);

  const changeThumbnailSlide = (newSlideId) => {
    setSlideBoxId(newSlideId);
  };

  const redirectToSlideBox = () => {
    history.push("");
  };

  return (
    <div className="image-panel">
      <div className="content">
        <div className="quorum-action-main-container">
          {group &&
            slideBoxId !== undefined &&
            (Object.keys(imageFromSlideBox).length > 0 || group.slide) ? (
            <div className="group-viewer-container">
              {slideBoxId && (
                <Viewer
                  when={"groups"}
                  slideBoxId={slideBoxId}
                  groupId={group.id}
                  groupAdmin={group.admin}
                  changeThumbnailSlide={changeThumbnailSlide}
                  tumorBoardSlideId={group.tumorBoardSlideId}
                />
              )}
            </div>
          ) : (
            <div
              className="add-quorum-button"
              onClick={group.admin ? toggleModal : (e) => { }}
              style={group.admin ? null : styles.disabledButton}
              data-tip={
                group.admin
                  ? null
                  : "This function is available only to group admins"
              }
            >
              <img src={PlusSrc} className="button-plus-img" />
              <h4 class="button-text">Add from SlideBox</h4>
            </div>
          )}
          {openSlidebox ? (
            <div
              className="overlay-quorum"
              id="quorum-modal-overlay"
              onClick={toggleModal}
            >
              <div
                className="modal-slidebox"
                onContextMenu={() => {
                  return false;
                }}
              >
                <FadeIn duration="300ms">
                  <div
                    className="header-modal"
                    style={{ paddingTop: 20, paddingBottom: "25px" }}
                  >
                    <div>
                      <img
                        src={AddFromSlideIcon}
                        alt="addSlide"
                        style={{ marginRight: 10 }}
                      />
                      <span
                        className="prompt-title"
                        style={{ fontSize: "16px" }}
                      >
                        Add from Slidebox
                      </span>
                    </div>
                    <img
                      className="close-icon"
                      src={ClosePopupIcon}
                      onClick={(e) => toggleModal("CLOSE MODAL")}
                      id="close_modal_streams"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div
                    className="modal_middle"
                    onContextMenu={() => {
                      return false;
                    }}
                  >
                    <SlideboxPage
                      closeModal={toggleModal}
                      groupId={group.id}
                      isModal={isModal}
                      setOpenSlidebox={setOpenSlidebox}
                      history={history}
                      isFolderSelected={isFolderSelected}
                      setIsFolderSelected={setIsFolderSelected}
                    />
                  </div>

                  <div className="modal_bottom">
                    {isModal && (
                      <button
                        disabled={isFolderSelected}
                        className={
                          isFolderSelected
                            ? "next_btn deactive"
                            : "next_btn active"
                        }
                        onClick={handleOnNext}
                      >
                        NEXT
                      </button>
                    )}
                  </div>
                </FadeIn>
              </div>
            </div>
          ) : null}
        </div>
        <ReactTooltip />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isImageLoaded: state.SlideBox.isQuorumLoaded,
  imageFromSlideBox: state.SlideBox.quorumImageFromSlideBox || "",
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  groups: state.Groups,
});

const mapDispatchToProps = (dispatch) => ({
  setSlideUrl: (payload) => dispatch(setSlideUrl(payload)),
  setSelectedBoard: (payload) => dispatch(setSelectedBoard(payload)),
  addTumorBoardEnable: (payload) => dispatch(addTumorBoardEnable(payload)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImagePanel)
);
