// to do
import axios from "../../../utils/axiosInstance";

export const dashboardUserListApi = async () => {
  try {
    return await axios.get(`/api/user/dxAccountUsers`);
  } catch (err) {
    throw err;
  }
};

export const searchUserListApi = async (data) => {
  try {
    return await axios.get(`/api/user/dxAccountUsers?filter=${data}`);
  } catch (err) {
    throw err;
  }
};

export const addNewUserApi = async (data) => {
  try {
    return await axios.post(`/api/auth/createDxUser`, data);
  } catch (err) {
    throw err;
  }
};

export const activateOrDeactivateUserApi = async (data) => {
  try {
    return await axios.post(`/api/user/activeOrDeactiveDxUser`, data);
  } catch (err) {
    throw err;
  }
};

export const updateDxUserRoleApi = async (data) => {
  try {
    return await axios.post(`/api/user/updateDxUserRole`, data);
  } catch (err) {
    throw err;
  }
};

export const resendUserInvitationApi = async (data) => {
  try {
    return await axios.post(`/api/auth/resendUserInvitation`, data);
  } catch (err) {
    throw err;
  }
};

export const revokeUserInvitationApi = async (data) => {
  try {
    return await axios.post(`/api/auth/revokeUserInvitation`, data);
  } catch (err) {
    throw err;
  }
};

export const getSlideboxCaseDataApi = async () => {
  try {
    return await axios.get(`/api/dashboardAnalytics/getSlideboxCaseData`);
  } catch (err) {
    throw err;
  }
};

export const getSlideboxNewAndCompletedCaseDataApi = async (data) => {
  try {
    return await axios.get(
      `/api/dashboardAnalytics/getSlideboxCaseActivityData?showBy=${data}`
    );
  } catch (err) {
    throw err;
  }
};

export const getSlideboxSlideDataApi = async (data) => {
  try {
    return await axios.get(
      `/api/dashboardAnalytics/getSlideboxSlidesData?showBy=${data}`
    );
  } catch (err) {
    throw err;
  }
};

export const getViewerAnnotationDataApi = async (data) => {
  try {
    return await axios.get(
      `/api/dashboardAnalytics/getViewerAnnotationData?showBy=${data}`
    );
  } catch (err) {
    throw err;
  }
};

export const getCaseSummaryApi = async (data) => {
  try {
    return await axios.get(`/api/dashboardAnalytics/getCaseData`);
  } catch (err) {
    throw err;
  }
};
