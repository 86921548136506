import React from 'react';
import ConversationItem from './components/conversation-item';
import JoinedItem from './components/joined-item';
import TimeItem from './components/time-item';
import FileItem from './components/file-item';
import ImageItem from './components/image-item';

const MessageRender = (data, index, edit, setEdit) => {
  switch (data.type) {
    case 'joined':
    case 'removed':
    case 'left':
    case 'setAdmin':
    case 'removeAdmin':
      return null;
    // return <JoinedItem key={index} {...data} />;
    case 'time':
      return <TimeItem key={index} {...data} />;
    case 'file':
      return <FileItem key={index} {...data} edit={edit} setEdit={setEdit} />;
    case 'image':
      return <ImageItem key={index} {...data} edit={edit} setEdit={setEdit} />;
    default:
      return (
        <ConversationItem key={index} {...data} edit={edit} setEdit={setEdit} />
      );
  }
};

export default MessageRender;
