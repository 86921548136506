import React from "react";
import UserSearch from "./components/DashboardItems/UserSearch";
import AddUser from "./components/DashboardItems/AddUser";
import TotalSlideBoxStorage from "./components/DashboardItems/TotalSlideBoxStorage";
import "./AdminDashboard.scss";
import DashboardTable from "./components/DashboardTable/DashboardTable";
import ActivateOrDeactivateUser from "./components/DashboardItems/ActivateOrDeactivateUser";
import { bytesToSize } from "../../utils/utils";
import { connect } from "react-redux";
import {
  getUserListDataAction,
  addNewUserAction,
  searchDashboardUserListAction,
  changeDxUserRoleAction,
  activateOrDeactivateUserAction,
  resetMessageAction,
  resendInvitationAction,
  revokeInvitationAction,
  getSlideboxSlideDataAction,
} from "./actions/adminDashboard-action";
import ChangeRoleType from "./components/DashboardItems/ChangeRoleType";
import { Notify } from "../../components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  getAccountDetailsAction,
  getUserDetailsAction,
} from "../../actions/global-action";
import DeleteUserInvitationConfirm from "./components/DashboardItems/DeleteUserInvitationConfirm";
import Can from "./../../hoc/FullControllHoc/can";
import AnalyticsDashboard from "./components/DashboardAnalytics/AnalyticsDashboard";
import { FeatureGate } from "react-feature-gate";

const AdminDashboard = ({
  showLoader,
  userList,
  getUserListFn,
  addNewUserFn,
  activateOrDeactivateUserFn,
  loggedUserDetails,
  loggedUserStorageUsed,
  loggedUserStorageLimit,
  searchDashboardUserListFn,
  changeDxUserRoleFn,
  message,
  showMessage,
  resetMessageActionFn,
  getAccountDetails,
  getLoggedUserDetails,
  dxAccountDetails,
  resendInvitation,
  deleteInvitationFn,
  getSlideData,
}) => {
  const [addNewUser, setAddNewUser] = React.useState(false);
  const [
    activateOrDeactivateUser,
    setActivateOrDeactivateUser,
  ] = React.useState(false);
  const [deleteUserInvitation, setDeleteUserInvitation] = React.useState(false);
  const [
    activateOrDeactivateUserData,
    setActivateOrDeactivateUserData,
  ] = React.useState(false);
  const [changeRoleType, setChangeRoleType] = React.useState(false);
  const [userRoleInfo, setUserRole] = React.useState({});

  const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);
  const [filterData, setFilterData] = React.useState(userList);
  const [blink, setBlink] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const [userStorageUsed, setUserStorageUsed] = React.useState(
    loggedUserStorageUsed
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (value === 2) {
      getAccountDetails();
    }
  }, [value]);

  React.useEffect(() => {
    getUserListFn();
    getLoggedUserDetails();
  }, []);

  React.useEffect(() => {
    if (changeRoleType || activateOrDeactivateUser) {
      return setIsPopUpOpen(true);
    }
    return setIsPopUpOpen(false);
  }, [changeRoleType, activateOrDeactivateUser]);

  React.useEffect(() => {
    setUserStorageUsed(loggedUserDetails.storageUsed);
  }, [loggedUserStorageUsed]);

  React.useEffect(() => {
    setFilterData(userList);
  }, [userList]);

  React.useEffect(() => {
    showMessage &&
      Notify({
        value: message,
        done: () => resetMessageActionFn(),
      });
  }, [showMessage]);

  const displaySlidesUploaded = (type) => {
    getSlideData(type);
  };

  return (
    <div className="admin-dashboard-container">
      <div className="slide-user-list-wrapper">
        <div className="dashboard-tabs-and-storage-container">
          <TotalSlideBoxStorage
            storageUsed={userStorageUsed}
            storageLimit={loggedUserStorageLimit}
          />
          {/* <TotalSlideBoxStorage storage={loggedUserDetails} /> */}
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={handleChange}
            aria-label="details-drawer"
            className="details-drawer-tab-container"
          >
            <Can
              task={"manage-members"}
              taskContainer="dashboard"
              edit={() => (
                <Tab
                  onClick={(e) => setValue(0)}
                  label="Manage members"
                  className="admin-bord-drawer-tab"
                />
              )}
              noAccess={() => (
                <Tab
                  onClick={(e) => setValue(0)}
                  label="Member list"
                  className="admin-bord-drawer-tab"
                />
              )}
            />
            <FeatureGate feature="dashboardAnalytics">
              <Can
                task={"analytics-tab"}
                taskContainer="dashboard"
                edit={() => (
                  <Tab
                    onClick={(e) => setValue(1)}
                    label="Analytics"
                    className="admin-bord-drawer-tab"
                  />
                )}
              />
            </FeatureGate>
            <Can
              task={"account-details"}
              taskContainer="dashboard"
              edit={() => (
                <Tab
                  onClick={(e) => setValue(2)}
                  label="Account Details"
                  className="admin-bord-drawer-tab"
                />
              )}
            />
          </Tabs>
        </div>

        {value === 0 && (
          <React.Fragment>
            <UserSearch
              {...{
                setAddNewUser,
                userList,
                filterData,
                setBlink,
                getUserListFn,
                setFilterData,
                searchDashboardUserListFn,
              }}
            />
            <DashboardTable
              {...{
                showLoader,
                activateOrDeactivateUser,
                setActivateOrDeactivateUser,
                filterData,
                setActivateOrDeactivateUserData,
                setChangeRoleType,
                isPopUpOpen,
                setUserRole,
                blink,
                setBlink,
                resendInvitation,
                setDeleteUserInvitation,
              }}
            />
          </React.Fragment>
        )}
        {value === 1 && (
          <AnalyticsDashboard displaySlidesUploaded={displaySlidesUploaded} />
        )}

        {value === 2 && dxAccountDetails && (
          <div className="account-details-continer">
            <div className="general-information">
              <div className="information-row">
                <div className="information-row-label">Account Id</div>
                <div className="information-row-content">
                  {dxAccountDetails.dx_account_id}
                </div>
              </div>

              <div className="information-row">
                <div className="information-row-label">Admin Email</div>
                <div className="information-row-content">
                  {dxAccountDetails.admin_email_id}
                </div>
              </div>

              <div className="information-row">
                <div className="information-row-label">License Number</div>
                <div className="information-row-content">
                  {dxAccountDetails.license_number}
                </div>
              </div>

              <div className="information-row">
                <div className="information-row-label">License End Date</div>
                <div className="information-row-content">
                  {dxAccountDetails.license_end_date.split("T")[0]}
                </div>
              </div>

              <div className="information-row">
                <div className="information-row-label">Number of Users</div>
                <div className="information-row-content">
                  {dxAccountDetails.number_of_users}
                </div>
              </div>

              <div className="information-row">
                <div className="information-row-label">Storage Limit</div>
                <div className="information-row-content">
                  {bytesToSize(dxAccountDetails.storage_limit)}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {addNewUser && (
        <AddUser
          {...{
            loggedUserDetails,
            addNewUserFn,
            message,
            showMessage,
            onClose: () => setAddNewUser(!addNewUser),
          }}
        />
      )}
      {activateOrDeactivateUser && (
        <ActivateOrDeactivateUser
          {...{
            activateOrDeactivateUserData,
            activateOrDeactivateUserFn,
            onClose: () =>
              setActivateOrDeactivateUser(!activateOrDeactivateUser),
          }}
        />
      )}

      {deleteUserInvitation && (
        <DeleteUserInvitationConfirm
          {...{
            deleteUserInvitation,
            deleteInvitationFn,
            onClose: () => setDeleteUserInvitation(!deleteUserInvitation),
          }}
        />
      )}

      {changeRoleType && (
        <ChangeRoleType
          {...{
            userRoleInfo,
            changeDxUserRoleFn,
            onClose: () => setChangeRoleType(!changeRoleType),
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
  loggedUserStorageUsed: state.Global.loggedUserDetails.storageUsed,
  loggedUserStorageLimit: state.Global.loggedUserDetails.storageLimit,
  loggedUserDetails: state.Global.loggedUserDetails,
  dxAccountDetails: state.Global.dxAccountDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getUserListFn: () => dispatch(getUserListDataAction()),
  addNewUserFn: (payload) => dispatch(addNewUserAction(payload)),
  activateOrDeactivateUserFn: (payload) =>
    dispatch(activateOrDeactivateUserAction(payload)),
  resendInvitation: (payload) => dispatch(resendInvitationAction(payload)),
  deleteInvitationFn: (payload) => dispatch(revokeInvitationAction(payload)),
  searchDashboardUserListFn: (payload) =>
    dispatch(searchDashboardUserListAction(payload)),
  changeDxUserRoleFn: (payload) => dispatch(changeDxUserRoleAction(payload)),
  getAccountDetails: () => dispatch(getAccountDetailsAction()),
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
  resetMessageActionFn: () => dispatch(resetMessageAction()),
  getSlideData: (payload) => dispatch(getSlideboxSlideDataAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
