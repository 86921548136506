import React, { Component } from "react";
import Viewer from "./components/osd-viewer";
import "./viewer-container.scss";
import axios from "../../utils/axiosInstance";
import $ from "jquery";
import OpenSeadragon from "./utils/fabricjs-overlay";
import { connect } from "react-redux";
import {
  downloadAnnotationAction,
  gernerateLiveSyncUrl,
  gernerateLiveSyncUrlAction,
  setAnnotationUniqueDataAction,
  setViewerDataArray,
  setSlideActivityLogAction,
  getSlideActivityLogAction,
} from "../imageViewer/actions/viewer-action";
import OpenSeadragonControls from "./components/osd-controls";
import ViewerToolBar from "./components/viewer-toolbar";
import SlideThumbnail from "./components/slide-thumbnail";
import { Notify } from "../../components";
import ViewerCollapsibleSidebar from "./components/collapsible-sidebar/viewer-collapsibe-sidebar";
import { toggleSidebarAction } from "../../actions/global-action";
import LiveSyncUrlPopup from "./components/live-sync-url-popup";
import {
  setLiveSyncMetaDataAction,
  deleteAnnotionPopupAction,
  getSlideHeatMapDataAction,
  closeDeleteAnnotionPopupAction,
} from "./actions/viewer-action";
import { deleteAnnotations } from "../../pages/slideSplitViewer/utils/viewer-utils";
import { Prompt } from "../../components";
import {
  getAnalysisResultAction,
  resetShowAnalysisResultInViewerAction,
} from "../aiAnalytics/actions/aiAnalytics-action";
import ScreenshotPopUp from "./components/screenshot-popup";
import Loader from "../slidebox/components/SlideTable/Loader";
import openseadragonAnnotations from "./utils/openseadragon-annotations";

let cropper = React.createRef(null);

class ViewerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDataLoaded: false,
      imageData: undefined,
      imageOtherSlideData: [],
      caseStremPostId: null,
      thumbnailSliderShow: props.when === "viewer" ? true : false,
      thumbToken: null,
      navigatorShow: true,
      loadFrom: null,
      fromCaseStream: false,
      postIsMine: null,
      slideOwnerOrCollaborator: null,
      isLiveShareActive: false,
      viewer: null,
      overlayInstance: undefined,
      overlayInstanceSplitViewer: undefined,
      // thumbnailSliderShow: true,
      OPENSEADRAGONVIEWER: undefined,
      OPENSEADRAGONBUTTON: undefined,
      OPENSEADRAGONVIEWER1: undefined,
      OPENSEADRAGONBUTTON1: undefined,
      currentViewerInstance: undefined,
      currentOverlayInstance: undefined,
      currentButtonInstance: undefined,
      selectedViewer: "0",
      showCollapsibleSidebar: true,
      splitSlideData: null,
      showCustomRotate: false,
      rotationStart: false,
      orgAngleArray: null,
      orgAngleArraySplitView: null,
      isAnalysis: false,
      heatMapOverlay: undefined,
      loadSlideId: null,
      openSnapshotUrl: null,
      openSnapshot: false,
      cropData: "",
      cropResult: null,
      rotationValue: 0,
      activeSnapShot: false,
    };
    this.setFullScreenRef = React.createRef();
  }

  componentDidMount() {
    this.props.toggleSidebarAction(true);
    this.updateImageViewer(this.props);
    this.props.resetShowAnalysisResultInViewer();
  }

  componentWillUnmount() {
    if (this.props.when === "viewer") {
      this.props.setSlideActivityLog({
        type: "update",
        slideId: this.state.loadSlideId,
        slideActivityId: this.props.viewerActivityLogId,
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.location !== this.props.location ||
      nextProps.value !== this.props.value ||
      nextProps.dziData !== this.props.dziData ||
      nextProps.slideBoxId !== this.props.slideBoxId
    ) {
      if (this.props.location && this.props.location.pathname === "/viewer") {
        const urlParams = new URLSearchParams(window.location.search);
        const folderKey = urlParams.get("image");
        const thumb = urlParams.get("thumb");
        const postId = urlParams.get("post");
        if (thumb) {
          this.loadImageDataWithThumbInSlideBox(folderKey, thumb);
        }
        if (postId) {
          this.loadImageDataWithThumbInCaseStream(folderKey, postId);
          this.setState({ fromCaseStream: true });
        }
      } else {
        this.updateImageViewer(nextProps);
      }
    }
    return true;
  }

  updateImageViewer = (props) => {
    if (props.location) {
      const {
        location: { search },
      } = this.props;
      const searchData = search.split("=");
      this.value = searchData[1].split("&")[0];
      if (typeof searchData[1].split("&")[1] !== "undefined") {
        const searchType = searchData[1].split("&")[1];
        if (searchType === "thumb") {
          const thumbToken = searchData[2].split("&")[0];
          this.setState({ thumbToken: thumbToken });
          this.loadImageDataWithThumbInSlideBox(this.value, thumbToken);
          if (searchData.length === 4) {
            this.setState({ isAnalysis: true });
          }
        }

        if (searchType === "liveShareToken") {
          const liveShareToken = searchData[2];
          this.loadImageDataWithLiveShare(this.value, liveShareToken);
        }
      } else {
        this.loadImageDataWithoutThumb(this.value);
      }
    } else {
      if (props.when === "groups") {
        if (props.slideBoxId) {
          this.setState({ postIsMine: props.postIsMine });
          this.loadImageDataWithoutThumb(props.slideBoxId, true);
        }
      }
    }
  };

  loadImageDataWithThumbInSlideBox = (value, thumToken) => {
    this.setState({ loadFrom: "slide-view" });
    axios
      .post("api/slidebox/getFileData", {
        thumbToken: thumToken,
        keyFolder: decodeURI(value),
        showSlideThumb: true,
      })
      .then((response) => {
        this.handleOnDziData(response.data[0]);
        this.setState({ imageData: response.data[0] });
        this.setState({
          imageOtherSlideData: response.data[0].slideThumData,
          slideOwnerOrCollaborator: response.data[0].slideOwnerOrCollaborator,
        });
      })
      .catch((e) => console.warn(e));
  };

  loadImageDataWithoutThumb = (value, slideId = false) => {
    let dataParams;
    if (slideId) {
      dataParams = {
        moduleName: "slidebox",
        showSlideThumb: false,
        fileId: value,
        isGroup: true,
        groupId: this.props.selectedGroup ? this.props.selectedGroup.id : null,
      };
    } else {
      dataParams = {
        moduleName: "slidebox",
        showSlideThumb: false,
        keyFolder: value,
      };
    }
    axios
      .post("api/slidebox/getFileData", dataParams)
      .then((response) => {
        if (response.data.length > 0) {
          this.props.setLiveSyncMetaData(null);
          this.handleOnDziData(response.data[0]);
          this.setState({
            imageData: response.data[0],
            imageOtherSlideData: response.data[0].slideThumData,
            slideOwnerOrCollaborator: response.data[0].slideOwnerOrCollaborator,
          });
        } else {
          Notify({
            value:
              "The slide you are trying to access has been deleted and no longer be viewed",
          });
        }
      })
      .catch((e) => console.warn(e));
  };

  fetchImageMetadata = (url, initialLoad, imgData) => {
    fetch(url + "vips-properties.xml").then((response) => {
      response.blob().then((blob) => {
        const reader = new FileReader();
        const ABS = !!reader.readAsBinaryString;
        reader.onload = (e) => {
          this.metadata = this.parseMetadataXML(e.target.result);
          const { zoom } = this.props;
          const dziTileData = this.tileSourceFromData(
            this.dziData,
            this.dziFilesUrl
          );
          this.props.getAnalysisResult({
            slideId: imgData.slidebox_slide_id,
            width: this.metadata.width,
            height: this.metadata.height,
          });

          this.setState({ loadSlideId: imgData.slidebox_slide_id });

          if (this.props.when === "viewer") {
            this.props.getSlideActivityLog({
              slideId: imgData.slidebox_slide_id,
            });
            if (!this.props.viewerActivityLogId) {
              this.props.setSlideActivityLog({
                type: "add",
                slideId: imgData.slidebox_slide_id,
              });
            }
          }

          const config = {
            basename: "example/image",
            getImageURL: dziTileData,
            thumbnail: dziTileData,
            osdConfig: {
              setStrings: [{ name: "Tooltips.Home", value: "Reset" }],
              defaultZoomLevel: zoom ? zoom : 0,
              tileSources: dziTileData,
              navigatorPosition: "ABSOLUTE",
              navigatorTop: "50px",
              navigatorLeft: "0px",
              navigatorHeight: "120px",
              navigatorWidth: "145px",
              visibilityRatio: 1.0,
              constrainDuringPan: true,
              sequenceMode: false,
              showReferenceStrip: false,
              showNavigator: true,
              navigatorAutoFade: false,
              zoomInButton:
                typeof this.props.viewerId === "undefined"
                  ? "zoom-in"
                  : `zoom-in-${this.props.viewerId}`,
              zoomOutButton:
                typeof this.props.viewerId === "undefined"
                  ? "zoom-out"
                  : `zoom-out-${this.props.viewerId}`,
              homeButton: "home",
              fullPageButton: "full-page",
              rotate: "rotate",
              crossOriginPolicy: "Anonymous",
              canvasDrag: false,
              slideId:
                typeof imgData !== "undefined"
                  ? initialLoad
                    ? imgData.slidebox_slide_id
                    : imgData.slideId
                  : this.props.dziData
                  ? this.props.dziData.slide_box_id
                  : null,
              // toolbar: "viewer-toolbar-container"
            },
            imageMetadata: {
              ...this.metadata,
              "aperio.Filename":
                typeof imgData !== "undefined"
                  ? typeof imgData.slide_name !== "undefined"
                    ? imgData.slide_name
                    : typeof imgData.slideName !== "undefined"
                    ? imgData.slideName
                    : ""
                  : "",
            },
            pages: [
              {
                id: 0,
                title: "MLK",
                sidebarThumbnail: dziTileData,
                transcript: "MLK",
                viewer: "OSD_VIEWER",
                cdmCollection: "mpls",
                cdmIdentifier: "3188",
                infoURL: dziTileData,
              },
            ],
          };
          if (initialLoad) {
            this.props.setViewerData([
              {
                id: `osd-viewer${1}`,
                config: config,
              },
            ]);
          } else {
            this.props.setViewerData([
              ...this.props.viewerDataArray,
              {
                id: `osd-viewer${this.props.viewerDataArray.length + 1}`,
                config: config,
              },
            ]);
          }
          this.props.getSlideHeatMapData();
          this.setState({ isDataLoaded: true });
        };

        if (ABS) reader.readAsBinaryString(blob);
        else reader.readAsArrayBuffer(blob);
      });
    });
  };

  parseMetadataXML = (data) => {
    let imageMetadata = {};
    const xml = $($.parseXML(data));
    const image = xml.find("image");
    const props = image.children().children();
    for (let p in props) {
      let property = props[p];
      if (property.firstElementChild) {
        let key = property.firstElementChild.innerHTML,
          value = property.lastElementChild.innerHTML;
        imageMetadata[key] = value;
      }
    }
    return imageMetadata;
  };

  handleOnDziData = (imgData) => {
    if (_.isEmpty(imgData)) {
      return;
    }
    this.props.setAnnotationUniqueData(imgData.unique_id);
    this.loadDziData(imgData);
  };

  loadDziData = (imgData, initialLoad = true) => {
    if (initialLoad) {
      this.setState({ isDataLoaded: false });
    }

    this.dziFilesUrl =
      typeof imgData.dzi_url !== "undefined" ? imgData.dzi_url : null;
    fetch(`${imgData.dzi_url.split(".dzi_files/")[0]}.dzi.dzi`).then(
      (response) => {
        response.blob().then((blob) => {
          const reader = new FileReader();
          const ABS = !!reader.readAsBinaryString;
          reader.onload = (e) => {
            this.dziData = e.target.result; // This will give the xml string of that file
            this.fetchImageMetadata(this.dziFilesUrl, initialLoad, imgData);
          };

          if (ABS) reader.readAsBinaryString(blob);
          else reader.readAsArrayBuffer(blob);
        });
      }
    );
  };

  tileSourceFromData = (data, filesUrl) => {
    const xml = $($.parseXML(data));
    const image = xml.find("Image");
    const size = xml.find("Size");
    let dzi = {
      Image: {
        xmlns: image.attr("xmlns"),
        Url: filesUrl,
        Format: image.attr("Format"),
        Overlap: image.attr("Overlap"),
        TileSize: image.attr("TileSize"),
        Size: {
          Height: size.attr("Height"),
          Width: size.attr("Width"),
        },
      },
    };

    return dzi;
  };

  changeSlideImage = (slide) => {
    const { thumbToken } = this.state;
    if (this.props.when == "groups") {
      this.props.changeThumbnailSlide(slide.slideId);
      return;
    }

    this.props.history.push(
      `/viewer?image=${slide.keyFolder}&thumb=${thumbToken}`
    );
  };

  toggleThumbnailSlider = () => {
    this.setState({ thumbnailSliderShow: !this.state.thumbnailSliderShow });
  };

  toggleNavigator = () => {
    this.setState({ navigatorShow: !this.state.navigatorShow });
  };

  splitSlide = async (slideData) => {
    this.setState({ splitSlideData: slideData, selectedViewer: "1" });
    if (this.props.viewerDataArray.length <= 1) {
      this.loadDziData(slideData, false);
    } else {
      Notify({ value: "Cannot add more than 2 slides" });
    }
  };

  setViewerObject = (viewer) => {
    this.setState({ viewer: viewer });
  };

  setViewerInstance = (instance, index) => {
    if (index <= 1) {
      if (index === 0) {
        this.setState({ OPENSEADRAGONVIEWER: instance });
      } else {
        this.setState({ OPENSEADRAGONVIEWER1: instance });
      }
    }
  };

  setButtonInstance = (instance, index) => {
    if (index <= 1) {
      if (index === 0) {
        this.setState({ OPENSEADRAGONBUTTON: instance });
      } else {
        this.setState({ OPENSEADRAGONBUTTON1: instance });
      }
    }
  };

  setOverlayInstance = (instance, index, heatMapOverlay) => {
    if (index === 0) {
      this.setState({ overlayInstance: instance, heatMapOverlay });
    } else {
      this.setState({ overlayInstanceSplitViewer: instance });
    }
  };

  generateLiveSyncUrl = (validPeriod) => {
    const data = {
      slideId: this.state.loadSlideId,
      validPeriod,
    };
    this.props.gernerateLiveSyncLink(data);
  };

  liveSyncPopupToggle = () => {
    this.props.toggleLiveSyncPopup(null);
  };

  viewerOnClick = (index) => {
    const {
      OPENSEADRAGONBUTTON,
      OPENSEADRAGONVIEWER,
      overlayInstance,
      OPENSEADRAGONBUTTON1,
      OPENSEADRAGONVIEWER1,
      overlayInstanceSplitViewer,
    } = this.state;
    if (index === 0) {
      this.setState({
        currentButtonInstance: OPENSEADRAGONBUTTON,
        currentViewerInstance: OPENSEADRAGONVIEWER,
        currentOverlayInstance: overlayInstance,
        selectedViewer: index.toString(),
        showCustomRotate: false,
        rotationStart: false,
        orgAngleArray: null,
      });
      // OPENSEADRAGONVIEWER.gestureSettingsMouse.clickToZoom = false;
    } else {
      this.setState({
        currentButtonInstance: OPENSEADRAGONBUTTON1,
        currentViewerInstance: OPENSEADRAGONVIEWER1,
        currentOverlayInstance: overlayInstanceSplitViewer,
        selectedViewer: index.toString(),
        showCustomRotate: false,
        rotationStart: false,
        orgAngleArray: null,
      });
      // OPENSEADRAGONVIEWER1.gestureSettingsMouse.clickToZoom = false;
    }
  };

  toggleSidebar = () => {
    this.setState({
      showCollapsibleSidebar: !this.state.showCollapsibleSidebar,
    });
  };

  removeSplitSlide = async (event, indexVal) => {
    event.stopPropagation();
    if (indexVal === 0) {
      const {
        OPENSEADRAGONBUTTON1,
        OPENSEADRAGONVIEWER1,
        overlayInstanceSplitViewer,
      } = this.state;
      await this.setState({
        currentButtonInstance: OPENSEADRAGONBUTTON1,
        currentViewerInstance: OPENSEADRAGONVIEWER1,
        currentOverlayInstance: overlayInstanceSplitViewer,
        OPENSEADRAGONVIEWER: OPENSEADRAGONVIEWER1,
        OPENSEADRAGONBUTTON: OPENSEADRAGONBUTTON1,
        overlayInstance: overlayInstanceSplitViewer,
        rotationStart: false,
        orgAngleArray: null,
        orgAngleArraySplitView: null,
      });
      this.props.history.push(
        `/viewer?image=${this.state.splitSlideData.keyFolder}&thumb=${this.state.thumbToken}`
      );
    } else {
      this.props.history.push(
        `/viewer?image=${this.state.imageData.dzi_key_folder}&thumb=${this.state.thumbToken}`
      );
    }
    await this.setState({
      selectedViewer: indexVal === 1 ? "0" : "1",
      splitSlideData: null,
    });
    const newSlideArray = this.props.viewerDataArray.filter(
      (data, index) => index !== indexVal
    );

    this.props.setViewerData(newSlideArray);
  };

  loadImageDataWithLiveShare = (value, liveSyncToken) => {
    let dataParams = {
      moduleName: "slidebox",
      showSlideThumb: false,
      keyFolder: value,
      liveSyncToken,
    };
    axios
      .post("api/slidebox/getFileData", dataParams)
      .then((response) => {
        if (response.data.length > 0) {
          this.props.setLiveSyncMetaData(response.data[0].liveSyncData);
          this.handleOnDziData(response.data[0]);
          this.setState({
            imageData: response.data[0],
            imageOtherSlideData: response.data[0].slideThumData,
            slideOwnerOrCollaborator: response.data[0].slideOwnerOrCollaborator,
            isLiveShareActive: true,
          });
          if (!response.data[0].liveSyncData) {
            Notify({
              value: "Viewer LiveSync is expired",
            });
          }
        } else {
          Notify({
            value:
              "The slide you are trying to access has been deleted and no longer be viewed",
          });
        }
      })
      .catch((e) => console.warn(e));
  };

  handleRotate = () => {
    this.setState({ showCustomRotate: !this.state.showCustomRotate });
  };

  rotateImageHandler = (val) => {
    this.setState({ rotationValue: val });
    if (this.state.OPENSEADRAGONVIEWER1) {
      this.state.OPENSEADRAGONVIEWER1.viewport.setRotation(val);
      this.rotateObjectViewerSplitView(val);
    }

    this.state.OPENSEADRAGONVIEWER.viewport.setRotation(val);
    this.rotateObject(val);
  };

  handleHomeClick = () => {
    let { OPENSEADRAGONVIEWER, OPENSEADRAGONVIEWER1 } = this.state;

    if (OPENSEADRAGONVIEWER1) {
      OPENSEADRAGONVIEWER1.viewport.setRotation(0);
      OPENSEADRAGONVIEWER1.viewport.goHome();
      openseadragonAnnotations.refresAnnotationData(
        this.props.allAnnotationData
      );
      this.setState({ rotationValue: 0 });
    }

    OPENSEADRAGONVIEWER.viewport.setRotation(0);
    OPENSEADRAGONVIEWER.viewport.goHome();
    openseadragonAnnotations.refresAnnotationData(this.props.allAnnotationData);
    this.setState({ showCustomRotate: false, rotationValue: 0 });
    // this.rotateObject(0);
  };

  rotateObject = (angle) => {
    let canvas = this.state.overlayInstance.fabricCanvas();
    let canvasCenter = new fabric.Point(0, 0); // center of canvas
    if (!this.state.rotationStart && !this.state.orgAngleArray) {
      this.setState({ rotationStart: true });
      let objArray = canvas
        .getObjects()
        .map((data) => ({ orgAngle: data.angle }));
      this.setState({ orgAngleArray: objArray });
    }
    canvas.getObjects().forEach((obj, index) => {
      let objectOrigin = new fabric.Point(obj.left, obj.top);
      let orgAngleVal = this.state.orgAngleArray
        ? this.state.orgAngleArray[index]["orgAngle"]
        : obj.angle;
      let radians = fabric.util.degreesToRadians(
        angle - (obj.angle - orgAngleVal)
      );
      let newAngle = orgAngleVal + angle;
      let angleValue = newAngle > 360 ? newAngle - 360 : newAngle;
      let new_loc = fabric.util.rotatePoint(
        objectOrigin,
        canvasCenter,
        radians
      );
      obj.top = new_loc.y;
      obj.left = new_loc.x;
      obj.angle = angleValue;
      obj.setCoords(); //rotate each object by the same angle
      canvas.renderAll();
    });
  };

  rotateObjectViewerSplitView = (angle) => {
    let canvasSplitView = this.state.overlayInstanceSplitViewer.fabricCanvas();
    let canvasCenterSplitView = new fabric.Point(0, 0); // center of canvas
    if (!this.state.orgAngleArraySplitView) {
      let objArraySplitView = canvasSplitView
        .getObjects()
        .map((data) => ({ orgAngle: data.angle }));
      this.setState({ orgAngleArraySplitView: objArraySplitView });
    }

    canvasSplitView.getObjects().forEach((obj, index) => {
      let objectOriginSplitView = new fabric.Point(obj.left, obj.top);
      let orgAngleValSplitView = this.state.orgAngleArraySplitView
        ? this.state.orgAngleArraySplitView[index]["orgAngle"]
        : obj.angle;
      let radiansSplitView = fabric.util.degreesToRadians(
        angle - (obj.angle - orgAngleValSplitView)
      );
      let newAngleSplitView = orgAngleValSplitView + angle;
      let angleValue2 =
        newAngleSplitView > 360 ? newAngleSplitView - 360 : newAngleSplitView;
      let new_loc2 = fabric.util.rotatePoint(
        objectOriginSplitView,
        canvasCenterSplitView,
        radiansSplitView
      );
      obj.top = new_loc2.y;
      obj.left = new_loc2.x;
      obj.angle = angleValue2;
      obj.setCoords(); //rotate each object by the same angle
      canvasSplitView.renderAll();
    });
  };

  onDeleteAnnotationComfirmed = () => {
    const annotationId = this.props.selectedAnnotationId;
    deleteAnnotations({
      annotationId,
      dispatch: this.props.dispatch,
    });
    this.setShowConfirmDelete(false);
  };

  setShowConfirmDelete = (status) => {
    this.props.deleteAnnotionPopup({ status, annotationId: null });
  };

  setShowConfirmDeleteClose = (status) => {
    this.props.closeDeleteAnnotionPopup({ status });
  };

  captureScreenshot = () => {
    // const canvas = this.state.OPENSEADRAGONVIEWER.drawer.canvas;
    // const captureData = canvas.toDataURL();
    // this.setBase64Data(captureData);
    const canvas = this.state.OPENSEADRAGONVIEWER.drawer.canvas;
    const cropperData = canvas.toDataURL();
    this.setState({ cropData: cropperData });
    return false;
  };

  setBase64Data = async (b64Data) => {
    const contentType = "image/jpeg";
    const blob = this.b64toBlob(b64Data, contentType);
    const blobUrl = URL.createObjectURL(blob);
    this.setState({ openSnapshotUrl: blobUrl, openSnapshot: true });
    await this.setState({ cropData: false });
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(
      b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  onSnapshotClose = async (isClose = false) => {
    if (!isClose) {
      this.setState({ activeSnapShot: true });
      this.captureScreenshot();
    } else {
      await this.setState({ activeSnapShot: false, openSnapshot: false });
    }
  };

  downloadSnapshot = async () => {
    const link = document.createElement("a");
    link.href = this.state.openSnapshotUrl;
    link.download = `screen-shot-${Date.now()}${".png"}`;
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(() => {
      link.remove();
    }, 100);
  };

  attachtoCase = () => {};

  cropImage = async (removeCrop) => {
    if (!removeCrop) {
      const { cropResult } = this.state;
      if (typeof this.cropper.getCroppedCanvas() === "undefined") {
        return;
      }
      if (cropResult !== this.cropper.getCroppedCanvas().toDataURL()) {
        const imageBlob = this.cropper.getCroppedCanvas().toDataURL();
        await this.setState({
          cropResult: imageBlob,
        });
        this.setBase64Data(imageBlob);
      }
    } else {
      await this.setState({ cropData: false });
    }
  };

  resetCroptool = async () => {
    // this.setState({ openSnapshotUrl: null, openSnapshot: true });
    // await this.setState({ cropData: false });
  };

  setRef = (cropper) => {
    this.cropper = cropper;
  };

  render() {
    if (!this.state.isDataLoaded) {
      return <Loader />;
    }

    const {
      imageData,
      imageOtherSlideData,
      navigatorShow,
      loadFrom,
      fromCaseStream,
      postIsMine,
      slideOwnerOrCollaborator,
      splitSlideData,
      showCustomRotate,
    } = this.state;

    return (
      <React.Fragment>
        {this.props.when !== "groups" && (
          <ViewerCollapsibleSidebar
            imageMetadata={
              this.props.viewerDataArray.length > 1 && this.state.selectedViewer
                ? this.props.viewerDataArray[
                    parseInt(this.state.selectedViewer)
                  ]["config"].imageMetadata
                : this.props.viewerDataArray[0]["config"].imageMetadata
            }
            showCollapsibleSidebar={this.state.showCollapsibleSidebar}
            toggleSidebar={this.toggleSidebar}
            viewer={
              this.state.currentViewerInstance
                ? this.state.currentViewerInstance
                : this.state.OPENSEADRAGONVIEWER
            }
            annotationDownloadUrl={this.props.annotationDownloadUrl}
            downloadAnnotation={this.props.downloadAnnotation}
            isAnalysis={this.state.isAnalysis}
          />
        )}
        <div className={`osd osd-viewer-page`}>
          <div className="row osd-row">
            <div className={`easer`}>
              <div className={"viewer-container"}>
                <div className="viewer">
                  <OpenSeadragonControls
                    when={this.props.when}
                    isMobile={this.props.isMobile}
                    viewerId={this.props.viewerId}
                    postId={null}
                    keyFolder={imageData ? imageData.dzi_key_folder : null}
                  />
                  <ViewerToolBar
                    onSnapshotClose={this.onSnapshotClose}
                    fromCaseStream={fromCaseStream}
                    slideOwnerOrCollaborator={slideOwnerOrCollaborator}
                    osdButtons={
                      this.state.currentButtonInstance
                        ? this.state.currentButtonInstance
                        : this.state.OPENSEADRAGONBUTTON
                    }
                    viewer={
                      this.state.currentViewerInstance
                        ? this.state.currentViewerInstance
                        : this.state.OPENSEADRAGONVIEWER
                    }
                    OpenSeadragon={OpenSeadragon}
                    overlay={
                      this.state.currentOverlayInstance
                        ? this.state.currentOverlayInstance
                        : this.state.overlayInstance
                    }
                    thumbnailSliderShow={this.state.thumbnailSliderShow}
                    toggleThumbnailSlider={this.toggleThumbnailSlider}
                    slideThumbData={imageOtherSlideData}
                    zoomValueBar={1}
                    slideId={
                      this.props.viewerDataArray.length > 1 &&
                      this.state.selectedViewer
                        ? this.props.viewerDataArray[
                            parseInt(this.state.selectedViewer)
                          ]["config"]["osdConfig"].slideId
                        : this.props.viewerDataArray[0]["config"]["osdConfig"]
                            .slideId
                    }
                    when={this.props.when}
                    featureId={
                      this.props.when === "groups" ? this.props.groupId : null
                    }
                    postIsMine={postIsMine}
                    groupAdmin={this.props.groupAdmin}
                    setFullScreenRef={this.setFullScreenRef}
                    enableFullScreenState={this.props.viewerFullScreen}
                    selectedGroupData={this.props.selectedGroupData}
                    postIsValidate={
                      this.props.postIsValidate
                        ? this.props.postIsValidate
                        : false
                    }
                    toggleSlideManager={this.toggleSidebar}
                    showSlideManager={this.state.showCollapsibleSidebar}
                    handleRotate={this.handleRotate}
                    rotateImageHandler={this.rotateImageHandler}
                    rotateObject={this.rotateObject}
                    showCustomRotate={showCustomRotate}
                    viewer1={this.state.OPENSEADRAGONVIEWER}
                    splitViewer={this.state.OPENSEADRAGONVIEWER1}
                    overlayInstance={this.state.overlayInstance}
                    overlayInstanceSplitViewer={
                      this.state.overlayInstanceSplitViewer
                    }
                    selectedViewer={parseInt(this.state.selectedViewer)}
                    handleHomeClick={this.handleHomeClick}
                    isAnalysis={this.state.isAnalysis}
                    heatMapOverlay={this.state.heatMapOverlay}
                    isSplitViewer={this.state.splitSlideData}
                    rotationValue={this.state.rotationValue}
                    openSnapshot={this.state.openSnapshot}
                    activeSnapShot={this.state.activeSnapShot}
                  />
                  {this.props.viewerDataArray.map((viewerData, index) => (
                    <React.Fragment>
                      <Viewer
                        {...this.props}
                        mainImage={this.value}
                        navigatorShow={navigatorShow}
                        toggleNavigator={this.toggleNavigator}
                        loadFrom={loadFrom}
                        showCollapsibleSidebar={
                          this.props.showCollapsibleSidebar
                        }
                        cropData={this.state.cropData}
                        setRef={this.setRef}
                        cropImage={this.cropImage}
                        resetCroptool={this.resetCroptool}
                        fromCaseStream={fromCaseStream}
                        postIsMine={postIsMine}
                        slideOwnerOrCollaborator={slideOwnerOrCollaborator}
                        when={
                          this.state.isLiveShareActive
                            ? "viewer"
                            : this.props.when
                        }
                        splitSlide={this.splitSlide}
                        setViewerObject={this.setViewerObject}
                        viewerData={viewerData}
                        setViewerInstance={(instance) =>
                          this.setViewerInstance(instance, index)
                        }
                        setButtonInstance={(instance) =>
                          this.setButtonInstance(instance, index)
                        }
                        heatMapOverlay={this.state.heatMapOverlay}
                        thumbnailSliderShow={this.state.thumbnailSliderShow}
                        toggleThumbnailSlider={this.toggleThumbnailSlider}
                        slideThumbData={imageOtherSlideData}
                        zoomValueBar={1}
                        slideId={
                          this.props.viewerDataArray.length > 1 &&
                          this.state.selectedViewer
                            ? this.props.viewerDataArray[
                                parseInt(this.state.selectedViewer)
                              ]["config"]["osdConfig"].slideId
                            : this.props.viewerDataArray[0]["config"][
                                "osdConfig"
                              ].slideId
                        }
                        buttonInstance={
                          index === 0
                            ? this.state.OPENSEADRAGONBUTTON
                            : this.state.OPENSEADRAGONBUTTON1
                        }
                        viewerInstance={
                          index === 0
                            ? this.state.OPENSEADRAGONVIEWER
                            : this.state.OPENSEADRAGONVIEWER1
                        }
                        setFullScreenRef={this.setFullScreenRef}
                        setOverlayInstance={this.setOverlayInstance}
                        viewerOnClick={this.viewerOnClick}
                        viewerIndex={index}
                        selectedViewer={parseInt(this.state.selectedViewer)}
                        removeSplitSlide={this.removeSplitSlide}
                        viewer1={this.state.OPENSEADRAGONVIEWER}
                        splitViewer={this.state.OPENSEADRAGONVIEWER1}
                        activeSnapShot={this.state.activeSnapShot}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>

            {this.state.thumbnailSliderShow &&
              this.state.isLiveShareActive !== true &&
              !this.state.isAnalysis && (
                <SlideThumbnail
                  toggleThumbnailSlider={this.toggleThumbnailSlider}
                  slides={imageOtherSlideData}
                  changeSlide={this.changeSlideImage}
                  splitSlide={this.splitSlide}
                  caseStremPostId={this.props.caseStremPostId}
                  defultKeyFolder={imageData ? imageData.dzi_key_folder : null}
                  showSidebar={this.props.showSidebar}
                  loadFrom={loadFrom}
                  showCollapsibleSidebar={this.props.showCollapsibleSidebar}
                  collapsibleSidebarExist={this.props.showCollapsibleSidebar}
                  when={this.props.when}
                  highlightSlide={null}
                  groupId={this.props.groupId ? this.props.groupId : null}
                  isAdmin={this.props.groupAdmin ? this.props.groupAdmin : null}
                  splitSlideData={splitSlideData}
                  state={this.state}
                />
              )}
            {this.props.liveSyncPopupload && (
              <LiveSyncUrlPopup
                onClose={() => this.liveSyncPopupToggle()}
                generateLiveSyncUrl={this.generateLiveSyncUrl}
                liveSyncUrl={this.props.liveSyncUrl}
              />
            )}

            {this.props.annotationPopupShow && (
              <Prompt
                title={`Delete Annotation`}
                content={`Are you sure you want to delete this annotation ?`}
                actionText="Yes"
                action={this.onDeleteAnnotationComfirmed}
                back={() => {
                  this.setShowConfirmDeleteClose(false);
                }}
              />
            )}
          </div>
        </div>
        {this.state.openSnapshot && this.state.openSnapshotUrl && (
          <ScreenshotPopUp
            onSnapshotClose={this.onSnapshotClose}
            openSnapshotUrl={this.state.openSnapshotUrl}
            downloadSnapshot={this.downloadSnapshot}
            attachtoCase={this.attachtoCase}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  viewerDataArray: state.Viewer.viewerDataArray,
  userDetails: state.Global.loggedUserDetails,
  windowSize: state.Global.windowSize,
  isMobile: false,
  liveShareIsOn: state.Viewer.liveShareOn,
  selectedGroupData: state.Groups.selectedBoard,
  userLiveShareOn: state.Viewer.userLiveShareOn,
  selectedMembers: state.Groups.selectedMembers,
  scaleUnit: state.Viewer.scaleUnit,
  liveSyncMetaData: state.Viewer.liveSyncMetaData,
  annotationDownloadUrl: state.Viewer.annotationDownloadUrl,
  liveSyncPopupload: state.Viewer.liveSyncPopupload,
  selectedListData: state.SlideBox.selectedListData,
  liveSyncUrl: state.Viewer.liveSyncUrl,
  viewerFullScreen: state.Viewer.viewerFullScreen,
  annotationPopupShow: state.Viewer.annotationPopupShow,
  selectedAnnotationId: state.Viewer.selectedAnnotationId,
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  viewerActivityLogId: state.Viewer.viewerActivityLogId,
  allAnnotationData: state.Viewer.annotationData,
  selectedGroup: state.Groups.selectedBoard,
});

const mapDispatchToProps = (dispatch) => ({
  setLiveSyncMetaData: (payload) =>
    dispatch(setLiveSyncMetaDataAction(payload)),
  setViewerData: (payload) => dispatch(setViewerDataArray(payload)),
  downloadAnnotation: (payload) => dispatch(downloadAnnotationAction(payload)),
  toggleSidebarAction: (payload) => dispatch(toggleSidebarAction(payload)),
  setAnnotationUniqueData: (payload) =>
    dispatch(setAnnotationUniqueDataAction(payload)),
  gernerateLiveSyncLink: (payload) =>
    dispatch(gernerateLiveSyncUrlAction(payload)),
  toggleLiveSyncPopup: (payload) => dispatch(gernerateLiveSyncUrl(payload)),
  deleteAnnotionPopup: (payload) =>
    dispatch(deleteAnnotionPopupAction(payload)),
  getSlideHeatMapData: (payload) =>
    dispatch(getSlideHeatMapDataAction(payload)),
  resetShowAnalysisResultInViewer: () =>
    dispatch(resetShowAnalysisResultInViewerAction()),
  getAnalysisResult: (payload) => dispatch(getAnalysisResultAction(payload)),
  getSlideActivityLog: (payload) =>
    dispatch(getSlideActivityLogAction(payload)),
  setSlideActivityLog: (payload) =>
    dispatch(setSlideActivityLogAction(payload)),
  closeDeleteAnnotionPopup: (payload) =>
    dispatch(closeDeleteAnnotionPopupAction(payload)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewerContainer);
