import React, { useState, useEffect } from "react";
import RootDirectory from "./RootDirectory";

const FolderTreeView = ({
  slideDirectory,
  setOpenDirectory,
  setCreateDirDisable,
  loadSlideboxDirDataList,
  slideboxLeftSiderIsCollapse,
  breadcrumbAction,
  setSelectedDirectory,
  setExpandDirectory,
  isMovePopup,
  setSelectedDirCollaborators,
  setSelectedDirAssignUsers,
}) => {
  const [rootDirOpen, setRootDirOpen] = useState(false);
  const [directoryTree, setDirectoryTree] = useState([]);

  useEffect(() => {
    if (slideDirectory.length > 0) {
      const dirs = getDirTree(slideDirectory);
      const mappedDir = dirs.map((dirData) => {
        if (!dirData.parentDir) {
          return {
            ...dirData,
            open: true,
          };
        } else {
          return dirData;
        }
      });
      setRootDirOpen(mappedDir[0]["open"]);
      setDirectoryTree(mappedDir);
    }
  }, [slideDirectory]);

  const toggleDirHandler = ({ dirId, listId, parentDir, dirType }) => {
    if (dirType === "case") {
      setCreateDirDisable(true);
    } else {
      setCreateDirDisable(false);
    }
    loadSlideboxDirDataList({
      dirId: dirId,
      dirType: parentDir ? dirType : "root",
    });

    if (parentDir) {
      setOpenDirectory({
        dirId,
        listId,
        parentDir,
        dirType: dirType,
      });
    } else {
      setOpenDirectory({
        dirId,
        listId,
        parentDir,
        dirType: "root",
      });
      breadcrumbAction();
    }
  };

  const setSelectedDir = ({ dirId, listId, parentDir, dirType }) => {
    if (dirType === "case") {
      setCreateDirDisable(true);
    } else {
      setCreateDirDisable(false);
    }
    loadSlideboxDirDataList({
      dirId: dirId,
      dirType: parentDir ? dirType : "root",
    });
    setSelectedDirectory({
      dirId,
      listId,
      parentDir,
      dirType: parentDir ? dirType : "root",
    });
    if (dirType === "case" || dirType === "folder") {
      setSelectedDirCollaborators({
        dirId,
        dirType: dirType,
      });
      if (dirType === "case") {
        setSelectedDirAssignUsers({
          dirId,
          dirType: dirType,
        });
      }
    }
  };

  const setExpandDir = ({ dirId, listId, parentDir, dirType }) => {
    setExpandDirectory({
      dirId,
      listId,
      parentDir,
      dirType: parentDir ? dirType : "root",
    });
  };

  const toggleDirHandlerMove = ({ dirId, listId, parentDir, dirType }) => {
    setOpenDirectory({
      dirId,
      listId,
      parentDir,
      dirType: parentDir ? dirType : "root",
    });
  };

  const setSelectedDirMove = ({ dirId, listId, parentDir, dirType }) => {
    setOpenDirectory({
      dirId,
      listId,
      parentDir,
      dirType: parentDir ? dirType : "root",
    });
  };

  const setExpandDirMove = ({ dirId, listId, parentDir, dirType }) => {
    setExpandDirectory({
      dirId,
      listId,
      parentDir,
      dirType: parentDir ? dirType : "root",
    });
  };

  const getDirTree = (directories) => {
    let map = {};
    let node;
    let roots = [];
    let i;
    for (i = 0; i < directories.length; i += 1) {
      map[directories[i].pk] = i;
      directories[i]["children"] = [];
    }

    for (i = 0; i < directories.length; i += 1) {
      node = directories[i];
      if (node.pkParentDir) {
        directories[map[node.pkParentDir]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  };

  return (
    <div className="folderTreeView-container" data-test="folderTreeView">
      {directoryTree.map((dir, index) => (
        <React.Fragment key={index}>
          {!dir.parentDir && (
            <RootDirectory
              {...{ ...dir, slideboxLeftSiderIsCollapse, rootDirOpen }}
              toggleDir={isMovePopup ? toggleDirHandlerMove : toggleDirHandler}
              setSelectedDir={isMovePopup ? setSelectedDirMove : setSelectedDir}
              setExpandDir={isMovePopup ? setExpandDirMove : setExpandDir}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default FolderTreeView;
