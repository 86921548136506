const initialState = {
  loggedUserDetails: {},
  otherUserDetails: null,
  dxAccountDetails: null,
  isLoggedUser: false,
  windowSize: null,
  showSidebar: false,
  profileUploadPending: false,
  permissions: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_DETAILS': {
      return {
        ...state,
        loggedUserDetails: action.payload,
        permissions: action.payload.accessLevelData,
      };
    }

    case 'RESET_PROFILE_PIC': {
      return {
        ...state,
        loggedUserDetails: {
          ...state.loggedUserDetails,
          userImageSrc: null,
        },
      };
    }
    case 'SET_USER_PROFILE': {
      return {
        ...state,
        loggedUserDetails: {
          ...state.loggedUserDetails,
          userImageSrc: action.payload,
        },
      };
    }

    case 'SET_PROFILE_UPLOAD_PENDING': {
      return {
        ...state,
        profileUploadPending: action.payload,
      };
    }

    case 'GET_DX_ACCOUNT_DETAILS': {
      return {
        ...state,
        dxAccountDetails: action.payload,
      };
    }
    
    case 'TOGGLE_SIDEBAR':
      return {
        ...state,
        showSidebar: action.payload,
      };
    default:
      return state;
  }
};
