import { getUserDetailsAction } from "../../../actions/global-action";
import {
  slideBoxFileApi,
  slideBoxCreateDirApi,
  slideBoxRenameDirApi,
  slideBoxLoadDirApi,
  slideBoxLoadDirDataListApi,
  slideBoxDeleteDirApi,
  slideBoxSearchDirApi,
  slideBoxDirDescriptionUpdateApi,
  slideBoxMoveDirApi,
  slideBoxDirCollaboratorsApi,
  slideBoxCollaboratorsApi,
  slideBoxRemoveCollaboratorsApi,
  slideBoxAssignUserToCaseApi,
  slideBoxRemoveAssignUserToCaseApi,
  slideBoxDirAssignUsersApi,
  slideBoxDirActivityApi,
  updateCaseStatusApi,
  getCaseStatusApi,
  dashboardUserListApi,
  slideboxAdvancedSearchApi,
  getStainTypeApi,
  slideBoxFileDownloadApi,
  slideBoxLabelsUpdateApi,
  getSlidesInfoApi,
} from "../services/slidebox-service";

const setFolderLists = (data) => {
  return {
    type: "SET_FOLDER_LISTS",
    data,
  };
};

const setSlideBoxDirList = (dirList) => {
  return {
    type: "SET_FOLDER_LISTS",
    payload: dirList,
  };
};

const setSlideBoxDirDataList = (dirDataList) => {
  return {
    type: "SET_FOLDER_DATA_LISTS",
    payload: dirDataList,
  };
};

const setCreateNewDir = (newDir) => {
  return {
    type: "SET_NEW_DIRECTORY",
    payload: newDir,
  };
};

const setRenameDir = (dirName, dirId, dirType) => {
  return {
    type: "RENAME_DIRECTORY",
    dirName,
    dirId,
    dirType,
  };
};

const setDeleteDir = (dirId, dirType) => {
  return {
    type: dirType !== "folder" ? "DELETE_DIRECTORY" : "DELETE_FOLDER",
    dirId,
    dirType,
  };
};

const showLoader = (show) => {
  return {
    type: "SHOW_LOADER",
    showLoader: show,
  };
};

export const switchViewAction = (toggle) => {
  return {
    type: "SWITCH_VIEW",
    isListView: toggle,
  };
};

const setSearchDirData = (searchResult) => {
  return {
    type: "SET_SEARCH_DIR_DATA",
    payload: searchResult,
  };
};

const setSlideBoxMoveDir = (payload) => {
  return {
    type: "SET_SLIDEBOX_MOVE_DIR",
    payload,
  };
};

export const resetMoveDirActive = (payload) => {
  return {
    type: "RESET_MOVE_DIR_ACTIVE",
    payload,
  };
};

const setSlideBoxDirCollaborators = (collaborators) => {
  return {
    type: "SET_SLIDEBOX_DIR_COLLABORATORS",
    payload: collaborators,
  };
};

const setSlideBoxCaseAssign = (users, dataParams) => {
  return {
    type: "SET_SLIDEBOX_CASE_ASSIGN_USERS",
    payload: users,
    caseData: dataParams,
  };
};

const setSlideBoxActivities = (activityData) => {
  return {
    type: "SET_SLIDEBOX_DIR_ACTIVITY",
    payload: activityData,
  };
};

const setSlideBoxCollaborators = (collaborators) => {
  return {
    type: "SET_SLIDEBOX_NEW_COLLABORATORS",
    payload: collaborators,
  };
};

const setSlideBoxRemoveCollaborators = (userId) => {
  return {
    type: "SET_SLIDEBOX_REMOVE_COLLABORATORS",
    payload: userId,
  };
};

const updateStainType = (data) => {
  return {
    type: "UPDATE_STAIN_TYPE",
    payload: data,
  };
};

const updateLabels = (data) => {
  return {
    type: "SLIDEBOX_LABELS_UPDATE",
    payload: data,
  };
};

const getSlides = (data) => {
  return {
    type: "GET_ALL_SLIDES",
    payload: data,
  };
};

export const setDirectories = (folders) => {
  return {
    type: "SET_FOLDERS",
    data: {
      folders,
    },
  };
};

export const setExpandDirectory = ({ dirId, listId, parentDir, dirType }) => {
  return {
    type: "SET_EXPAND_FOLDERS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setExpandDirectoryInMove = ({
  dirId,
  listId,
  parentDir,
  dirType,
}) => {
  return {
    type: "SET_EXPAND_FOLDERS_IN_MOVE",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const getSearchDirData = (data) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await slideBoxSearchDirApi(data);
      if (response.data.success) {
        dispatch(setSearchDirData(response.data.data));
        dispatch(showLoader(false));
      }
    } catch (error) {
      dispatch(setSearchDirData([]));
      dispatch(showLoader(false));
    }
  };
};

export const setOpenDirectory = ({ dirId, listId, parentDir, dirType }) => {
  return {
    type: "SET_OPEN_FOLDERS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setOpenDirectoryInMove = ({
  dirId,
  listId,
  parentDir,
  dirType,
}) => {
  return {
    type: "SET_OPEN_FOLDERS_IN_MOVE",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setSelectedDirectory = ({ dirId, listId, parentDir, dirType }) => {
  return {
    type: "SET_SELECTED_DIRS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setSelectedDirectoryInMove = ({
  dirId,
  listId,
  parentDir,
  dirType,
}) => {
  return {
    type: "SET_SELECTED_DIRS_IN_MOVE",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setOpenListDirectory = ({ dirId, listId, parentDir, dirType }) => {
  return {
    type: "SET_OPEN_LIST_FOLDERS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setSelectedListDirectory = ({
  dirId,
  listId,
  parentDir,
  dirType,
}) => {
  return {
    type: "SET_SELECTED_LIST_DIRS",
    folder: dirId,
    listId: listId,
    parentFolder: parentDir,
    dirType: dirType,
  };
};

export const setSelectedDirCollaborators = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxDirCollaboratorsApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxDirCollaborators(response.data.data));
      } else {
      }
    } catch (error) {}
  };
};

export const setSelectedDirAssignUsers = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxDirAssignUsersApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxCaseAssign(response.data.data, dataParams[0]));
      } else {
      }
    } catch (error) {}
  };
};

export const setSelectedListActivity = (dataParams) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxDirActivityApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxActivities(response.data.data));
      } else {
      }
    } catch (error) {}
  };
};

export const setSelectedUploadFolder = (folder) => {
  return {
    type: "SET_SELECTED_UPLOAD_FOLDER",
    data: {
      folder,
    },
  };
};

export const setSlideBoxStorageSize = (data) => {
  return {
    type: "SET_SLIDE_BOX_SIZE",
    data,
  };
};

export const setSlideBoxLoading = (loading) => {
  return {
    type: "SET_SLIDE_BOX_LOADING",
    loading,
  };
};

export const addTumorBoardEnable = (enable) => {
  return {
    type: "SET_ADD_TUMOR_BOARDS_ENABLE",
    enable,
  };
};

export const fetchSlideBoxFolders = (
  folder = "slidebox",
  type = "withFolder"
) => {
  return async (dispatch) => {
    dispatch(setSlideBoxLoading(true));
    dispatch(getUserDetailsAction());
    try {
      const response = await slideBoxFileApi({ folder: folder, type });
      if (response.status === 200) {
        dispatch(setFolderLists(response.data));
      }
    } catch (error) {}
  };
};

export const setSlideBoxStorageLimit = () => {
  return async (dispatch) => {
    try {
      const response = await slideBoxStorageDetailsApi();
      dispatch(setSlideBoxStorageSize(response.data));
    } catch (error) {}
  };
};

export const createSlideBoxDirectory = (dirType, data, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxCreateDirApi(dirType, data);
      if (response.data.success) {
        dispatch(setCreateNewDir(response.data.data));
        onSuccess();
      } else {
        onError(response.data.msg);
      }
    } catch (error) {
      onError();
    }
  };
};

export const renameSlideBoxDirectory = (dirType, data, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxRenameDirApi(dirType, data);
      if (response.data.success) {
        dispatch(setRenameDir(data.dirName, data.dirId, dirType));
        onSuccess();
      } else {
        onError(response.data.msg);
      }
    } catch (error) {
      onError();
    }
  };
};

export const deleteSlideBoxItemAction = (dirType, data, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxDeleteDirApi(dirType, data);
      if (response.data.success) {
        dispatch(setDeleteDir(data.dirId, dirType));
        dispatch(getUserDetailsAction());
        onSuccess();
      } else {
        onError();
      }
    } catch (error) {
      onError();
    }
  };
};

export const loadSlideboxDirectory = () => {
  return async (dispatch) => {
    try {
      const response = await slideBoxLoadDirApi();
      dispatch(setSlideBoxDirList(response.data));
      dispatch(getUserDetailsAction());
    } catch (error) {}
  };
};

export const loadSlideboxDirDataList = (dataParams) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader(true));
      const response = await slideBoxLoadDirDataListApi(dataParams);
      dispatch(setSlideBoxDirDataList(response.data));
      dispatch(getUserDetailsAction());
      dispatch(showLoader(false));
      // dispatch(switchViewAction(true));
    } catch (error) {}
  };
};

export const showSnackBarComponent = ({
  show,
  fileList,
  conversionComplete,
  percentCompleted,
}) => {
  return {
    type: "SHOW_SNACK_BAR_COMPONENT",
    payload: { show, fileList, conversionComplete, percentCompleted },
  };
};

export const setFileUploadProgress = ({ fileIndex, percentCompleted }) => {
  return {
    type: "SET_FILE_UPLOAD_PROGRESS",
    payload: { fileIndex, percentCompleted },
  };
};

export const setFileCanceller = ({ fileIndex, canceller }) => {
  return {
    type: "SET_FILE_CANCELLER",
    payload: { fileIndex, canceller },
  };
};

export const resetSlideUploadState = () => {
  return {
    type: "RESET_SLIDE_UPLOAD_STATE",
  };
};

export const removeUploadingFile = (fileIndex) => {
  return {
    type: "REMOVE_UPLOADING_FILE",
    payload: { fileIndex },
  };
};

export const clearUploadingList = () => {
  return {
    type: "CLEAR_UPLOADING_LIST",
  };
};

export const breadcrumbAction = (payload) => {
  return {
    type: "BREADCRUMB_ICONS_STATUS",
    payload,
  };
};

export const setSlideBoxDirDetailsUpdate = (payload) => {
  return {
    type: "SLIDEBOX_DIR_DETAILS_UPDATE",
    payload,
  };
};

export const setSlideBoxMovePopupAction = (payload) => {
  return {
    type: "SET_SLIDEBOX_MOVE_POPUP_STATE",
    payload,
  };
};

export const setSlideBoxAssignUserToCase = (payload, dirId) => {
  return {
    type: "SET_SLIDEBOX_CASE_ASSIGN",
    payload,
    dirId,
  };
};

export const removeSlideBoxAssignUserToCase = (payload) => {
  return {
    type: "REMOVE_SLIDEBOX_CASE_ASSIGN",
    payload,
  };
};

export const updateSlideBoxSlideDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxDirDescriptionUpdateApi(data);
      if (response.data.success) {
        dispatch(setSlideBoxDirDetailsUpdate(data));
        dispatch(updateStainType(data));
      }
    } catch (error) {}
  };
};

export const updateSlideboxLabels = (data) => {
  return async (dispatch) => {
    try {
      const response = await slideBoxLabelsUpdateApi(data);
      if (response.data.success) {
        dispatch(updateLabels(data));
      }
    } catch (error) {}
  };
};

export const setSlideBoxMoveDirAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxMoveDirApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxMoveDir(dataParams));
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const setNewDirCollaboratorsAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxCollaboratorsApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxCollaborators(dataParams.collaborateUsers));
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const setNewAssignUserToCaseAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxAssignUserToCaseApi(dataParams);
      if (response.data.success) {
        dispatch(
          setSlideBoxAssignUserToCase(dataParams.assignUsers, dataParams.dirId)
        );
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const removeAssignUserToCaseAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxRemoveAssignUserToCaseApi(dataParams);
      if (response.data.success) {
        dispatch(removeSlideBoxAssignUserToCase(dataParams));
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const removeDirCollaboratorsAction = (dataParams, callbackFn) => {
  return async (dispatch) => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxRemoveCollaboratorsApi(dataParams);
      if (response.data.success) {
        dispatch(setSlideBoxRemoveCollaborators(dataParams.collaboratorId));
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };
};

export const setSlideBoxRightClickSelectedAction = (payload) => {
  return {
    type: "SET_SLIDEBOX_RIGHT_CLICK_SLECTED",
    payload,
  };
};

export const setSelectedSlideData = (payload) => {
  return {
    type: "SET_SLIDE_DATA_FROM_LIST",
    payload,
  };
};

const setCaseReviewStatus = (payload) => {
  return {
    type: "SET_CASE_REVIEW_STATUS",
    payload,
  };
};

export const getCaseStatusAction = () => {
  return async (dispatch) => {
    try {
      const response = await getCaseStatusApi();
      if (response.status === 200) {
        // dispatch(setCaseReviewStatus(response.data));
      }
    } catch (error) {
      dispatch(getCaseStatusApi([]));
    }
  };
};

export const updateCaseStatusAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await updateCaseStatusApi(data);
      if (response.status === 200) {
        dispatch(setCaseReviewStatus(response.data));
        dispatch(
          setSelectedListActivity({
            dirId: response.data.dirId,
            dirType: "case",
          })
        );
      }
    } catch (error) {
      dispatch(updateCaseStatusApi([]));
    }
  };
};

const setStainType = (data) => {
  return {
    type: "SET_STAIN_TYPE",
    payload: data,
  };
};

export const getStainTypeAction = () => {
  return async (dispatch) => {
    try {
      const response = await getStainTypeApi();
      if (response.status === 200) {
        dispatch(setStainType(response.data.data));
      }
    } catch (error) {
      // console.log(error);
    }
  };
};

export const getFileDownloadUrlAction = (dataParams, callbackFn) => {
  return async () => {
    const { onSuccess, onError } = callbackFn;
    try {
      const response = await slideBoxFileDownloadApi(dataParams);
      if (response.data.success) {
        onSuccess(response.data.data);
      }
    } catch (error) {
      onError();
    }
  };
};

export const deselectListDirAction = () => {
  return {
    type: "UNSET_SELECTED_LIST_DATA",
  };
};

export const getAllSildesAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await getSlidesInfoApi(data);
      if (response.data.success) {
        dispatch(getSlides(response.data.slidesInfo));
      }
    } catch (error) {}
  };
};