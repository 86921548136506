import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FolderDirInactiveIcon from "../../../../assets/icons/folder-close-new.svg";
import CaseDirInActiveIcon from "../../../../assets/icons/case-close.svg";
import DocumentSingleIcon from "../../../../assets/icons/doc-single.png";
import defaultFileIcon from "../../../../assets/icons/default-file.svg";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ToolTip from "react-tooltip";
import { connect } from "react-redux";
import { breadcrumbAction } from "../../actions/slidebox-action";
import EmptyMessage from "./EmptyMessage";
import Loader from "./Loader";
import { bytesToSize } from "../../../../utils/utils";
import { ContextMenu, Notify } from "../../../../components";
import ImageTag from "../SlideItems/ImageTag";
import DefaultAvatar from "../../../../assets/icons/default-avatar.svg";
import UserProfilePopup from "../../../profile/components/UserProfilePopup";
import Can from "../../../../hoc/FullControllHoc/can";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    width: "100%",
    overflow: "scroll",
    tableLayout: "fixed",
  },
  arrowIcon: {
    color: "#6a6e72",
    fontSize: 18,
    marginLeft: 5,
  },
  headerBg: {
    backgroundColor: "#ECF0F4",
  },
});

const emptyMessage = {
  root: "Use the New Button to create a case",
  folder: "Create a case or upload images",
  case: "Upload images",
  undefined: "",
  emptySearch: "No results found",
};

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { sortedListData: sortedItems, requestSort, sortConfig };
};

const SlideTable = ({
  listData,
  history,
  breadcrumbAction,
  showLoader,
  toggleDirHandler,
  setSelectedDir,
  selectedList,
  selectedListType,
  selectedFolderType,
  isSearch,
  showSharedPopup,
  deleteDirItemPopup,
  renameSlideItem,
  handleToggleMovePopup,
  setShowDetails,
  selectedListItem,
  showGroupsSelectPopup,
  selectedListSlideData,
  downloadSlide,
  isModal,
  selectedFolderName,
  description,
  when,
  setContinueButtonDisabled,
  setSelectedSlideDataToAnalyze,
  importSlide,
  getSearchDirData,
  setLabel,
  radioButtonChecked,
  setRadioButtonChecked,
}) => {
  const classes = useStyles();
  const { sortedListData, requestSort } = useSortableData(listData);
  const [isTableRowSelected, setIsTableRowSelected] = useState({});
  const [nameSorting, setNameSorting] = useState(true);
  const [sizeSorting, setSizeSorting] = useState(true);
  const [lastModifiedSorting, setLastModifiedSorting] = useState(true);
  const [annotationSorting, setAnnotationSorting] = useState(true);
  const [blink, setBlink] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [userDetails, setUserDetails] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [placement, setPlacement] = React.useState();
  const [selectedDataToAnalyze, setSelectedDataToAnalyze] = useState();
  const [selectedDirDetails, setSelectedDirDetails] = useState({});

  const handleModalOpen = (
    index,
    firstName,
    lastName,
    userEmail,
    roleName,
    profile,
    userImageSrc,
    newPlacement
  ) => (event) => {
    setAnchorEl(event.currentTarget);
    setUserDetails({
      firstName,
      lastName,
      userEmail,
      roleName,
      profile,
      userImageSrc,
    });
    // setModalOpen((prev) => placement !== newPlacement || !prev);
    setModalOpen(!modalOpen);
    setPlacement(newPlacement);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleSearch = (e, search) => {
    e.stopPropagation();
    console.log(search);
    getSearchDirData({ search: search, label: search });
    setLabel(search);
  };

  const handleShowInViewer = (slideItem = selectedListSlideData) => {
    if (!isModal) {
      history.push({
        pathname: `/viewer`,
        search: `?image=${slideItem.keyFolder}&thumb=${slideItem.thumbDataToken}`,
      });
    }
  };

  const onSelectedRow = (e, listDataItem, index) => {
    const { dirDataId, parentDir, caseOrFolder } = listDataItem;
    if (when === "analysis") {
      setSelectedSlideDataToAnalyze(listDataItem);
      setContinueButtonDisabled(true);
    }
    setIsTableRowSelected({ [index]: true });
    setRadioButtonChecked(false);
    setSelectedDir({
      dirId: dirDataId,
      parentDir,
      dirType: caseOrFolder,
    });
    setSelectedDataToAnalyze(listDataItem);

    breadcrumbAction({ showIcon: true, [index]: listDataItem });
  };

  const onDirDoubleClick = (e, listDataItem) => {
    const {
      dirDataId,
      parentDir,
      caseOrFolder,
      description,
      dirDataName,
    } = listDataItem;
    if (caseOrFolder === "slide" || caseOrFolder === "document") {
      if (!isModal) {
        toggleDirHandler({
          dirId: dirDataId,
          parentDir,
          dirType: caseOrFolder,
        });
      }
    } else {
      toggleDirHandler({
        dirId: dirDataId,
        parentDir,
        dirType: caseOrFolder,
      });
    }
  };

  const mapSlideStatus = (status) => {
    if (status === "success") {
      return "Ready";
    }
    if (status === "pending") {
      return "Progress";
    }
    if (status === "fail") {
      return "Retry";
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleClickAnalyzeSlide = () => {
    history.push({
      pathname: `/analysis`,
      state: { fromSlidebox: true, selectedDataToAnalyze, selectedDirDetails },
    });
  };

  const handleSelectSlide = (e, row, index) => {
    const { dirDataId, parentDir, caseOrFolder } = row;
    if (when === "analysis") {
      if (row.status === "pending") {
        Notify({ value: "Slide conversion is in progress" });
        return;
      }
      setSelectedSlideDataToAnalyze(row);
      setContinueButtonDisabled(false);
    }

    setIsTableRowSelected({ [index]: true });
    setSelectedDir({
      dirId: dirDataId,
      parentDir,
      dirType: caseOrFolder,
    });
    if (!selectedDataToAnalyze) return;
    setRadioButtonChecked({ [index]: true });
    setSelectedDataToAnalyze(row);
  };

  React.useEffect(() => {
    setSelectedDirDetails({ dirDataName: selectedFolderName, description });
  }, [selectedFolderName, description]);

  React.useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, [setScreenWidth, window.innerWidth]);

  let slideFileNameSize =
    screenWidth > 1600 && screenWidth < 1900
      ? 18
      : screenWidth < 1400
      ? 11
      : screenWidth > 1900
      ? 25
      : 14;

  return showLoader ? (
    <div style={{ marginTop: "300px" }}>
      <Loader />
    </div>
  ) : sortedListData.length ? (
    <React.Fragment>
      <UserProfilePopup
        openModal={modalOpen}
        onClose={handleModalClose}
        userDetails={userDetails}
        isDashboard={true}
        isHeader={false}
        placement={placement}
        anchorEl={anchorEl}
      />
      <Can
        task={"viewing-folder-or-case"}
        taskContainer="slidebox"
        edit={() => (
          <TableContainer
            className="table-scroll"
            elevation={0}
            component={Paper}
          >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className="table-padding">
                  <TableCell
                    width={"25%"}
                    style={{ backgroundColor: "#ECF0F4", paddingLeft: "30px" }}
                    align="left"
                  >
                    Name
                    <span
                      onClick={() => {
                        setBlink(true);
                        setNameSorting(!nameSorting);
                        requestSort("dirDataName");
                        setTimeout(() => setBlink(false), 500);
                      }}
                    >
                      <i
                        className={`fas fa-arrow-up ${
                          nameSorting ? "" : " arrow-down"
                        } arrow-icon`}
                      />
                    </span>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: "#ECF0F4" }}
                  >
                    Size
                    <span
                      onClick={() => {
                        setBlink(true);
                        requestSort("dirDataSize");
                        setSizeSorting(!sizeSorting);
                        setTimeout(() => setBlink(false), 500);
                      }}
                    >
                      <i
                        className={`fas fa-arrow-up ${
                          sizeSorting ? "" : " arrow-down"
                        } arrow-icon`}
                      />
                    </span>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ backgroundColor: "#ECF0F4" }}
                    width={"15%"}
                  >
                    Last Modified
                    <span
                      onClick={() => {
                        setBlink(true);
                        setLastModifiedSorting(!lastModifiedSorting);
                        requestSort("lastModified");
                        setTimeout(() => setBlink(false), 500);
                      }}
                    >
                      <i
                        className={`fas fa-arrow-up ${
                          lastModifiedSorting ? "" : " arrow-down"
                        } arrow-icon`}
                      />
                    </span>
                  </TableCell>
                  <TableCell
                    align="center"
                    width={"25%"}
                    style={{ backgroundColor: "#ECF0F4" }}
                  >
                    Labels
                  </TableCell>

                  {selectedFolderType !== "case" && (
                    <TableCell
                      align="center"
                      style={{ backgroundColor: "#ECF0F4" }}
                    >
                      Assigned
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#ECF0F4" }}
                  >
                    Status
                  </TableCell>
                  {selectedFolderType !== "root" && (
                    <TableCell
                      align="center"
                      style={{ backgroundColor: "#ECF0F4" }}
                    >
                      {!importSlide ? "Stain Type" : "Analyze"}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody id="slideTableBody">
                {sortedListData.map((row, index) => {
                  const tableRowSelected =
                    selectedList === row.dirDataId &&
                    selectedListType === row.caseOrFolder;
                  const className = tableRowSelected
                    ? "selected-table-cell-color"
                    : "";
                  return isModal === true &&
                    row.caseOrFolder === "document" ? null : (
                    <TableRow
                      id="slideTableBodyRow"
                      key={row.listDataId}
                      onDoubleClick={(e) => {
                        row.caseOrFolder === "slide" && when !== "analysis"
                          ? handleShowInViewer(row)
                          : row.caseOrFolder === "document"
                          ? ""
                          : onDirDoubleClick(e, row);
                      }}
                      onClick={(e) =>
                        row.caseOrFolder !== "slide"
                          ? onSelectedRow(e, row, index)
                          : handleSelectSlide(e, row, index)
                      }
                      className={`table-padding ${
                        selectedList === row.dirDataId &&
                        selectedListType === row.caseOrFolder
                          ? "selected-table-row-bg"
                          : ""
                      }`}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={className}
                      >
                        <img
                          src={
                            row.caseOrFolder === "folder"
                              ? FolderDirInactiveIcon
                              : row.caseOrFolder === "case"
                              ? CaseDirInActiveIcon
                              : row.dzi_thumbnail
                              ? row.dzi_thumbnail
                              : row.caseOrFolder === "document"
                              ? DocumentSingleIcon
                              : defaultFileIcon
                          }
                          className={
                            row.caseOrFolder ? "slide-dir" : "slide-image"
                          }
                        />
                        <span
                          data-for="slidebox-tooltip"
                          data-tip={row.dirDataName}
                        >
                          {row.dirDataName.length > 16
                            ? row.dirDataName.substring(0, 15) + "..."
                            : row.dirDataName}
                        </span>
                      </TableCell>
                      <TableCell align="left" className={className}>
                        {row.dirDataSize ? bytesToSize(row.dirDataSize) : "—"}
                      </TableCell>
                      <TableCell align="left" className={className}>
                        <span
                          data-for="slidebox-tooltip"
                          data-tip={moment(row.lastModified).format("lll")}
                        >
                          {moment(row.lastModified).format("ll")}
                        </span>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={className}
                        width={"25%"}
                      >
                        {row.labels &&
                          row.labels
                            .split(",")
                            .slice(0, 4)
                            .map((label, index) => (
                              <div
                                className="lable_tags"
                                onClick={(e) => handleSearch(e, label)}
                                key={index}
                              >
                                <span>{index < 3 ? label : "..."}</span>
                              </div>
                            ))}
                      </TableCell>

                      {selectedFolderType !== "case" && (
                        <TableCell align="center" className={className}>
                          {row.caseOrFolder === "case" ? (
                            <React.Fragment>
                              {row.assignTo && row.assignTo.length > 0 ? (
                                <ImageTag
                                  onMouseOver={handleModalOpen(
                                    index,
                                    row.assignTo[0].first_name,
                                    row.assignTo[0].last_name,
                                    row.assignTo[0].user_email,
                                    row.assignTo[0].role_name,
                                    "",
                                    row.assignTo[0].user_image_src,
                                    "left-end"
                                  )}
                                  onMouseOut={handleModalClose}
                                  src={
                                    row.assignTo &&
                                    row.assignTo.length > 0 &&
                                    row.assignTo[0].user_image_src
                                      ? row.assignTo[0].user_image_src
                                      : DefaultAvatar
                                  }
                                  className="user-profile-image"
                                />
                              ) : null}
                            </React.Fragment>
                          ) : null}
                        </TableCell>
                      )}
                      <TableCell align="center" className={className}>
                        {row.caseOrFolder === "slide"
                          ? mapSlideStatus(row.status)
                          : row.caseOrFolder === "case" &&
                            row.review_status !== ""
                          ? capitalizeFirstLetter(row.review_status)
                          : ""}
                      </TableCell>
                      {when === "analysis" && selectedFolderType !== "root" && (
                        <TableCell align="center" className={className}>
                          {row.caseOrFolder === "slide" ? (
                            <label class="radio-button-container">
                              <input
                                className="select-slide-radio"
                                type="radio"
                                name="select-slide"
                                value={row.dirDataId}
                                onChange={(e) => {
                                  handleSelectSlide(row);
                                }}
                              />
                              <span
                                className={`checkmark-radio-button ${
                                  radioButtonChecked[index] === true
                                    ? "checked"
                                    : "unchecked"
                                }`}
                              ></span>
                            </label>
                          ) : null}
                        </TableCell>
                      )}
                      {when !== "analysis" && selectedFolderType !== "root" && (
                        <TableCell align="center" className={className}>
                          {row.stainType || "—"}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <ToolTip
              id="slidebox-tooltip"
              place="top"
              type="dark"
              effect="solid"
            />
            {!importSlide && (
              <ContextMenu
                domElement={"table-padding"}
                showSharedPopup={showSharedPopup}
                deleteDirItemPopup={deleteDirItemPopup}
                renameSlideItem={renameSlideItem}
                handleToggleMovePopup={handleToggleMovePopup}
                setShowDetails={setShowDetails}
                selectedListItem={selectedListItem}
                showGroupsSelectPopup={showGroupsSelectPopup}
                downloadSlide={downloadSlide}
                openInViewer={() => handleShowInViewer()}
                rightClickFrom={"list-table"}
                isModal={isModal}
                analyzeSlide={() => handleClickAnalyzeSlide()}
                importSlide={importSlide}
              />
            )}
          </TableContainer>
        )}
      />
    </React.Fragment>
  ) : (
    <EmptyMessage
      mainMessage={emptyMessage[isSearch ? "emptySearch" : selectedFolderType]}
      selectedFolderType={selectedFolderType}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  selectedFolderData: state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
  ...state.SlideBox.selectedFolderData,
});
const mapDispatchToProps = (dispatch) => ({
  breadcrumbAction: (payload) => dispatch(breadcrumbAction(payload)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SlideTable)
);
