import React, { useState, useEffect, useContext } from 'react';
import RectangularAnno from '../icons/anno-ractangle-new.svg';
import CircleAnno from '../icons/anno-circle-new.svg';
import PolygonAnno from '../icons/brush-icon-new.svg';
import ArrowAnno from '../icons/arrow-anno-icon-new.svg';
import HideAnno from '../icons/hide-anno-icon-new.svg';
import ShowAnno from '../icons/show-anno-icon-new.svg';
import EditAnno from '../icons/edit-anno-new.svg';
import ColorChangeIcon from '../icons/paint-plate-new.svg';
import { Slider } from 'material-ui-slider';
import ToolTip from 'react-tooltip';
import { Notify, Prompt } from '../../../components';
import { SocketContext } from '../../../utils/serviceContext';

import openseadragonAnnotations from '../utils/openseadragon-annotations';
import openSeadragonScreenshot from '../utils/openseadragon-screenshot-helper';
import AnnotationEditPopup from './annotation-edit-popup';
import AnnotationDetailsPopup from './annotation-details-popup';
import AnnotationColorPicker from './annotation-color-picker';

import {
  setAnnotations,
  getAnnotations,
  updateAnnotations,
  toggleAnnotations,
  setSocketDataUpdateFlase,
  getAnnotationDescription,
} from '../utils/viewer-utils';
import { connect } from 'react-redux';
import { getRandomColor } from '../../../utils/utils';
import ScreenshotIcon from '../../../assets/icons/screenCapture.svg';

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const ViewerToolbarAnnotation = ({
  viewer,
  overlay,
  zoomValueBar,
  slideId,
  dispatch,
  allAnnotationData,
  toogleAnnotation,
  when,
  featureId,
  userDetails,
  postIsMine,
  fromCaseStream,
  slideOwnerOrCollaborator,
  postIsValidate,
  isSoketDataUpdate,
  isAnnotationUpload,
  deleteAnnotionPopup,
  selectedAnnotationId,
  annotationPopupShow,
  resetAnnotationUpload,
  splitViewer,
  viewer1,
  overlayInstance,
  overlayInstanceSplitViewer,
  selectedViewer,
  selectedAalysisResult,
  heatMapOverlay,
  heatMapData,
  selectedAalysisId,
  selectedAalysisVisible,
  isAnalysis,
  onSnapshotClose,
  openSnapshot,
  isSplitViewer,
  activeSnapShot,
}) => {
  const socket = useContext(SocketContext);
  const [forceReloadAnnotationData, setForceReloadAnnotationData] = useState(
    false
  );
  const [pageMountedViewer1, setPageMountedViewer1] = useState(false);
  const [pageMountedViewer2, setPageMountedViewer2] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [addAnnotations, setAddAnnotations] = useState(false);
  const [editAnnotations, setEditAnnotations] = useState(false);
  const [currentAnnotationType, setCurrentAnnotationType] = useState(null);
  const [currentAnnotations, setCurrentAnnotations] = useState(null);
  const [currentOrgAnnotations, setCurrentOrgAnnotations] = useState(null);
  const [currentAnnotationName, setCurrentAnnotationName] = useState(null);
  const [BrushWidth, setBrushWidth] = useState(60);
  const [showAnnotationDataPopup, setShowAnnotationDataPopup] = useState(false);
  const [showAnnotationEditPopup, setShowAnnotationEditPopup] = useState(false);
  const [globleColorChangeActive, setGlobleColorChangeActive] = useState(false);
  const [showBrushSizeTool, setshowBrushSizeTool] = useState(false);
  const [globleAnnotationColor, setGlobleAnnotationColor] = useState('#FF0000');
  const [otherUserAnnotations, setOtherUserAnnotations] = useState(null);
  const [currentUserAnnotations, setCurrentUserAnnotations] = useState(null);
  const [editAnnotationData, setEditAnnotationData] = useState(null);
  useEffect(() => {
    socket.getAnnotationMessageSubject().subscribe((item) => {
      if (item && overlayInstance && item.fromSrc === 'collapsible-sidebar') {
        // get active object
        let activeObj = overlayInstance
          .fabricCanvas()
          .getObjects()
          .find((i) => i.pk === item.pk);
        handleEditAnnotation(null, activeObj);
      }
      if (
        item &&
        overlayInstanceSplitViewer &&
        item.fromSrc === 'collapsible-sidebar'
      ) {
        // get active object
        let activeObj = overlayInstanceSplitViewer
          .fabricCanvas()
          .getObjects()
          .find((i) => i.pk === item.pk);
        handleEditAnnotation(null, activeObj);
      }
    });
  }, [overlayInstance, overlayInstanceSplitViewer, currentUserAnnotations]);

  // useEffect(() => {
  //   if (typeof viewer1 !== "undefined" && overlayInstance) {
  //     setPageMountedViewer1(true);
  //     getAnnotations({ dispatch, slideId, when, featureId });
  //     getAnnotationDescription({ slideId, dispatch });
  //     openseadragonAnnotations.initialize(overlayInstance, viewer1);
  //     if (when === "quorum" && !postIsMine) {
  //       const globleColorInQuorum = getRandomColor();
  //       setGlobleAnnotationColor(globleColorInQuorum);
  //     }
  //   }
  //   if (typeof splitViewer !== "undefined" && overlayInstanceSplitViewer) {
  //     setPageMountedViewer2(true);
  //     getAnnotations({ dispatch, slideId, when, featureId });
  //     getAnnotationDescription({ slideId, dispatch });
  //     openseadragonAnnotations.initialize(
  //       overlayInstanceSplitViewer,
  //       splitViewer
  //     );
  //     if (when === "quorum" && !postIsMine) {
  //       const globleColorInQuorum = getRandomColor();
  //       setGlobleAnnotationColor(globleColorInQuorum);
  //     }
  //   }
  // }, [overlayInstance, overlayInstanceSplitViewer]);

  useEffect(() => {
    if (
      typeof viewer1 !== 'undefined' &&
      overlayInstance &&
      // heatMapOverlay &&
      selectedViewer === 0
    ) {
      setPageMountedViewer1(true);
      getAnnotations({ dispatch, slideId, when, featureId });
      getAnnotationDescription({ slideId, dispatch });
      openseadragonAnnotations.initialize(overlayInstance, viewer1, false);
      openseadragonAnnotations.initialize(heatMapOverlay, viewer1, true);
      if (when === 'quorum' && !postIsMine) {
        const globleColorInQuorum = getRandomColor();
        setGlobleAnnotationColor(globleColorInQuorum);
      }
    }
    if (
      typeof splitViewer !== 'undefined' &&
      overlayInstanceSplitViewer &&
      selectedViewer === 1
    ) {
      setPageMountedViewer2(true);
      getAnnotations({ dispatch, slideId, when, featureId });
      getAnnotationDescription({ slideId, dispatch });
      openseadragonAnnotations.initialize(
        overlayInstanceSplitViewer,
        splitViewer
      );
      if (when === 'quorum' && !postIsMine) {
        const globleColorInQuorum = getRandomColor();
        setGlobleAnnotationColor(globleColorInQuorum);
      }
    }
  }, [
    overlayInstance,
    heatMapOverlay,
    overlayInstanceSplitViewer,
    selectedViewer,
  ]);

  // useEffect(() => {
  //   if (
  //     (typeof viewer1 !== "undefined" || typeof splitViewer !== "undefined") &&
  //     (overlayInstanceSplitViewer || overlayInstance) &&
  //     (addAnnotations ||
  //       pageMountedViewer1 ||
  //       pageMountedViewer2 ||
  //       isSoketDataUpdate ||
  //       isAnnotationUpload)
  //   ) {
  //     setUserAnnoatationData(allAnnotationData);
  //     openseadragonAnnotations.appendAllAnnotations(
  //       pageMountedViewer1 || pageMountedViewer2
  //         ? allAnnotationData
  //         : allAnnotationData.length > 0
  //         ? [allAnnotationData[allAnnotationData.length - 1]]
  //         : [],
  //       when,
  //       addAnnotations
  //     );
  //     setPageMountedViewer1(false);
  //     setPageMountedViewer2(false);
  //     setAddAnnotations(false);
  //     setSocketDataUpdateFlase({ dispatch });
  //     if (isAnnotationUpload) {
  //       resetAnnotationUpload();
  //     }
  //   } else if (
  //     (typeof viewer1 || typeof splitViewer !== "undefined") &&
  //     overlayInstance &&
  //     overlayInstanceSplitViewer &&
  //     forceReloadAnnotationData
  //   ) {
  //     setUserAnnoatationData(allAnnotationData);
  //     openseadragonAnnotations.refresAnnotationData(allAnnotationData);
  //     setForceReloadAnnotationData(false);
  //   }
  // }, [allAnnotationData]);

  useEffect(() => {
    if (
      typeof viewer1 !== 'undefined' &&
      overlayInstance &&
      (addAnnotations ||
        pageMountedViewer1 ||
        isSoketDataUpdate ||
        isAnnotationUpload)
    ) {
      setUserAnnoatationData(allAnnotationData);
      openseadragonAnnotations.appendAllAnnotations(
        pageMountedViewer1
          ? allAnnotationData
          : allAnnotationData.length > 0
            ? [allAnnotationData[allAnnotationData.length - 1]]
            : [],
        when,
        addAnnotations
      );
      setPageMountedViewer1(false);
      setAddAnnotations(false);
      setSocketDataUpdateFlase({ dispatch });
      if (isAnnotationUpload) {
        resetAnnotationUpload();
      }
      if (isAnalysis) {
        toggleAnnotation();
      }
    } else if (
      typeof viewer1 !== 'undefined' &&
      overlayInstance &&
      forceReloadAnnotationData
    ) {
      setUserAnnoatationData(allAnnotationData);
      openseadragonAnnotations.refresAnnotationData(allAnnotationData);
      setForceReloadAnnotationData(false);
    }

    if (
      typeof splitViewer !== 'undefined' &&
      overlayInstanceSplitViewer &&
      (addAnnotations ||
        pageMountedViewer2 ||
        isSoketDataUpdate ||
        isAnnotationUpload)
    ) {
      setUserAnnoatationData(allAnnotationData);
      openseadragonAnnotations.appendAllAnnotations(
        pageMountedViewer2
          ? allAnnotationData
          : allAnnotationData.length > 0
            ? [allAnnotationData[allAnnotationData.length - 1]]
            : [],
        when,
        addAnnotations
      );
      setPageMountedViewer2(false);
      setAddAnnotations(false);
      setSocketDataUpdateFlase({ dispatch });
      if (isAnnotationUpload) {
        resetAnnotationUpload();
      }
    } else if (
      typeof splitViewer !== 'undefined' &&
      overlayInstanceSplitViewer &&
      forceReloadAnnotationData
    ) {
      setUserAnnoatationData(allAnnotationData);
      openseadragonAnnotations.refresAnnotationData(allAnnotationData);
      setForceReloadAnnotationData(false);
    }
  }, [allAnnotationData, viewer1, splitViewer]);

  useEffect(() => {
    if (
      toogleAnnotation &&
      !editAnnotations &&
      (typeof viewer1 !== 'undefined' || typeof splitViewer !== 'undefined') &&
      (overlayInstance || overlayInstanceSplitViewer)
    ) {
      const { annotationIds, onOrOff } = toogleAnnotation;
      if (annotationIds) {
        openseadragonAnnotations.turnAnnotationsOnOffByUser(
          onOrOff,
          annotationIds
        );
      }
    }
  }, [toogleAnnotation]);

  useEffect(() => {
    if (
      !selectedAnnotationId &&
      !annotationPopupShow &&
      (overlayInstance || overlayInstanceSplitViewer)
    ) {
      hideDetailsPopup();
    }
  }, [selectedAnnotationId, annotationPopupShow]);

  useEffect(() => {
    if (selectedAalysisResult) {
      if (overlayInstance) {
        appendAnalysisResult(
          selectedAalysisResult,
          selectedAalysisId,
          selectedAalysisVisible
        );
      }
    }
  }, [selectedAalysisResult, selectedAalysisVisible]);

  useEffect(() => {
    if (heatMapOverlay && heatMapData) {
      openseadragonAnnotations.appendHeatMapData(
        heatMapData,
        selectedAalysisVisible
      );
    }
  }, [heatMapOverlay, heatMapData, selectedAalysisVisible]);

  const sentChangeAnnotationEvent = (item) => {
    socket
      .getAnnotationMessageSubject()
      .next({ ...item, fromSrc: 'toolbar-annotation' });
  };

  const switchCursor = (isDrawing = false) => {
    if (isDrawing) {
      overlay.fabricCanvas().moveCursor = 'crosshair';
      overlay.fabricCanvas().hoverCursor = 'crosshair';
    } else {
      overlay.fabricCanvas().moveCursor = 'move';
      overlay.fabricCanvas().hoverCursor = 'move';
    }
  };

  const handleAnnotation = (e, annotationType) => {
    e.preventDefault();
    if (currentAnnotationType === 'brush') {
      overlay.fabricCanvas().isDrawingMode = false;
      toggleshowBrushSizeTool();
    }
    if (currentAnnotationType !== annotationType) {
      setGlobleColorChangeActive(false);
      if (!postIsMine) {
        openseadragonAnnotations.changeAllAnnotationStrokeColor(
          globleAnnotationColor
        );
      }
      openseadragonAnnotations.setMouseMode('addAnnotation');
      switchCursor(true);
      openseadragonAnnotations.annotationType = annotationType;
      var strokeWidth = 80 / zoomValueBar;
      openseadragonAnnotations.strokeWidthForDrawing = strokeWidth;
      if (annotationType == 'brush') {
        resetBrushSize();
        toggleshowBrushSizeTool();
        if (!overlay.fabricCanvas().isDrawingMode) {
          overlay.fabricCanvas().isDrawingMode = true;
          overlay.fabricCanvas().freeDrawingBrush.width = BrushWidth;
          overlay.fabricCanvas().freeDrawingBrush.color = convertHexToRGBA(
            globleAnnotationColor,
            50
          );
        }
      }
      setCurrentAnnotationType(annotationType);
    } else {
      setCurrentAnnotationType(null);
      switchCursor(false);
      openseadragonAnnotations.setMouseMode('OSD');
      if (annotationType == 'brush') {
        overlay.fabricCanvas().isDrawingMode = false;
      }
      if (annotationType == 'brush') {
        toggleshowBrushSizeTool();
      }
    }
  };

  const handleEditAnnotation = (e, activeAnnotation) => {
    if (e) {
      e.preventDefault();
    }
    setShowAnnotationEditPopup(false);
    setCurrentAnnotationType(null);
    if (!editAnnotations) {
      hideOtherUserAnnotations();
      overlay
        .fabricCanvas()
        .getObjects()
        .some((obj) => {
          obj.set({
            transparentCorners: false,
            cornerColor: 'blue',
            cornerStrokeColor: 'red',
            borderColor: 'red',
            cornerSize: 12,
            padding: 10,
            cornerStyle: 'circle',
            borderDashArray: [3, 3],
          });
        });
      openseadragonAnnotations.setMouseMode('editAnnotation');
      // set active annotation selected from sidebar
      if (activeAnnotation) {
        overlay.fabricCanvas().setActiveObject(activeAnnotation);
        onSelectAnnotationToEdit(activeAnnotation);
      }
    } else {
      if (when !== 'quorum' && showAnnotations) {
        openseadragonAnnotations.turnAnnotationsOnOff('on');
      }
      openseadragonAnnotations.setMouseMode('OSD');
    }
    setEditAnnotations(!editAnnotations);
  };

  const toggleAnnotation = () => {
    // e.preventDefault();
    if (showAnnotations) {
      openseadragonAnnotations.setMouseMode('OSD');
      openseadragonAnnotations.turnAnnotationsOnOff('off');
    } else {
      openseadragonAnnotations.turnAnnotationsOnOff('on');
    }
    setShowAnnotations(!showAnnotations);
  };

  const onClose = () => {
    if (currentAnnotationType) {
      openseadragonAnnotations.deleteCurrentAnnotation();
    } else {
      handleSaveClick();
    }
    setShowAnnotationDataPopup(false);
    setShowAnnotationEditPopup(false);
    setCurrentAnnotations(null);
    setCurrentAnnotationType(null);
  };

  const setAnnoatationName = (name) => {
    setCurrentAnnotationName(name);
  };

  const handleSaveClick = () => {
    const allAnnotationNames = allAnnotationData.map((item) => item.text);
    if (currentAnnotationType) {
      const data = {
        ...currentAnnotations,
        text: currentAnnotationName,
      };
      if (!allAnnotationNames.includes(currentAnnotationName.toLowerCase())) {
        setAddAnnotations(true);
        setAnnotations({
          annotationData: data,
          slideId,
          featureId,
          dispatch,
          when,
          callback: deleteCurrentDraw,
        });
        setForceReloadAnnotationData(true);
      } else {
        Notify({ value: 'Annotation already exists' });
        openseadragonAnnotations.deleteCurrentAnnotation();
      }
      setCurrentAnnotationType(null);
    } else {
      const canvasObject = overlay.fabricCanvas().getActiveObject();
      canvasObject.set({ text: currentAnnotationName });
      const data = {
        ...currentAnnotations,
        text: currentAnnotationName,
      };
      updateAnnotations({ annotationData: data, slideId, when, dispatch });
    }
    setCurrentAnnotationName(null);
    setShowAnnotationDataPopup(false);
  };

  const deleteCurrentDraw = () => {
    openseadragonAnnotations.deleteCurrentAnnotation();
  };

  const editBtnOnClick = () => {
    setEditAnnotationData(currentOrgAnnotations);
    setShowAnnotationEditPopup(false);
    setShowAnnotationDataPopup(true);
  };

  const deleteBtnOnClick = () => {
    const annotationId = currentAnnotations.pk;
    deleteAnnotionPopup({ status: true, annotationId });
  };

  const resetBrushSize = () => {
    setBrushSizeInAnnotation(60);
  };

  const onColorChange = (color) => {
    const canvasObject = overlay.fabricCanvas().getActiveObject();
    if (canvasObject.type == 'path') {
      var updatedColor = convertHexToRGBA(color['hex'], 50);
    } else {
      var updatedColor = color['hex'];
    }
    canvasObject.set({ stroke: updatedColor });
    const data = {
      ...currentAnnotations,
      stroke: updatedColor,
    };
    updateAnnotations({ annotationData: data, slideId, dispatch });
    overlay.fabricCanvas().renderAll();
  };

  const hideDetailsPopup = () => {
    overlay.fabricCanvas().remove(currentOrgAnnotations);
    overlay.fabricCanvas().renderAll();
    setShowAnnotationEditPopup(false);
  };

  const changeGlobleAnnotationColor = () => {
    setGlobleColorChangeActive(!globleColorChangeActive);
  };

  const onGlobleAnnoColorChange = (color) => {
    setGlobleAnnotationColor(color['hex']);
    openseadragonAnnotations.changeAllAnnotationStrokeColor(color['hex']);
  };

  const toggleshowBrushSizeTool = () => {
    setshowBrushSizeTool(!showBrushSizeTool);
  };

  const convertHexToRGBA = (hex, opacity) => {
    const tempHex = hex.replace('#', '');
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  };

  const onSelectAnnotationToEdit = (activeObjs) => {
    const convertedObj = openseadragonAnnotations.duplicateObject(activeObjs);
    setCurrentOrgAnnotations(activeObjs);
    setCurrentAnnotationName(convertedObj['text']);
    setCurrentAnnotations(convertedObj);
    setShowAnnotationEditPopup(true);
    sentChangeAnnotationEvent(convertedObj);
  };

  if (overlayInstance) {
    overlayInstance.fabricCanvas().observe('mouse:down', (o) => {
      if (
        openseadragonAnnotations.getMouseMode() === 'addAnnotation' &&
        openseadragonAnnotations.annotationType === 'brush'
      ) {
        toggleshowBrushSizeTool();
      }
    });

    overlayInstance.fabricCanvas().observe('mouse:up', (o) => {
      if (openseadragonAnnotations.getMouseMode() === 'addAnnotation') {
        switchCursor(false);
        setCurrentAnnotationName(null);
        if (openseadragonAnnotations.annotationType === 'brush') {
          overlayInstance.fabricCanvas().isDrawingMode = false;
        }
        const canvasObjects = overlayInstance.fabricCanvas().getObjects();
        if (canvasObjects.length !== 0) {
          const last = canvasObjects[canvasObjects.length - 1];
          const convertedObj = openseadragonAnnotations.duplicateObject(last);
          setCurrentAnnotations(convertedObj);
          if (when !== 'quorum') {
            setEditAnnotationData(last);
            setShowAnnotationDataPopup(true);
          } else {
            const data = {
              ...convertedObj,
              text: featureId ? 'quorum' : 'initial-annotation',
            };
            setAddAnnotations(true);
            setAnnotations({
              annotationData: data,
              slideId,
              featureId,
              dispatch,
              when,
              callback: deleteCurrentDraw,
            });
            setCurrentAnnotationType(null);
          }
        }
      }
      if (openseadragonAnnotations.getMouseMode() === 'editAnnotation') {
        const canvasObjects = overlayInstance.fabricCanvas().getActiveObject();
        if (canvasObjects) {
          if (!showAnnotationEditPopup) {
            onSelectAnnotationToEdit(canvasObjects);
          }
        }
      }
    });

    overlayInstance.fabricCanvas().observe('before:selection:cleared', (o) => {
      if (openseadragonAnnotations.getMouseMode() !== 'OSD') {
        openseadragonAnnotations.setMouseMode('OSD');
        const convertedObj = openseadragonAnnotations.duplicateObject(o.target);
        setEditAnnotations(false);
        setShowAnnotationEditPopup(false);
        setShowAnnotationDataPopup(false);
        updateAnnotations({
          annotationData: convertedObj,
          slideId,
          dispatch,
        });

        if (when !== 'quorum' && showAnnotations) {
          openseadragonAnnotations.turnAnnotationsOnOff('on');
        }
      }
    });
  }

  if (overlayInstanceSplitViewer) {
    overlayInstanceSplitViewer.fabricCanvas().observe('mouse:down', (o) => {
      if (
        openseadragonAnnotations.getMouseMode() === 'addAnnotation' &&
        openseadragonAnnotations.annotationType === 'brush'
      ) {
        toggleshowBrushSizeTool();
      }
    });

    overlayInstanceSplitViewer.fabricCanvas().observe('mouse:up', (o) => {
      if (openseadragonAnnotations.getMouseMode() === 'addAnnotation') {
        switchCursor(false);
        setCurrentAnnotationName(null);
        if (openseadragonAnnotations.annotationType === 'brush') {
          overlayInstanceSplitViewer.fabricCanvas().isDrawingMode = false;
        }
        const canvasObjects = overlayInstanceSplitViewer
          .fabricCanvas()
          .getObjects();
        if (canvasObjects.length !== 0) {
          const last = canvasObjects[canvasObjects.length - 1];
          const convertedObj = openseadragonAnnotations.duplicateObject(last);
          setCurrentAnnotations(convertedObj);
          if (when !== 'quorum') {
            setEditAnnotationData(last);
            setShowAnnotationDataPopup(true);
          } else {
            const data = {
              ...convertedObj,
              text: featureId ? 'quorum' : 'initial-annotation',
            };
            setAddAnnotations(true);
            setAnnotations({
              annotationData: data,
              slideId,
              featureId,
              dispatch,
              when,
              callback: deleteCurrentDraw,
            });
            setCurrentAnnotationType(null);


          }
        }
      }
      if (openseadragonAnnotations.getMouseMode() === 'editAnnotation') {
        const canvasObjects = overlayInstanceSplitViewer
          .fabricCanvas()
          .getActiveObject();
        if (canvasObjects) {
          if (!showAnnotationEditPopup) {
            onSelectAnnotationToEdit(canvasObjects);
          }
        }
      }
    });

    overlayInstanceSplitViewer
      .fabricCanvas()
      .observe('before:selection:cleared', (o) => {
        if (openseadragonAnnotations.getMouseMode() !== 'OSD') {
          openseadragonAnnotations.setMouseMode('OSD');
          const convertedObj = openseadragonAnnotations.duplicateObject(
            o.target
          );
          setEditAnnotations(false);
          setShowAnnotationEditPopup(false);
          setShowAnnotationDataPopup(false);
          updateAnnotations({
            annotationData: convertedObj,
            slideId,
            dispatch,
          });

          if (when !== 'quorum' && showAnnotations) {
            openseadragonAnnotations.turnAnnotationsOnOff('on');
          }
        }
      });
  }

  const setUserAnnoatationData = (allAnnotationData) => {
    const dataSet = allAnnotationData.reduce((r, a) => {
      r[a.user_id] = [...(r[a.user_id] || []), a];
      return r;
    }, {});

    const modifyAnnotationData = Object.keys(dataSet).map((key) => {
      return {
        user_id: parseInt(key),
        firstname: dataSet[key][0]['firstname'],
        surname: dataSet[key][0]['surname'],
        slide_id: dataSet[key][0]['slide_id'],
        annotationIds: dataSet[key].map((annoData) => annoData.pk),
        quorum_color: dataSet[key][0]['stroke'],
        is_public: dataSet[key][0]['is_public'],
        toggle: dataSet[key][0]['toggle'],
        isValidate: dataSet[key][0]['isValidate'],
      };
    });

    const otherUserAnnoataions = modifyAnnotationData
      .filter((userAnnotation) => {
        if (
          userAnnotation.user_id !== userDetails.userID &&
          userAnnotation.user_id !== 0
        ) {
          return true;
        }
      })
      .map((filterData) => filterData.annotationIds);

    const currentUserAnnoataions = modifyAnnotationData
      .filter((userAnnotation) => {
        if (
          userAnnotation.user_id === userDetails.userID ||
          userAnnotation.user_id === 0
        ) {
          return true;
        }
      })
      .map((filterData) => filterData.annotationIds)[0];

    setOtherUserAnnotations(otherUserAnnoataions.flat(1));
    setCurrentUserAnnotations(currentUserAnnoataions);
  };

  const hideOtherUserAnnotations = () => {
    openseadragonAnnotations.turnAnnotationsOnOffByUser(
      'on',
      currentUserAnnotations
    );
    openseadragonAnnotations.turnAnnotationsOnOffByUser(
      'off',
      otherUserAnnotations
    );
    const otherUserData = {
      annotationIds: otherUserAnnotations,
      onOrOff: 'off',
      dispatch: dispatch,
    };
    toggleAnnotations(otherUserData);

    const currentUserData = {
      annotationIds: currentUserAnnotations,
      onOrOff: 'on',
      dispatch: dispatch,
    };
    toggleAnnotations(currentUserData);
  };

  const setBrushSizeInAnnotation = (width) => {
    setBrushWidth(width);
    overlay.fabricCanvas().isDrawingMode = true;
    overlay.fabricCanvas().freeDrawingBrush.width = width;
    overlay.fabricCanvas().freeDrawingBrush.color = convertHexToRGBA(
      globleAnnotationColor,
      50
    );
  };

  const appendAnalysisResult = (
    points,
    selectedAalysisId,
    selectedAalysisVisible
  ) => {
    openseadragonAnnotations.appendAnalysisData(
      points,
      selectedAalysisId,
      selectedAalysisVisible
    );
  };

  return (
    <React.Fragment>
      <ul>
        <li
          id="toggle-annotation"
          className={!showAnnotations ? 'active-tool' : 'nonactive-tool'}
          data-for="viewer-toolbar"
          data-tip={'Toggle annotations'}
          onClick={toggleAnnotation}
        >
          <img src={showAnnotations ? ShowAnno : HideAnno} />
        </li>
        {(postIsMine || slideOwnerOrCollaborator || when === 'groups') && (
          <React.Fragment>
            <li
              id="rect-annotation"
              className={
                !showAnnotations || postIsValidate
                  ? 'anno-tool-disable'
                  : currentAnnotationType === 'rectangle'
                    ? 'active-tool'
                    : ''
              }
              data-for="viewer-toolbar"
              data-tip={'Annotate rectangle'}
              onClick={(e) =>
                showAnnotations &&
                !postIsValidate &&
                handleAnnotation(e, 'rectangle')
              }
            >
              <img src={RectangularAnno} />
            </li>
            <li
              id="circle-annotation"
              className={
                !showAnnotations || postIsValidate
                  ? 'anno-tool-disable'
                  : currentAnnotationType === 'ellipse'
                    ? 'active-tool'
                    : ''
              }
              data-for="viewer-toolbar"
              data-tip={'Annotate ellipse'}
              onClick={(e) =>
                showAnnotations &&
                !postIsValidate &&
                handleAnnotation(e, 'ellipse')
              }
            >
              <img src={CircleAnno} />
            </li>
            <li
              id="polygon-annotation"
              className={
                !showAnnotations || postIsValidate
                  ? 'anno-tool-disable'
                  : currentAnnotationType === 'brush'
                    ? 'active-tool'
                    : ''
              }
              data-for="viewer-toolbar"
              data-tip={'Annotate brush'}
              onClick={(e) =>
                showAnnotations &&
                !postIsValidate &&
                handleAnnotation(e, 'brush')
              }
            >
              <img src={PolygonAnno} />
            </li>
            <li
              id="arrow-annotation"
              className={
                !showAnnotations || postIsValidate
                  ? 'anno-tool-disable'
                  : currentAnnotationType === 'arrow'
                    ? 'active-tool'
                    : ''
              }
              data-for="viewer-toolbar"
              data-tip={'Annotate arrow'}
              onClick={(e) =>
                showAnnotations &&
                !postIsValidate &&
                handleAnnotation(e, 'arrow')
              }
            >
              <img src={ArrowAnno} />
            </li>

            <li
              id="edit-annotation"
              className={
                !showAnnotations
                  ? 'anno-tool-disable'
                  : editAnnotations
                    ? 'active-tool'
                    : 'nonactive-tool'
              }
              data-for="viewer-toolbar"
              data-tip={'Edit annotation'}
              onClick={handleEditAnnotation}
            >
              <img src={EditAnno} />
            </li>
            <li
              id="annotation-color-change"
              className={
                !showAnnotations || postIsValidate
                  ? 'anno-tool-disable'
                  : globleColorChangeActive
                    ? 'active-tool'
                    : when === 'quorum'
                      ? postIsMine
                        ? 'nonactive-tool'
                        : 'anno-tool-disable'
                      : 'nonactive-tool'
              }
              data-for="viewer-toolbar"
              data-tip={'Set annotation color'}
              onClick={
                when === 'quorum'
                  ? postIsMine
                    ? changeGlobleAnnotationColor
                    : null
                  : changeGlobleAnnotationColor
              }
            >
              <img src={ColorChangeIcon} />
              <span
                style={{ backgroundColor: globleAnnotationColor }}
                class="notification-counter"
              ></span>
            </li>
            <li
              id="take-screenshot"
              data-for="viewer-toolbar"
              data-tip={'Take a screenshot'}
              className={
                isSplitViewer
                  ? 'anno-tool-disable'
                  : !activeSnapShot
                    ? 'nonactive-tool'
                    : 'active-tool'
              }
              onClick={() => onSnapshotClose(activeSnapShot ? true : false)}
            >
              <img src={ScreenshotIcon} />
            </li>
          </React.Fragment>
        )}
      </ul>
      {showBrushSizeTool && (
        <div className="right-image-menu-control-parent color-adjustment-menu">
          <p className="right-image-menu-color-control-title">Brush Size</p>
          <div className="right-image-menu-item">
            <Slider
              min={10}
              max={400}
              defaultValue={60}
              onChange={setBrushSizeInAnnotation}
            />
          </div>
        </div>
      )}
      {showAnnotationDataPopup && (
        <AnnotationEditPopup
          onClose={onClose}
          setAnnoatationName={setAnnoatationName}
          handleSaveClick={handleSaveClick}
          currentAnnotationName={currentAnnotationName}
          currentAnnotationData={editAnnotationData}
        />
      )}
      {showAnnotationEditPopup && (
        <AnnotationDetailsPopup
          currentAnnotationName={currentAnnotationName}
          editBtnOnClick={editBtnOnClick}
          deleteBtnOnClick={deleteBtnOnClick}
          onColorChange={onColorChange}
          when={when}
          currentAnnotationData={currentOrgAnnotations}
        />
      )}
      {globleColorChangeActive && (
        <AnnotationColorPicker
          customClass={'toolbar-color-picker-continer'}
          onColorChange={onGlobleAnnoColorChange}
        />
      )}
      <ToolTip id="viewer-toolbar" place="bottom" type="info" effect="solid" />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  allAnnotationData: state.Viewer.annotationData,
  toogleAnnotation: state.Viewer.toogleData,
  isSoketDataUpdate: state.Viewer.isSoketDataUpdate,
  userDetails: state.Global.loggedUserDetails,
  isAnnotationUpload: state.Viewer.isAnnotationUpload,
  selectedAnnotationId: state.Viewer.selectedAnnotationId,
  annotationPopupShow: state.Viewer.annotationPopupShow,
  selectedAalysisResult: state.AIAnalytics.selectedAalysisResult,
  heatMapData: state.Viewer.heatMapData,
  selectedAalysisId: state.AIAnalytics.selectedAalysisId,
  selectedAalysisVisible: state.AIAnalytics.selectedAalysisVisible,
});

export default connect(mapStateToProps)(ViewerToolbarAnnotation);
