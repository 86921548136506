import React, { useState, useEffect } from "react";
import { StainTags } from "../../../../config/constants";
import FilterIcon from "../../../assets/icons/filter-icon.svg";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const NewFilters = ({
  memberDetails,
  stainType,
  getSearchDirData,
  search,
  loadSlideboxDirDataList,
  dirType,
  selectedFolderId,
}) => {
  let mediaQuery;

  const [showAdvancedSearchOptions, setShowAdvancedSearchOptions] = useState(
    false
  );
  const [width, setWidth] = useState(115);

  const [folderType, setFolderType] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [stains, setStains] = useState([]);
  const [dateModified, setDateModified] = useState([]);

  const directoryType = [{ id: 1, value: "case", label: "Case" }];

  const caseStatusValues = [
    { id: 1, value: "pending", label: "Pending" },
    { id: 2, value: "review", label: "Review" },
    { id: 3, value: "completed", label: "Completed" },
  ];

  const allStainTypes = [...stainType, ...StainTags];
  let jsonObject = allStainTypes.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  const uniqueStainTypes = Array.from(uniqueSet).map(JSON.parse);

  const dateModifiedValues = [
    { value: "0", label: "Today" },
    { value: "1", label: "Yesterday" },
    { value: "7", label: "Last 7 days" },
  ];
  const activeMembers = memberDetails.filter((members) => {
    return members.status === "active";
  });

  const filteredAssignToMembers = activeMembers.map((members) => {
    const fullName = members.firstName + " " + members.lastName;
    const displayName =
      fullName.length > 18 ? fullName.substring(0, 18) + "..." : fullName;
    return {
      value: displayName,
      label: displayName,
      userId: members.userID,
    };
  });

  const handleClickAdvancedSearch = () => {
    setShowAdvancedSearchOptions(!showAdvancedSearchOptions);
  };

  const handleApply = () => {
    const dirType =
      folderType.length > directoryType.length
        ? directoryType.map((dir) => dir.value)
        : folderType.map((item) => item.value);
    folderType.map((type) => type.value);

    const assignedMembers =
      assignedTo.length > filteredAssignToMembers.length
        ? filteredAssignToMembers.map((user) => user.userId)
        : assignedTo.map((user) => user.userId);

    const stainType =
      stains.length > allStainTypes.length
        ? allStainTypes.map((stain) => stain.value)
        : stains.map((item) => item.value);

    const reviewStatus =
      caseStatus.length > caseStatusValues.length
        ? caseStatusValues.map((item) => item.value)
        : caseStatus.map((item) => item.value);

    const dateModifiedValue = dateModified.value;

    getSearchDirData({
      search: search,
      type: dirType.length > 0 ? dirType : "",
      reviewStatus:
        reviewStatus.length > 0
          ? JSON.stringify(reviewStatus)
              .replace(/]|[[]/g, "")
              .split(",")
          : "",
      stainType:
        stainType.length > 0
          ? JSON.stringify(stainType)
              .replace(/]|[[]/g, "")
              .split(",")
          : "",
      assignTo: assignedMembers.length > 0 ? assignedMembers : "",
      dateModified: dateModifiedValue ? dateModifiedValue : "",
    });
  };
  const handleClear = () => {
    setFolderType([]);
    setStains([]);
    setDateModified([]);
    setCaseStatus([]);
    setAssignedTo([]);
    loadSlideboxDirDataList({
      dirId: selectedFolderId,
      dirType: dirType,
    });
  };

  const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
    if (value && value.some((o) => o.value === "*")) {
      return `All`;
    } else if (value && value.some((o) => o.value !== "*")) {
      return `${value ? value.map((item) => item.label) : ""}`;
    } else {
      return `${placeholderButtonLabel}`;
    }
  };

  function handleChangeValues(value, event) {
    console.log({ value, event });
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  function handleChangeDateModified(value, event) {
    this.setState(value);
  }

  const customStyles = {
    menu: (provided, state) => ({
      width: 200,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      fontSize: 14,
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    }),

    optionContainer: (provided, state) => ({
      marginLeft: 10,
      marginTop: 15,
      width: "100%",
    }),
    option: (provided, state) => ({
      width: "100%",
      padding: 5,
      paddingLeft: 10,
    }),
  };

  useEffect(() => {
    mediaQuery = window.matchMedia("(min-width: 1610px)");
    if (mediaQuery.matches) {
      setWidth(190);
    } else {
      setWidth(115);
    }
  }, [mediaQuery, width]);

  return (
    <React.Fragment>
      <button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClickAdvancedSearch}
        style={{
          backgroundColor: "transparent",
          borderRadius: "5px",
          width: "95px",
          height: 35,
          paddingLeft: 10,
          outline: "none",
          padding: "5px 15px",
          position: "absolute",
          right: 10,
          border: !showAdvancedSearchOptions
            ? "1px solid #ddd"
            : "2px solid #3f51b5",
        }}
      >
        <img
          src={FilterIcon}
          alt="filter"
          style={{
            marginRight: "5px",
            height: "15px",
            width: "15px",
          }}
        />
        Filter
      </button>

      {showAdvancedSearchOptions && (
        <div className="advanced-search-options">
          <div className="filter-option">
            <ReactMultiSelectCheckboxes
              options={[...directoryType]}
              placeholderButtonLabel="Type"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={folderType}
              onChange={handleChangeValues}
              setState={setFolderType}
              hideSearch={true}
              styles={customStyles}
            />
          </div>
          <div className="filter-option">
            <ReactMultiSelectCheckboxes
              options={[
                { id: 0, label: "All", value: "*" },
                ...filteredAssignToMembers,
              ]}
              placeholderButtonLabel="Assigned To"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={assignedTo}
              onChange={handleChangeValues}
              setState={setAssignedTo}
              hideSearch={true}
              styles={customStyles}
            />
          </div>
          <div className="filter-option">
            <ReactMultiSelectCheckboxes
              options={[{ label: "All", value: "*" }, ...caseStatusValues]}
              placeholderButtonLabel="Case Status"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={caseStatus}
              onChange={handleChangeValues}
              setState={setCaseStatus}
              styles={customStyles}
              hideSearch={true}
            />
          </div>
          <div className="filter-option">
            <ReactMultiSelectCheckboxes
              options={[{ label: "All", value: "*" }, ...allStainTypes]}
              placeholderButtonLabel="Stain Type"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={stains}
              onChange={handleChangeValues}
              setState={setStains}
              hideSearch={true}
              styles={customStyles}
            />
          </div>

          <div className="filter-option">
            <ReactMultiSelectCheckboxes
              options={[...dateModifiedValues]}
              placeholderButtonLabel="Date Modified"
              value={dateModified}
              onChange={handleChangeDateModified}
              setState={setDateModified}
              hideSearch={true}
              styles={customStyles}
              isMulti={false}
            />
          </div>

          <div className="apply-clear-container">
            <button className="clear-button" onClick={handleClear}>
              Reset
            </button>
            <button className="apply-button" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default NewFilters;
