import React from 'react';
import UploadButton from './UploadButton';
import CreateButton from './CreateButton';


// manage create and upload buttons based on the folder or case selection 
const SlideBoxAction = ({ rootOnlySelect, isCreateButtonShow, ...rest, isModal, userData }) => {
  if (isModal) {
    return null;
  }
  return (
    <React.Fragment>

      <CreateButton {...rest} />
      { rootOnlySelect ? <UploadButton {...rest} showUploadButton="false" /> : <UploadButton {...rest} showUploadButton="true" />}
    </React.Fragment>
  );
};

export default SlideBoxAction;
