import React from "react";
import { FadeIn } from "animate-components";
import DeactivateIcon from '../../../../assets/icons/deactivate.svg'
import {
  Overlay,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from "../../../../components";

const ActivateOrDeactivateUser = ({
  onClose,
  activateOrDeactivateUserData,
  activateOrDeactivateUserFn,
}) => {
  const {
    firstName,
    lastName,
    userID: userId,
    status,
  } = activateOrDeactivateUserData;
  const label = status === "active" ? "Deactivate" : "Activate";
  const isActive = status === "active" ? false : true;

  const handleActivateOrDeactivateClick = () => {
    const dataStatus = status === "active" ? "deactivated" : "active";
    const data = { userId, status: dataStatus };
    activateOrDeactivateUserFn({ data, isActive });
    onClose(false);
  };

  return (
    <React.Fragment>
      <Overlay />
      <div className="prompt">
        <FadeIn duration="300ms">
          <ModalHeader
            title={`${label + " " + firstName + "'s account?"}`}
            onClick={() => onClose(false)}
            img={DeactivateIcon}
            alt={'deactivate'}
          />
          <div className="modal_middle">
            <div className="deactivate-info">
              <p>
                What happens when an account is{" "}
                {!isActive ? "deactivated" : "activated"}?
              </p>
              <ul>
                {!isActive ? (
                  <li>
                    The member will no longer be able to sign in to workspace.
                  </li>
                ) : (
                    <li>
                      The member will be able to sign in to workspace again.
                    </li>
                  )}
              </ul>
            </div>
          </div>
          <div className="prompt-bottom" style={{ marginTop: 0 }}>
            <SecondaryButton label="Cancel" onClick={() => onClose(false)} />
            <PrimaryButton
              label={label}
              onClick={handleActivateOrDeactivateClick}
              extraClass="prompt-done"
            />
          </div>
        </FadeIn>
      </div>
    </React.Fragment>
  );
};

export default ActivateOrDeactivateUser;
