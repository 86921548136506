import React from "react";
import PropTypes from "prop-types";

const NotificationType = ({
  featureSubType,
  user_username,
  file_name,
  type,
  groupName,
  notificationType,
}) => {
  return (
    <span>
      {notificationType === "groups"
        ? `You are invitied to ${groupName} group`
        : featureSubType}
    </span>
  );
};

NotificationType.propTypes = {
  type: PropTypes.string.isRequired,
  user_username: PropTypes.string,
};

export default NotificationType;
