import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FolderTreeView from './SlideItems/FolderTreeView';
import {
  setOpenDirectory,
  loadSlideboxDirectory,
  loadSlideboxDirDataList,
  breadcrumbAction,
  setSelectedDirectory,
  setExpandDirectory,
  getSearchDirData,
  setSelectedDirAssignUsers,
  setSelectedDirCollaborators,
} from '../actions/slidebox-action';
import SlideBoxAction from './SlideItems/SlideboxActionButtons';
import CreateDirectory from './SlideItems/CreateDirectory';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  CssBaseline,
  Divider,
  IconButton,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getUserDetailsAction } from '../../../actions/global-action';

const drawerWidth = 264;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    left: 'auto',
    top: 54,
    border: 'none',
    position: 'unset',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    left: 'auto',
    top: 54,
    zIndex: 1,
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(17) + 5,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbarCollapse: {
    padding: [[0, 25]],
  },
  iconButton: {
    outline: 'none !important',
    float: 'right',
  },
}));

const SlideboxLeftSider = ({
  slideDirectory,
  setSelectedDirectory,
  setExpandDirectory,
  setOpenDirectory,
  selectedFolder,
  selectedFolderType,
  loadSlideboxDirectory,
  loadSlideboxDirDataList,
  breadcrumbAction,
  getSearchDirData,
  uploadPageLoad,
  userData,
  isModal,
  getLoggedUserDetails,
  setSelectedDirAssignUsers,
  setSelectedDirCollaborators,
}) => {
  const classes = useStyles();
  const [rootOnlySelect, setRootOnlySelect] = useState(false);
  const [openCreateDir, setOpenCreateDir] = useState(false);
  const [createDirDisable, setCreateDirDisable] = useState(false);
  const [dirType, setdDirType] = useState(null);
  const [
    slideboxLeftSiderIsCollapse,
    setSlideboxLeftSiderIsCollapse,
  ] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (!uploadPageLoad) {
      loadSlideboxDirectory();
      loadSlideboxDirDataList({
        dirId: null,
        dirType: 'root',
      });
    } else {
      loadSlideboxDirDataList({
        dirId: selectedFolder,
        dirType: selectedFolderType,
      });
    }
    getLoggedUserDetails();
  }, []);

  useEffect(() => {
    const openDirs = slideDirectory.filter((dirData) => dirData.selected);
    setRootOnlySelect(openDirs.length === 1 ? true : false);
  }, [slideDirectory]);

  const createDir = (e, dirType) => {
    setOpenCreateDir(true);
    setdDirType(dirType);
  };

  const createDirOnClose = () => {
    setOpenCreateDir(false);
  };

  const handleDrawer = () => {
    setSlideboxLeftSiderIsCollapse(!slideboxLeftSiderIsCollapse);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getSearchDirData({ search: event.target.value });
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: slideboxLeftSiderIsCollapse,
          [classes.drawerClose]: !slideboxLeftSiderIsCollapse,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: slideboxLeftSiderIsCollapse,
            [classes.drawerClose]: !slideboxLeftSiderIsCollapse,
          }),
        }}
      >
        <List className="tree-view-section">
          <div className="action-buttons-newUpload">
            <SlideBoxAction
              {...{
                rootOnlySelect:
                  selectedFolderType === 'root' ||
                    selectedFolderType === 'slide'
                    ? true
                    : false,
                createDir,
                createDirDisable:
                  selectedFolderType === 'folder' ||
                    selectedFolderType === 'root'
                    ? false
                    : true,
                slideboxLeftSiderIsCollapse,
                isCreateButtonShow: userData.roleId !== 3 ? true : false,
              }}
              isModal={isModal}
              userData={userData}
            />
            {!isModal && (
              <IconButton onClick={handleDrawer} className={classes.iconButton}>
                {slideboxLeftSiderIsCollapse ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            )}
          </div>

          <div>
            <FolderTreeView
              slideDirectory={slideDirectory}
              setOpenDirectory={setOpenDirectory}
              setSelectedDirectory={setSelectedDirectory}
              setExpandDirectory={setExpandDirectory}
              loadSlideboxDirDataList={loadSlideboxDirDataList}
              slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
              setCreateDirDisable={(status) => setCreateDirDisable(status)}
              breadcrumbAction={(status) =>
                breadcrumbAction(
                  dirType === 'root' ? { showIcon: false } : { showIcon: true }
                )
              }
              isMovePopup={false}
              setSelectedDirCollaborators={setSelectedDirCollaborators}
              setSelectedDirAssignUsers={setSelectedDirAssignUsers}
            />
          </div>
          {openCreateDir && (
            <CreateDirectory
              dirType={dirType}
              isFile={false}
              onClose={createDirOnClose}
              selectedDir={
                selectedFolder ? selectedFolder : slideDirectory[0]['dirId']
              }
              selectedFolderType={selectedFolderType}
              selectedFolder={selectedFolder}
            />
          )}
        </List>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.folders,
  uploadPageLoad: state.SlideBox.uploadPageLoad,
  userData: state.Global.loggedUserDetails,
  ...state.SlideBox.selectedFolderData,
});

const mapDispatchToProps = (dispatch) => ({
  setOpenDirectory: (payload) => dispatch(setOpenDirectory(payload)),
  setSelectedDirectory: (payload) => dispatch(setSelectedDirectory(payload)),
  setExpandDirectory: (payload) => dispatch(setExpandDirectory(payload)),
  loadSlideboxDirectory: () => dispatch(loadSlideboxDirectory()),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  breadcrumbAction: (payload) => dispatch(breadcrumbAction(payload)),
  getSearchDirData: (payload) => dispatch(getSearchDirData(payload)),
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
  setSelectedDirAssignUsers: (payload) =>
    dispatch(setSelectedDirAssignUsers(payload)),
  setSelectedDirCollaborators: (payload) =>
    dispatch(setSelectedDirCollaborators(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideboxLeftSider);
