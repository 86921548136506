import React, { Component } from "react";
import "./ContextMenu.scss";
import MoveIcon from "./icons/move.svg";
import RenameIcon from "./icons/rename.svg";
import CollaborateIcon from "./icons/collaborate.svg";
import ViewDetailsIcon from "./icons/view-details.svg";
import DeleteIcon from "./icons/delete.svg";
import GetLinkIcon from "./icons/get-link.svg";
import Analyze from "./icons/analyze.svg";
import OpenViewerIcon from "./icons/open-viewer.svg";
import AddToGroup from "./icons/add-to-group.svg";
import DownloadIcon from "./icons/download.svg";

import LinkIcon from "@material-ui/icons/Link";
import GetAppIcon from "@material-ui/icons/GetAppOutlined";
import AddSlideIcon from "@material-ui/icons/AddPhotoAlternate";
import SlideOpenIcon from "@material-ui/icons/SlideshowOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Notify } from "../index";
import { connect } from "react-redux";
import { setSlideBoxRightClickSelectedAction } from "../../pages/slidebox/actions/slidebox-action";
import { getUserDetailsAction } from "../../actions/global-action";
import Can from "../../hoc/FullControllHoc/can";
import { FeatureGate } from "react-feature-gate";

class ContextMenu extends Component {
  state = {
    visible: false,
    rightClickElement: null,
  };

  componentDidMount() {
    document.addEventListener("contextmenu", this._handleContextMenu);
    document.addEventListener("click", this._handleClick);
    document.addEventListener("scroll", this._handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("contextmenu", this._handleContextMenu);
    document.removeEventListener("click", this._handleClick);
    document.removeEventListener("scroll", this._handleScroll);
  }

  _handleContextMenu = async (event) => {
    if (this.props.isModal || this.props.importSlide) {
      event.preventDefault();
      this.setState({ visible: false });
      return;
    }
    event.preventDefault();
    if (
      event.path[0].className.includes("selected-table-cell-color") ||
      event.path[0].id.includes("last-breadcrum-item") ||
      event.path[0].className.includes("grid-view-card")
    ) {
      if (event.path[0].className.includes("breadcrumb-child")) {
        let element = document.getElementById("last-breadcrum-item");
        element.classList.add("selected");
        await this.setState({ rightClickElement: "non-list" });
        this.props.setSlideBoxRightClickSelected("non-list");
      } else {
        await this.setState({ rightClickElement: "list" });
        this.props.setSlideBoxRightClickSelected("list");
      }
      this.setState({ visible: true });
      const clickX = event.clientX;
      const clickY = event.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const rootW = this.root.offsetWidth;
      const rootH = this.root.offsetHeight;

      const right = screenW - clickX > rootW;
      const left = !right;
      const top = screenH - clickY > rootH;
      const bottom = !top;

      if (right) {
        this.root.style.left = `${clickX + 5}px`;
      }

      if (left) {
        this.root.style.left = `${clickX - rootW - 5}px`;
      }

      if (top) {
        this.root.style.top = `${clickY + 5}px`;
      }

      if (bottom) {
        this.root.style.top = `${clickY - rootH - 5}px`;
      }
    }
  };

  _handleClick = (event) => {
    let element = document.getElementById("last-breadcrum-item");
    element && element.classList.remove("selected");
    const { visible } = this.state;
    const wasOutside = !(event.target.contains === this.root);
    if (wasOutside && visible) this.setState({ visible: false });
  };

  _handleScroll = () => {
    const { visible } = this.state;
    if (visible) this.setState({ visible: false });
  };

  onMove = () => {
    const isOutsideClick =
      this.state.rightClickElement === "non-list" ? true : false;
    this.props.handleToggleMovePopup(isOutsideClick);
  };

  onRename = () => {
    const isOutsideClick =
      this.state.rightClickElement === "non-list" ? true : false;
    this.props.renameSlideItem(isOutsideClick);
  };

  onCollaborate = () => {
    const isOutsideClick =
      this.state.rightClickElement === "non-list" ? true : false;
    this.props.showSharedPopup(isOutsideClick);
  };

  onInfoLoad = () => {
    const isOutsideClick =
      this.state.rightClickElement === "non-list" ? true : false;
    this.props.setShowDetails(isOutsideClick);
  };

  onDelete = () => {
    const isOutsideClick =
      this.state.rightClickElement === "non-list" ? true : false;
    this.props.deleteDirItemPopup(isOutsideClick);
    this.props.getLoggedUserDetails();
  };

  onAddSlideToGroups = () => {
    this.props.showGroupsSelectPopup();
  };

  onOpenInViewer = () => {
    this.props.openInViewer();
  };

  onDownloadSLide = () => {
    this.props.downloadSlide();
  };

  onAnalyzeSlide = () => {
    this.props.analyzeSlide();
  };

  render() {
    if (this.props.isModal) {
      return null;
    }
    let selectedDir = null;
    if (this.state.rightClickElement === "list") {
      selectedDir = {
        type: this.props.selectedListData.selectedListType,
        keyFolder:
          this.props.selectedListData.selectedListType === "slide"
            ? this.props.selectedListSlideData.keyFolder
            : null,
      };
    } else {
      selectedDir = {
        type: this.props.selectedFolderData.selectedFolderType,
      };
    }
    const { visible } = this.state;
    return (
      (visible || null) && (
        <div
          ref={(ref) => {
            this.root = ref;
          }}
          className="contextMenu"
        >
          <React.Fragment>
            {selectedDir !== undefined && selectedDir.type !== "document" && (
              <Can
                task={"create-case"}
                taskContainer="slidebox"
                edit={() => (
                  <div className="contextMenu--option" onClick={this.onMove}>
                    <img src={MoveIcon} alt="move" />
                    <span>Move</span>
                  </div>
                )}
              />
            )}
            <Can
              task={"create-case"}
              taskContainer="slidebox"
              edit={() => (
                <div className="contextMenu--option" onClick={this.onRename}>
                  <img src={RenameIcon} alt="rename" />
                  <span>Rename</span>
                </div>
              )}
            />

            {selectedDir !== undefined &&
              selectedDir.type !== "slide" &&
              selectedDir.type !== "document" && (
                <Can
                  task={"create-case"}
                  taskContainer="slidebox"
                  edit={() => (
                    <div
                      className="contextMenu--option"
                      onClick={this.onCollaborate}
                    >
                      <img src={CollaborateIcon} alt="collaborate" />
                      <span>Collaborate</span>
                    </div>
                  )}
                />
              )}
          </React.Fragment>

          {selectedDir !== undefined && selectedDir.type === "slide" && (
            <React.Fragment>
              <div className="contextMenu--option">
                <img src={GetLinkIcon} alt="get-link" />

                <CopyToClipboard
                  text={`${location.href +
                    "viewer?image=" +
                    (selectedDir !== undefined && selectedDir.keyFolder)}`}
                  onCopy={() => Notify({ value: "Copied slide URL" })}
                >
                  <span>Shareable link</span>
                </CopyToClipboard>
              </div>

              <Can
                task={"add-slide"}
                taskContainer={"groups"}
                edit={() => (
                  <div
                    className="contextMenu--option"
                    onClick={this.onAddSlideToGroups}
                  >
                    <img src={AddToGroup} alt="add group" />
                    <span>Add to groups</span>
                  </div>
                )}
              />
              <FeatureGate feature="analysisSection">
                <div
                  className="contextMenu--option"
                  onClick={this.onAnalyzeSlide}
                >
                  <img src={Analyze} alt="analyze" />
                  <span>Analyze Slide</span>
                </div>
              </FeatureGate>

              <div
                className="contextMenu--option"
                onClick={this.onOpenInViewer}
              >
                <img src={OpenViewerIcon} alt="open-viewer" />
                <span>Open with viewer</span>
              </div>
            </React.Fragment>
          )}

          <div className="contextMenu--option" onClick={this.onInfoLoad}>
            <img src={ViewDetailsIcon} alt="view details" />
            <span>View details</span>
          </div>

          {selectedDir !== undefined &&
            (selectedDir.type === "slide" ||
              selectedDir.type === "document") && (
              <Can
                task={"create-case"}
                taskContainer="slidebox"
                edit={() => (
                  <React.Fragment>
                    <div
                      className="contextMenu--option"
                      onClick={this.onDownloadSLide}
                    >
                      <img src={DownloadIcon} alt="download" />
                      <span>Download</span>
                    </div>
                  </React.Fragment>
                )}
              />
            )}
          {this.state.rightClickElement !== "non-list" && (
            <Can
              task={"create-case"}
              taskContainer="slidebox"
              edit={() => (
                <React.Fragment>
                  <div className="contextMenu--separator" />
                  <div className="contextMenu--option" onClick={this.onDelete}>
                    <img src={DeleteIcon} alt="delete" />
                    <span>Delete</span>
                  </div>
                </React.Fragment>
              )}
            />
          )}

          {/* <div className="contextMenu--separator" /> */}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.folders,
  slideDirDataList: state.SlideBox.dirDataList,
  showBreadCrumbIcon: state.SlideBox.breadCrumbIcon,
  showLoader: state.SlideBox.showLoader,
  isSearch: state.SlideBox.isSearchData,
  userDetails: state.Global.loggedUserDetails,
  selectedRightClickItem: state.SlideBox.selectedRightClickItem,
  selectedFolderData: state.SlideBox.selectedFolderData,
  selectedListData: state.SlideBox.selectedListData,
  selectedListSlideData: state.SlideBox.selectedListSlideData,
});

const mapDispatchToProps = (dispatch) => ({
  setSlideBoxRightClickSelected: (payload) =>
    dispatch(setSlideBoxRightClickSelectedAction(payload)),
  getLoggedUserDetails: () => dispatch(getUserDetailsAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
