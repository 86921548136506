import React, { Fragment, useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ViewListIcon from "@material-ui/icons/ViewList";
import GridOnIcon from "../../../assets/icons/grid-icon.svg";
import LinkIcon from "../../../assets/icons/Link-icon.svg";
import InfoOutlinedIcon from "../../../assets/icons/info-icon.svg";
import DeleteOutlineOutlinedIcon from "../../../assets/icons/delete-icon-new.svg";
import PersonAddOutlinedIcon from "../../../assets/icons/collaborate-icon.svg";
import PersonAssignOutlinedIcon from "@material-ui/icons/AssignmentInd";
import ToolTip from "react-tooltip";
import { Notify } from "../../../components";
import SlideBreadcrumb from "./SlideTable/SlideBreadcrumb";
import MoreDetailsDropDown from "./SlideTable/MoreDetailsDropDown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FeatureGate } from "react-feature-gate";
import { switchViewAction } from "../actions/slidebox-action";
import { connect } from "react-redux";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import {
  loadSlideboxDirDataList,
  getSearchData,
  updateCaseStatusAction,
  getCaseStatusAction,
  setSelectedListActivity,
} from "../actions/slidebox-action";
import { valuesObject, keysObject } from "../../../utils/utils";
import Can from "../../../hoc/FullControllHoc/can";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        // color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const SlideboxHeader = ({
  openDirData,
  toggleDirHandler,
  deleteDirItem,
  selectedListType,
  renameSlideItem,
  selectedList,
  isSearch,
  userDetails,
  showSharedPopup,
  showAssignUserPopup,
  showDetails,
  setShowDetails,
  setSelectedDetails,
  handleToggleMovePopup,
  showGroupsSelectPopup,
  outSideList,
  switchView,
  setSwitchView,
  switchViewAction,
  updateCaseStatus,
  slideboxCaseId,
  setSelectedListActivity,
  isListView,
  downloadSlide,
  isModal,
  selectedCaseAssignUsers,
  when,
  importSlide,
}) => {
  const handleSwitchView = () => {
    setSwitchView(!switchView);
    switchViewAction(!switchView);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const ButtonAttributes = {
    Pending: {
      onClick: () => {
        updateCaseStatus({
          dirId: slideboxCaseId,
          reviewStatus: "pending",
        });
        setSelectedListActivity({ dirId: slideboxCaseId, dirType: "case" });

        setAnchorEl(null);
        Notify({ value: "Case status updated" });
      },
    },
    Review: {
      onClick: () => {
        updateCaseStatus({
          dirId: slideboxCaseId,
          reviewStatus: "review",
        });
        setSelectedListActivity({ dirId: slideboxCaseId, dirType: "case" });
        setAnchorEl(null);
        Notify({ value: "Case status updated" });
      },
    },
    Completed: {
      onClick: () => {
        updateCaseStatus({
          dirId: slideboxCaseId,
          reviewStatus: "completed",
        });
        setSelectedListActivity({ dirId: slideboxCaseId, dirType: "case" });

        setAnchorEl(null);
        Notify({ value: "Case status updated" });
      },
    },
  };

  useEffect(() => {
    if (!showDetails) {
      if (outSideList) {
        if (selectedList !== undefined) {
          const mappedData = {
            caseOrFolder: selectedList.caseOrFolder,
            description: selectedList.description,
            dirDataId: selectedList.dirId,
            dirDataName: selectedList.dirName,
            dirDataSize: selectedList.dirSize,
            first_name: selectedList.first_name,
            last_name: selectedList.last_name,
            annotationCount: selectedList.annotationCount,
            assignTo: selectedList.assignTo,
            review_status: selectedList.review_status,
            lastModified: selectedList.lastModified,
            notes: selectedList.notes,
            stainType: null,
            uniqueId: null,
          };

          setSelectedDetails(mappedData);
        }
      } else {
        setSelectedDetails(selectedList);
      }
    } else {
      if (selectedList && typeof selectedList.dirId !== "undefined") {
        const mappedData = {
          caseOrFolder: selectedList.caseOrFolder,
          description: selectedList.description,
          dirDataId: selectedList.dirId,
          dirDataName: selectedList.dirName,
          dirDataSize: selectedList.dirSize,
          annotationCount: selectedList.annotationCount,
          first_name: selectedList.first_name,
          last_name: selectedList.last_name,
          assignTo: selectedList.assignTo,
          review_status: selectedList.review_status,
          lastModified: selectedList.lastModified,
          notes: selectedList.notes,
          stainType: null,
          uniqueId: null,
        };
        setSelectedDetails(mappedData);
      }
      if (selectedList && typeof selectedList.dirDataId !== "undefined") {
        setSelectedDetails(selectedList);
      }
    }
  }, [selectedList]);

  const handleCaseOptions = (event) => {
    setAnchorEl(event.currentTarget);
    // updateCaseStatus({
    //   dirId:slideboxCaseId,
    //   reviewStatus: event.target.value
    // })
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /*const afterCaseStatusUpdateSuccess=()=>{
    Notify({
      value: `Case status updated`,
    });
    setEditing(false);
  };

  const afterCaseStatusUpdateFail = () =>{
    Notify({
      value: `Failed to update status`,
    });
    setEditing(false);
  };*/
  return (
    <div className="slidebox-header" data-test="slideboxHeader">
      <div className="slidebox-selected">
        <SlideBreadcrumb
          openDirData={openDirData}
          toggleDirHandler={toggleDirHandler}
          isSearch={isSearch}
          userDetails={userDetails}
          deleteDirItemPopup={deleteDirItem}
          showSharedPopup={showSharedPopup}
          showAssignUserPopup={showAssignUserPopup}
          renameSlideItem={renameSlideItem}
          handleToggleMovePopup={handleToggleMovePopup}
          setShowDetails={setShowDetails}
          isModal={isModal}
          data-test="slideBreadcrumb"
          importSlide={importSlide}
        />
      </div>
      {when !== "analysis" && (
        <div className="slidebox-view" data-test="slideView">
          {selectedListType === "case" && !isModal && (
            <Fragment>
              <Can
                task={"update-case-status"}
                data-test="update-case-status"
                taskContainer="slidebox"
                edit={() => (
                  <Fragment>
                    <Can
                      task={"create-case"}
                      taskContainer="slidebox"
                      data-test="create-case"
                      edit={() => (
                        <IconButton
                          color="info"
                          aria-label="info"
                          component="span"
                          anchorEl={anchorEl}
                          onClick={() => Boolean(anchorEl)}
                          data-for="viewer-tooltip"
                          data-tip="Update case status"
                        >
                          <AssignmentTurnedInOutlinedIcon
                            style={{ fill: "#0F0DA1" }}
                            onClick={handleCaseOptions}
                          />
                        </IconButton>
                      )}
                      noAccess={() => {
                        if (
                          selectedCaseAssignUsers !== undefined &&
                          selectedCaseAssignUsers.length !== 0 &&
                          selectedCaseAssignUsers[0].userID ===
                            userDetails.userID
                        ) {
                          return (
                            <IconButton
                              color="info"
                              aria-label="info"
                              component="span"
                              anchorEl={anchorEl}
                              onClick={() => Boolean(anchorEl)}
                              data-for="viewer-tooltip"
                              data-tip="Update case status"
                            >
                              <AssignmentTurnedInOutlinedIcon
                                style={{ fill: "#0F0DA1" }}
                                onClick={handleCaseOptions}
                              />
                            </IconButton>
                          );
                        } else {
                          return <div></div>;
                        }
                      }}
                    />
                  </Fragment>
                )}
              />
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {valuesObject(ButtonAttributes).map(
                  ({ icon, type, ...rest }, index) => (
                    <StyledMenuItem
                      className={"dropdown-li"}
                      {...rest}
                      key={index}
                    >
                      {icon}
                      <ListItemText
                        primary={keysObject(ButtonAttributes)[index].replace(
                          /_/g,
                          " "
                        )}
                      />
                    </StyledMenuItem>
                  )
                )}
              </StyledMenu>
            </Fragment>
          )}
          {/* {userDetails.roleId !== 3 && ( */}
          <Fragment>
            {selectedListType && !isModal && (
              <Fragment>
                {selectedListType === "slide" && (
                  <IconButton
                    color="info"
                    component="span"
                    data-for="viewer-tooltip"
                    data-tip="Get sharable link"
                    data-test="getSharableLink"
                  >
                    <CopyToClipboard
                      data-test="copyToClipboard"
                      text={`${location.href +
                        "viewer?image=" +
                        (selectedList !== undefined &&
                          selectedList.keyFolder)}`}
                      onCopy={() => Notify({ value: "Copied slide URL" })}
                    >
                      <img src={LinkIcon} alt="getLink" data-test="linkIcon" />
                    </CopyToClipboard>
                  </IconButton>
                )}
                {selectedListType === "case" && !isModal && (
                  <Can
                    task={"create-case"}
                    taskContainer="slidebox"
                    data-test="create-case"
                    edit={() => (
                      <IconButton
                        color="info"
                        aria-label="info"
                        component="span"
                        onClick={() => showAssignUserPopup()}
                        data-for="viewer-tooltip"
                        data-tip="Assign"
                      >
                        <PersonAssignOutlinedIcon style={{ fill: "#0F0DA1" }} />
                      </IconButton>
                    )}
                  />
                )}
                {selectedListType !== "slide" && !isModal && (
                  <Can
                    task={"create-case"}
                    taskContainer="slidebox"
                    data-test="create-case"
                    edit={() => (
                      <IconButton
                        color="info"
                        aria-label="info"
                        component="span"
                        onClick={() => showSharedPopup()}
                        data-for="viewer-tooltip"
                        data-tip="Collaborate with users"
                      >
                        <img src={PersonAddOutlinedIcon} alt="collab" />
                      </IconButton>
                    )}
                  />
                )}
                <Can
                  task={"create-case"}
                  taskContainer="slidebox"
                  data-test="create-case"
                  edit={() => (
                    <IconButton
                      color="info"
                      aria-label="info"
                      component="span"
                      onClick={() => deleteDirItem()}
                      data-for="viewer-tooltip"
                      data-tip="Delete"
                    >
                      <img src={DeleteOutlineOutlinedIcon} alt="delete" />
                    </IconButton>
                  )}
                />

                <Can
                  task={"create-case"}
                  taskContainer="slidebox"
                  data-test="create-case"
                  edit={() => (
                    <MoreDetailsDropDown
                      renameDir={renameSlideItem}
                      selectedListType={selectedListType}
                      selectedList={selectedList}
                      showGroupsSelectPopup={showGroupsSelectPopup}
                      downloadSlide={downloadSlide}
                    />
                  )}
                  noAccess={() => {
                    if (selectedListType === "slide") {
                      return (
                        <MoreDetailsDropDown
                          renameDir={renameSlideItem}
                          selectedListType={selectedListType}
                          selectedList={selectedList}
                          showGroupsSelectPopup={showGroupsSelectPopup}
                          downloadSlide={downloadSlide}
                        />
                      );
                    } else {
                      return null;
                    }
                  }}
                />
                <ToolTip
                  id="viewer-tooltip"
                  place="bottom"
                  type="dark"
                  effect="solid"
                />
              </Fragment>
            )}
            {/* <FeatureGate feature="slideboxViewToggle"> */}
            {isListView ? (
              <IconButton
                color="info"
                aria-label="change view"
                component="span"
                data-for="viewer-tooltip"
                data-tip="Grid View"
                // onClick={() => setSwitchView(!switchView, !isListView)}
                onClick={handleSwitchView}
              >
                <img src={GridOnIcon} alt="grid" data-test="gridOnIcon" />
              </IconButton>
            ) : (
              <IconButton
                color="info"
                aria-label="change view"
                component="span"
                data-for="viewer-tooltip"
                data-tip="List View"
                // onClick={() => setSwitchView(!switchView, !isListView)}
                onClick={handleSwitchView}
              >
                <ViewListIcon style={{ fill: "#0F0DA1" }} />
              </IconButton>
            )}

            {/* </FeatureGate> */}
          </Fragment>
          {/* )} */}
          {selectedListType && !isModal && (
            <IconButton
              data-test="selectedList"
              color="info"
              aria-label="info"
              component="span"
              data-for="viewer-tooltip"
              data-tip="Info"
              onClick={() => {
                setShowDetails();
                setSelectedDetails(selectedList);
              }}
            >
              <img
                src={InfoOutlinedIcon}
                alt="info"
                data-test="infoOutlinedIcon"
              />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isListView: state.SlideBox.isListView,
  folderType: state.SlideBox.selectedFolderData.selectedFolderType,
  selectedFolderId: state.SlideBox.selectedFolderData.selectedFolder,
  slideboxCaseId: state.SlideBox.selectedListData.selectedList,
  memberDetails: state.SlideBox.selectedDirCollaborators,
  selectedCaseAssignUsers: state.SlideBox.selectedCaseAssignUsers,
});

const mapDispatchToProps = (dispatch) => ({
  switchViewAction: (payload) => dispatch(switchViewAction(payload)),
  getSearchDirData: (payload) => dispatch(getSearchDirData(payload)),
  getSearchData: (payload) => dispatch(getSearchData(payload)),
  loadSlideboxDirDataList: (payload) =>
    dispatch(loadSlideboxDirDataList(payload)),
  getCaseStatus: (payload) => dispatch(getCaseStatusAction(payload)),
  updateCaseStatus: (payload) => dispatch(updateCaseStatusAction(payload)),
  setSelectedListActivity: (payload) =>
    dispatch(setSelectedListActivity(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideboxHeader);
