import io from "socket.io-client";
import { fromEvent, Subject } from "rxjs";

export class SocketService {
  constructor() {
    this.server = process.env.SERVER_URL ? process.env.SERVER_URL : "/";
    this.socket = {};
    this.annotationMessage$ = {};
  }

  init() {
    this.socket = io(this.server);
    this.annotationMessage$ = new Subject();
    return this;
  }

  getSocketInstance() {
    return this.socket;
  }

  getAnnotationMessageSubject() {
    return this.annotationMessage$;
  }

  onRequest() {
    return fromEvent(this.socket, "request");
  }

  onUpdateMembers() {
    return fromEvent(this.socket, "updateMembers");
  }

  // onUpdateTumorImage() {
  //   return fromEvent(this.socket, 'updateTumorImage')
  // }

  onUpdateBoard() {
    return fromEvent(this.socket, "updateTB");
  }

  onUpdateBoardAnnotation() {
    return fromEvent(this.socket, "updateBoardAnnotation");
  }

  onUpdateNotifications() {
    return fromEvent(this.socket, "updateNotifs");
  }

  onMessage() {
    return fromEvent(this.socket, "message");
  }

  onAnnotation() {
    return fromEvent(this.socket, "annotation");
  }

  onMessageDeleted() {
    return fromEvent(this.socket, "messageDeleted");
  }

  onMessageUpdated() {
    return fromEvent(this.socket, "messageUpdated");
  }

  onFileUploadDone() {
    return fromEvent(this.socket, "FileUploadDone");
  }

  onFileUploadProgress() {
    return fromEvent(this.socket, "FileUploadProgress");
  }

  onConversionDone() {
    return fromEvent(this.socket, "ConversionDone");
  }

  onPublishAnnotation() {
    return fromEvent(this.socket, "publishAnnotation");
  }

  onNotifyToCollaborators() {
    return fromEvent(this.socket, "NotifyToCollaborator");
  }

  onViewerSyncroniztion() {
    return fromEvent(this.socket, "ViewerSyncroniztion");
  }

  onViewerSyncroniztionStart() {
    return fromEvent(this.socket, "ViewerSyncroniztionStart");
  }

  disconnect() {
    this.socket.disconnect();
  }
}
