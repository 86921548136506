import React from "react";

const StainCard = ({ stain, addSelf }) => {
  return (
    <div className="stainCard" onClick={() => addSelf()}>
      <div
        style={{
          height: "55px",
          display: "flex",
          flexDirection: "column",
          paddingTop: "7px",
        }}
      >
        <p className="searchName">
          {typeof stain !== "undefined" && stain.value}
        </p>
      </div>
    </div>
  );
};

export default StainCard;
