import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { StainTags } from "../../../../config/constants";
import FilterIcon from "../../../assets/icons/filter-icon.svg";
import dropdown from "@material-ui/icons/ExpandMore";
import { PrimaryButton, SecondaryButton } from "../../../components";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 120,
    marginTop: 10,
    marginLeft: 10,
    fontSize: 15,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dateModified: {
    "& .MuiInputLabel-formControl": {
      fontSize: 15,
    },
    width: 160,
    marginTop: 10,
    marginLeft: 10,
  },
  searchRoots: {
    outline: "none",
    border: "none !important",
  },
  buttonSelected: {
    border: "1px solid #ddd",
  },
  buttonNotSelected: {
    border: "2px solid #3f51b5",
  },
  memberListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 13,
  },
  optionsFontSize: {
    fontSize: 13,
  },
}));

const AdvancedSearch = ({
  memberDetails,
  stainType,
  getSearchDirData,
  search,
  loadSlideboxDirDataList,
  dirType,
  selectedFolderId,
}) => {
  const classes = useStyles();
  const [folderType, setFolderType] = useState("");
  const [stain_type, setStainType] = useState("");
  const [date_modified, setDateModified] = useState("");
  const [caseStatus, setCaseStatus] = useState("");
  const [showAdvancedSearchOptions, setShowAdvancedSearchOptions] = useState(
    false
  );

  const directoryType = [
    { value: "all", label: "All" },
    { value: "folder", label: "Folder" },
    { value: "case", label: "Case" },
  ];

  const caseStatusValues = [
    { value: "all", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "review", label: "Review" },
    { value: "completed", label: "Completed" },
  ];

  const allStainTypes = [...stainType, ...StainTags];
  let jsonObject = allStainTypes.map(JSON.stringify);
  let uniqueSet = new Set(jsonObject);
  const uniqueStainTypes = Array.from(uniqueSet).map(JSON.parse);

  const dateModifiedValues = [
    { value: "All", label: "All" },
    { value: "0", label: "Today" },
    { value: "1", label: "Yesterday" },
    { value: "7", label: "Last 7 days" },
  ];

  const [state, setState] = useState({
    membersSelected: [],
    search_query_for_members: "",
  });

  const filteredAssignToMembers = memberDetails.filter((members) => {
    return (
      members !== undefined &&
      members.status !== "invited" &&
      members.firstName
        .toLowerCase()
        .indexOf(state.search_query_for_members.toLowerCase()) !== -1
    );
  });

  const handleClickAdvancedSearch = () => {
    setShowAdvancedSearchOptions(!showAdvancedSearchOptions);
  };

  const handleChange = (event) => {
    setFolderType(event.target.value);
  };

  const handleChangeDate = (event) => {
    setDateModified(event.target.value);
  };

  const handleChangeStainType = (event) => {
    setStainType(event.target.value);
  };

  const handleChangeMemberNames = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleChangeCaseStatus = (event) => {
    setCaseStatus(event.target.value);
  };

  const handleApply = () => {
    getSearchDirData({
      search: search,
      type: folderType,
      reviewStatus: caseStatus,
      stainType: stain_type,
      assignTo:
        state.membersSelected.length !== 0
          ? JSON.stringify(state.membersSelected)
              .replace(/]|[[]/g, "")
              .split(",")
          : "",
      dateModified: date_modified,
    });
  };

  const handleClear = () => {
    setFolderType("");
    setStainType("");
    setDateModified("");
    setCaseStatus("");
    setState({ membersSelected: [], search_query_for_members: "" });
    loadSlideboxDirDataList({
      dirId: selectedFolderId,
      dirType: dirType,
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: "200px",
      },
    },
  };

  return (
    <React.Fragment>
      <button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClickAdvancedSearch}
        className={
          !showAdvancedSearchOptions
            ? classes.buttonSelected
            : classes.buttonNotSelected
        }
        style={{
          backgroundColor: "transparent",
          borderRadius: "5px",
          width: "95px",
          height: 35,
          paddingLeft: 10,
          outline: "none",
          padding: "5px 15px",
          position: "absolute",
          right: 10,
        }}
      >
        <img
          src={FilterIcon}
          alt="filter"
          style={{
            marginRight: "5px",
            height: "15px",
            width: "15px",
          }}
        />
        Filter
      </button>
      {showAdvancedSearchOptions && (
        <div className="advanced-search-options">
          <FormControl
            // className={classes.formControl}
            className="dropdown-styles"
          >
            <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
            <Select
              disableUnderline={true}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={folderType}
              onChange={handleChange}
              MenuProps={{
                autoFocus: false,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              iconStyle={{ fill: "#ff0000" }}
              IconComponent={dropdown}
            >
              {directoryType.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.value}
                  className={classes.optionsFontSize}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            className="dropdown-styles"
            //  className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-helper-label">
              Assigned To
            </InputLabel>
            <Select
              disableUnderline={true}
              title="Select members"
              multiple
              value={state.membersSelected}
              displayEmpty
              inputProps={{ name: "membersSelected" }}
              MenuProps={{
                ...MenuProps,
                autoFocus: false,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              onChange={(e) => handleChangeMemberNames(e)}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "";
                }
                const selected_member_names = selected
                  .map((selectedId) =>
                    memberDetails.filter(
                      (member) => member.userID === selectedId
                    )
                  )
                  .map((item) =>
                    item[0] === undefined || item[0].length === 0
                      ? ""
                      : item[0].firstName
                  );
                return `
                      ${
                        selected_member_names === false
                          ? ""
                          : selected_member_names
                      }
                    `;
              }}
              IconComponent={dropdown}
            >
              <ListItem
                classes={{
                  root: classes.searchRoots,
                }}
              >
                <TextField
                  value={state.search_query_for_members}
                  name="search_query_for_members"
                  onChange={handleChangeMemberNames}
                  placeholder="Search Members"
                />
              </ListItem>

              {memberDetails &&
                filteredAssignToMembers.map((member, index) => (
                  <ListItem
                    title={member.firstName + " " + member.lastName}
                    key={member.userID}
                    value={member.userID}
                    className={classes.memberListItem}
                    disableGutters
                  >
                    <Checkbox
                      color="primary"
                      checked={
                        state.membersSelected.indexOf(member.userID) > -1
                      }
                    />
                    <ListItemText
                      primary={member.firstName + " " + member.lastName}
                      disableTypography={true}
                    />
                  </ListItem>
                ))}
            </Select>
          </FormControl>

          <FormControl
            // className={classes.formControl}
            className="dropdown-styles"
          >
            <InputLabel id="demo-simple-select-helper-label">
              Case Status
            </InputLabel>
            <Select
              disableUnderline={true}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={caseStatus}
              onChange={handleChangeCaseStatus}
              MenuProps={{
                autoFocus: false,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              IconComponent={dropdown}
            >
              {caseStatusValues.map((status, index) => (
                <MenuItem
                  key={index}
                  value={status.value}
                  className={classes.optionsFontSize}
                >
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            // className={classes.formControl}
            className="dropdown-styles"
          >
            <InputLabel id="demo-simple-select-helper-label">
              Stain Type
            </InputLabel>
            <Select
              disableUnderline={true}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={stain_type}
              onChange={handleChangeStainType}
              MenuProps={{
                autoFocus: false,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              IconComponent={dropdown}
            >
              {uniqueStainTypes.map((tag, index) => (
                <MenuItem
                  value={tag.label}
                  key={index}
                  className={classes.optionsFontSize}
                >
                  {tag.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            // className={classes.dateModified}
            className="dropdown-styles"
          >
            <InputLabel id="demo-simple-select-helper-label">
              Date Modified
            </InputLabel>
            <Select
              disableUnderline={true}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={date_modified}
              onChange={handleChangeDate}
              MenuProps={{
                autoFocus: false,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              IconComponent={dropdown}
            >
              {dateModifiedValues.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.value}
                  className={classes.optionsFontSize}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="prompt-bottom">
            <SecondaryButton label="Reset" onClick={handleClear} />
            <PrimaryButton
              label="Apply"
              onClick={handleApply}
              extraClass="prompt-done"
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdvancedSearch;
