import React, { useState, useEffect } from "react";
import TimeAgo from "handy-timeago";
import NotificationType from "./NotificationType";
import NotificationActionType from "./NotificationActionType";
import avatarSrc from "../../../assets/icons/default-avatar.svg";
import AppLink from "./link";
import {
  changeNotificationStatusAction,
  getNotificationsAction,
} from "../actions/notification-actions";
import { connect } from "react-redux";
import ToolTip from "react-tooltip";
import { withRouter } from "react-router-dom";

const NotificationList = (props) => {
  let {
    notify_by,
    notifyByName,
    notify_by_surname,
    firstName,
    lastName,
    notify_time,
    type,
    user_username,
    postid_token,
    file_folder,
    featureSubType,
    thumbnail,
    fileName,
    notifyTime,
    status,
    notifyId,
    updateNotificationStatus,
    getNotificationList,
    setNotifications,
    slideData,
    groupInvitationId,
    invitedGroupId,
    history,
    notificationList,
    userImageSrc,
    groupName,
  } = props;
  const [clicked, setClicked] = useState(false);
  const handleNotificationClick = (notifyId) => {
    setClicked(true);
    updateNotificationStatus(notifyId);
    getNotificationList();
  };
  const convertUTCDateToLocalDate = (date) => {
    let newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    const time = date - 60 * 1000;
    // let offset = date.getTimezoneOffset() / 60;
    // let hours = date.getHours();
    // newDate.setHours(hours - offset);
    return time;
  };

  const loadInViewer = (slideData) => {
    const { dzi_key_folder, dzi_thumbnail } = slideData;
    history.push({
      pathname: `/viewer`,
      search: `?image=${dzi_key_folder}&thumb=${dzi_thumbnail}&isAnalysis=true`,
    });
  };

  return (
    <div
      className={`noti follow_noti ${
        status === "read" || clicked === true
          ? "read"
          : status === "unread" || clicked === false
          ? "unread"
          : "read"
      }`}
      onClick={() => handleNotificationClick(notifyId)}
    >
      <div className="noti_left">
        {<img src={userImageSrc || avatarSrc} className="noti_avatar" />}

        {type === "slidebox" && slideData && (
          <a
            className="noti_bold"
            data-tip={slideData && slideData.slide_original_name}
            data-for="tooltip"
            onClick={() => loadInViewer(slideData)}
          >
            {slideData && slideData.slide_original_name.length > 12
              ? slideData.slide_original_name.substring(0, 12) + "..."
              : slideData.slide_original_name}{" "}
          </a>
        )}

        {type === "case" && (
          <a
            className="noti_bold"
            label={slideData && slideData.slide_original_name}
            onClick={() => loadInViewer(slideData)}
          />
        )}
        <NotificationType
          featureSubType={featureSubType}
          user_username={user_username}
          file_name={featureSubType}
          notifyByName={notifyByName}
          notificationType={type}
          groupName={groupName}
        />
        <span className="noti_time">
          {TimeAgo(convertUTCDateToLocalDate(new Date(notifyTime)))}
        </span>
      </div>

      <NotificationActionType
        details={{ ...props }}
        setNotifications={setNotifications}
      />
      <ToolTip id="tooltip" place="bottom" type="dark" effect="solid" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  notificationList: state.Notifications.notificationList,
  showLoader: state.Notifications.showLoader,
});

const mapDispatchToProps = (dispatch) => ({
  updateNotificationStatus: (id) =>
    dispatch(changeNotificationStatusAction(id)),
  getNotificationList: () => dispatch(getNotificationsAction()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationList)
);
