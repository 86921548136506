import React, { Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Bell from '../../assets/icons/bell.svg';
import DefaultAvatar from '../../assets/icons/default-avatar.svg';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import PDFViewer from '../../pages/profile/components/PDFViewer';
import pdfReaderImg from '../../assets/icons/pdf-reader.svg';

const HeaderTopLinks = (props) => {
  const [imageUrl, setImageUrl] = useState('');
  const { userProfileImage } = props;
  let id = 2;
  const [len, setLen] = useState(0);
  useEffect(() => {
    setLen(props.len);
  }, [props.len]);

  const [openUserGuideModal, setOpenUserGuideModal] = React.useState(false);

  const handleUserGuideModalOpen = () => {
    setOpenUserGuideModal(!openUserGuideModal);
  };
  const handleUserGuideModalClose = () => {
    setOpenUserGuideModal(false);
  };

  return (
    <Fragment>
      <PDFViewer
        openModal={openUserGuideModal}
        onClose={handleUserGuideModalClose}
      />
      <IconButton
        color="info"
        aria-label="info"
        component="span"
        onClick={handleUserGuideModalOpen}
      >
        <img src={pdfReaderImg} alt="" />
      </IconButton>
      <IconButton
        color="info"
        aria-label="info"
        component="span"
      >
        <NavLink
          to="/notifications"
          activeClassName="ha_active"
          className="notification"
        >
          {props.len ? (
            <span className="notification_span nav_icon" data-badge={len}>
              <img className="bell-image" src={Bell} />
            </span>
          ) : (
            <span className="notification_empty_span nav_icon">
              <img className="bell-image" src={Bell} />
            </span>
          )}
        </NavLink>
      </IconButton >
      <a
        activeClassname="ha_active"
        className="sp"
        onClick={(e) => props.toggleOptions(e)}
      >
        <img
          src={userProfileImage !== null ? userProfileImage : DefaultAvatar}
          alt="avatar"
          className="sp_img"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = { DefaultAvatar };
          }}
          id="profile-nav-image"
        />
      </a>
    </Fragment >
  );
};

const mapStateToProps = (state) => ({
  len:
    state.Notifications.unreadNotificationList !== undefined &&
    state.Notifications.unreadNotificationList.length,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTopLinks);
