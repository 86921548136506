import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import axios from '../../../utils/axiosInstance';
import {
  setFile,
  addMessage,
} from '../../../pages/groups/actions/groups-action';
import mime from 'mime-types';
import { Notify } from '../../index';
import { Picker } from 'emoji-mart';
import emojiIcon from '../icons/emoji.svg';
import ClosePopupIcon from '../../../assets/icons/close-new.svg';
import ShareIcon from '../icons/share-icon.svg';
import PdfIcon from '../../../assets/icons/pdf-icon-new.svg';
import DocIcon from '../../../assets/icons/DocIcon-new.svg';
import CsvIcon from '../../../assets/icons/csvIcon-new.svg';
import xlsIcon from '../../../assets/icons/xls.svg';

const ChatFile = ({ file, dispatch, selectedBoard, isMobile, fileRef }) => {
  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (file && file.type.includes('image')) {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', () => {
        imgRef.current.src = fileReader.result;
      });
      fileReader.readAsDataURL(file);
    }
    if (file) {
      const nameArray = file.name.split('.');
      nameArray.pop();
      setName(nameArray.join('.'));
    }
  }, [file]);

  const close = () => {
    dispatch(setFile(null));
    fileRef.current.value = null;
  };

  const sendMsg = async () => {
    if (!loading) {
      setLoading(true);
      const formData = new FormData();
      formData.append('tumorBoardId', selectedBoard.id);
      formData.append('msg', text);
      formData.append('img', file);
      const { status, data } = await axios
        .post('/api/groups/msgs', formData, { withCredentials: true })
        .catch(() => {
          Notify({ value: 'Could not send message. ' });
        });
      if (status === 201) {
        dispatch(
          addMessage({
            email: data.sender.email,
            firstName: data.sender.firstname,
            surname: data.sender.surname,
            username: data.sender.username,
            id: data.msgId,
            board: { id: selectedBoard.id },
            type: data.msg.group_message_type,
            time: data.msg.group_message_time,
            body: data.msg.group_message_msg,
            userId: data.sender.id,
            img: data.msg.group_message_img,
            status: data.msg.group_message_status,
            filename: data.msg.group_message_filename,
            userImageSrc: data.sender.userImageSrc,
          })
        );
      }
      setText('');
      dispatch(setFile(null));
      setLoading(false);
    }
  };

  return (
    <div id="create-board-overlay">
      <div id="create-board">
        {loading && <div id="loading-file">Sending...</div>}
        <div id="file-div">
          <div
            id="create-board-header"
          >
            <div className="create-board-header-icon">
              <img src={ShareIcon} alt="share" />
              <p id="create-board-title">Send File</p>
            </div>
            <img
              src={ClosePopupIcon}
              id="close-create-board"
              onClick={() => close()}
              style={{
                cursor: 'pointer',
                height: '12px',
                width: '12px',
              }}
            ></img>
          </div>
          <div
            id="edit-board-content"
            style={{
              flexDirection: 'column',
            }}
          >
            {file.type.includes('image') && (
              <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginBottom: '15px' }}>
                <img
                  alt="preview"
                  id="preview"
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '160px',
                    marginBottom: '20px',
                    border: '1px solid #eee'
                  }}
                  ref={imgRef}
                />
                {mime.extension(file.type) && (
                  <p className="file-name">{name}.{mime.extension(file.type)}</p>
                )}
              </div>
            )}
            {!file.type.includes('image') && (
              <div style={{ display: 'flex' }}>
                {
                  mime.extension(file.type) === 'docx' ?
                    (
                      <img src={DocIcon} alt="doc" />
                    ) : mime.extension(file.type) === 'csv' ? (
                      <img src={CsvIcon} alt="csv" />
                    ) : mime.extension(file.type) === 'pdf' ? (
                      <img src={PdfIcon} alt="pdf" />
                    ) : (
                      <img src={xlsIcon} alt="xlsIcon" />
                    )
                }
                <div style={{ margin: 'auto 0' }}>
                  <p style={{ fontWeight: 'bold' }}>This file cannot be previewed</p>
                  {mime.extension(file.type) && (
                    <p>{name}.{mime.extension(file.type)}</p>
                  )}
                </div>
              </div>
            )}
            <div
              id="sender-container"
              style={{ position: 'relative', marginTop: '10px' }}
            >
              <input
                placeholder="Send a message"
                id="input-send"
                value={text}
                ref={inputRef}
                onChange={() => setText(inputRef.current.value)}
              ></input>
              <div id="buttons-send">
                <img
                  src={emojiIcon}
                  alt="emoji"
                  style={{ width: '20px', height: '20px' }}
                  className="attach"
                  onClick={() => setShowPicker(!showPicker)}
                />
              </div>
            </div>
          </div>

          <div className="share-btn">
            <button id="create-btn" onClick={() => sendMsg()}>
              Share
            </button>
          </div>
          {showPicker && !isMobile && (
            <Picker onSelect={(emoji) => setText(text + emoji.native)} />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { file, selectedBoard } = state.Groups;
  const { isMobile, windowSize } = state.Groups;
  return {
    file,
    selectedBoard,
    isMobile: isMobile || windowSize < 992,
  };
};

export default connect(mapStateToProps)(ChatFile);
