import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FolderDirActiveIcon from "../../../../assets/icons/folder-dir-open.svg";
import FolderDirInactiveIcon from "../../../../assets/icons/folder-close-new.svg";
import CaseDirActiveIcon from "../../../../assets/icons/case-open.svg";
import CaseDirInActiveIcon from "../../../../assets/icons/case-close.svg";
import AddIcon from "../../../../assets/icons/add_new_folder.svg";
import ImageTag from "./ImageTag";
import { keysObject, valuesObject } from "../../../../utils/utils";
import ToolTip from "react-tooltip";
import Can from "../../../../hoc/FullControllHoc/can";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    marginLeft: 12,
  },
})((props) => (
  <Menu
    elevation={5}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const CreateButton = ({
  createDir,
  createDirDisable,
  slideboxLeftSiderIsCollapse,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [FolderDirHoverIcon, setFolderDirHoverIcon] = React.useState(
    FolderDirInactiveIcon
  );
  const [CaseDirInHoverIcon, setCaseDirInHoverIcon] = React.useState(
    CaseDirInActiveIcon
  );

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const folderDirHoverIconFn = (icon) => setFolderDirHoverIcon(icon);

  const caseDirInHoverIconFn = (icon) => setCaseDirInHoverIcon(icon);

  const ButtonAttributes = {
    Case: {
      onClick: (e) => {
        createDir(e, "case"), handleClose();
      },
      onMouseEnter: () => caseDirInHoverIconFn(CaseDirActiveIcon),
      onMouseLeave: () => caseDirInHoverIconFn(CaseDirInActiveIcon),
      icon: CaseDirInHoverIcon,
    },
  };

  return (
    <Can
      task={"create-case"}
      taskContainer="slidebox"
      edit={() => (
        <div
          className={`create-button-container${
            createDirDisable ? " disable-btn-create" : ""
          }`}
        >
          <button
            className={
              !slideboxLeftSiderIsCollapse
                ? "new-button-collapse"
                : "new-button"
            }
            aria-controls="customized-menu"
            aria-haspopup="true"
            onClick={handleClick}
            data-for="viewer-tooltip"
            data-tip="New"
            data-tip-disable={slideboxLeftSiderIsCollapse}
          >
            <ImageTag
              className={
                !slideboxLeftSiderIsCollapse
                  ? "plus-icon-collapse"
                  : "plus-icon"
              }
              src={AddIcon}
            />

            {slideboxLeftSiderIsCollapse && <span>New</span>}
          </button>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            // className="dropdown-li--test"
          >
            {valuesObject(ButtonAttributes).map(({ icon, ...rest }, index) => (
              <StyledMenuItem className="dropdown-li" {...rest} key={index}>
                <ListItemIcon>
                  <ImageTag src={icon} className="dropdown-menu-icon" />
                </ListItemIcon>
                <ListItemText
                  className="new-dir"
                  primary={keysObject(ButtonAttributes)[index]}
                />
              </StyledMenuItem>
            ))}
          </StyledMenu>
          <ToolTip
            id="viewer-tooltip"
            place="bottom"
            type="dark"
            effect="solid"
          />
        </div>
      )}
    />
  );
};

export default CreateButton;
