import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { MedicalTags } from "../../../config/constants";
import { StainTags } from "../../../config/constants";
import _ from "lodash";


const customStyles = {
  container: provided => ({
    ...provided,
    marginTop: 20,
    backgroundColor: "#eee",
    border: "none",
    outline: "none",
    borderRadius: 5
  }),
  control: provided => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    backgroundColor: "#eee",
    border: "none",
    outline: "none",
    borderRadius: 5
  })
};

const defaultStyles = {
  container: provided => ({
    ...provided
  }),
  control: provided => ({
    // none of react-select's styles are passed to <Control />
    ...provided
  })
};

export default class SingleSelect extends Component {
  constructor(props) {
    super(props);
    const wait = 2000; // milliseconds
    const loadOptions = inputValue => this.getAsyncOptions(inputValue);
    this.debouncedLoadOptions = _.debounce(loadOptions, wait);
  }

  getAsyncOptions = inputValue => {
    if (inputValue) {
      const tagType = this.props.type === "stains" ? StainTags : MedicalTags;
      return new Promise(resolve => {
        setTimeout(() => {
          const filtered = _.filter(tagType, o =>
            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
          );
          // return resolve(filtered.slice(0, 1))
          if (filtered.length > 0) {
            resolve(filtered.slice(0, 1));
          } else {
            resolve([{ value: inputValue, label: inputValue }]);
          }
        }, 100);
      });
    }
  };

  render() {
    return (
      <AsyncSelect
        isMulti={this.props.type === "stains" ? false : true}
        className={this.props.classname}
        classNamePrefix="select"
        name="search"
        loadOptions={this.getAsyncOptions}
        onChange={this.props.handleChange}
        placeholder={this.props.placeholder || "Select..."}
        styles={this.props.customStyles ? customStyles : defaultStyles}
        defaultValue={this.props.defaultValue}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
      />
    );
  }
}
