import React, { useState, useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Notify } from "../../../components";

import SlideboxHeader from "./SlideboxHeader";
import SlideboxListView from "./SlideboxListView";
import * as slideboxAction from "../actions/slidebox-action";
import CreateDirectory from "./SlideItems/CreateDirectory";
import DeleteConfirm from "./SlideItems/DeleteConfirm";
import SharedPopup from "./SlideTable/SharedPopup";
import { SocketContext } from "../../../utils/serviceContext";
import GroupsSelection from "./SlideTable/AddToGroupPopup";
import MoveToPopUp from "./SlideTable/MoveToPopUp";
import Details from "./SlideItems/Details";
import AssignUserPopup from "./SlideTable/AssignUserPopup";
import SlideboxSearch from "./SlideboxSearch";
import * as dashBoardAction from "../../adminDashboard/actions/adminDashboard-action";
import * as globalAction from "../../../actions/global-action";
import { useOutsideClick } from "../../../utils/utils";
const SlideBoxDataSection = ({
  slideDirectory,
  slideDirDataList,
  showBreadCrumbIcon,
  showLoader,
  selectedList,
  selectedListType,
  selectedListName,
  selectedFolder,
  selectedFolderType,
  selectedFolderName,
  loadSlideboxDirDataList,
  breadcrumbAction,
  deleteSlideBoxItemAction,
  setSelectedListDirectory,
  setSelectedDirCollaborators,
  setSelectedDirAssignUsers,
  setOpenListDirectory,
  isSearch,
  userDetails,
  selectedListSlideData,
  dirDataList,
  setSelectedSlideData,
  movePopupState,
  setSlideBoxMovePopupAction,
  setSelectedListActivity,
  loadSlideboxDirectory,
  isListView,
  getSearchDirData,
  selectedFolderId,
  getSearchData,
  folderType,
  getUserListDataAction,
  getUserDetailsAction,
  dxAccountUsers,
  getStainTypeAction,
  stainType,
  isModal,
  getFileDownloadUrlAction,
  deselectListDirAction,
  setIsFolderSelected,
  resetMoveDirActive,
  isMoveDirActive,
  when,
  setContinueButtonDisabled,
  setSelectedSlideDataToAnalyze,
  importSlide,
  selectedSlideDataToAnalyze,
}) => {
  const listRef = useRef();
  const socket = useContext(SocketContext);
  const [openDirData, setOpenDirData] = useState([]); // OpenDirData contains list of nested open directories to display on breadcrumb
  const [openCreateDir, setOpenCreateDir] = useState(false); // to check if create/rename directory modal is open
  const [dirType, setdDirType] = useState(null);
  const [openDeleteDir, setOpenDeleteDir] = useState(false);
  const [openSharedPopup, setOpenSharedPopup] = useState(false);
  const [showGroupsSelectionPopup, setShowGroupsSelectionPopup] = useState(
    false
  );
  const [outSideList, setOutSideList] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [openAssignUserPopup, setOpenAssignUserPopup] = useState(false);
  const [switchView, setSwitchView] = useState(isListView);
  const [filteredData, setFilteredData] = useState(slideDirDataList);
  const [label, setLabel] = useState("");
  const [radioButtonChecked, setRadioButtonChecked] = useState({});

  useOutsideClick(listRef, () => {
    deselectListDirAction();
    setRadioButtonChecked({});
  });

  useEffect(() => {
    getUserDetailsAction();
    getUserListDataAction();
    getStainTypeAction();
    getUserDetailsAction();
  }, []);

  //
  useEffect(() => {
    if (slideDirectory.length > 0) {
      if (!isMoveDirActive) {
        const openDirs = mapOpenDirData(slideDirectory);
        setOpenDirData(openDirs);
      } else {
        resetMoveDirActive(false);
      }
    }
  }, [slideDirectory]);

  // reloads slidebox when conversion is done
  useEffect(() => {
    getUserDetailsAction();
    socket.onConversionDone().subscribe(() => {
      loadSlideboxDirDataList({
        dirId: selectedFolder,
        dirType: selectedFolderType ? dirType : "root",
      });
    });
  }, []);

  // set the filtered results in filterData State
  useEffect(() => {
    setFilteredData(slideDirDataList);
  }, [setFilteredData, slideDirDataList]);

  // set list of all nested open directories to display on breadcrumb
  const mapOpenDirData = (dirs) => {
    const openDirs = dirs.filter((dirData) => dirData.selected);
    return openDirs.length > 0 ? openDirs : [dirs[0]];
  };

  // set state to declare the create directory modal is closed
  const createDirOnClose = () => {
    setOpenCreateDir(false);
  };

  // to load the contents of the folder/case entered into via Breadcrumb or ListView
  const toggleDirHandler = ({ dirId, parentDir, dirType }) => {
    console.log(dirDataList);
    setSelectedDetails({});
    const folderListId = slideDirectory.filter(
      (dirData) => dirData.dirId === dirId && dirData.caseOrFolder === dirType
    );
    loadSlideboxDirDataList({
      dirId: dirId,
      dirType: dirType ? dirType : "root",
    });
    setOpenListDirectory({
      dirId,
      listId: folderListId[0]["listId"],
      parentDir,
      dirType: dirType ? dirType : "root",
    });

    breadcrumbAction();
    getUserDetailsAction();
  };

  // setter function for selecting folder or case from the list view
  const setSelectedDir = ({ dirId, parentDir, dirType }) => {
    const folderListId = slideDirectory.filter(
      (dirData) => dirData.dirId === dirId && dirData.caseOrFolder === dirType
    );
    setSelectedListDirectory({
      dirId,
      listId: folderListId.length > 0 ? folderListId[0]["listId"] : dirId,
      parentDir,
      dirType: dirType,
    });

    setSelectedListActivity({
      dirId,
      dirType,
    });

    if (dirType === "case" || dirType === "folder") {
      setSelectedDirCollaborators({
        dirId,
        dirType: dirType,
      });
      if (dirType === "case") {
        setSelectedDirAssignUsers({
          dirId,
          dirType: dirType,
        });
      }
      if (isModal) {
        setIsFolderSelected(true);
      }
    }

    if (dirType === "slide") {
      const slideData = dirDataList.filter(
        (dirData) =>
          dirData.dirDataId === dirId && dirData.caseOrFolder === "slide"
      );
      if (isModal) {
        setIsFolderSelected(false);
      }
      setSelectedSlideData(slideData[0]);
    }
  };

  // delete case/folder/slide modal
  const deleteDirItemPopup = (outSideListSelect = false) => {
    getUserDetailsAction();
    setOutSideList(outSideListSelect);
    setOpenDeleteDir(!openDeleteDir);
  };

  // handler for deleting case/folder/
  const handleDeleteDirItem = (e) => {
    setShowDetails(false);
    deleteSlideBoxItemAction(
      outSideList ? selectedFolderType : selectedListType,
      {
        dirId: outSideList ? selectedFolder : selectedList,
      },
      {
        onSuccess: afterDirDeleteSuccess,
        onError: afterDirDeleteFail,
      }
    );
    getUserDetailsAction();
  };

  const renameSlideItem = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    setOpenCreateDir(true);
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const afterDirDeleteSuccess = () => {
    Notify({
      value: `${capitalizeFirstLetter(selectedListType)} deleted successfully`,
    });
    // if (selectedListType === "folder") {
    //   loadSlideboxDirectory();
    //   getUserDetailsAction();
    // }
    getUserDetailsAction();

    deleteDirItemPopup(false);
  };

  const afterDirDeleteFail = () => {
    Notify({
      value: `${capitalizeFirstLetter(selectedListType)} deletion failed`,
    });
    deleteDirItemPopup(false);
  };

  const showDirDetails = (outSideListSelect = false) => {
    getUserDetailsAction();
    setSelectedListActivity({
      dirId: selectedList,
      dirType: selectedListType,
    });
    setOutSideList(outSideListSelect);
    setShowDetails(!showDetails);
  };

  const showSharedPopup = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    setOpenSharedPopup(!openSharedPopup);
  };

  const handleToggleMovePopup = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    setSlideBoxMovePopupAction(!movePopupState);
  };

  const showGroupsSelectPopup = () => {
    setShowGroupsSelectionPopup(!showGroupsSelectionPopup);
  };

  const showAssignUserPopup = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    setOpenAssignUserPopup(!openAssignUserPopup);
  };

  const downloadSlide = (outSideListSelect = false) => {
    setOutSideList(outSideListSelect);
    let downloadFilePath;
    if (selectedDetails.caseOrFolder === "slide") {
      downloadFilePath = `${selectedDetails.slidePath}/${selectedDetails.dirDataOriName}`;
    }
    if (selectedDetails.caseOrFolder === "document") {
      downloadFilePath = selectedDetails.slidePath;
    }
    getFileDownloadUrlAction(
      { filePath: downloadFilePath },
      {
        onSuccess: downloadFile,
        onError: downloadFileFail,
      }
    );
  };

  const downloadFile = (newFileUrl) => {
    let element = document.createElement("a");
    element.setAttribute("href", newFileUrl);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const downloadFileFail = () => {
    Notify({ value: `${selectedListType} download failed` });
  };

  return (
    <div
      className={`${
        importSlide
          ? "slideboxData-container analysis-table"
          : "slideboxData-container"
      }`}
      ref={listRef}
    >
      <div className="slidebox-search-container">
        <SlideboxSearch
          getSearchDirData={getSearchDirData}
          selectedListType={selectedListType}
          loadSlideboxDirDataList={loadSlideboxDirDataList}
          folderType={folderType}
          selectedFolderId={selectedFolderId}
          getSearchData={getSearchData}
          slideDirDataList={slideDirDataList}
          setFilteredData={setFilteredData}
          memberDetails={dxAccountUsers}
          slideDirectory={slideDirectory}
          stainType={stainType}
          isModal={isModal}
          filteredData={filteredData}
          isListView={isListView}
          label={label}
          setLabel={setLabel}
          setRadioButtonChecked={setRadioButtonChecked}
        />
      </div>

      <SlideboxHeader
        {...{
          showBreadCrumbIcon,
          toggleDirHandler,
          openDirData,
          renameSlideItem,
          deleteDirItem: deleteDirItemPopup,
          selectedListType,
          selectedListName,
          isSearch,
          userDetails,
          selectedList: outSideList
            ? slideDirectory.find(
                ({ dirId, caseOrFolder }) =>
                  dirId === selectedFolder &&
                  caseOrFolder === selectedFolderType
              )
            : slideDirDataList.find(
                ({ dirDataId, caseOrFolder }) =>
                  dirDataId === selectedList &&
                  caseOrFolder === selectedListType
              ),
          showSharedPopup: showSharedPopup,
          showAssignUserPopup,
          showDetails,
          setShowDetails: showDirDetails,
          setSelectedDetails,
          handleToggleMovePopup,
          showGroupsSelectPopup,
          outSideList,
          switchView,
          setSwitchView,
          showLoader,
          slideDirDataList,
          setFilteredData,
          downloadSlide,
          isModal,
          listRef,
          isListView,
          when,
          importSlide,
        }}
      />
      <div className={`slidebar${showDetails ? " active" : ""}`}>
        <SlideboxListView
          slideDirDataList={slideDirDataList}
          showLoader={showLoader}
          toggleDirHandler={toggleDirHandler}
          setSelectedDir={setSelectedDir}
          isSearch={isSearch}
          showSharedPopup={showSharedPopup}
          deleteDirItemPopup={deleteDirItemPopup}
          renameSlideItem={renameSlideItem}
          handleToggleMovePopup={handleToggleMovePopup}
          setShowDetails={showDirDetails}
          selectedListItem={slideDirDataList.find(
            ({ dirDataId, caseOrFolder }) =>
              dirDataId === selectedList && caseOrFolder === selectedListType
          )}
          showGroupsSelectPopup={showGroupsSelectPopup}
          switchView={switchView}
          setSwitchView={setSwitchView}
          selectedListType={selectedListType}
          selectedList={selectedList}
          selectedFolderType={selectedFolderType}
          isListView={isListView}
          downloadSlide={downloadSlide}
          filteredData={filteredData}
          isModal={isModal}
          when={when}
          setContinueButtonDisabled={setContinueButtonDisabled}
          setSelectedSlideDataToAnalyze={setSelectedSlideDataToAnalyze}
          importSlide={importSlide}
          selectedSlideDataToAnalyze={selectedSlideDataToAnalyze}
          getSearchDirData={getSearchDirData}
          setLabel={setLabel}
          radioButtonChecked={radioButtonChecked}
          setRadioButtonChecked={setRadioButtonChecked}
        />
      </div>
      {when !== "analysis" && openCreateDir && (
        <CreateDirectory
          dirType={outSideList ? selectedFolderType : selectedListType}
          isFile={
            outSideList
              ? false
              : selectedListType === "slide" || selectedListType === "document"
              ? true
              : false
          }
          rename={true}
          onClose={createDirOnClose}
          selectedDir={{
            slideItemId: outSideList ? selectedFolder : selectedList,
            name: outSideList ? selectedFolderName : selectedListName,
          }}
          selectedFolderType={selectedFolderType}
          selectedFolder={selectedFolder}
        />
      )}
      {openDeleteDir && (
        <DeleteConfirm
          dirType={outSideList ? selectedFolderType : selectedListType}
          isFile={outSideList ? false : selectedListType ? false : true}
          handleDelete={handleDeleteDirItem}
          modalClose={() => deleteDirItemPopup(false)}
        />
      )}
      {openSharedPopup && (
        <SharedPopup
          onClose={setOpenSharedPopup}
          selectedListType={outSideList ? selectedFolderType : selectedListType}
          selectedList={outSideList ? selectedFolder : selectedList}
        />
      )}
      {openAssignUserPopup && (
        <AssignUserPopup
          onClose={setOpenAssignUserPopup}
          selectedListType={outSideList ? selectedFolderType : selectedListType}
          selectedList={outSideList ? selectedFolder : selectedList}
        />
      )}
      {showGroupsSelectionPopup && (
        <GroupsSelection
          dialogOpen={showGroupsSelectionPopup}
          onClose={showGroupsSelectPopup}
          selectedFile={selectedListSlideData}
        />
      )}
      {movePopupState && (
        <MoveToPopUp
          outSideList={outSideList}
          onClose={() => handleToggleMovePopup()}
        />
      )}
      {selectedDetails && (
        <Details
          {...{
            showDetails,
            setShowDetails,
            selectedDetails,
            getStainTypeAction,
            setSelectedDetails,
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  slideDirectory: state.SlideBox.folders,
  slideDirDataList: state.SlideBox.dirDataList,
  showBreadCrumbIcon: state.SlideBox.breadCrumbIcon,
  showLoader: state.SlideBox.showLoader,
  isSearch: state.SlideBox.isSearchData,
  userDetails: state.Global.loggedUserDetails,
  selectedRightClickItem: state.SlideBox.selectedRightClickItem,
  selectedListSlideData: state.SlideBox.selectedListSlideData,
  dirDataList: state.SlideBox.dirDataList,
  movePopupState: state.SlideBox.movePopupState,
  isListView: state.SlideBox.isListView,
  selectedFolderId: state.SlideBox.selectedFolderData.selectedFolder,
  folderType: state.SlideBox.selectedFolderData.selectedFolderType,
  dxAccountUsers: state.SlideBox.userList,
  stainType: state.SlideBox.stainType,
  isMoveDirActive: state.SlideBox.isMoveDirActive,
  ...state.SlideBox.selectedFolderData,
  ...state.SlideBox.selectedListData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...slideboxAction, ...dashBoardAction, ...globalAction },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlideBoxDataSection);
