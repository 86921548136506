import React from "react";

import RootDirActiveIcon from "../../../../assets/icons/root-dir-active-new.svg";
import RootDirWhite from "../../../../assets/icons/root-dir-white.svg";
import RootDirInActiveIcon from "../../../../assets/icons/root-dir-inactive-new.svg";
import DirOpenIcon from "../../../../assets/icons/dir-open.png";
import DirCloseIcon from "../../../../assets/icons/dir-close.png";
import ChildDirectory from "./ChildDirectory";
import classnames from "classnames";
import ToolTip from "react-tooltip";

const RootDirectory = ({
  open,
  active,
  dirName,
  dirId,
  listId,
  children,
  rootDirOpen,
  toggleDir,
  setSelectedDir,
  setExpandDir,
  slideboxLeftSiderIsCollapse,
}) => {
  return (
    <React.Fragment>
      <div
        className={classnames(
          active ? "rootDirectory-container active" : "rootDirectory-container",
          !slideboxLeftSiderIsCollapse
            ? `${active
              ? "rootDirectory-collapse-btn active"
              : "rootDirectory-collapse-btn"
            }`
            : ""
        )}
        onClick={() =>
          setSelectedDir({ dirId, listId, parentDir: null, dirType: null })
        }
        onDoubleClick={() =>
          toggleDir({ dirId, listId, parentDir: null, dirType: null })
        }
        data-for="viewer-tooltip"
        data-tip={dirName}
        data-tip-disable={slideboxLeftSiderIsCollapse}
        data-test="root-container"
      >
        {slideboxLeftSiderIsCollapse && (
          <img
            data-test="dirIcon"
            src={open ? DirOpenIcon : DirCloseIcon}
            className="dir-open-close"
            onClick={() =>
              setExpandDir({ dirId, listId, parentDir: null, dirType: null })
            }
          />
        )}

        {slideboxLeftSiderIsCollapse ? (
          <img
            data-test="dirIcon"
            src={active ? RootDirActiveIcon : RootDirInActiveIcon}
            className={classnames(
              "root-dir",
              !slideboxLeftSiderIsCollapse &&
              `${active
                ? "rootDirectory-collapse-img active"
                : "rootDirectory-collapse-img"
              }`
            )}
          />
        ) :
          (
            <img
              data-test="dirIconWhite"
              src={RootDirWhite}
              className={classnames(
                "root-dir",
                !slideboxLeftSiderIsCollapse &&
                `${active
                  ? "rootDirectory-collapse-img active"
                  : "rootDirectory-collapse-img"
                }`
              )}
            />
          )}

        {slideboxLeftSiderIsCollapse && dirName}
        {
          slideboxLeftSiderIsCollapse ?
            <span style={{ paddingLeft: '5px' }} data-test="children">({children.length})</span>
            : ''
        }
      </div>
      {children.length > 0 && rootDirOpen && slideboxLeftSiderIsCollapse && (
        <ChildDirectory
          children={children}
          toggleDir={toggleDir}
          setSelectedDir={setSelectedDir}
          setExpandDir={setExpandDir}
          slideboxLeftSiderIsCollapse={slideboxLeftSiderIsCollapse}
        />
      )}
      <ToolTip id="viewer-tooltip" place="bottom" type="dark" effect="solid" data-test="toopTip" />
    </React.Fragment>
  );
};

export default RootDirectory;
