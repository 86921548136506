import React from "react";
import ToolTip from "react-tooltip";
import { connect } from "react-redux";
import Notify from "handy-notification";
import {
  clearNotificationAction,
  getNotificationsAction,
} from "./actions/notification-actions";
import "./notification.scss";
import closeIcon from '../../assets/icons/close-new.svg';

const NotificationsHeader = ({ len, clearNotifications }) => {
  let clear = () => {
    clearNotifications();
    Notify({ value: "Notifications cleared!!" });
  };

  return (
    <div className="notifications_header">
      <span className="noti_count">
        {len === 0
          ? ""
          : len === 1
            ? `${len} notification`
            : `${len} notifications`}
        {""}
      </span>

      {len != 0 && (
        <div>
          <span
            onClick={clear}
            className="clear_noti"
            data-tip="Clear notifications"
          >
            <img src={closeIcon} />
          </span>
          <ToolTip place="bottom" type="info" effect="solid" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  // len:
  //   (state.Notification.notifications || []).length +
  //   state.TumorBoards.invitations.length +
  //   state.TumorBoards.requests.length,
});

const mapDispatchToProps = (dispatch) => ({
  clearNotifications: () => dispatch(clearNotificationAction()),
  getNotificationList: () => dispatch(getNotificationsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsHeader);
