import React, { Component, Fragment } from "react";
import { Prompt } from "../../../../components";

class DeleteConfirm extends Component {
  state = {
    deleteFileOrFolder: false,
  };

  delete = (e) => {
    e.preventDefault();
    this.props.handleDelete();
  };

  render() {
    let { dirType, isFile } = this.props;
    return (
      <Fragment>
        <Prompt
          title={isFile ? "Delete file" : `Delete ${dirType}`}
          content={`This ${dirType} will be deleted. There's no undo so you won't be able to find it.`}
          actionText="Delete"
          action={this.delete}
          back={this.props.modalClose}
        />
      </Fragment>
    );
  }
}

export default DeleteConfirm;
