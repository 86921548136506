import React from "react";
import DashboardFilter from "./DashboardFilter";
import Can from "../../../../hoc/FullControllHoc/can";
import SearchIcon from "../../../../assets/icons/search-icon.svg";
import CloseIcon from "../../../../assets/icons/close-new.svg";

const UserSearch = ({
  setAddNewUser,
  searchDashboardUserListFn,
  getUserListFn,
  ...rest
}) => {
  const [search, setSearch] = React.useState("");
  const [showCloseIcon, setShowCloseIcon] = React.useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setShowCloseIcon(true);
      searchDashboardUserListFn(event.target.value);
    }
  };

  const handleSearch = () => {
    setShowCloseIcon(true);
    searchDashboardUserListFn(search);
  }

  return (
    <div className="user-list-container">
      <div className="user-list-items">
        {/* <div>
                    <h5 className="user-list-title"></h5>
                </div> */}
        <Can
          task={"manage-members"}
          taskContainer="dashboard"
          edit={() => (
            <div className="btn-group">
              {/* <button className="btn-outlined">Export Member List</button> */}
              <button
                className="btn-default"
                onClick={() => setAddNewUser(true)}
              >
                Invite People
              </button>
            </div>
          )}
        />
      </div>
      <div className="filter-wrapper">
        <div className="filter-i-input">
          <img
            src={SearchIcon}
            className="searchIcon"
            alt="search"
            onClick={() => handleSearch()}
          />
          <input
            className="filter-input"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {showCloseIcon && (
            <span
              className="search-close-icon"
              onClick={() => {
                setShowCloseIcon(false);
                setSearch("");
                getUserListFn();
              }}
            >
              <img src={CloseIcon} alt="close" />
            </span>
          )}
        </div>
        <DashboardFilter {...{ ...rest }} />
      </div>
    </div >
  );
};

export default UserSearch;
